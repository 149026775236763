import { client } from "components/services/apiService";

const url = `${process.env.REACT_APP_API_URL_USER}/login`;

// efunfun 验证
export async function getKey() {
  return await client
    .post({
      url: `${url}/get_key`,
      form: {
        // device_id: 'hnbsgp1korlq6fhtk5c1983c6h2bmprv',
      },
    })
}

// efunfun 验证
export async function verifyKey(key) {
  return await client
    .post({
      url: `${url}/verify_key`,
      form: {
        key,
        // device_id: 'hnbsgp1korlq6fhtk5c1983c6h2bmprv',
      },
    })
}

export default {
  getKey,
  verifyKey
}