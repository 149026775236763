import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import intl from "react-intl-universal";
import { Row, Col, List, Icon, Divider, Select, Empty, Skeleton } from 'antd';

import styles from "assets/css/IntegralHistory.module.scss";

import coin from "components/services/coinService";

const { Option } = Select;

const SkeletonList = () => {
  return Array.from({length: 4}, (_, i) => <div key={i}><Skeleton active /><Divider /></div>);
}

const months = () => {
  return Array.from({length: 7}, (_, i) => moment().subtract(i, "months").format("YYYYMM"));
}

const IntegralHistory = () => {
  const { user, translations } = useSelector(state => state)
  const [ loading, setLoading ] = useState(false);
  const [ monthValue, setMonthValue ] = useState(moment().format("YYYYMM"));
  const [ selectValue, setSelectValue ] = useState('all');
  const [ data, setData ] = useState({ total: 0, rows: [] });

  const lang = (value) => translations.initDone && intl.get(value);

  const getData = async (page) => {
    try {
      const params = { offset: page, month: monthValue };
      const ret = await coin.history(params).then(ret => ret).catch(() => ({ total: 0, rows: [] }));
      setData(ret);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    getData(0);
  }, [monthValue])

  // const handleSelectChange = (value) => {
  //   setSelectValue(value);
  // }

  const handleChangeMonth = (value) => {
    setMonthValue(value);
  }

  const filterData = useMemo(() => {
    return data.rows.filter(item => selectValue === 'all' ? item : !!item[selectValue]);
  }, [data, selectValue]);

  return (
    <div className={styles.wrap}>
      <Row type="flex" justify="center">
        <Col
          xl={14} lg={18} md={20} xs={22}
          className={styles.inner}
        >
          <div className={styles.title}>
            {lang('integralHistory.title')}
          </div>
          <div className={styles.main}>
            {loading ? <SkeletonList /> : (
              !!data.rows.length ? (
                <>
                  <div className={styles.action}>
                    <div className={styles.total}>
                      {lang('integralHistory.total')}
                      <br />
                      <span>{user.coin}</span>
                    </div>
                    <div>
                      <Select
                        value={monthValue}
                        style={{ width: 100 }}
                        onChange={handleChangeMonth}
                      >
                        {months().map(item => (
                          <Option key={item} value={item} >
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {/* <div>
                      <Select 
                        value={selectValue} 
                        style={{ width: 100 }}
                        onChange={handleSelectChange}
                      >
                        <Option value="all">全部</Option>
                        <Option value="add_coin">積分收入</Option>
                        <Option value="sub_coin">積分支出</Option>
                      </Select>
                    </div> */}
                  </div>
                  <Divider />
                  <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={filterData}
                    pagination={{
                      onChange: page => {
                        getData(page - 1);
                      },
                      pageSize: 10,
                      total: data.total
                    }}
                    renderItem={item => (
                      <List.Item
                        key={item.id}
                        actions={[
                          <span>
                            <Icon type="clock-circle" style={{ marginRight: 8 }}/>
                            {item.create_time}
                          </span>
                        ]}
                        extra={
                          <span style={{color: !!item.add_coin ? 'green' : 'red', fontSize: 30, fontWeight: 'bold'}}>
                            {(!!item.add_coin ? '+' : '-') + item.coin}
                          </span>
                        }
                      >
                        <List.Item.Meta
                          // avatar={<Avatar src={item.avatar} />}
                          title={
                            <span>
                              <Icon type={'read'} style={{marginRight: 8}} />
                              {item.cat_name}
                            </span>
                          }
                          description={
                            <span style={{fontSize: 20, color: 'rgba(0, 0, 0, 0.65)'}}>
                              {item.title}
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </>
              ) : <Empty />
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default IntegralHistory;