import React, { useState } from "react";
import intl from "react-intl-universal";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import toast from "components/utils/toast";

import auth_API from "components/services/authService";
import userService from "components/services/userService";

import { putChildren } from "~/store/action/family";

import styles from "../../sass/bindEviAccount.module.scss";

const { Option } = Select;

const BindEviAccount = (props) => {
  const { subNext, subPrev, setStep } = props;

  const { getFieldDecorator, validateFields } = props.form;

  const dispatch = useDispatch();

  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    user: { id, family = [], language },
    translations: { initDone },
  } = useSelector((state) => state);

  const lang = (value, params) =>
    initDone && intl.get(`register.${value}`, params);

  const loginErrors = (error) => {
    const errors = new Map().set(210, () => {
      dispatch(
        putChildren({
          language,
          family_id: id ? family[0].family_id : undefined,
          kid_token: error.data,
          callback: async (error) => {
            if (error) {
              message.error(initDone && intl.get(`general.msg.add_error`));
              return;
            }
            await userService
              .me(true)
              .then((payload) => dispatch({ type: "INIT", payload }));

            setStep(5);
          },
        })
      );
    });
    return errors.get(error.result)
      ? errors.get(error.result)()
      : message.error("此帳戶已被其他家庭綁定");
  };

  const submitHandle = (e) => {
    if (e.cancelable) e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        setSubmitLoading(true);
        const { user_name, password } = values;
        try {
          await auth_API.login(user_name, password);
          message.error("此帳戶已被其他家庭綁定");
        } catch (error) {
          loginErrors(error);
        } finally {
          setSubmitLoading(false);
        }
      }
    });
  };

  return (
    <div className={styles.root}>
      <div>
        <img
          src={require("assets/image/logo_s.png")}
          alt=""
          height="120"
          className={styles.logo}
        />

        <h1 className={styles.title}>
          {lang("step_three.subitem_step_one.title")}
        </h1>

        <h3 className={styles.describe}>
          {lang("step_three.subitem_step_one.describe")}
        </h3>
      </div>

      <Form onSubmit={submitHandle}>
        <Form.Item>
          {getFieldDecorator(`user_name`, {
            rules: [
              {
                required: true,
                message: "Please input your username!",
              },
            ],
          })(
            <Input
              placeholder={lang("step_three.subitem_step_one.username")}
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator(`password`, {
            rules: [
              {
                required: true,
                message: "Please input your password!",
              },
            ],
          })(
            <Input
              placeholder={lang("step_three.subitem_step_one.pwd")}
              type="password"
              size="large"
            />
          )}
        </Form.Item>
        {/* <Row gutter={[20, 20]}> */}
        {/* <Col span={12}>
            <Form.Item>
              <Button
                block
                className={`${styles.btn} ${styles.back}`}
                onClick={() => setStep(1)}
              >
                {lang("btn_back")}
              </Button>
            </Form.Item>
          </Col> */}
        {/* <Col span={24}> */}
        <Form.Item>
          <Button
            block
            htmlType="submit"
            loading={submitLoading}
            className={`${styles.btn} ${styles.next}`}
          >
            {lang("btn_login")}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button block className={`${styles.btn}`} onClick={() => setStep(5)}>
            {lang("btn_back")}
          </Button>
        </Form.Item>
        {/* </Col> */}
        {/* </Row> */}
      </Form>
      <h3 className={styles.account} onClick={() => setStep(2)}>
        {lang("step_three.subitem_step_one.create")}
      </h3>
    </div>
  );
};

export default Form.create()(BindEviAccount);
