import {
  client,
  setMID,
  // getMID,
  setUToken,
  getUToken,
  setLocal,
  getUUID,
} from "components/services/apiService";

const url = `${process.env.REACT_APP_API_URL_USER}/auth`;

// 登录
export async function login(name, pwd) {
  return await client
    .post({
      url: `${url}/login`,
      form: {
        username: name,
        password: pwd,
      },
    })
    .then((ret) => {
      setMID("");
      setUToken(ret);
      return ret;
    });
}

//注册
export async function register(getType, value) {
  const type = getType === "tel" ? "tel" : "email";
  return await client
    .post({
      url: `${process.env.REACT_APP_API_URL_USER}/user/register`,
      form: {
        [type]: getType === "tel" ? value.pre + value.tel : value.email,
        password: value.pwd,
        sex: value.sex,
        first_name: value.fsn,
        last_name: value.lsn,
        language : localStorage.getItem("lang"),
        family_token: value.family_token,
        region: value.region,
        // kid_token: value.kid_token || null
      },
    })
    .then((ret) => {
      setMID("");
      setUToken(ret);
      return ret;
    });
}

//验证
export async function verify(captcha, kid_token) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/user/verify`,
    form: {
      vcode: captcha,
      kid_token,
    },
  });
}

export async function deleteAccount(id) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/user/delete`,
    form: {
      user_id: id,
      action: 'DELETE'
    },
  });
}

//重发验证码
export async function resend() {
  await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/user/resend`,
    form: {},
  });
}

export async function logout() {
  return await client
    .post({
      url: `${url}/logout`,
      form: {},
    })
    .then((ret) => {
      resetUser();
      return ret;
    })
    .catch((ret) => {
      return ret.result;
    });
}

export async function checkToken(token) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/token/check_token`,
    form: {
      type: "ZOOM",
      cache: "Y",
      third_party_token: token,
    },
  });
}

export async function getUserToken(code) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/token/bind`,
    form: {
      type: "ZOOM",
      code,
    },
  });
}

export async function accountUnbind(type) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/token/unbind`,
    form: {
      type,
    },
  });
}

export async function kidBind(form) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/token/bind_kid`,
    form,
  });
}

export function resetUser() {
  setUToken("");
  setMID("");
  setLocal("persist:root");
}

export function isAuth() {
  return !!(getUToken() && getUUID());
}

export default {
  login,
  register,
  verify,
  deleteAccount,
  resend,
  logout,
  checkToken,
  getUserToken,
  accountUnbind,
  kidBind,
  isAuth,
};
