import { client } from "components/services/apiService";

const url = `${process.env.REACT_APP_API_URL_USER}/common`;

export async function getSetting() {
  return await client.post({
    url: `${url}/get_setting`,
    form: {},
  });
}

export default {
  getSetting,
};
