import React, { useEffect, useState, Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Button, notification, message } from 'antd';

import Login from "components/common/login";

import { getKey } from "components/services/verifyService";

import user_API from "components/services/userService";

const langConfig = {
  zh: 'zh',
  cn: 'cn',
  english: 'en'
}

export default function LoginPage() {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(useLocation().search)
  const key = urlParams.get('app');

  const { id: user_id, language } = useSelector(state => state.user);

  const [getKeyLoading, setGetKeyLoading] = useState(false); 
  const [getKeyStatus, setGetKeyStatus] = useState(true);

  const [ thirdPartyApp , set3rdPartyApp ] = useState([
    { "alias" : "parentsdaily", "name" : "ParentsDaily" , "url" : process.env.REACT_APP_PD_URL , "type" : ["PARENT" , "STAFF"] , supportMultiLang : false},
    { "alias" : "efunfun" ,  "name" : "eFunFun" , "url" : process.env.REACT_APP_EFUNFUN_URL , "type" : ["PARENT" , "STAFF"] , supportMultiLang : true},
  ]);
  const [appRedirect , setAppRedirect] = useState(thirdPartyApp.find(_app => _app.alias === key));


  /**
   * @description 驗證第三方應用是否已經認證
   * @todo [thirdparty should get from API]
   */
  useEffect(() => {
    if(!!key && !!user_id) {
      console.log('found :' , thirdPartyApp.find(_app => _app.alias === key));
      setAppRedirect(thirdPartyApp.find(_app => _app.alias === key));
    }
  } , [user_id , key , thirdPartyApp]);

  /**
   * @description 基於第三方應用；驗證帳戶身份。
   * @todo [send user type to user management by redux for the handling]
   */
  useEffect(() => {
    if(!key || !appRedirect || !user_id) {
      return;
    }
    if (appRedirect.type.indexOf(user_API.getType()) === -1) {
      message.error(`請切換成家長用戶，繼續登入${!!appRedirect && appRedirect.name || ""}`);
      dispatch({ type: "SHOW_USER_MANAGER", payload: true })
      return;
    }
    if (appRedirect.url !== null){
      handleAppRedirect();
    }
  } , [user_id , appRedirect]);


  /**
   * @description 第三方應用跳轉
   */
  const handleAppRedirect = useCallback(async () => {
    setGetKeyLoading(true);
    /**
     * @description Get app verify key
     */
    const ret = await getKey().then(ret => {
      if(!!appRedirect) {
        window.location = `${appRedirect.url}/${!!appRedirect.supportMultiLang && (langConfig[language] || 'zh') || ""}/login/verify?key=${ret}`;
      }
      return ret;
    })
    /**
     * @description catch all error
     * @return false
     */
    .catch((error) => {
      notification.error({
        message: '驗證失敗，請重試',
        placement: 'topLeft'
      })
      return false;
    })
    /**
     * @description finally
     */
    .then((ret) => {
      setGetKeyLoading(false);
      setGetKeyStatus(!!ret);
    });
    return ret;
  }, [user_id , appRedirect])
  
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      {user_id && ['efunfun','parentsdaily'].indexOf(key) > -1 ? (
        <Fragment>
          {getKeyLoading && <h3>正在驗證，請稍候...</h3>}
          {!getKeyLoading && (
            <>
              {getKeyStatus && <h3>正在進入 {`${!!appRedirect && appRedirect.name || "第三方應用"}`}，請稍候...</h3>}
              {!getKeyStatus && (
                <Button type="link" onClick={handleAppRedirect}>
                  點擊重新驗證
                </Button>
              )}
            </>
          )}
        </Fragment>
      ) : (
        <div style={{ width: 400 }}>
          <Login />
        </div>
      )}
    </div>
  )
}
