import React, { useEffect } from 'react';
import { Drawer, Empty } from 'antd';

const Document = (props) => {
  const { file, visible, onCancel } = props;

  useEffect(() => {
    if(visible) {
      document.body.style.overflow = 'hidden';
    }
  }, [visible])

  return (
    <Drawer
      title=" "
      visible={visible}
      onClose={onCancel}
      placement="bottom"
      closable
      height="100%"
      drawerStyle={{
        overflow: 'hidden'
      }}
      headerStyle={{
        height: 56
      }}
      bodyStyle={{
        padding: 0,
        height: '100%'
      }}
    > 
      {file ? (
        <object data={file} width="100%" height="100%">
          此瀏覽器不支持預覽 pdf 文件，請更換瀏覽器
        </object>
      ) : <Empty style={{ marginTop: 40 }} />}
    </Drawer>
  )
}

export default Document;