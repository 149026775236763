import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import intl from "react-intl-universal";

import { Steps, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { skipRegisterPay } from "../../../store/action/global";

import RegisterForm from "./registerParent";
import Verification from "./verification";
import NewChildren from "./NewChildren";
import Finish from "./finish";

import stepStyles from "../sass/step.module.scss";
import user_API from "components/services/userService";
import * as payment_api from "~/api/payment_api";

// import useSetInterval from "components/utils/useSetInterval";

const { Step } = Steps;

const step = (props) => {
  const timer = useRef();
  const history = useHistory();
  const { location, codeData } = props;

  const dispatch = useDispatch();
  const {
    user: { id },
    translations: { initDone },
    new_global: { status },
    auth: { userState, loginCode },
    route: { locationUrl },
  } = useSelector((state) => state);
  const { verifyCode } = useSelector((state) => state.new_global);
  const { skipBindChildren } = useSelector((state) => state.new_global);

  const [loading, setLoading] = useState(false);

  const lang = (value) => initDone && intl.get(`register.${value}.title`);

  const urlParams = new URLSearchParams(useLocation().search);

  const key = urlParams.get("action");
  const invoiceCode = urlParams.get("invoice_code");
  const transCode = urlParams.get("trans_code");

  const search = {
    success: 3, //
    cancel: 2,
  };

  // if (
  //   !skipBindChildren &&
  //   key !== "success" &&
  //   !!id &&
  //   status !== "VERIFY" &&
  //   userState !== "verify"
  // ) {
  //   history.push(user_API.getHomeURL());
  // }

  const getInvoiceView = async () => {
    const ret = await payment_api
      .getInvoiceView(invoiceCode, transCode)
      .then((ret) => ret.status === "SUCCESS")
      .catch(() => false);

    if (ret) {
      clearInterval(timer.current);
      setLoading(false);
      history.push(user_API.getHomeURL());
      return;
    }

    if (!timer.current) {
      timer.current = setInterval(() => {
        getInvoiceView();
      }, 10000);
    }
  };

  useEffect(
    () => () => {
      clearInterval(timer.current);
      // 離開這個頁面時清除 login code
    },
    []
  );

  useEffect(() => {
    if (key === "success") {
      if (invoiceCode && transCode) {
        setLoading(true);
        getInvoiceView();
        return;
      }
      setTimeout(() => {
        history.push(user_API.getHomeURL());
      }, 3000);
    }
  }, [key, invoiceCode, transCode]);

  useEffect(() => {
    if (
      !skipBindChildren &&
      key !== "success" &&
      !!id &&
      status !== "VERIFY" &&
      !!id &&
      userState !== "verify"
      // loginCode !== 210
    ) {
      history.push(user_API.getHomeURL());
    }
  }, [skipBindChildren, key, id, status, userState, loginCode]);

  const initStep = !!id ? (userState === "verify" ? 1 : search[key] || 2) : 0;
  const [current, setCurrent] = useState(initStep);

  const next = (step) => {
    setCurrent(step || current + 1);
  };

  const prev = () => {
    setCurrent(current + -1);
  };

  // 跳過付款流程
  const skipPay = () => {
    dispatch(skipRegisterPay({ payload: true }));
    setCurrent(3);
  };

  // 跳过新增子女流程
  const skipBind = () => {
    setCurrent(3);
  };

  const steps = [
    {
      title: lang("step_one"),
      content: (
        <RegisterForm
          location={location}
          next={(step) => next(step)}
          prev={() => prev()}
          codeData={codeData}
        />
      ),
    },
    {
      title: lang("step_two"),
      content: (
        <Verification
          next={skipBindChildren ? skipPay : next}
          prev={() => prev()}
        />
      ),
    },
    {
      title: lang("step_three"),
      content: (
        <NewChildren
          next={() => next()}
          prev={() => prev()}
          skipPay={() => skipPay()}
          // showFamily={true}
        />
      ),
    },
    {
      title: lang("step_four"),
      content: <Finish loading={loading} />,
    },
  ];

  return (
    <div style={{ padding: "36px 0" }}>
      <Steps
        current={current}
        labelPlacement={"vertical"}
        className={stepStyles.step}
      >
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </div>
  );
};

export default step;
