import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import intl from "react-intl-universal";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { Row, Col, Select, Menu, Button, TreeSelect, Popover, Icon as AntdIcon } from "antd";

import { getListAction } from "components/actions/schoolCourse_action";

import Icon from "./common/icon";
import CourseEditor from "components/course/CourseEditor";
import user_API from "components/services/userService";

import "./sass/schoolCourseFilter.scss";
import { useImperativeHandle } from "react";

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

const SchoolCourseFilter = (props) => {
  const editRef = React.createRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { course_id } = useParams();
  const isXl = useMediaQuery({ query: "(min-width: 1200px)" });

  const { type, selectValue, typeList, onSelectOrder, onSelectType } = props;

  useEffect(() => {
    // console.log('ref : ', editRef);
  }, [editRef])

  const {
    translations,
    schoolCourse: { info, class: classData }
  } = useSelector((state) => state);

  const [grade, setGrade] = useState([]);
  const [visible, setVisible] = useState([]);

  const lang = value => translations.initDone && intl.get(value);

  const handleChange = newValue => {
    if (onSelectOrder) onSelectOrder(newValue);
  };

  const handleSelectType = newValue => {
    if (onSelectType) onSelectType(newValue);
  };

  const onSelect = (i_select, node, extra) => {
    setGrade(i_select);
    let _ret = (extra["allCheckedNodes"] || []).map((_select) => {
      if (!_select.pos) {
        return false;
      }
      let level = _select.pos.split("-");
      if (level.length > 2) {
        return (
          (!!_select.children &&
            _select.children.map((_child) => _child.node.props.value)) ||
          _select.node.props.value
        );
      }
      return _select.node.props.value;
    });
    const params = {
      id: course_id,
      grade: [].concat(..._ret).map((__ret) => {
        if (!__ret) {
          return null;
        }
        let map = __ret.split("-");
        return map.length === 3
          ? `dummy-${map[0]}-${map[2]}`
          : `dummy-${__ret}`;
      })
    }
    dispatch(getListAction(params));
  };

  const config = {
    COURSE: lang("schoolCourse.type_course"),
    video: lang("schoolCourse.type_video"),
    project: lang("schoolCourse.type_poject"),
    eval: lang("schoolCourse.type_eval"),
  };

  const SortSelect = () => (
    <Select
      value={selectValue}
      style={{ width: '100%' }}
      onChange={handleChange}
    >
      <Option value="all">
        {lang("general.title.courseSort.sortAll")}
      </Option>
      <Option value="ASC">
        {lang("general.title.courseSort.sortASC")}
      </Option>
      <Option value="DESC">
        {lang("general.title.courseSort.sortDESC")}
      </Option>
    </Select>
  )

  const GradeSelect = () => (
    <TreeSelect
      treeData={classData.classTree}
      filterTreeNode={(_search, node) =>
        !!node.props &&
        !!node.props.title &&
        node.props.title
          .toUpperCase()
          .indexOf(_search.toUpperCase()) > -1
      }
      treeCheckable={true}
      placeholder={lang("course_1.content.option.grade")}
      allowClear={true}
      showCheckedStrategy={SHOW_PARENT}
      showSearch={true}
      onChange={onSelect}
      style={{ minWidth: 120 }}
      value={grade}
    />
  )

  const CourseFilter = () => (
    <Menu
      mode={isXl ? 'horizontal' : 'vertical'}
      defaultSelectedKeys={["all"]}
      onSelect={({ key }) => handleSelectType(key)}
      style={{ border: "none" }}
      selectedKeys={type}
      className={isXl ? "schoolCourseFilter_menu" : ''}
    >
      <Menu.Item key="all">{lang("schoolCourse.type_all")}</Menu.Item>
      {typeList.map((item, index) => (
        <Menu.Item key={item}>
          <Icon type={item} size={50} /> {config[item] || config.video}
        </Menu.Item>
      ))}
    </Menu>
  )

  const content = (
    <Row type="flex" gutter={[0, 10]} style={{ maxWidth: 220 }}>
      <Col span={24}>
        <SortSelect />
      </Col>
      <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <GradeSelect />
        <Button onClick={() => !!(editRef && editRef.current) && editRef.current.showModal() || void (0)}>
          {lang("course_1.content.option.calssAdd")}
        </Button>
      </Col>
      <Col span={24}>
        <CourseFilter />
      </Col>
    </Row>
  )

  return (
    <Row
      type="flex"
      align="middle"
      justify="start"
      gutter={25}
      style={{
        fontWeight: "bold",
        flexWrap: 'wrap',
        maxWidth: 1450,
        margin: "0 auto",
        padding: isXl ? '0 10px' : '10px'
      }}
    >
      <Col>
        <Button onClick={() => history.goBack()}>
          {lang("general.button.back")}
        </Button>
      </Col>
      {isXl && (
        <>
          <Col style={{ width: 169 }}>
            <SortSelect />
          </Col>
          <Col style={{ flex: '1 1' }}>
            <CourseFilter />
          </Col>
          {user_API.getType() === "STAFF" && (
            <>
              <Col>
                <GradeSelect />
              </Col>
              <Col>
                <Button onClick={() => !!(editRef && editRef.current) && editRef.current.showModal() || void (0)}>
                  {lang("course_1.content.option.calssAdd")}
                </Button>
              </Col>
            </>
          )}
        </>
      )}
      {!isXl && (
        <Col style={{ flex: '1 1', textAlign: 'right' }}>
          <Popover content={content} trigger="click" placement="bottomRight">
            <AntdIcon type="menu" />
          </Popover>
        </Col>
      )}
      <CourseEditor
        ref={editRef}
        URLid={info.id}
        refresh={() => dispatch(getListAction({ id: course_id }))}
      />
    </Row>
  );
};

export default SchoolCourseFilter;
