import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";
import {
  Row,
  Col,
  Button,
  Input,
  Checkbox,
  message,
  Select,
  DatePicker,
  Tag,
} from "antd";

import File from "components/common/File";
import Upload from "./components/upload";
import DeleteFile from "./components/deleteFile";
import ImgPreview from "./components/imgPreview";

import styles from "assets/css/studentReport/infoPage.module.scss";

import course from "components/services/courseService";
import { Fragment } from "react";

const { TextArea } = Input;
const { Option } = Select;

const icons = ["cmt_icon1", "cmt_icon2", "cmt_icon3", "cmt_icon4"];
const Tags = ["構圖豐富", "加油!", "畫面生動", "顏色鮮艷"];
const familiar = ["构图丰富", "加油!", "画面生动", "颜色鲜艳"];

const getDate = () => {
  const thisYear = moment().year();
  const nextYear = moment().clone().add(1, "year").year();
  const isPast = moment().isAfter(`${thisYear}-07-31 00:00`);
  const year = isPast ? nextYear : thisYear;
  return moment(`${year}-07-31 00:00`);
};

const InfoPage = (props) => {
  const { translations, route: { currentLocation } } = useSelector((state) => state);
  const { data, courseId, setPage, getData, isProject } = props;

  const [loading, setLoading] = useState(false);
  const [star, setStar] = useState(data.star);
  const [comment, setComment] = useState(data.comment);
  const [highlight, setHighlight] = useState(data.highlight || "N");
  const [isComment, setIsComment] = useState(data.is_comment || "N");
  const [rank, setRank] = useState(data.rank);
  const [reward, setReward] = useState(data.reward);
  const [publishTime, setPublishTime] = useState(
    data.publish_time ? moment(data.publish_time) : undefined
  );
  const [endTime, setEndTime] = useState(
    data.end_time
      ? moment(data.end_time)
      : data.highlight === "N"
        ? getDate()
        : undefined
  );

  const lang = (value) => translations.initDone && intl.get(value);

  const handleSelectStar = (index) => {
    if (star === index) {
      setStar();
      return;
    }
    setStar(index);
  };

  const handleSubmit = () => {
    if (data.ur_id && star) {
      setLoading(true);
      const formData = {
        ur_id: data.ur_id,
        star,
        comment,
        highlight,
        is_comment: isComment,
        rank,
        reward,
        publish_time: !!publishTime
          ? moment(publishTime).format("YYYY-MM-DD HH:mm")
          : undefined,
        end_time: !!endTime
          ? moment(endTime).format("YYYY-MM-DD HH:mm")
          : undefined,
      };
      course
        .addComment(formData)
        .then((ret) => {
          getData();
          message.success(lang("general.msg.update_success"));
        })
        .catch((err) => {
          message.error(lang("general.msg.update_error"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const disabledStartDate = (startValue) => {
    if (!endTime) {
      return false;
    }
    const endValue = moment(endTime);
    return startValue.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = (endValue) => {
    if (!publishTime) {
      return false;
    }
    const startValue = moment(publishTime);
    return endValue.valueOf() <= startValue.valueOf();
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Button type="link" icon="left" onClick={setPage}>
          {lang("general.button.back_prev_page")}
        </Button>
      </div>
      <div className={styles.main}>
        <Row type="flex" gutter={[30, 30]}>
          <Col span={14} className={styles.preview_wrap}>
            <File
              src={data.project_file || data.cover}
              className={styles.filePreview_wrap}
              imgPreview
            />
            {/* {data.movie_status === "Y" && data.project_file && (
              <File
                src={data.project_file}
                className={styles.filePreview_wrap}
                imgPreview
              />
            )} */}
            {/* {data.movie_status === "N" && data.cover && (
              <File
                src={data.cover}
                className={styles.filePreview_wrap}
                imgPreview
              />
            )} */}
            <Button
              className={styles.btn_download}
              icon="download"
              href={data.project_file}
              target="blank"
            >
              {lang("general.button.download")}
            </Button>
          </Col>
          <Col span={10} className={styles.edit_wrap}>
            <div className={styles.name}>{data.name}</div>
            <div className={`${styles.rating_wrap} ${styles.horizontal}`}>
              <div className={styles.label}>
                {lang("student_reports.form.rating")}
              </div>
              <div className={styles.icon_box}>
                {icons.map((item, index) => {
                  const currentIndex = (index + 1).toString();
                  return (
                    <img
                      key={item}
                      src={require(`assets/image/${item}.png`)}
                      onClick={() => handleSelectStar(currentIndex)}
                      className={currentIndex === star ? styles.active : ""}
                      alt="icon"
                    />
                  );
                })}
              </div>
            </div>
            <div className={`${styles.audio_wrap} ${styles.horizontal}`}>
              <div className={styles.label}>
                {lang("student_reports.form.voice")}
              </div>
              {data.comment_voice ? (
                <div className={styles.audio_box}>
                  <audio src={data.comment_voice} controls />
                  <DeleteFile id={data.ur_id} action="voice" callback={getData}>
                    <Button size="small" className={styles.delete_btn}>
                      {lang("general.button.delete")}
                    </Button>
                  </DeleteFile>
                </div>
              ) : (
                <Upload
                  type="audio"
                  id={{
                    ur_id: data.ur_id,
                    res_id: data.res_id,
                    course_id: courseId,
                  }}
                  callback={getData}
                >
                  <Button className={styles.upload_button} block>
                    {lang("student_reports.form.upload_voice")}
                  </Button>
                </Upload>
              )}
            </div>
            <div className={styles.file_wrap}>
              <div className={styles.label}>
                {lang("student_reports.form.file")}
              </div>
              {data.comment_file ? (
                <ImgPreview src={data.comment_file}>
                  <div className={styles.img_preview_box}>
                    {data.comment_file.endsWith(".pdf") ? (
                      "PDF"
                    ) : (
                      <img src={data.comment_file} alt="" />
                    )}
                    <span className={styles.preview_text}>
                      {lang("student_reports.form.preview")}
                    </span>
                    <DeleteFile
                      id={data.ur_id}
                      action="file"
                      callback={getData}
                    >
                      <Button size="small" className={styles.delete_btn}>
                        {lang("general.button.delete")}
                      </Button>
                    </DeleteFile>
                  </div>
                </ImgPreview>
              ) : (
                <Upload
                  type="file"
                  id={{
                    ur_id: data.ur_id,
                    res_id: data.res_id,
                    course_id: courseId,
                  }}
                  callback={getData}
                >
                  <Button className={styles.upload_button} block>
                    {lang("student_reports.form.upload_file")}
                  </Button>
                </Upload>
              )}
            </div>
            <div className={styles.text_wrap}>
              <div className={styles.label}>
                {lang("student_reports.form.comment")}
              </div>
              <div className={styles.text_area_box}>
                <TextArea
                  value={comment}
                  rows={4}
                  className={styles.text_area}
                  onChange={(e) => setComment(e.target.value)}
                >
                  sdfasff
                </TextArea>
                <div className={styles.tag_box}>
                  {
                    currentLocation === "cn" ? familiar.map((item, index) => (
                      <Tag
                        key={index}
                        color="#EBF2FF"
                        style={{ color: "#2B4B80" }}
                        onClick={() => setComment(item)}
                      >
                        {item}
                      </Tag>
                    )) : Tags.map((item, index) => (
                      <Tag
                        key={index}
                        color="#EBF2FF"
                        style={{ color: "#2B4B80" }}
                        onClick={() => setComment(item)}
                      >
                        {item}
                      </Tag>
                    ))
                  }
                </div>
              </div>
            </div>
            {isProject && (
              <Checkbox
                checked={highlight === "Y"}
                onChange={(e) => setHighlight(e.target.checked ? "Y" : "N")}
              >
                <span className={styles.label}>
                  {currentLocation === "cn" ? lang("student_reports.form.exhibition") : lang("student_reports.form.highlight")}
                </span>
              </Checkbox>
            )}
            <br />
            {highlight === "Y" && isProject && (
              <Fragment>
                <Checkbox
                  checked={isComment === "Y"}
                  onChange={(e) => setIsComment(e.target.checked ? "Y" : "N")}
                >
                  <span className={styles.label}>
                    {lang("student_reports.form.is_comment")}
                  </span>
                </Checkbox>
                <div className={`${styles.rank_wrap} ${styles.horizontal}`}>
                  <span className={styles.label}>
                    {lang("student_reports.form.rank")}
                  </span>
                  <Select
                    value={rank}
                    style={{ width: 88, marginRight: 10, flexShrink: 0 }}
                    placeholder={lang("student_reports.form.rank")}
                    onChange={(value) => setRank(value)}
                  >
                    <Option value="1">
                      {lang("student_reports.form.option_champion")}
                    </Option>
                    <Option value="2">
                      {lang("student_reports.form.option_runner_up")}
                    </Option>
                    <Option value="3">
                      {lang("student_reports.form.option_third_place")}
                    </Option>
                  </Select>
                  <span className={styles.label}>
                    {lang("student_reports.form.reward")}
                  </span>
                  <Input
                    className={styles.rank_name}
                    placeholder={lang(
                      "student_reports.form.placeholder_reward"
                    )}
                    value={reward}
                    onChange={(e) => {
                      if (e.target.value.length <= 6) {
                        setReward(e.target.value);
                      }
                    }}
                  />
                </div>
                <div>
                  <span className={styles.label}>
                    {lang("student_reports.form.publish_time")}
                  </span>
                  <DatePicker
                    showTime
                    placeholder={lang("student_reports.form.placeholder_time")}
                    onChange={(value) => setPublishTime(value)}
                    format="YYYY-MM-DD HH:mm"
                    value={publishTime}
                    disabledDate={disabledStartDate}
                  />
                </div>
                <div>
                  <span className={styles.label}>
                    {lang("student_reports.form.end_time")}
                  </span>
                  <DatePicker
                    showTime
                    placeholder={lang("student_reports.form.placeholder_time")}
                    onChange={(value) => setEndTime(value)}
                    format="YYYY-MM-DD HH:mm"
                    value={endTime}
                    disabledDate={disabledEndDate}
                  />
                </div>
              </Fragment>
            )}
          </Col>
        </Row>
      </div>
      <div className={styles.footer}>
        {data.comment_last_update_time && (
          <span className={styles.end_time}>
            {lang("student_reports.last_update_time")}
            {data.comment_last_update_time}
          </span>
        )}
        <Button loading={loading} onClick={handleSubmit}>
          {lang("general.button.confirm")}
        </Button>
      </div>
    </div>
  );
};

export default InfoPage;
