import React, { useEffect, useState } from "react";
import { Row, Col, message, Select, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import * as redemptionAction from "../../store/action/redemption";
import intl from "react-intl-universal";

import RedeemModal from "./components/RedeemModal";
import ErrorContent from "./components/ErrorContent";
import SuccessContent from "./components/SuccessContent";

import multipleText from "./utils/resultCode";
import styles from "assets/css/redemption/index.module.scss";
import logo from "assets/image/redemption/logo.png";

const Redemption = () => {
  const { info, result } = useSelector((state) => state.new_redemption);

  const dispatch = useDispatch();

  const { code } = useParams();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (code) {
      setLoading(true);
      dispatch(
        redemptionAction.checkRedeem({
          code,
          errorCallback: (err) => {
            console.log(err.result);
            message.error(intl.get(multipleText.get(err.result || "default")));
          },
          finallyCb: () => {
            setLoading(false);
          },
        })
      );
    }
  }, [code]);

  // 文本标识
  const redeemResult = result === 1;

  return (
    <Row
      type="flex"
      justify="space-around"
      align="middle"
      style={{
        margin: "30px 16px",
        textAlign: "center",
        boxShadow: "0 3px 6px #00000029",
        flexDirection: "column",
        paddingBottom: "30px",
      }}
    >
      <Col xs={22} className={styles.block_padding}>
        <img src={logo} alt="" />
        <h2
          style={{ marginTop: 8, color: redeemResult ? "#11852C" : "#000000" }}
        >
          {redeemResult
            ? intl.get("redemption.redeem_later")
            : intl.get("redemption.redeem_before")}
        </h2>
      </Col>

      {(!code || !!info.errorCode) && (
        <Col xs={20} className={styles.input_content}>
          <ErrorContent />
        </Col>
      )}

      {loading && <Spin />}

      {!loading && code && !info.errorCode && (
        <>
          <SuccessContent setVisible={setVisible} />
          <RedeemModal visible={visible} setVisible={setVisible} />
        </>
      )}
    </Row>
  );
};

export default Redemption;
