import React, { useState, useCallback, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { Row, Col, Pagination, Spin, Empty } from 'antd';

import course from 'components/services/courseService';

import HighlightActions from './HighlightActions';
import HighlightItem from './HighlightItem';
// import HighlightContext from './HighlightContext';

import styles from '../sass/highlightList.module.scss';

export default function HighlightList() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ total: 0, rows: [] });
  const [page, setPage] = useState(1);
  const [grade, setGrade] = useState();
  const [search, setSearch] = useState('');

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev' || type === 'next') {
      return null;
    }
    if (type === 'jump-prev' || type === 'jump-next') {
      return originalElement;
    }
    return (
      <div className={`${styles.page_btn} ${current === page ? styles.page_btn_active : ''}`}>
        {current}
      </div>
    );
  }

  const getData = useCallback((value) => {
    if (!data.rows.length) {
      setLoading(true);
    }
    const [gradeKey, gradeValue] = grade ? grade.split('-') : [];
    const formData = {
      offset: page - 1,
      [gradeKey]: gradeValue,
      search: value
    }
    course.getHighlightList(formData).then(ret => {
      setData(ret);
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
    })
  }, [page, data.rows, grade]);

  useEffect(() => {
    getData(search);
  }, [page, grade])

  return (
    <div className={styles.root}>
      <HighlightActions
        grade={[grade, setGrade]}
        search={[search, setSearch]}
        setPage={setPage}
        getData={getData}
      />
      {loading && (
        <div className={styles.spin}>
          <Spin size="large" />
        </div>
      )}
      {!loading && (
        <>
          <Row type="flex" gutter={[40, 20]} className={styles.main}>
            {data.rows.map((item, index) => (
              <Col 
                key={index} 
                md={8} 
                sm={12} 
                xs={24}
              >
                <HighlightItem data={item} callback={() => getData(search)} />
              </Col>
            ))}
          </Row>
          {/* 分頁 */}
          <div className={styles.pagination_box}>
            <Pagination 
              current={page} 
              total={data.total}
              pageSize={6}
              onChange={currentPage => setPage(currentPage)}
              itemRender={itemRender}
              className={styles.pagination}
              hideOnSinglePage
            />
          </div>
        </>
      )}
      {!loading && !data.rows.length && (
        <div className={styles.spin}>
          <Empty />
        </div>
      )}
    </div>
  )
}
