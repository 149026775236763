import React from "react";
import intl from "react-intl-universal";
import { Icon, Row, Col, Button } from "antd";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import styles from "../../sass/newChildren/introduce.module.scss";

const Introduce = (props) => {
  const {
    user: { id: user_id },
    translations: { initDone }
  } = useSelector(state => state);

  const lang = (value, params) => initDone && intl.get(`register.${value}`, params);

  const { subNext, prev, skipPay } = props;
  return (
    <div className={styles.box}>
      <h2 className={styles.describe}>{lang('step_three.title')}</h2>
      <h2 className={styles.describe2}>
        {lang('step_three.tip')}
      </h2>
      <div className={styles.center} onClick={subNext}>
        <Icon type="plus-circle" theme="filled" className={styles.addIcon} />
        <p className={styles.add}>
          <span>{lang('step_three.title')}</span>
        </p>
      </div>
      <div className={styles.buttonGroup}>
        <Button block className={styles.next} type="primary" onClick={subNext}>
          {lang('btn_continue')}
        </Button>

        <Button block className={styles.back} onClick={prev} disabled={!!user_id}>
          {lang('btn_back')}
        </Button>

        <span href="#" className={styles.skip} onClick={skipPay}>
          {lang('step_three.btn_skip')}
        </span>
      </div>
    </div>
  );
};

export default Introduce;
