import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuth } from "components/services/authService";
import EVIFrame from "components/core/Frame";
import NotFound from "components/layout/404";

import { overridePath } from "components/utils/routeExpand";

export default function ProtectedRoute(props) {
  const {
    EVIComponent: Component,
    preLogin: preComponent,
    render,
    noFrame,
    preLoginNoFrame,
    auth,
    location: { pathname },
    location,
    ...rest
  } = props;

  const { $rootURL } = { ...rest };

  /** 用户是否登录 */
  const _auth = auth === true ? isAuth() : true;

  /** 是否存在组件,如果不存在一律跳转404 */
  const flag = preComponent || Component;

  const ProtectedRoute = !_auth ? (!!preComponent ? Route : Redirect) : Route;
  const newNoFrame =
    typeof noFrame == undefined ? true : !_auth ? true : noFrame;

  // console.log(props);
  // console.log("_auth", _auth);

  if (!flag) {
    return (
      <Redirect
        {...rest}
        from={window.decodeURIComponent(pathname)}
        to={overridePath({
          location,
          _auth,
          // key: "redemption",
          oldPath: $rootURL,
        })}
        path={window.decodeURIComponent(props.path)}
        render={(props) => {
          return (
            <EVIFrame
              nonAuthElement={NotFound}
              authElement={NotFound}
              noFrame={!_auth ? preLoginNoFrame : newNoFrame}
              isAuth={auth}
              path={window.decodeURIComponent(props.path)}
              {...props}
              {...rest}
            />
          );
        }}
      />
    );
  }

  return (
    <ProtectedRoute
      {...rest}
      to={
        !_auth
          ? overridePath({
              location,
              _auth,
              // key: "redemption",
              oldPath: $rootURL,
            })
          : "/"
      }
      render={(props) => {
        return (
          <EVIFrame
            nonAuthElement={preComponent}
            authElement={Component}
            noFrame={!_auth ? preLoginNoFrame : newNoFrame}
            isAuth={auth}
            path={window.decodeURIComponent(props.path)}
            {...props}
            {...rest}
          />
        );
      }}
    />
  );
}
