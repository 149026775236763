import { client } from "components/services/apiService";

// 注册 -- 上传子女头像
export async function uploadAvatar({ bg_id }) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/user/update_bg`,
    form: {
      bg_id,
    },
  });
}
