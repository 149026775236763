import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";
import { Row, Col, Typography, Modal, Button } from "antd";

import styles from "assets/css/PersonalCenter.module.scss";

import user_API from "components/services/userService";

import UserEdit from "../../components/UserEdit";
import Relationship from "./Relationship";
import UseTime from "./UseTime";
import AccountDeletionNew from "./AccountDeletionNew";

import DraggerImgUploading from "components/common/UploadingAvatar";
import ChangePassword from "components/ChangePassword";

const PersonalPage = () => {
  const {
    user,
    route: { currentLanguage = {} },
    translations,
  } = useSelector((state) => state);

  const { img: avatar, id, full_name, family = [], intranet = {} } = user;

  const [changePwdVisible, setChangePwdVisible] = useState(false);
  const [changeAvatarVisible, setChangeAvatarVisible] = useState(false);

  const lang = (value) => translations.initDone && intl.get(value);

  const userInfo = family.find((_fM) => _fM.permit_user === id) || {};
  const school = intranet.school
    ? intranet.school["name_" + currentLanguage.value]
    : "";
  const grade = intranet.grade
    ? `${intranet.grade["name_" + currentLanguage.value]}${
        intranet.class["name_" + currentLanguage.value]
      }`
    : "";
  const sex = userInfo ? (userInfo.permit_user_sex === "M" ? "男" : "女") : "";

  const config = [
    {
      label: lang("personalCenter.personalInfo.name"),
      value: full_name,
      render: <UserEdit type="name" value={full_name} />,
    },
    {
      label: lang("personalCenter.personalInfo.gender"),
      value: sex,
      render: <UserEdit type="sex" value={sex} />,
    },
    { label: lang("personalCenter.personalInfo.schoolName"), value: school },
    {
      label: lang("personalCenter.personalInfo.class"),
      value: grade,
      disabled: user_API.getType() !== "STUDENT",
    },
    {
      label: lang("personalCenter.personalInfo.tel"),
      value: userInfo.permit_user_tel,
      disabled: true,
    },
    {
      label: lang("personalCenter.personalInfo.e-mail"),
      value: userInfo.permit_user_email,
      disabled: true,
    },
    {
      label: lang("personalCenter.personalInfo.password"),
      value: "修改密碼",
      render: (
        <Button onClick={() => setChangePwdVisible(true)}>
          {lang("home.publicMsg.drawer.ChangePword")}
        </Button>
      ),
    },
    {
      label: <AccountDeletionNew />,
      hiddenColon: true,
    },
  ];

  return (
    <div className={styles.personalPage}>
      <Row type="flex" gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col md={12} xs={24}>
          <Relationship />
        </Col>
        <Col md={12} xs={24}>
          <UseTime />
        </Col>
      </Row>
      <Row
        type="flex"
        className={`${styles.personal_detail} ${styles.card}`}
        gutter={[0, 10]}
      >
        <Col md={10} xs={24} className={styles.avatar_box}>
          <img
            src={avatar || require("assets/image/personal/user.png")}
            alt="avatar"
            className={styles.avatar}
          />
          <Button icon="upload" onClick={() => setChangeAvatarVisible(true)}>
            {lang("personalCenter.personalInfo.uploadProfilePicture")}
          </Button>
        </Col>
        <Col md={14} xs={24} className={styles.text_box}>
          <Row type="flex" justify="center" gutter={[0, 25]}>
            {config
              .filter((_) => !_.disabled)
              .map((item, index) => (
                <Fragment key={index}>
                  <Col md={5} xs={24}>
                    <Typography.Text className={styles.text}>
                      {item.label}
                      {!item.hiddenColon && "："}
                    </Typography.Text>
                  </Col>
                  <Col
                    md={19}
                    xs={24}
                    style={{ fontSize: 18, color: "#676767" }}
                  >
                    {item.render || (
                      <Typography.Text
                        title={item.value}
                        style={{ width: "100%" }}
                        className={styles.text}
                        ellipsis={true}
                      >
                        {item.value}
                      </Typography.Text>
                    )}
                  </Col>
                </Fragment>
              ))}
          </Row>
        </Col>
      </Row>
      <Modal
        centered
        title={lang("home.publicMsg.avatar.tip")}
        bodyStyle={{ backgroundColor: "#fff" }}
        visible={changeAvatarVisible}
        onCancel={() => setChangeAvatarVisible(false)}
        footer={null}
        maskClosable={false}
      >
        <DraggerImgUploading onCancel={() => setChangeAvatarVisible(false)} />
      </Modal>
      {/* 修改密碼 */}
      <Modal
        title={lang("home.publicMsg.drawer.ChangePword")}
        visible={changePwdVisible}
        footer={null}
        onCancel={() => setChangePwdVisible(false)}
        style={{ background: "#fff", padding: "15px 20px" }}
        destroyOnClose={true}
      >
        <ChangePassword onCancel={() => setChangePwdVisible(false)} />
      </Modal>
    </div>
  );
};

export default PersonalPage;
