import { client } from "components/services/apiService";

// 新版注册
export async function newRegister({ registerType, account, ...rest }) {
  const type = registerType === "tel" ? "tel" : "email";
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/user/register`,
    form: {
      [type]: account,
      language : localStorage.getItem("lang"),
      ...rest,
    },
  });
}

// 新版验证
export async function newVerify({ vcode, access_token }) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/user/verify`,
    form: {
      vcode,
      access_token,
    },
  });
}
