import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'antd';

import HighlightList from './HighlightList';
import HighlightInfo from './HighlightInfo';
import HighlightContext from './HighlightContext';

import styles from '../sass/highlight.module.scss';

const SchoolCourseHighlight = (props) => {
  const { route: { currentLanguage ,currentLocation} } = useSelector(state => state);
  
  const { children } = props;

  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState({});

  useEffect(() => {
    if (!visible) {
      setCurrent({});
    }
  }, [visible])

  return (
    <Fragment>
      <div
        style={{ display: 'inline-block' }}
        onClick={() => setVisible(true)}
      >
        {children}
      </div>
      <Modal
        visible={visible}
        footer={null}
        width={1119}
        closable={false}
        bodyStyle={{
          padding: 0,
        }}
        className={styles.modal}
        destroyOnClose
        centered
      >
        <div className={styles.root}>
          <div className={styles.close}>
            <Button 
              icon="close" 
              shape="circle"
              onClick={() => setVisible(false)}
            />
          </div>
          <div className={styles.title}>
            {currentLocation==="cn"?
            <img src={require(`assets/image/schoolCourse/${currentLanguage.value}/highlight_title_cn.png`)} alt="highlight title"/>
            :
            <img src={require(`assets/image/schoolCourse/${currentLanguage.value}/highlight_title.png`)} alt="highlight title"/>}
          </div>
          {/* main */}
          <div className={styles.main}>
            <HighlightContext.Provider 
              value={{
                useCurrent: [current, setCurrent]
              }}
            >
              {!current.ur_id && <HighlightList />}
              {!!current.ur_id && <HighlightInfo />}
            </HighlightContext.Provider>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}

export default SchoolCourseHighlight;