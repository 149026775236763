import React from "react";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";
import { Upload, message } from "antd";

import course from "components/services/courseService";

import { fileTypeOf } from "components/utils/type";

const key = "upload";

const EditPageUpload = (props) => {
  const { translations } = useSelector((state) => state);

  const { children, type, id, callback } = props;

  const lang = (value) => translations.initDone && intl.get(value);

  const typeConfig = {
    audio: {
      accept: ".mp3",
      getPermit: course.getUserRecordCommentVoicePermit,
    },
    file: {
      accept: ".png,.jpg,.jpeg,.pdf",
      getPermit: course.getUserRecordCommentFilePermit,
    },
  };

  const beforeUpload = async (file) => {
    try {
      const isDesignated = fileTypeOf(
        file.name,
        typeConfig[type].accept.split(",")
      );
      // if (!isDesignated) {
      //   message.error('不支援上傳該格式');
      //   return;
      // }
      message.loading({ content: lang("general.msg.uploading"), key });
      const permit = await typeConfig[type].getPermit(file, id);
      await course
        .uploadFile(permit, file)
        .then((ret) => {
          message.success({ content: lang("general.msg.upload_success"), key });
          if (callback) callback();
        })
        .catch((ret) => {
          message.error({ content: lang("general.msg.upload_failed"), key });
        });
    } catch {
      message.error({ content: lang("general.msg.upload_failed"), key });
    }
    return false;
  };

  return (
    <Upload
      showUploadList={false}
      beforeUpload={beforeUpload}
      accept={typeConfig[type].accept}
    >
      {children}
    </Upload>
  );
};

export default EditPageUpload;
