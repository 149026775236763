import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Spin, Modal, Icon } from 'antd';

import main from "components/services/mainService";

export default function Html5Game(params) {

    const { id, visible } = params
    const { location } = useSelector((state) => state.new_global);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]); // api 数据
    const [prompt, setPrompt] = useState(''); //title

    const [Cantonese, setCantonese] = useState('');
    const [Putonghua, setPutonghua] = useState('');
    const [English, setEnglish] = useState('');

    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

    const onLanguage = (value) => {
        switch (value) {
            case "CANTONESE":
                return <>
                    <a href={Cantonese.file || ''} >
                        <span style={{ color: 'rgb(31,115,198)', fontSize: '18px' }}>
                            廣東話
                        </span>
                    </a>
                </>;
            case "PUTONGHUA":
                return <>
                    <a href={Putonghua.file || ''} >
                        <span style={{ color: 'rgb(209,32,38)', fontSize: '18px' }}>
                            普通話
                        </span>
                    </a>
                </>;
            case "ENGLISH":
                return <>
                    <a href={English.file || ''} >
                        <span style={{ color: 'rgb(54,121,13)', fontSize: '18px' }}>
                            ENGLISH
                        </span>
                    </a>
                </>;
            default:
                break;
        }
    }

    useEffect(() => {
        if (id) {
            setLoading(true);
            document.body.style.overflow = "hidden";
            main
                .getFullInfo(id)
                .then((ret) => {
                    // console.log(ret);
                    setData(ret);
                    setPrompt(ret.name)
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [id]);

    useEffect(() => {
        if (data.item) {
            // console.log(location);
            const loca = ['my', 'cn']
            const langs = data.item.map((item) => item.lang);
            const cant = langs[0].filter((_) => {
                return _.vo_lang === 'CANTONESE' && _;
            })
            setCantonese(...cant);
            const eng = langs[0].filter((_) => {
                return _.vo_lang === 'ENGLISH' && _;
            })
            setEnglish(...eng);
            const put = langs[0].filter((_) => {
                return _.vo_lang === 'PUTONGHUA' && _;
            })
            const myandcn = put.filter((item)=> {
                return loca.includes(location) ? item.lang === 'cn' : item.lang === 'zh'
            })
            setPutonghua(...myandcn);
        }
    }, [data]);

    return (
        <Modal
            // title={prompt}
            // onClose={() => window.close()}
            visible={visible}
            maskClosable={false}
            footer={null}
            centered
            width={300}
            closable={false}
            bodyStyle={{ padding: '8px', background: 'rgb(85,194,67)', borderRadius: '7px' }}
            mask={false}
        >
            {
                loading ?
                    <div style={{ textAlign: 'center' }}>
                        <Spin indicator={antIcon} />
                    </div>
                    :
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ position: 'relative', padding: '11px 0px' }}>
                            <span style={{ color: '#fff' }}>
                                {prompt}
                            </span>
                            <span
                                style={{ position: 'absolute', right: 0, top: '21%' }}
                                onClick={() => window.close()}
                            >
                                <Icon type="close-circle" style={{ fontSize: 24, color: '#fff' }} />
                            </span>
                        </div>
                        <div style={{
                            background: '#fff',
                            borderBottomLeftRadius: '3px',
                            borderBottomRightRadius: '3px',
                            padding: '12px 0px'
                        }}>
                            {
                                data.length !== 0 && data.support_vo.map((item) => {
                                    return <div key={item} style={{ margin: '10px 0px' }}>
                                        {onLanguage(item)}
                                    </div>
                                })
                            }
                        </div>
                    </div>
            }
        </Modal>
    );
}