import React, { useState } from "react";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import { Form, Input, Select, Button, Switch, Checkbox, message } from "antd";
import { useDispatch, useSelector } from "react-redux";

// import OperationButton from "../common/operationButton";

import { fetchRegister } from "store/action/auth";
import { setVerifyCodeToken } from "store/action/global";

import { SUPPORT_LOCALES } from "config/locale.json";
import { PASSWORD } from "config/app.json";
import { validateRegisterAccount } from "../../utils";

import styles from "../../sass/registerForm.module.scss";

const { Option } = Select;

const RegisterForm = (props) => {
  const { next, subPrev, subNext, location, setAccount } = props;

  const dispatch = useDispatch();

  const {
    translations: { initDone },
    route: { locationUrl },
    auth: {
      loginCode
    }
  } = useSelector((state) => state);

  const lang = (value) => initDone && intl.get(`register.${value}`);

  const roleList = [
    { key: "FATHER", name: "父亲" },
    { key: "MOTHER", name: "母亲" },
    { key: "MATERNAL_GRANDFATHER", name: "爷爷" },
    { key: "MATERNAL_GRANDMOTHER", name: "奶奶" },
    { key: "PATERNAL_GRANDFATHER", name: "外公" },
    { key: "PATERNAL_GRANDMOTHER", name: "外婆" },
    { key: "OTHER", name: "监护人及其他" },
  ];

  const {
    getFieldDecorator,
    validateFields,
    getFieldsError,
    getFieldsValue,
    getFieldValue
  } = props.form;

  const [registerType, setRegisterType] = useState("email");
  const [submitLoading, setSubmitLoading] = useState(false);

  // 禁用状态 通过 error value 共同判断
  const disabled =
    Object.values(getFieldsError()).filter((item) => item).length !== 0 ||
    Object.values(getFieldsValue()).filter((item) => item).length === 0 ||
    Object.keys(getFieldsValue())
    .filter(key => !((['subscription']).indexOf(key) > -1))
    .map(key => getFieldValue(key))
    .reduce((acc , cur) => {
      acc = !!acc ? acc : !(cur);
      return acc;
    } , false)


  const validateAccount = (rule, value, callback) => {
    const { type, result } = validateRegisterAccount(location, value);
    // console.log(rule);
    setRegisterType(type);
    if (!result) {
      callback((" "));
    }
    callback();
  };

  const onSubmit = (e) => {
    if (e.cancelable) e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const { account, password, first_name, relation , subscription } = values;

        const username = registerType === "tel" ? `${SUPPORT_LOCALES[location].tel}${account}` : account;

        const sex = [
          "FATHER",
          "MATERNAL_GRANDFATHER",
          "PATERNAL_GRANDFATHER",
        ].includes(relation)
          ? "F"
          : "M";
        setSubmitLoading(true);
        dispatch(
          fetchRegister({
            registerType,
            account: username,
            password,
            region: location,
            sex,
            first_name,
            relation,
            subscription : (!!subscription ? "Y":"N"),
            callback: (error) => {
              setSubmitLoading(false);
              dispatch(setVerifyCodeToken({ payload: true }));
              if (error) {
                switch(error.result) {
                  case 206:
                  case 207:
                    if (loginCode === 210) {
                      setAccount(username);
                      return subNext();
                    }
                    message.error(initDone && intl.get("loading.publicMsg.register.form.occupied") || "");
                    break;
                  default:
                    return false;
                }
                return;
              }
              next();
            },
          })
        );
      }
    });
  };

  return (
    <div className={styles.registerForm}>
      <h2 className={styles.describe}>{lang("step_one.form_tile")}</h2>
      <h2 className={styles.describe2}>{lang("step_one.form_tip")}</h2>
      <Form onSubmit={onSubmit}>
        <Form.Item hasFeedback>
          {getFieldDecorator("account", {
            rules: [
              {
                required: true,
                message: (initDone && intl.get("loading.publicMsg.register.form.invalidMsg.account")),
              },
              {
                validator: validateAccount,
              },
            ],
          })(<Input placeholder={lang("step_one.form_email")} size="large" />)}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: initDone && intl.get("loading.publicMsg.register.form.invalidMsg.password") || "",
              },
              {
                pattern : PASSWORD,
                message: initDone && intl.get("loading.publicMsg.register.form.verifyMsg.pwdWarnMsg") || "",
              }
            ],
          })(
            <Input.Password
              placeholder={lang("step_one.form_password")}
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator("first_name", {
            rules: [
              {
                required: true,
                message: initDone && intl.get("loading.publicMsg.register.form.verifyMsg.name") || "",
              },
            ],
          })(<Input placeholder={lang("step_one.form_name")} size="large" />)}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator("relation", {
            rules: [
              {
                required: true,
                message: initDone && intl.get("loading.publicMsg.register.form.verifyMsg.name") || "",
              },
            ],
          })(
            <Select placeholder={lang("step_one.form_identity")} size="large">
              {roleList.map((item) => (
                <Option value={item.key} key={item.key}>
                  {initDone &&
                    intl.get(`home.publicMsg.role.addParent.form.${item.key}`)}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator("subscription" , {
            valuePropName : 'checked',
            rules : [
              {
                required : false
              }
            ]
          })(
            <Checkbox>{lang("step_one.form_details_l")}
              <Link to={`${locationUrl}relevant-terms`} target="blank">
                {lang("step_one.form_details_r")}
              </Link>
            </Checkbox>
          )}
        </Form.Item>

        <Form.Item style={{marginBottom:16}}>
          <Button
            block
            className={disabled ? "" : styles.next}
            htmlType="submit"
            disabled={disabled}
            type="primary"
            loading={submitLoading}
          >
            {lang("btn_continue")}
          </Button>
        </Form.Item>

        <Button block className={styles.back} onClick={subPrev}>
          {lang("btn_back")}
        </Button>
      </Form>
    </div>
  );
};

export default Form.create()(RegisterForm);
