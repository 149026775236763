import school from "components/services/school";

const defaultState = {
  class: {
    classTree: [],
    classList: []
  }
}

export function getClass(payload) {
  return { type: 'GET_CLASS', payload }
}

export function getStudent(payload) {
  return { type: 'GET_STUDENT', payload }
}

export function getStaff(payload) {
  return { type: 'GET_STAFF', payload }
}

export function getClassAction(currentLocation, grade)  {
  return (dispatch) => {
    school.getClassTree(currentLocation).then(ret => {
      dispatch(getClass({
        classTree: ret.classTree,
        classList: !!grade ? ret.classList.filter((item) =>
          grade.includes(item.grade_type) 
        ) : ret.classList
      }));
    }).catch(() => {
      dispatch(getClass(defaultState.class));
    });
  }
};

export function getStudentAction(grade)  {
  return (dispatch) => {
    school.getStudentTree(grade).then(ret => {
      dispatch(getStudent(ret));
    }).catch(() => {
      dispatch(getClass([]));
    });
  }
};

export function getStaffAction()  {
  return (dispatch) => {
    school.getStaffList().then(ret => {
      dispatch(getStaff(ret));
    }).catch(() => {
      dispatch(getStaff([]));
    });
  }
};