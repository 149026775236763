const defaultState = {
  token: null,
  pay: [],
  verification210Load: false,
  loginCode: null,
};

export const new_auth = (state = defaultState, { type, payload, temp }) => {
  switch (type) {
    case "SAVE_TOKEN":
      return {
        ...state,
        token: payload,
        temp: { account: temp.account, type: temp.registerType },
      };
    case "RESET_PAY_SUBSCRIBE":
      return { ...state, pay: [] };
    case "ADD_PAY_SUBSCRIBE":
      const { pay } = state;
      const result = (pay || []).find((item) => item.id === payload.id);

      if (payload.service_id === "free") {
        return {
          ...state,
          pay: [
            ...(pay || [])
              .map((item) => (item.id == payload.id ? null : item))
              .filter((item) => !!item),
          ],
        };
      }
      if (result) {
        return {
          ...state,
          pay: [
            ...(pay || []).map(
              (item) => (item.id == payload.id && payload) || item
            ),
          ],
        };
      }
      return { ...state, pay: [...pay, payload] };
    case "VERIFICATION_210_LOAD":
      return { ...state, verification210Load: payload };
    case "SAVE_LOGIN_CODE":
      return { ...state, loginCode: payload.loginCode };
    default:
      return state;
  }
};
