import React from "react";

import intl from "react-intl-universal";

import basicStyle from "assets/css/contact/basic.module.scss";

const transitionPosition = `otherPage.disclaimer`;

const disclaimer = () => {
  return (
    <div className={basicStyle.center} style={{ textAlign: 'justify' }}>
      {intl.getHTML(`${transitionPosition}`)}
      {/* <h1>免責聲明</h1>
      <p>
        EVI Services Limited / evi.com.hk
        <br />
        免責聲明
      </p>
      <p>
        此份公告(另有聲明除外)，均適用於 EVI Services Ltd.
        。聲明內所有"我們"及"本公司"等均泛指 EVI Services Ltd.有關公司。{" "}
      </p>
      <p>
        免責聲明 <br /> 此公告適用於:{" "}
      </p>
      <p>
        (a)使用由 EVI Services Ltd.
        通過"www.evi.com.hk"("EVI網站")所提供的任何服務（EVI服務）。{" "}
      </p>
      <p>
        (b)由 EVI Services Ltd.
        操作的任何相聯伺服器所儲存或應收取的信息(統稱為"EVI伺服器")("該信息")；和{" "}
      </p>
      <p>(c)閣下通過該伺服器所提供,取得或獲得任何商品或服務("使用者")。</p>
      <p>
        此公告免除 EVI Services Ltd.
        和其附屬公司、員工、代理人；承辦商和其他信息提供的第三者(統稱"EVI")。關於任何使用信息，商品和服務所引起的責任。{" "}
      </p>
      <p>
        2.版權 <br />
        該信息是受知識產權法律所保護(包括但不限於保護版權的法律)。如果未經該等權利所有人的同意下載、複製、傳播、印刷、儲存或其他方式使用該信息，使用者可能侵犯版權法。使用者的責任是自己決定有否觸犯版權法。{" "}
      </p>
      <p>
        3.一般免責聲明 <br />
        EVI在法律容許下特此明示捨棄以下各種責任、陳述或擔保：
      </p>
      <p>
        (a)由EVI服務或該信息所帶出有關質量、用途、使用、準確性、時限或適用性。{" "}
      </p>
      <p>(b)通過EVI伺服器或依據該信息取得、提供或獲得任何商品或服務。 </p>
      <p>
        (c)伺服器或該信息是否不會被中斷或不會發生錯誤。該信息不能遏制任何病毒，或有污染性或有破壞性的特質。{" "}
      </p>
      <p>(d)該信息沒有對使用者或其他人士引起不妥當或令人厭惡的後果。 </p>
      <p>
        (e)該信息導致有錯誤、遺漏或失實陳述。 EVI Services Ltd.
        沒有贊同或推薦，該信息所提供的任何人士、機構、名稱、產品或服務。該信息並不成為
        EVI Services Ltd. 的觀點或意見。除非以書面形式明示，否則EVI Services
        Ltd.
        將不會成為在通過EVI伺服器或該信息使用者和其他第三者進行任何交易中的立約人。
      </p>
      <p>
        4.責任的限制 <br />
        在法律容許下， EVI Services Ltd.
        將不會負責使用者或其他人士因侵權法、合約法、成文法或其他形式(包括但不限於因疏忽、違約、誹謗及侵犯知識產權)
      </p>
      <p>(a)所引致的直接損失 </p>
      <p>(b)相應引起的或間接的損失(包括但不限於收入或利潤上的損失) </p>
      <p>
        (c)使用者容許，或引致其他人士，或經由該信息或EVI服務而引致其他人士的責任。
      </p>
      <p>
        在不局限前文的原則下， EVI Services Ltd.
        將不會負責使用者，或其他人士，法定團體、非法組織或主管當局，因千禧年來臨所引起直接或間接令到EVI服務或功能中斷、延誤，功能失常或不能執行的責任。{" "}
      </p>
      <p>
        5.保密條件 <br />
        EVI Services Ltd.
        不會保證(亦不負責)經EVI網站和EVI服務以私人或其他形式傳播的機密性信息或數據。而
        <br />
        EVI Services Ltd. 一貫的政策是採取合理步驟維持保密。
      </p>
      <p>
        (請不時參考 EVI Services Ltd. 關於個人資料的政策)
        <br />
        (請瀏覽現行版本)。
      </p>
      <p>
        6.使用者的責任 <br />
        由於 EVI Services Ltd.
        容許使用者取用信息，包括EVI網站、EVI伺服器和EVI服務，而使用者同意：
      </p>
      <p>(a)不會進行或容許觸犯版權； </p>
      <p>
        (b)不會使用該信息，或使用者不可取用EVI網站、EVI伺服器和EVI服務作為非法、不妥當、不雅或誹謗的目的。{" "}
      </p>
      <p>(c)採取一切步驟確保該信息不會破壞使用者的信息或系統。 </p>
      <p>(d) EVI Services Ltd. 進行研究取用該信息的調查。 </p>
      <p>
        (e)此公告所提到關於 EVI Services Ltd. 的免責聲明和責任限制，和關於 EVI
        Services Ltd. 現行個人資料的政策。
      </p>
      <p>
        7.法律和劃分性 <br />
        此公告受香港法律管制，此公告的每一部份只可適用於容許範圍內。若果任何司法管轄區禁止或不能強制執行此公告任何一部份的話，只代表禁止或不能強制執行的部份在該管轄區失效力。但不代表此公告的其他部份在其他司法管轄區失去效用，亦不代表會影響其禁止或不能強制執行的效力。雖然此免責聲明以中文書寫，但以英文版本為依據。若中英文版本有不同之處，以英文為準。
      </p>
      <p>
        安全聲明
        <br />
        此份聲明(另有聲明除外)內所有"我們"及"本公司"等均泛指 EVI Services Ltd.
        內之有關公司。
        <br />
        雖然互聯網在本質上並不是一個安全的通訊環境，但透過運用適當的技術，可以增強其安全性，本公司做法便是一例。然而，互聯網的安全並非單純的技術問題。個人及交易數據安全防範之常識及慣常措施亦同樣重要。「黑客」入侵系統必須經過一道「門」，而這一道「門」，往往是由於在傳遞機密文件或者在處理密碼、個人識別代碼等機密數據過程中疏忽大意，而給黑客造成可乘之機。因此，用戶必須萬分小心地保護這些文件和數據。
        <br />
        本公司對待安全問題十分審慎，嚴格保密從本公司網站用戶獲得的所有個人識別資料。除了採用防火牆和其他精密設備以外，本公司還提供及維護以下措施，來保護本公司的系統、資訊以及數據，使其免遭意外或惡意破壞。
      </p>
      <p>
        *採用安全標準
        <br />
        本公司堅持採用國際標準，以確保交易完整及保護機密資料。此等標準與（i）商業活動及資訊保密慣例；（ii）交易完整性及（iii）資訊保護有關係。
      </p>
      <p>
        *獲取個人識別資料之內部指引
        <br />
        本公司所有員工均遵循本公司的獲取個人識別資訊之內部指引，製訂該指引旨在限制個人資料的獲取，只有具備正當需要的人員，方可獲取此等資料。
      </p> */}
    </div>
  );
};

export default disclaimer;
