const defaultState = {
  uploadResult: null,
};

export const new_user = (state = defaultState, { type, payload }) => {
  switch (type) {
    case "UPLOAD_SUCCESS":
      return { ...state, uploadResult: payload };
    default:
      return state;
  }
};
