import React from "react";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";
import { Modal, Icon, message } from "antd";

import course from "components/services/courseService";

const DeleteFile = (props) => {
  const { children, id, action, callback, style } = props;

  const { translations } = useSelector((state) => state);

  const lang = (value) => translations.initDone && intl.get(value);

  const handleDeleteFile = (e) => {
    console.log(e);
    e.stopPropagation();
    Modal.confirm({
      title: lang("general.msg.delete_confirm_msg"),
      icon: <Icon type="delete" theme="twoTone" twoToneColor="#eb2f96" />,
      onOk: (e) => {
        if (id && action) {
          return course
            .deleteCommentFile(id, action)
            .then((ret) => {
              message.success(lang("general.msg.delete_success"));
              if (callback) callback();
            })
            .catch(() => {
              message.error(lang("general.msg.delete_error"));
            });
        }
      },
      okText: lang(`general.button.confirm`),
      cancelText: lang(`general.button.cancel`),
    });
  };

  return (
    <div onClick={handleDeleteFile} style={style}>
      {children}
    </div>
  );
};

export default DeleteFile;
