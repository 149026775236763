import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { skipRegisterBindChildren } from "../../store/action/global";

import Step from "./components/step";

const Register = (props) => {
  const { $location, location } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    user,
    route: { locationUrl },
  } = useSelector((state) => state);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const key = urlParams.get("app");
    /**
     * @description 如果是从 第三方 跳转过来，并且已登录，跳转回 login 完成验证流程
     */
    if (!!user.id && !!key && key === "efunfun") {
      history.push(`${locationUrl}user/login?app=${key}`);
      return;
    }

    // if (key === "parentsdaily") {
    //   dispatch(skipRegisterBindChildren({ payload: true }));
    // } else {
    //   dispatch(skipRegisterBindChildren({ payload: false }));
    // }
  }, []);

  useEffect(() => {
    return () => {
      dispatch({
        type: "UPDATE_AUTH",
        payload: {
          loginCode: "",
          kid_token: "",
        },
      });
    };
  }, [location.pathname]);

  return (
    <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
      <Step
        location={$location}
        codeData={
          props.location.state ? props.location.state : { code: "", email: "" }
        }
      />
    </div>
  );
};

export default Register;
