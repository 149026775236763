// 跳过注册付款页面
export function skipRegisterPay({ payload }) {
  return (dispatch) => {
    dispatch({ type: "SKIP_REGISTER_PAY", payload });
  };
}

// 跳过新增子女页面
export function skipRegisterBindChildren({ payload }) {
  return (dispatch) => {
    dispatch({ type: "SKIP_BIND_CHILDREN", payload });
  };
}

// registger 验证码状态持久
export function setVerifyCodeToken({ payload }) {
  return (dispatch) => {
    dispatch({ type: "SET_VERIFY_CODE", payload });
  };
}

// registger 验证码状态持久
export function switchLanguageLocation({ payload }) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_LANGUAGE_LOCATION", payload });
  };
}
