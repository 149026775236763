const defaultState = {
  addChildrenId: undefined,
};

export const new_family = (state = defaultState, { type, payload }) => {
  switch (type) {
    case "SAVE_ID":
      return { ...state, addChildrenId: payload };
    case "SET_ID":
      return { ...state, addChildrenId: payload };
    default:
      return state;
  }
};
