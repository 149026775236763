import React, { useState, useEffect } from "react";
import { Avatar, Popover, Row, Col } from "antd";

import "components/studentReport/less/popcomfirm.less";

const icons = ["cmt_icon1", "cmt_icon2", "cmt_icon3", "cmt_icon4"];

const styles = {
  icon: {
    width: 68,
    height: 68,
    marginRight: 9,
    cursor: "pointer",
    borderRadius: "50%",
  },
};

const CustomPopover = (props) => {
  const [active, setActive] = useState(-1);

  const { record, updateTableRow } = props;

  const src = record.icon || record.jttw_icon || "";

  useEffect(() => {
    // 获得数据时,更新 star
    setActive(parseInt(record.star, 10));
  }, [record.star]);

  const content = () => (
    <Row type="flex" justify="space-around" align="middle" gutter={9}>
      {icons.map((item, index) => (
        <Col key={index} span={6}>
          <img
            style={{
              border: active === index + 1 ? "3px solid #00c6ff" : 0,
              filter: `grayscale(${
                active === index + 1 || !active ? "0" : "100"
              }%)`,
              ...styles.icon,
            }}
            src={require(`assets/image/${item}.png`)}
            onClick={() => setActive(index + 1)}
            alt="icon"
          />
        </Col>
      ))}
    </Row>
  );

  return (
    record.icon && (
      <Popover
        placement="right"
        content={content()}
        trigger="hover"
        overlayClassName="popover"
        onVisibleChange={(visible) => {
          // 当 star 没有改变时,不进行更新
          if (visible === false && parseInt(record.star, 10) !== active) {
            updateTableRow({
              record,
              _star: active,
              callback: () => setActive(-1),
            });
          }

          if (visible && record.ur_id) {
            setActive(parseInt(record.star));
          }
        }}
      >
        <Avatar src={src} />
      </Popover>
    )
  );
};

export default CustomPopover;
