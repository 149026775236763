import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; 
import { Row, Col, Skeleton, message, Timeline } from 'antd';

import intl from "react-intl-universal";
import InfiniteScroll from "react-infinite-scroller";

// import Resource from "components/course/Resource";
import Img from "components/common/Img";
import TitleCard from "components/common/TitleCard";
import Adapter from "components/resource/Adapter";
import userLog from "components/services/userLogService";

import "assets/css/History.module.scss";

const SkeletonList = () => Array.from({ length: 10 }, (_, i) => <Skeleton key={i} active avatar />);

const HistoryPage = () => {
  const { translations } = useSelector(state => state);

  const [ loading, setLoading ] = useState(false);
  const [ data, setData ] = useState({ total: 0, rows: [] });

  const lang = (value) => translations.initDone && intl.get(value);

  //获取数据
  const getList = (offset) => {
    userLog.history(offset).then(ret => {
      const { total, rows } = ret;
      setData(old => {
        return { total, rows: [ ...old.rows, ...rows ] }
      })
    }).finally(() => {
      setLoading(false);
    })
  }

  const handleInfiniteOnLoad = (page) => {
    const offset = page * 10;
    if(loading) {
      return;
    }
    if(data.rows.length >= data.total) {
      message.warning(lang("general.no_more_record"));
      return;
    }
    getList(offset);
  }

  useEffect(() => {
    setLoading(true);
    getList();
  }, [])

  return (
    <TitleCard 
      title={lang("history.content.title")}
      titleBgColor='#1cb394'
      titleColor='#fff'
      mainBgColor='#fff'
      span={{ span: 24 }}
    >
      <div className='infinite_container' style={{ height: 800, padding: '30px 10px' }}>
        {!loading ? (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            hasMore={true}
            useWindow={false}
          >
            <Timeline mode="alternate" className="timeline_container">
              {data.rows.map(item => (
                <Timeline.Item key={item.id} >
                  <Adapter 
                    item={item}
                    res_type={item.type}
                    ref_id={item.ref_id}
                    nWindow={true}
                  >
                    <Row 
                      type="flex" 
                      justify="center" 
                      align="middle"
                      gutter={[20, 10]}
                    >
                      <Col>
                        <Img
                          style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: '50%' }}
                          src={item.file || 'https://oss-resource.evigarten.com/school_resource/default/document.png'}
                          alt="course"
                        />
                      </Col>
                      <Col style={{ textAlign: 'left', color: 'inherit' }}>
                        <h3>{item.name}</h3>
                        <h5>{item.create_time}</h5>
                      </Col>
                    </Row>
                  </Adapter>
                </Timeline.Item>
              ))}
            </Timeline>
          </InfiniteScroll>
        ) : (<SkeletonList />)}
      </div>
    </TitleCard>
  )
}

export default HistoryPage;