import React, { useState } from 'react';
import intl from "react-intl-universal";
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Select, message, Tooltip, Icon, Switch } from 'antd';

import user_API from "components/services/userService";
import locale from "config/locale";

const { Option } = Select;



const language = {
  zh: 'zh-hk',
  english: 'en'
}

export default function Setting() {
  const dispatch = useDispatch();

  const {
    user,
    route: { currentLocation, realUrl },
    translations: { initDone }
  } = useSelector(state => state);

  const [loading, setLoading] = useState(false);

  const lang = value => initDone && intl.get(value);
  const { region } = user;

  const _user = (user.family || []).filter(_fM => {
    return _fM.permit_user === user.id;
  });

  const sex = _user.length > 0 ? _user[0].permit_user_sex : "M";

  const handleChange = async (e) => {
    try {
      setLoading(true);
      await user_API.updateUserMsg({
        first_name: user.full_name,
        sex,
        relation: _user.length > 0 ? _user[0].relation : "FATHER",
        language: e
      });
      const ret = await user_API.me(true).then(_user => {
        dispatch({ type: "INIT", payload: _user })
        return _user;
      });
      if (currentLocation !== 'cn' && ret.language === 'cn') {
        window.location = "https://garten.evifamily.com.cn/cn/";
        return;
      }
      if (language[ret.language]) {
        window.location = `/${currentLocation}/${language[ret.language]}${realUrl}`
      }
    } catch (ex) {
      message.error('更改失败');
    } finally {
      setLoading(false);
    }
  }

  const crossBorder = (bool) => {
    window.location.href = bool ? `${process.env.REACT_APP_CBS_URL}/${currentLocation}` : process.env.REACT_APP_BASE_URL;
  }

  return (
    <Row
      type="flex"
      gutter={[10, 20]}
      align="middle"
    >
      <Col span={3} style={{ textAlign: 'right' }}>
        <span>{lang('personalCenter.setting.language')}：</span>
      </Col>
      <Col span={21}>
        <Select
          value={user.language}
          style={{ width: 120 }}
          onChange={handleChange}
          loading={loading}
        >
          {
            locale.SUPPORT_LOCALES[region && region.toLowerCase()] &&
            locale.SUPPORT_LOCALES[region && region.toLowerCase()].lang.map((_lang) => (
              <Option value={_lang.value} key={_lang.url}>
                {_lang.name}
              </Option>
            ))
          }
        </Select>
      </Col>
      <Col span={3} style={{ textAlign: 'right' }}>
        <span>
          {lang('general.msg.transborder_title')}
          <Tooltip
            title={<div dangerouslySetInnerHTML={{__html: lang('general.msg.transborder_msg')}} />} placement='bottom'
          >
            <Icon type="info-circle" style={{marginLeft: 5}} />
          </Tooltip>
          ：
        </span>
      </Col>
      <Col span={21}>
        <Switch
          checkedChildren={<Icon type="check" />}
          unCheckedChildren={<Icon type="close" />}
          defaultChecked={window.location.origin === process.env.REACT_APP_CBS_URL}
          onChange={crossBorder}
        />
      </Col>
      {/* {user_API.getType() === "PARENT" && (
        <>
          <Col span={3} style={{ textAlign: 'right' }}>
            <span>
              是否開通 Parentsdaily：
            </span>
          </Col>
          <Col span={21}>
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              // defaultChecked={window.location.origin === process.env.REACT_APP_CBS_URL}
              // onChange={crossBorder}
            />
          </Col>
        </>
      )} */}
    </Row>
  )
}
