import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Icon, Input, message } from "antd";
import intl from "react-intl-universal";

import authService from "components/services/authService";
import user_API from "components/services/userService";

import styles from "./sass/AccountDeletionNew.module.scss";

const useCode = () => {
  const arr = "unbind";
  // const index = Math.floor(Math.random() * arr.length + 1) - 1;
  return arr
    .split("")
    .map((code) => {
      const isUp = !!Math.floor(Math.random() * 2);
      return isUp ? code.toUpperCase() : code.toLowerCase();
    })
    .join("");
};

export default function AccountDeletion() {
  const inputRef = useRef();

  const [deleteModal, setDeleteModal] = useState(false);

  const {
    user: { id, first_name, family },
    user,
    translations,
  } = useSelector((state) => state);

  // 当前家庭中对应的信息
  const familyItem = family.find((item) => item.permit_user === id);

  const lang = (value) => translations.initDone && intl.get(value);

  const renderMsg = (type, arg) => {
    const resource_permit =
      familyItem.role_group_list.indexOf("USER_RESOURCE") > 0;
    const isParent = familyItem.relation !== "CHILD";
    const isSchool = !!familyItem.permit_kid;

    if (!!isParent) {
      return intl.getHTML(
        `personalCenter.personalInfo.role.${type}.administrator`,
        arg
      );
    } else if (!!isSchool) {
      return intl.getHTML(
        `personalCenter.personalInfo.role.${type}.schoolUser`,
        arg
      );
    } else if (!!resource_permit) {
      return intl.getHTML(
        `personalCenter.personalInfo.role.${type}.paidVisitor`,
        arg
      );
    } else {
      return intl.getHTML(
        `personalCenter.personalInfo.role.${type}.trialVisitor`,
        arg
      );
    }
  };

  const code = useCode();

  const UNbindHandle = () => {
    const value = inputRef.current.state.value;

    if ((value || "").trim() !== code) {
      message.error(lang("personalCenter.personalInfo.deleteAccountErrorMsg"));
      return;
    }
    Modal.confirm({
      title: <h2>{renderMsg("dialogTitle")}</h2>,
      className: styles.describe,
      centered: true,
      content: (
        <div>
          {renderMsg("html", {
            family_name: family && family[0].family_name,
            userName: first_name,
            userAccPassport: familyItem.permit_kid_acc,
          })}
        </div>
      ),
      icon: null,
      // 位置对调 cancel 显示 是
      okText: lang(`general.button.no`),
      cancelText: lang(`general.button.yes`),
      onCancel: () => {
        authService
          .deleteAccount(id)
          .then(() => {
            Modal.confirm({
              title: <h2>{lang("personalCenter.personalInfo.role.msg")}</h2>,
              className: styles.hint,
              centered: true,
              icon: null,
              content: (
                <div>{lang("personalCenter.personalInfo.role.content")}</div>
              ),
              okText: lang("personalCenter.personalInfo.role.confirm"),
              onOk: () => {
                window.location.href = user_API.getHomeURL();
              },
            });
          })
          .catch(() => {
            message.error(lang(`general.msg.delete_error`));
          });

        // const value = inputRef.current.state.value;
        // if ((value || "").trim() !== code) {
        //   message.error(
        //     lang("personalCenter.personalInfo.deleteAccountErrorMsg")
        //   );
        //   return Promise.reject();
        // }
        // return authService
        //   .deleteAccount(id)
        //   .then(() => {
        //     window.location.href = user_API.getHomeURL();
        //   })
        //   .catch(() => {
        //     message.error(lang(`general.msg.delete_error`));
        //   });
      },
    });

    // message.error(
    //   lang("personalCenter.personalInfo.deleteAccountErrorMsg")
    // );
    // authService
    //   .deleteAccount(id)
    //   .then(() => {
    //     window.location.href = user_API.getHomeURL();
    //   })
    //   .catch(() => {
    //     message.error(lang(`general.msg.delete_error`));
    //   });
  };

  return (
    <div>
      <Button
        type="danger"
        // onClick={handleConfirm}
        onClick={() => setDeleteModal(true)}
      >
        {lang("personalCenter.personalInfo.deleteAccount")}
      </Button>
      <Modal
        title=""
        visible={deleteModal}
        footer={null}
        centered
        maskStyle={{ backgroundColor: "#262626" }}
        // onOk={this.handleOk}
        onCancel={() => setDeleteModal(false)}
        className={styles.modal}
      >
        <h2>
          {intl.getHTML(`personalCenter.personalInfo.delete_Account.describe`, {
            code,
          })}
        </h2>

        <Input size="large" ref={inputRef} />
        <Button
          block
          size="large"
          type="danger"
          onClick={UNbindHandle}
          style={{ backgroundColor: "#ff000d" }}
        >
          {lang(`personalCenter.personalInfo.delete_Account.delete`)}
        </Button>
        <Button block onClick={() => setDeleteModal(false)}>
          {lang(`personalCenter.personalInfo.delete_Account.back`)}
        </Button>
      </Modal>
    </div>
  );
}
