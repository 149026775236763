import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";
import { Button, Modal, Row, Col } from "antd";
import Logintime from "components/chart/logintime";

import styles from "assets/css/PersonalCenter.module.scss";

import userLog from "components/services/userLogService";

const UseTime = () => {
  const { translations } = useSelector((state) => state);
  const [timeData, setTimeData] = useState([]);
  const [timeType, setTimeType] = useState([]);
  const [time, setTime] = useState(0);

  const lang = (value) => translations.initDone && intl.get(value);

  //今日使用时间数据
  const getTimeData = async (type = "DAY") => {
    await userLog
      .daily(type)
      .then((ret) => {
        let typeArr = [];
        let obj = {};
        let dataArr = [];
        let num = 0;
        ret.forEach((item) => {
          typeArr.push(item.course_name);
          obj[item.course_name] = Number(item.duration);
          num += Number(item.duration);
        });
        dataArr.push(obj);
        setTimeData(dataArr);
        setTimeType(typeArr);
        setTime(num);
      })
      .catch((_msg) => {
        console.log(_msg);
      });
  };

  useEffect(() => {
    getTimeData();
  }, []);

  return (
    <div className={styles.use_time}>
      <h2>{lang("personalCenter.personalInfo.timeInEVIGartenToday")}</h2>
      <h3>
        {Math.ceil(time)}
        {lang("personalCenter.personalInfo.min")}
      </h3>
      <Logintime
        data={timeData}
        type={timeType}
        select={(type) => getTimeData(type)}
      />
    </div>
  );
};

export default UseTime;
