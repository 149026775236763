import React, { useState } from 'react';

// 用于图片加载失败后设置默认图片
const Img = (props) => {
  const { src, alt, defaultImg, ...rest } = props;

  const [isError, setIsError] = useState(true)

  const handleImageErrored = (e) => {
    // 只调用一次 onError 防止默认图加载不出来导致死循环
    console.log(e.target);
    if (isError) {
      setIsError(false);
      e.target.src = defaultImg || 'https://oss-resource.evigarten.com/school_resource/default/document.png';
    }
  }

  return (
    <img 
      src={src}
      alt={alt}
      onError={handleImageErrored}
      {...rest}
    />
  )
}

export default Img;