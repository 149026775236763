import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon, Modal } from "antd";
import intl from "react-intl-universal";

import ShowVideoModal from "components/common/modal/ShowVideoModal";
// import DrWongModal from "./drWong";
// import CantoneseOpera from "./CantoneseOpera";
import SchoolResourceProject from "components/resource/SchoolResourceProject";
import SchoolResourceDefault from "components/resource/SchoolResourceDefault";
import SchoolResourceDownload from "components/resource/SchoolResourceDownload";

import { toast } from "react-toastify";

import user from "components/services/userService";
import log from "components/services/logService";

import { SUPPORT_LOCALES } from "config/locale.json";
class Adapter extends Component {
  state = {
    visible: false,
    visibleUpgrade: false,
    list: [], // 储存其他类型返回数据
    comment: [],
  };

  typeList = [
    "mix",
    "video",
    "audio",
    "worksheet",
    "document",
    "img_real",
    "project",
    "teach_guide",
    "download",
    // "dr_wong",
    "eval",
    // "mc",
    // "jttw360",
    // 'cantonese_opera'
  ];

  componentDidMount = async () => {
    // console.log(this.props);
    if (this.props.autoRun) {
      this.action();
    }
  };

  action = async () => {
    const { locationUrl } = this.props.route;
    const { res_type, ref_id, id, item } = this.props;
    if ((res_type || item.res_type || item.type) === "COURSE") {
      window.location = `${locationUrl}course/${!!this.props.info ? "info/" : ""
        }${item.display_id || id || item.ref_id}`;
    } else {
      this._action(
        res_type || item.res_type || item.type,
        ref_id || item.ref_id,
        id || item.id,
        true
      );
    }
  };

  resourceModal = (id, refId, type, courseId, item) => {
    const { visible } = this.state;
    const {
      user: { latest_version },
    } = this.props;
    switch (type) {
      case "mix":
        return (
          <ShowVideoModal id={id} visible={visible} onCancel={this.onCancel} />
        );
      case "eval":
      case "project":
        return (
          // (type === 'eval' || latest_version === 'Y') ? (
          <SchoolResourceProject
            refId={refId}
            courseId={courseId}
            visible={visible}
            item={item}
            onCancel={this.onCancel}
          />
          // ) : (
          //   <SchoolResourceDefault
          //     refId={refId}
          //     visible={visible}
          //     courseId={type === "project" && courseId}
          //     onCancel={this.onCancel}
          //   />
          // )
        );
      // case "dr_wong":
      //   return (
      //     <DrWongModal id={refId} visible={visible} onCancel={this.onCancel} />
      //   );
      // case 'cantonese_opera':
      //   return (
      //     <CantoneseOpera id={refId} visible={visible} onCancel={this.onCancel} />
      //   );
      case "video":
      case "audio":
      case "worksheet":
      case "document":
      case "img_real":
      case "teach_guide":
        return (
          <SchoolResourceDefault
            refId={refId}
            visible={visible}
            onCancel={this.onCancel}
          />
        );
      case "download":
        return (
          <SchoolResourceDownload
            refId={refId}
            visible={visible}
            onCancel={this.onCancel}
          />
        );
      // case "jttw360":
      //   return (
      //     <SchoolResourceMc
      //       refId={refId}
      //       visible={visible}
      //       onCancel={this.onCancel}
      //     />
      //   );
      // case "mc":
      //   return (
      //     <SchoolResourceMcTest
      //       refId={refId}
      //       visible={visible}
      //       onCancel={this.onCancel}
      //     />
      //   );
      default:
        return;
    }
  };

  _action = async (type, refId, id, setUrl) => {
    if (setUrl) {
      let _route = this.props.route;
      _route.realUrl = "/resource/" + refId;
      log.PV(_route);
    }
    // const typeList = ['mix', 'video', 'audio', 'worksheet', 'document', 'img_real', 'project', 'teach_guide', 'mc', 'download', 'dr_wong', 'eval'];
    if (this.typeList.includes(type)) {
      this.setState({ visible: true });
    } else {
      if (!!refId) {
        this.goResource(refId);
      }
    }
  };

  //关闭对话框
  onCancel = () => {
    this.setState({ visible: false });
  };

  /**
   *
   * @param {int} res_id
   * @description 預設課件跳轉
   * @returns {}
   */
  async goResource(res_id) {
    let msg = "";
    this.setState({
      list: await user
        .goResource(res_id)
        .then((ret) => {
          try {
            const { redirect } = ret.parms;
            const { section } = this.props.match.params;
            if (redirect && section) {
              ret.parms.redirect = `${redirect}/${section}`;
            }
          } catch (error) {
            console.log(error);
          }
          return ret;
        })
        .catch((_err) => {
          //SHOW MESSAGE
          console.log(_err + "数据请求加載失敗");
          msg = _err.msg;
          return {};
        }),
    });

    if (Object.keys(this.state.list).length > 0) {
      const { parms, url } = this.state.list;
      this.openPostWindow(parms, url);
    } else {
      toast.error(msg, {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  //打开新窗口
  openPostWindow = (parms, url) => {
    // 特定条件阻止跳转新页面
    const { no_create_from } = this.props;
    if (no_create_from) {
      return;
    }

    var tempForm = document.createElement("form");
    tempForm.id = "tempForm1";
    tempForm.method = "post";
    tempForm.action = url;
    //      if(!navigator.userAgent.match(/(iPod|iPhone|iPad)/i)) {
    //        tempForm.target = "_blank";
    //      }
    if (!!this.props.course && !!this.props.course.lang) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = "res_lang";
      input.value =
        this.props.course.lang.length > 0
          ? this.props.course.lang.map(({ lang }) => lang).join(",")
          : SUPPORT_LOCALES[this.props.route.currentLocation].lang
            .map(({ value }) => value)
            .join(",");
      tempForm.appendChild(input);
    }
    Object.keys(parms).map((_key) => {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = _key;
      input.value = parms[_key];
      tempForm.appendChild(input);
      return null;
    });
    if (document.all) {
      tempForm.attachEvent("onsubmit", function () { }); //IE
    }
    document.body.appendChild(tempForm);
    if (document.all) {
      tempForm.fireEvent("onsubmit");
    } else {
      tempForm.dispatchEvent(new Event("submit"));
    }
    tempForm.submit();
    document.body.removeChild(tempForm);
  };

  state = { visible: false };

  showModal = () => {
    const {
      item,
      route: { currentLocation },
    } = this.props;
    if (!!item.permit_menu_id && !["cn", "tw"].includes(currentLocation)) {
      this.props.showUser(true);
      // this.setState({
      //   visibleUpgrade: true,
      // });
    }
  };

  onOpend = (ref) => {
    this.child = ref;
  };

  hideModal = () => {
    this.setState({
      visibleUpgrade: false,
    });
  };

  render() {
    // console.log(user.getType());
    const { locationUrl } = this.props.route;
    const { res_type, ref_id, id, nWindow, translations, disabledClick } =
      this.props;
    const {
      item,
      route: { currentLocation },
    } = this.props;

    const isCourse =
      (res_type || item.res_type || item.type || "") === "COURSE";

    const tAction =
      this.typeList.indexOf(res_type || item.res_type || item.type || "") > -1;

    const courseType = "course";
    // console.log(item);
    return (
      <React.Fragment>
        {!!item && (item.status !== "LOCKED" || user.getType() === "STAFF") && (
          <React.Fragment>
            {!!isCourse && (
              <Link
                onClick={(e) => {
                  if (disabledClick) {
                    e.preventDefault();
                  }
                }}
                to={{
                  pathname: `${locationUrl}${courseType}/${item.display_id || id || item.ref_id
                    }`,
                  state: { locationUrl },
                  query: { id: item.id },
                }}
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                {this.props.children}
              </Link>
            )}
            {!isCourse && !tAction && !!nWindow && (
              <Link
                onClick={(e) => {
                  if (disabledClick) {
                    e.preventDefault();
                  }
                }}
                target="_blank"
                to={{
                  pathname: `${locationUrl}resource/${id || item.ref_id}`,
                  state: { locationUrl },
                  query: { id: item.id },
                }}
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                {this.props.children}
              </Link>
            )}
            {!isCourse && (!!tAction || (!tAction && !nWindow)) && (
              <React.Fragment>
                <div
                  onClick={() => {
                    if (disabledClick) {
                      return false;
                    }
                    this._action(
                      res_type || item.res_type || item.type,
                      ref_id || item.ref_id,
                      id || item.id,
                      false
                    );
                  }}
                  className={"cursor-pointer"}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    cursor: disabledClick ? "auto" : "pointer",
                  }}
                >
                  {this.props.children}
                </div>
                {this.resourceModal(
                  id || item.id,
                  ref_id || item.ref_id,
                  res_type || item.res_type || item.type,
                  item.course_id,
                  item
                )}
                {/* {this.state.type === 'dr_wong' && (
                  <DrWongModal
                    id={ref_id || item.ref_id}
                    visible={this.state.visible}
                    onCancel={this.onCancel}
                  />
                )} */}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <Modal
          width={600}
          visible={this.state.visibleUpgrade}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          okText={translations.initDone && intl.get("general.button.confirm")}
          cancelText={
            translations.initDone && intl.get("general.button.cancel")
          }
        >
          <p>
            {currentLocation === "cn"
              ? translations.initDone &&
              intl.getHTML("general.msg.please_upgrade_cn")
              : translations.initDone &&
              intl.getHTML("general.msg.please_upgrade")}
          </p>
        </Modal>
        {!!item && item.status === "LOCKED" && user.getType() !== "STAFF" && (
          <div
            onClick={() => {
              if (disabledClick) {
                return false;
              }
              this.showModal();
            }}
          >
            <div style={{ position: "absolute", width: "90%", zIndex: "2" }}>
              <div
                style={{
                  margin: "0 auto",
                  width: "30px",
                  color: "#FFF",
                  fontSize: "30px",
                  paddingTop: "30%",
                }}
              >
                <Icon type="lock" />
              </div>
            </div>
            <div style={{ filter: "brightness(50%)" }}>
              {this.props.children}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps({ route, user, translations, new_global }) {
  return {
    route,
    user,
    translations,
    new_global,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showUser: (payload) => dispatch({ type: "SET_SHOW_USER", payload }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Adapter);
