import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button, List, Avatar, Icon, Input, message, Modal } from 'antd';
import intl from "react-intl-universal";

import HighlightItem from './HighlightItem';
import HighlightContext from './HighlightContext';

import course from 'components/services/courseService';

import styles from '../sass/highlightInfo.module.scss';

const { TextArea } = Input;

export default function HighlightInfo() {
  const inputRef = useRef();
  const { 
    user: { id: user_id, img: user_avatar },
    translations: { initDone }
  } = useSelector(state => state);
  const { useCurrent } = useContext(HighlightContext); // 從 index.js 傳過來的
  const [current, setCurrent] = useCurrent;

  const [value, setValue] = useState(''); // textAre框 value
  const [loading, setLoading] = useState(false); // commentList loading
  const [commentList, setCommentList] = useState({ total: 0, rows: [] });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [updateId, setUpdateId] = useState(''); // 用於更改的 id
  const [updateLoading, setUpdateLoading] = useState(false);

  const lang = value => initDone && intl.get(value);

  const getList = useCallback(async () => {
    await course.getHighlightCommentList(current.ur_id).then(ret => {
      setCommentList(ret);
      setCurrent(current => ({ ...current, num_of_comment: ret.total }));
    }).finally(() => {
      setLoading(false);
    })
  }, [current.ur_id])

  const handleDelete = (id) => {
    Modal.confirm({
      title: lang('general.msg.delete_confirm_msg'),
      okText: lang('general.button.confirm'),
      cancelText: lang('general.button.cancel'),
      onOk: () => {
        return course.deleteHighlightComment(id).then(() => getList()).catch(() => message.error(lang('general.msg.delete_error')));
      }
    })
  }

  const setComment = async (type, id, value) => {
    await course.setHighlightComment(type, id, value);
    await getList();
  }

  const handleUpdate = (prevValue) => async (e) => {
    const value = e.target.value;
    // 判断是否有更改
    if (e.target.value === prevValue) {
      setUpdateId('');
      return;
    }
    // 判断是否为空
    if (!(value || '').trim()) {
      message.warn(lang('schoolCourse.highlight.msg_no_empty'));
      return;
    }
    try {
      setUpdateLoading(true);
      await setComment('update', updateId, value);
    } catch (error) {
      message.error(lang('schoolCourse.highlight.msg_comment_error'));
    } finally {
      setUpdateLoading(false);
      setUpdateId('');
    }
  }

  const onSubmit = async () => {
    if (!(value || '').trim()) {
      message.warn(lang('schoolCourse.highlight.msg_no_empty'));
      return;
    }
    try {
      setSubmitLoading(true);
      await setComment('add', current.ur_id, value);
    } catch (error) {
      message.error(lang('schoolCourse.highlight.msg_comment_error'));
    } finally {
      setSubmitLoading(false);
      setValue('');
    }
  }

  useEffect(() => {
    setLoading(true);
    getList();
  }, [])

  return (
    <div className={styles.root}>
      <Button 
        type="link"
        icon="left"
        onClick={() => setCurrent({})}
        style={{
          padding: 0,
          fontSize: 20,
          fontWeight: 'bold',
          color: '#29ABE2',
          marginBottom: 21
        }}
      >
        {lang('general.button.back_prev_page')}
      </Button>
      <Row
        type="flex"
        gutter={[40, 20]}
        className={styles.main}
      >
        <Col md={8} xs={24}>
          <HighlightItem 
            data={current}
            maskHidden
          />
        </Col>
        {current.is_comment === 'Y' && (
          <Col md={16} xs={24} style={{ maxHeight: 420, overflow: 'auto' }}>
            <List
              itemLayout="horizontal"
              dataSource={commentList.rows}
              loading={loading}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        size={80}
                        src={item.user_img} 
                      />
                    }
                    title={
                      <div className={styles.list_title}>
                        <span>{item.name}</span>
                        <span>{item.last_update_time}</span>
                        {item.user_id === user_id && (
                          <>
                            <span
                              className={styles.action_btn}
                              onClick={() => setUpdateId(item.id)}
                            >
                              <Icon type="edit" />&nbsp;{lang('general.button.update')}
                            </span>
                            <span
                              className={styles.action_btn}
                              onClick={() => handleDelete(item.id)}
                            >
                              <Icon type="delete" />&nbsp;{lang('general.button.delete')}
                            </span>
                          </>
                        )}
                      </div>
                    }
                    description={
                      updateId === item.id ? (
                        <Input
                          ref={inputRef}
                          defaultValue={item.comment}
                          autoFocus
                          onBlur={() => setUpdateId('')}
                          onPressEnter={handleUpdate(item.comment)}
                          disabled={updateLoading}
                        />
                      ) : (
                        <div className={styles.list_description}>
                          {item.comment}
                        </div>
                      )
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        )}
      </Row>
      {current.is_comment === 'Y' && (
        <Row type="flex" gutter={20} style={{ marginTop: 20 }}>
          <Col>
            <Avatar
              size={80}
              src={user_avatar}
            />
          </Col>
          <Col style={{ flexGrow: 1 }}>
            <TextArea
              autoSize={{ minRows: 6 }}
              value={value}
              onChange={e => setValue(e.target.value)}
              onPressEnter={onSubmit}
              disabled={submitLoading}
            />
            <div className={styles.btn_box}>
              <Button 
                className={styles.submit_btn}
                onClick={onSubmit}
                loading={submitLoading}
              >
                {lang('schoolCourse.highlight.comment')}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
