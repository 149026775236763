import { putSubscription, paySubscription } from "../../api/payment_api";

// 付款订阅结算
export function subscriptionTotal({ callback, ...rest }) {
  return (dispatch) => {
    putSubscription(rest)
      .then((ret) => {
        console.log(ret);
        dispatch({ type: "SAVE_SUBSCRIPTION_TOTAL", payload: ret });
        callback();
      })
      .catch(() => {});
  };
}

export function subscriptionPaypal({ callback, ...rest }) {
  return (dispatch) => {
    paySubscription(rest)
      .then((ret) => {
        console.log(ret);
        callback(ret);
        dispatch({ type: "SAVE_PAYPAL", payload: ret });
      })
      .catch(error => callback());
  };
}
