import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Icon, Empty } from 'antd';
import Slider from "react-slick";

import Video from "components/common/Video";

import styles from 'assets/css/DrWongModal.module.scss';

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const imgConfig = [
  {lang: 'zh', vo_lang: 'CANTONESE', img: 'cantonese_zh.png'},
  {lang: 'zh', vo_lang: 'PUTONGHUA', img: 'putonghua_zh.png'},
  {lang: 'english', vo_lang: 'ENGLISH', img: 'english_english.png'},
]

const defaultLang = {
  lang: 'zh',
  vo_lang: 'CANTONESE'
}

const VocabularyModal = (props) => {
  const sliderRef = useRef();
  const audioRef = useRef(new Audio());

  const { data, visible, onCancel } = props;

  const { route: { currentLanguage } } = useSelector(state => state);

  const [ page, setPage ] = useState(0);

  const [ currentLang, setCurrentLang ] = useState(defaultLang);

  const [ view, setView ] = useState('img');

  const init = () => {
    setPage(0);
    setCurrentLang(defaultLang);
    setView('img')
  }

  // 幻灯片切换的回调
  const onSlierChange = (index) => {
    setView('img');
    setPage(index);
    // setCurrentLang(defaultLang);
    if(audioRef.current) {
      audioRef.current.src = '';
    }
  }
  // 上一页
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  }
  // 下一页
  const handleNext = () => {
    sliderRef.current.slickNext();
  }
  // 切换语言
  const handleChangeLang = (item) => {
    try {
      setView('img');
      const file = data[page].sound.find(_ => _.vo_lang === item.vo_lang).file;
      if(file) {
        audioRef.current.src = file;
        audioRef.current.play();
      }
      setCurrentLang({
        lang: item.lang,
        vo_lang: item.vo_lang
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if(!visible) {
      audioRef.current.src = '';
      init();
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      className={styles.vocabulary}
      footer={null}
      width={600}
      bodyStyle={{
        padding: 0
      }}
      destroyOnClose
    >
      {/* header */}
      <div className={styles.header}>
        <img src={require(`assets/image/drWong/vocab/${currentLanguage.value}/vocab_icon.png`)} alt="vocab_icon"/>
      </div>
      {/* 幻灯片 */}
      <div className={styles.sliderWrap}>
        {/* 按钮 */}
        {data.length > 1 && (
          <Fragment>
            {page > 0 && (
              <div 
                className={`${styles.btn} ${styles.btnLeft}`}
                onClick={handlePrev}
              >
                <Icon type="left" />
              </div>
            )}
            {page + 1 < data.length && (
              <div 
                className={`${styles.btn} ${styles.btnRight}`}
                onClick={handleNext}
              >
                <Icon type="right" />
              </div>
            )}
          </Fragment>
        )}
        {data.length ? (
          <Slider 
            {...settings}
            ref={sliderRef}
            className={styles.slider}
            afterChange={onSlierChange}
          >
            {data.map((item, index) => {
              const current = ((item.lang || []).find(_ => _.lang === currentLang.lang && _.vo_lang === currentLang.vo_lang) || {});
              return (
                <Fragment key={item.id}>
                  {view === 'img' || !(current.streaming_url || current.video_file) ? (
                    // 詞匯圖片
                    <div className={styles.imgBox}>
                      <img
                        src={current.file}
                        className={styles.vocabImg}
                        alt="vocab"
                      />
                      {(current.streaming_url || current.video_file) && (
                        <img
                          src={require("assets/image/drWong/vocab/stroke_order.png")}
                          className={styles.strokeOrder}
                          alt="stroke order"
                          onClick={() => setView('video')}
                        />
                      )}
                    </div>
                  ) : (
                    // 筆順視頻
                    <div className={styles.videoBox}>
                      <Video 
                        videosrc={current.streaming_url || current.video_file} 
                        playing={page === index}
                        light={false} 
                      />
                      <img
                        src={require("assets/image/drWong/vocab/return.png")}
                        className={styles.return}
                        alt="return"
                        onClick={() => setView('img')}
                      />
                    </div>
                  )}
                </Fragment>
              )
            })}
          </Slider>
        ) : (
          <Empty />
        )}
      </div>
      {/* footer */}
      <div className={styles.footer}>
        {imgConfig.map(item => (
          <img
            key={item.vo_lang}
            src={require(`assets/image/drWong/vocab/${item.img}`)} 
            alt={item.vo_lang}
            onClick={() => handleChangeLang(item)}
          />
        ))}
      </div>
    </Modal>
  )
}

export default VocabularyModal;