import React, { useState, useEffect } from "react";

import intl from "react-intl-universal";

import Icon1 from "assets/image/cantoneseOpera/1.png";
import Icon2 from "assets/image/cantoneseOpera/2.png";
import Icon4 from "assets/image/cantoneseOpera/4.png";
import Icon5 from "assets/image/cantoneseOpera/5.png";
import teacherGuide from "assets/image/cantoneseOpera/cantonese_opera_teacherGuide.png";

import user_api from "components/services/userService";

import RecommendModal from "./RecommendModal";
import KTVModal from "./KTVModal";

export default function Titles(params) {
  const { data, setKtvStop, setRecommendStop } = params;
  const translate = (value, params) =>
    intl.get(`cantoneseOpera.${value}`, params);

  // ktv
  const [ktvData, setKtvData] = useState([]);
  const [ktvControl, setKtvControl] = useState(false);
  // 'EXTERNAL_RESOURCES'
  const [recommendData, setRecommendData] = useState([]);
  const [recommendControl, setRecommendControl] = useState(false);
  // 'ICON'
  const [iconData, setIconData] = useState([]);
  // 'ANSWER'
  const [AnswerData, setAnswerData] = useState([]);

  const titleText = [
    // {
    //     icon: Icon2,
    //     text: translate('OnlineGames'),
    //     key: 'GAME',
    //     hide: gameData.length !== 0 ? true : false
    // },
    {
      icon: Icon1,
      text: translate("RecommendResources"),
      key: "RECOMMEND",
      hide: recommendData.length !== 0 ? true : false,
      // 不是老师隐藏
      Staff: user_api.getType() === "STAFF"
    },
    {
      icon: Icon4,
      text: translate("RelatedVideos"),
      key: "KTV",
      hide: ktvData.length !== 0 ? true : false,
      Staff: true
    },
  ];

  const RecommendClose = () => {
    setRecommendControl(false);
  };

  const KtvClose = () => {
    setKtvControl(false);
  };

  const textType = (value) => {
    switch (value) {
      case "RECOMMEND":
        return setRecommendControl(true);
      // case 'GAME':
      //     return setGameControl(true);
      case "KTV":
        return setKtvControl(true);
      default:
        return null;
    }
  };

  useEffect(() => {
    if (data.item.length !== 0) {
      // KTV
      const Ktvitem = data.item.filter(
        (item) => item.section === "KTV" && item
      );
      if (Ktvitem.length !== 0) {
        setKtvData(Ktvitem);
      }
      //EXTERNAL_RESOURCES
      const RecommendItem = data.item.filter(
        (item) => item.section === "RECOMMEND" && item
      );
      if (RecommendItem.length !== 0) {
        setRecommendData(RecommendItem);
      }
      // ICON
      // const Iconitem = data.item.filter((item) => item.section === 'ICON' && item)
      // if (Iconitem.length !== 0) {
      //     setIconData(...Iconitem)
      // }
      // // ICON
      // const Answeritem = data.item.filter((item) => item.section === "ANSWER" && item)
      // if (Answeritem.length !== 0) {
      //     setAnswerData(...Answeritem)
      // }
    }
  }, [data]);

  useEffect(() => {
    setKtvStop(ktvControl);
    setRecommendStop(recommendControl);
  }, [ktvControl, recommendControl])

  return (
    <div
      style={{
        marginTop: "8px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {titleText.map((item) => {
      //  console.log(item.hide);
       return (
        <div
          key={item.key}
          style={{
            display: (item.hide && item.Staff) ? "flex" : "none",
            alignItems: "center",
            marginRight: 28.37,
            marginBottom: "8px",
            cursor: "pointer",
          }}
          onClick={() => textType(item.key)}
        >
          <img
            src={item.icon}
            alt=""
            style={{ width: 21.79, height: 21.79, marginRight: 8 }}
          />
          <span style={{ fontSize: "18px", color: "#000000" }}>
            {item.text}
          </span>
        </div>
      )})}
      {!!iconData.file && (
        <a target="_blank" href={iconData.file}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 28.37,
              marginBottom: "8px",
              cursor: "pointer",
            }}
          >
            <img
              src={Icon5}
              alt=""
              style={{ width: 21.79, height: 21.79, marginRight: 8 }}
            />
            <span style={{ fontSize: "18px", color: "#000000" }}>
              {translate("SongCollection")}
            </span>
          </div>
        </a>
      )}
      {!!AnswerData.file && user_api.getType() === "STAFF" && (
        <a target="_blank" href={AnswerData.file}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 28.37,
              marginBottom: "8px",
              cursor: "pointer",
            }}
          >
            <img
              src={teacherGuide}
              alt=""
              style={{ width: 21.79, height: 21.79, marginRight: 8 }}
            />
            <span style={{ fontSize: "18px", color: "#000000" }}>
              {translate("manual")}
            </span>
          </div>
        </a>
      )}
      <RecommendModal
        RecommendControl={recommendControl}
        RecommendClose={RecommendClose}
        RecommendData={recommendData}
      />
      <KTVModal ktvControl={ktvControl} KtvClose={KtvClose} ktvData={ktvData} />
    </div>
  );
}
