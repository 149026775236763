import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Statistic, Typography, Avatar, Icon } from "antd";

import WithSubMenuConfig from "components/common/HOC/WithSubMenuConfig";
import ProfileMenu from "./components/ProfileMenu";
import ProfileContent from "./components/ProfileContent";
import MobileMenu from "./components/MobileMenu";

import intl from "react-intl-universal";
import { Textfit } from 'react-textfit';

import styles from "assets/css/PersonalCenter.module.scss";

import config from "./route";

const Profile = (props) => {
  const token = window.localStorage.getItem("access_token");
  const device_id = window.localStorage.getItem("uuid");

  // TODO: should waiting for client request , answer the request after checking.
  if (window && window.parent) {
    window.parent.postMessage({access_token: token, device_id:device_id}, "*");
  }
  
  const { menuConfig } = props;
  const { translations, route } = useSelector((state) => state);
  const { img: avatar, full_name, coin } = useSelector((state) => state.user);

  const lang = (value) => translations.initDone && intl.get(value);

  const { operation } = useParams();

  const title = (menuConfig.find((_) => _.icon === operation) || {}).label;

  return (
    <div className={styles.personalCenterRoot}>
      {/* 头部 */}
      <div className={styles.header}>
        <div className={styles.header_inner}>
          <Row
            type="flex"
            justify="start"
            gutter={10}
            style={{
              height: "100%",
            }}
          >
            <Col lg={5} sm={7} xs={12} className={styles.img_box}>
              <img
                src={require("assets/image/personal/header_cat.png")}
                alt="cat"
                className={styles.cat}
              />
            </Col>
            <Col lg={19} sm={17} xs={12} className={styles.title_box}>
              <Textfit
                mode="single"
                forceSingleModeWidth={false}
                min={14}
                max={46}
                className={styles.title}
              >
                {title}
              </Textfit>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.main_inner}>
          <Row type="flex" justify="start" gutter={10}>
            <Col lg={5} md={7} xs={0}>
              <Link to={`/${route.currentLocation}/${route.currentLanguage.url}/`}>
                <div className={styles.back_home}>
                  <Icon type="home" theme="filled" />
                  {lang("personalCenter.menu.home")}
                </div>
              </Link>
              <ProfileMenu menuConfig={menuConfig} />
            </Col>
            <Col xs={24} md={0}>
              <MobileMenu menuConfig={menuConfig} />
            </Col>
            <Col lg={19} md={17} xs={24}>
              <ProfileContent menuConfig={menuConfig} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default WithSubMenuConfig(Profile);
