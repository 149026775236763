import { combineReducers } from "redux";
import { user } from "components/reducers/user";
import { route } from "components/reducers/route_reducer";
import { translations } from "components/reducers/translations_reducer";
import { auth } from "components/reducers/auth_reducer";
import { merchant } from "components/reducers/merchant_reducer";
import { schoolCourse } from "components/reducers/schoolCourse_reducer";
import { main } from "components/reducers/main_reducer";
import { common } from "components/reducers/common_reducer";
import { eventReducer } from "components/reducers/event_reducer";
import { globalReducer } from "components/reducers/global_reducer";

// 新版 reducer
import { new_auth } from "store/reducers/auth_reducer";
import { new_user } from "store/reducers/user_reducer";
import { new_family } from "store/reducers/family_reducer";
import { new_global } from "store/reducers/global_reducer";
import { new_payment } from "store/reducers/payment_reducer";
import { new_redemption } from "store/reducers/redemption_reducer";

const allReducers = {
  user,
  route,
  translations,
  auth,
  merchant,
  schoolCourse,
  main,
  common,
  event: eventReducer,
  global: globalReducer,
  new_auth,
  new_user,
  new_family,
  new_global,
  new_payment,
  new_redemption,
};

export const rootReducer = combineReducers(allReducers);
