import React, { useState } from "react";
import { Modal, Row, Divider, Checkbox, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import intl from "react-intl-universal";

import { getAvatar } from "../../../../../store/action/user";

import UploadLocal from "./uploadLocal";
import DefaultImg from "./defaultImg";

const AvatarEdit = (props) => {
  const { visible, close, defaultBg, setDefaultBg } = props;

  const { initDone } = useSelector((state) => state.translations);

  const dispatch = useDispatch();

  // 是否选择预设图片
  // const [check, setCheck] = useState(false);

  // 选中的预设图片
  // const [defaultIdx, setDefaultIdx] = useState(null);

  const translation = function (text) {
    return initDone && intl.get(text);
  };

  return (
    <Modal
      // title="Basic Modal"
      centered
      bodyStyle={{ backgroundColor: "#fff" }}
      visible={visible}
      // onOk={uploadHandle}
      onCancel={close}
      footer={null}
      maskClosable={false}
      destroyOnClose={true}
    >
      {/* <UploadLocal /> */}
      {/* <Row type="flex" justify="space-around">
        <Divider />
      </Row> */}
      {/* <Row type="flex" justify="space-around">
        <Checkbox
          checked={check}
          onChange={(e) => {
            const { checked } = e.target;
            setCheck(checked);
            if (!checked) {
              setDefaultIdx(null);
            }
          }}
        >
          {translation("course_1.UploadingFile.uploadDefaultImg")}
        </Checkbox>
      </Row> */}
      <Row
        type="flex"
        justify="space-around"
        gutter={16}
        style={{ margin: "1rem 0" }}
      >
        <DefaultImg defaultBg={defaultBg} setDefaultBg={setDefaultBg} />
      </Row>
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ color: "#fff", backgroundColor: "#45a735" }}
          onClick={close}
        >
          {translation("home.publicMsg.drawer.Btnconfirm")}
        </Button>
      </div>
      {/* <Row type="flex" justify="space-around">
        <Button type="primary" onClick={close}>
          {translation("course_1.UploadingFile.btn")}
        </Button>
        <Button type="primary" onClick={close}>
          {translation("course_1.UploadingFile.cancel")}
        </Button>
      </Row> */}
    </Modal>
  );
};

export default AvatarEdit;
