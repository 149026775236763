import React from 'react';
import { useSelector } from 'react-redux';
import intl from "react-intl-universal";
import { Button, Select, InputNumber } from 'antd';

const { Option } = Select;

const Notification = ({ data, onChange }) => {
  const { translations: { initDone } } = useSelector(state => state);

  const lang = (value) => {
    return initDone && intl.get("zoomCalendar." + value);
  }

  const handleAdd = () => {
    const newData = JSON.parse(JSON.stringify(data));
    newData.push({ type: 'NOTIFICATION', interval_value: 10, interval_field: 'MIN' });
    onChange(newData);
  }
  const handleDelete = (index) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData.splice(index, 1);
    onChange(newData);
  }
  const handleChangeNum = (index, num) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData[index].interval_value = num;
    onChange(newData);
  }
  const handleChangeField = (index, value) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData[index].interval_field = value;
    onChange(newData);
  }

  return (
    <div>
      {data.map((item, index) => (
        <div key={item.id}>
          <Select value={item.type} style={{marginRight: 10, width: 130}}>
            <Option value="NOTIFICATION">{lang('select_notification')}</Option>
          </Select>
          <InputNumber 
            value={item.interval_value} 
            style={{marginRight: 10, width: 80}}
            onChange={(num) => handleChangeNum(index, num)}
          />
          <Select 
            value={item.interval_field}
            style={{marginRight: 10, width: 80}}
            onChange={(value) => handleChangeField(index, value)}
          >
            <Option value="MIN">{lang('select_min')}</Option>
            <Option value="HOUR">{lang('select_hour')}</Option>
            <Option value="DAY">{lang('select_day')}</Option>
            <Option value="WEEK">{lang('select_week')}</Option>
          </Select>
          <Button shape="circle" size="small" icon="close" onClick={() => handleDelete(index)} />
        </div>
      ))}
      <Button size="small" onClick={handleAdd} disabled={data.length >= 5}>{lang('btn_add_notification')}</Button>
    </div>
  )
}

export default Notification;