import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'; 
import moment from 'moment';
import { Badge, Popover, List, Avatar, Typography, Modal, Icon, message, Button } from 'antd';
import { useMediaQuery } from 'react-responsive';
import InfiniteScroll from "react-infinite-scroller";
import intl from "react-intl-universal";

import * as device from 'components/services/deviceService';

var REFERENCE = moment();
var TODAY = REFERENCE.clone().startOf('day');
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

function isToday(momentDate) {
  return momentDate.isSame(TODAY, 'd');
}

function isYesterday(momentDate) {
  return momentDate.isSame(YESTERDAY, 'd');
}

const defaultState = { total: 0, unread: 0, rows: [] };

export default function Notification() {
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });
  const { translations } = useSelector(state => state);
  
  const [list, setList] = useState(defaultState);
  const [current, setCurrent] = useState({});
  // const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const lang = (value) => translations.initDone && intl.get(value);

  const getList = async (offset) => {
    const ret = await device.getList(offset || list.rows.length).then(ret => ret).catch(() => defaultState);
    setList(list => {
      return offset === 0 ? ret : { ...ret, rows: [...list.rows, ...ret.rows] };
    });
    return ret;
  }

  const handleInfiniteOnLoad = () => {
    // setLoading(true);
    if (list.rows.length > list.total) {
      message.warning('已加載全部');
      // setLoading(false);
      return;
    }
    getList();
  };

  const handleUpdateRead = (item) => () => {
    setCurrent(item);
    if (item.id && item.data && item.data.is_read === 'N') {
      device.updateRead(item.id).then(ret => {
        setList(list => {
          return {
            ...list,
            unread: list.unread - 1,
            rows: list.rows.map(row => {
              return row.id === item.id ? { ...row, data: { ...row.data, is_read: 'Y' }} : row;
            })
          }
        });
        // getList();
      }).catch(error => {
        console.log(error);
      })
    }
  }

  const handleDelete = (id) => (e) => {
    e.stopPropagation();
    if (id) {
      setDeleteLoading(true)
      device.archive(id).then(() => {
        // getList();
        setList(list => {
          return {...list, rows: list.rows.filter(item => item.id !== id)}
        });
      }).catch(() => {
        message.error('刪除失敗');
      }).finally(() => {
        setDeleteLoading(false);
      })
    }
  }

  useEffect(() => {
    getList(0);
  }, [])

  const content = (
    <div style={{ maxHeight: 400, overflow: 'auto' }}>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={handleInfiniteOnLoad}
        hasMore={list.rows.length < list.total}
        useWindow={false}
      >
        <List
          itemLayout="horizontal"
          dataSource={list.rows}
          style={{ width: isMobile ? 250 : 500 }}
          // pagination={{
          //   onChange: page =>  setPage(page - 1),
          //   pageSize: 10,
          //   total: list.total
          // }}
          renderItem={item => {
            const { data={} } = item;
            return (
              <List.Item
                actions={[
                  <span style={{ fontsize: 14, color: '#43BAFF' }}>
                    {isToday(moment(item.send_time)) && (
                      `${lang('general.msg.today')} ${moment(item.send_time).format('hh:mm')}` 
                    )}
                    {isYesterday(moment(item.send_time)) && (
                      `${lang('general.msg.yesterday')} ${moment(item.send_time).format('hh:mm')}` 
                    )}
                    {!isToday(moment(item.send_time)) && !isYesterday(moment(item.send_time)) && (
                      moment(item.send_time).format('YYYY-MM-DD hh:mm')
                    )}
                  </span>,
                  <Icon
                    onClick={handleDelete(item.id)}
                    type={deleteLoading ? 'loading' : 'close-circle'} 
                  />
                ]}
                onClick={handleUpdateRead(item)}
                style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-start' }}
              >
                <Badge status={data.is_read === 'Y' ? 'default' : 'success'} />
                <Avatar style={{ flexShrink: 0 }} size={31} src={item.create_user_img} />
                <Typography.Text
                  title={data.nTitle}
                  ellipsis
                  style={{
                    fontSize: 16,
                    color: '#747474',
                    marginLeft: 8,
                    flexGrow: 1
                  }}
                >
                  {data.nTitle}
                </Typography.Text>
              </List.Item>
            )
          }}
        />
      </InfiniteScroll>
    </div>
  );

  return (
    <div id="notificationPopover" style={{ height: '100%' }}>
      <Popover
        trigger="click"
        content={content}
        getPopupContainer={() => document.getElementById('notificationPopover')}
        placement={isMobile ? "bottomRight" : "bottom"}
      >
        <div style={{ height: '100%' }}>
          <Badge count={list.unread}>
            <img src={require('assets/image/button/notification.svg')} alt="notification"/>
          </Badge>
        </div>
        <Modal
          title={current.title}
          visible={!!current.id}
          // onOk={() => setCurrent({})}
          onCancel={() => setCurrent({})}
          zIndex={1999}
          footer={
            <Button onClick={() => setCurrent({})}>
              Ok
            </Button>
          }
        >
          <Typography.Paragraph>
            {current.msg}
          </Typography.Paragraph>
        </Modal>
      </Popover>
    </div>
  )
}
