import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Tooltip, Icon, Button } from "antd";
import intl from "react-intl-universal";
import { Textfit } from "react-textfit";

import { ExploreContext } from "components/context/explore360Context";
import TableListPage from "components/studentReport/tablePage";
import InfoPage from "components/studentReport/infoPage";

import { getClassAction } from "components/actions/common_action";

import userLog from "components/services/userLogService";

import styles from "assets/css/studentReport/index.module.scss";

const style = {
  width: 40,
  height: 40,
  background: "rgba(0, 0, 0, 0.5)",
  color: "#fff",
  fontSize: "20px",
  lineHeight: "40px",
  textAlign: "center",
  borderRadius: "0 20px 0 0",
  cursor: "pointer",
};

const StudentReport = (props) => {
  const dispatch = useDispatch();
  const { children, item } = props;

  const isProject = item.res_type === 'project';

  const { 
    translations,
    route: { currentLocation },
    schoolCourse: { info },
    common: { class: classData },
  } = useSelector((state) => state);

  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1); // 用於切換列表和詳情視圖
  const [classId, setClassId] = useState(); // 年級 filter
  const [currentUser, setCurrentUser] = useState(""); // 當前選中的學生

  // 设置 page === 1 的子页面
  const [tablePage, setTablePage] = useState("table");

  // page ===1 子页面过滤条件
  // const [checked, setChecked] = useState({
  //   submitY: false,
  //   submitN: false,
  //   scoreY: false,
  //   scoreN: false,
  // });
  const [checked, setChecked] = useState('all');

  // page === 1 子页面 loading
  const [tableLoading, setTableLoading] = useState(false);

  const lang = (value) => translations.initDone && intl.get(value);

  const init = () => {
    setData([]);
    setPage(1);
    setClassId();
    setCurrentUser('');
    setTablePage("table");
  }

  const getData = (callback) => {
    try {
      const { ref_id, course_id } = item;
      setTableLoading(true);
      userLog
        .getUserReport({ class_id: classId, res_id: ref_id, course_id })
        .then((ret) => {
          setData(ret);
          if (callback) callback();
        }).catch(() => {
          setData([]);
        }).finally(() => {
          setTableLoading(false);
        });
    } catch (error) {
      setData([]);
      setTableLoading(false);
    }
  };

  useEffect(() => {
    if (visible && classId) {
      getData();
    }
  }, [visible, classId]);

  // 默认选中第一个班级
  useEffect(()=>{
    if(classData.classList.length!==0){
      setClassId(classData.classList[0].class_id)
    }
  },[classData])

  useEffect(() => {
    if (visible) {
      dispatch(getClassAction(currentLocation, info.grade));
    }
    return () => init();
  }, [visible])

  const handleCancel = (e) => {
    e.preventDefault();
    setVisible(false);
    setPage(1);
  };

  const againGetData = (callback) => getData(callback);

  return (
    <Fragment>
      <div onClick={() => setVisible(true)}>
        {children || (
          <Tooltip placement="right" title={lang("course_1.content.reportbtn")}>
            <div style={style}>
              <Icon type="pie-chart" />{" "}
            </div>
          </Tooltip>
        )}
      </div>
      <Modal
        width={1200}
        visible={visible}
        footer={null}
        destroyOnClose={true}
        className={styles.root}
        zIndex={888}
        closable={false}
        bodyStyle={{
          padding: 0,
        }}
        centered
      >
        {/* 頭部 */}
        <div className={styles.header}>
          <Textfit
            forceSingleModeWidth={false}
            mode="single"
            min={12}
            max={28}
            className={styles.title}
          >
            {lang('student_reports.title')} - {item.name}
          </Textfit>
          <Button
            icon="close"
            shape="circle"
            type="link"
            className={styles.close}
            size="large"
            onClick={handleCancel}
          />
        </div>
        <ExploreContext.Provider value={againGetData}>
          {page === 1 && (
            <TableListPage
              data={data}
              tablePage={tablePage}
              setTablePage={setTablePage}
              checked={checked}
              setChecked={setChecked}
              courseId={item.course_id}
              resId={item.ref_id}
              tableLoading={tableLoading}
              setCurrentUser={(ret) => {
                setCurrentUser(ret);
                setPage(2);
              }}
              classId={classId}
              setPage={(page) => setPage(page)}
              onSelect={(ret) => setClassId(ret)}
              setVisible={() => setVisible(false)}
              isProject={isProject}
            />
          )}
          {page === 2 && (
            <InfoPage
              data={data.find((_) => _.user_id === currentUser)}
              setPage={() => setPage(1)}
              courseId={item.course_id}
              getData={getData}
              isProject={isProject}
            />
          )}
        </ExploreContext.Provider>
      </Modal>
    </Fragment>
  );
};

export default StudentReport;
