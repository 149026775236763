import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";
import { Row, Col, Button, Icon, Modal } from "antd";

import user_API from "components/services/userService";
import userLog from "components/services/userLogService";

import Achieve from "../components/Achieve";
import ImgPreview from "components/common/ImgPreview";

import common from "components/services/commonService";

import "assets/css/Achievement.module.scss";

const AchievementPage = () => {
  const { translations, route } = useSelector((state) => state);

  const [achvisible, setAchvisible] = useState(false);

  const [certJob, setCertJob] = useState();
  const [medal, setMedal] = useState(null);
  const [loadStatus, setLoadStatus] = useState(null);
  const [setting, setSetting] = useState({});

  const lang = (value) => translations.initDone && intl.get(value);

  const genCertJob = () => {
    if (medal) {
      userLog.genCertJob().then((ret) => {
        setCertJob(ret);
        onRefresh();
      });
    }
  };

  const onRefresh = () => {
    userLog.getByUser().then((ret) => {
      if (ret.status === "DONE") {
        setLoadStatus(true);
      }
    });
  };

  const achieveCallback = (medal) => {
    setMedal(medal);
  };

  const getSetting = async () => {
    const ret = await common.getSetting(ret => ret).catch(() => ({}));
    setSetting(ret);
  }

  useEffect(() => {
    getSetting();
  }, [])

  return (
    <div className="achieve" style={{ marginTop: 30 }}>
      <img
        className="title"
        src={require("assets/image/achieve_title" +
          (route.currentLanguage.value === "english" ? "_en" : "") +
          ".png")}
        alt="title"
      />
      <div className="modalIcon">
        <Button className="modalbtn" onClick={() => setAchvisible(true)}>
          <Icon type="question" />
        </Button>
        <Modal
          title={lang("achievements.content.medal")}
          visible={achvisible}
          footer={null}
          closable={true}
          onCancel={() => setAchvisible(false)}
          style={{ maxWidth: 400 }}
        >
          <Row className="Icon">
            <Col xs={18}>
              <h3>{lang("achievements.content.diamond")}</h3>
            </Col>
            <Col xs={6}>
              <img src={require(`assets/image/achievement_04.png`)} alt="" />
            </Col>
            <Col xs={18}>
              <h3>{lang("achievements.content.goldMedal")}</h3>
            </Col>
            <Col xs={6}>
              <img src={require(`assets/image/achievement_03.png`)} alt="" />
            </Col>
            <Col xs={18}>
              <h3>{lang("achievements.content.silverMedal")}</h3>
            </Col>
            <Col xs={6}>
              <img src={require(`assets/image/achievement_02.png`)} alt="" />
            </Col>
            <Col xs={18}>
              <h3>{lang("achievements.content.bronzeMedal")}</h3>
            </Col>
            <Col xs={6}>
              <img src={require(`assets/image/achievement_01.png`)} alt="" />
            </Col>
          </Row>
        </Modal>
      </div>
      {user_API.getType() === "STUDENT" && setting.course_cert === 'Y' && (
        <div style={{ textAlign: "right", marginTop: -30 }}>
          <ImgPreview
            data={[certJob]}
            loadStatus={loadStatus}
            onRefresh={onRefresh}
            onClose={() => setLoadStatus(false)}
            medal={medal}
          >
            <img
              src={require(`assets/image/achievement/button_cert_${route.currentLanguage.value}.png`)}
              onClick={genCertJob}
              style={{ maxWidth: 250 }}
              alt="preview"
            />
          </ImgPreview>
        </div>
      )}
      <Row gutter={20} style={{ textAlign: "center", marginTop: 20 }}>
        <Achieve
          lg={6}
          md={8}
          sm={12}
          xs={12}
          styles={"achievePage"}
          callback={(medal) => achieveCallback(medal)}
        />
      </Row>
    </div>
  );
};

export default AchievementPage;
