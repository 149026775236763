import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Divider, Empty } from "antd";

import intl from "react-intl-universal";

import user_API from "components/services/userService";

import styles from "assets/css/PersonalCenter.module.scss";

const GamePage = () => {
  const { user, route, translations } = useSelector((state) => state);

  const lang = (value) => translations.initDone && intl.get(value);

  return (
    <div className={`${styles.gamePage} ${styles.card}`}>
      {!!user.id &&
        !!user_API.checkRPermit() ? (
        <Fragment>
          {(route.currentLocation == 'hk' || route.currentLocation == 'mo') && (
            <Link
              to={`/${route.currentLocation}/${route.currentLanguage.url}/capsule`}
              target="_blank"
            >
              <Row type="flex" gutter={[20, 10]}>
                <Col lg={7} sm={9} xs={24}>
                  <img
                    src={require(`assets/image/personal/coin/${route.currentLanguage.value}/b2.png`)}
                    alt=""
                  />
                </Col>
                <Col lg={17} sm={15} xs={24}>
                  <div className={styles.title}>
                    {lang("personalCenter.happyZone.EVIGARTENCapsuleMachine")}
                  </div>
                </Col>
              </Row>
            </Link>
          )}
          <Divider />
          <Link
            to={`/${route.currentLocation}/${route.currentLanguage.url}/fishgame`}
            target="_blank"
          >
            <Row type="flex" gutter={[20, 10]}>
              <Col lg={7} sm={9} xs={24}>
                <img
                  src={route.currentLocation === "cn" ? require(`assets/image/personal/coin/${route.currentLanguage.value}/b1s.png`)
                    : require(`assets/image/personal/coin/${route.currentLanguage.value}/b1.png`)}
                  alt=""
                />
              </Col>
              <Col lg={17} sm={15} xs={24}>
                <div className={styles.title}>
                  {lang("personalCenter.happyZone.happyAquarium")}
                </div>
              </Col>
            </Row>
          </Link>
        </Fragment>
      ) : (
        <div style={{ padding: 20 }}>
          <Empty />
        </div>
      )}
    </div>
  );
};

export default GamePage;
