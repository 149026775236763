import React, { useState, Fragment } from 'react';
import { Icon, Modal } from 'antd';
import Video from 'components/common/Video';
import CommonPdf from 'components/common/CommonPdf';
import { fileTypeOf } from 'components/utils/type';

import styles from 'assets/css/file.module.scss';

const File = (props) => {
  const { src, style, className, imgPreview, videoToImg, isShowContextMenu = true } = props;

  const [imgModalVisible, setImgModalVisible] = useState(false);

  const handleImgPreview = () => {
    if (!imgPreview) {
      return;
    }
    setImgModalVisible(true);
  };

  const view = () => {
    if (!src) {
      return null;
    }
    const file = src.toLowerCase();
    switch (true) {
      case fileTypeOf(file, ['.mp4', '.mov', '.m3u8']):
        return videoToImg ? <img src={file + '?x-oss-process=video/snapshot,t_1000,f_jpg,m_fast'} alt="" /> : <Video videosrc={file} light={false} playing={true} />;
      case fileTypeOf(file, ['.jpeg', '.jpg', '.png', '.gif']):
        return <img src={file + '!auto'} alt="" onClick={handleImgPreview} />;
      case file.endsWith('.mp3'):
        return <audio src={file} autoPlay controls></audio>;
      case file.endsWith('.pdf'):
        return <CommonPdf file={file} pageClass={styles.documentPage} />;
      default:
        return <Icon type="file" className="icon" />;
    }
  };

  return (
    <Fragment>
      <div onContextMenu={(e) => !isShowContextMenu && e.preventDefault()} style={style} className={`${className} ${styles.root}`}>
        {view()}
      </div>
      {imgPreview && (
        <Modal visible={imgModalVisible} onCancel={() => setImgModalVisible(false)} footer={null}>
          <img
            src={src}
            style={{
              width: '100%'
            }}
            alt="preview"
          />
        </Modal>
      )}
    </Fragment>
  );
};

export default File;
