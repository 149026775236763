import React, { useState, useMemo } from "react";
import { Row, Col, Typography, Empty, Spin, Pagination } from "antd";

import { fileTypeOf } from "components/utils/type";

import styles from "assets/css/studentReport/previewPage.module.scss";

const previewListPage = (props) => {
  const { data, loading, setCurrentUser } = props;

  const [page, setPage] = useState(1);
  const newData = useMemo(() => {
    const limit = 12;
    const start = (page - 1) * limit;
    const end = start + 12;
    return JSON.parse(JSON.stringify(data)).slice(start, end);
  }, [page, data])

  return (
    <Spin spinning={loading}>
      <div className={styles.root}>
        {!data.length && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {!!data.length && (
          <>
            <Row type="flex" gutter={[20, 20]} className={styles.row}>
              {newData.map((item) => (
                <Col key={item.user_id} lg={6} sm={12} xs={24} className={styles.col}>
                  <div
                    onClick={() => setCurrentUser(item.user_id)}
                    style={{
                      // backgroundImage: fileTypeOf(item.project_file, [
                      //   ".mp4",
                      //   ".mov",
                      //   ".m3u8",
                      // ])
                      //   ? `url(${item.project_file}?x-oss-process=video/snapshot,t_1000,f_jpg,m_fast)`
                      //   : `url(${item.project_file})`,
                       backgroundImage: `url(${item.cover})`,
                    }}
                    className={styles.item}
                  >
                    {!item.project_file && (
                      <div className={styles.not_submitted}>未提交</div>
                    )}
                    <div className={styles.streamer}>
                      <Typography.Text
                        title={item.name}
                        className={styles.name}
                        ellipsis
                      >
                        {item.name}
                      </Typography.Text>
                      <Typography.Text className={styles.comment}>
                        {item.comment || item.comment_file || item.comment_voice
                          ? "已評價"
                          : "未評價"}
                      </Typography.Text>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 20 }}>
              <Pagination 
                current={page} 
                onChange={page => setPage(page)} 
                total={data.length} 
                pageSize={12}
              />
            </div>
          </>
        )}
      </div>
    </Spin>
  );
};

export default previewListPage;
