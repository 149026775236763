import * as api from "../../api/redemption_api";

// 兑换
export function fetchRedeem({ callback, errorCallback, ...rest }) {
  return (dispatch) => {
    api
      .fetchRedeem(rest)
      .then((ret) => {
        dispatch({ type: "REDEEM_SUCCESS", payload: ret });
        callback();
      })
      .catch((err) => {
        errorCallback(err);
        console.log(err);
      });
  };
}

// 检测兑换
export function checkRedeem({ errorCallback, finallyCb, ...rest }) {
  return (dispatch) => {
    api
      .checkRedeem(rest)
      .then((ret) => {
        dispatch({ type: "CHECK_REDEEM_SUCCESS", payload: ret });
      })
      .catch((err) => {
        errorCallback(err);
        console.log(err.result);
        dispatch({ type: "CHECK_REDEEM_FAILED", payload: err.result });
      })
      .finally(() => {
        finallyCb && finallyCb();
      });
  };
}
