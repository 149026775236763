import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TreeSelect, Input } from 'antd';
import intl from "react-intl-universal";

import school from 'components/services/school';

const { Search } = Input;

export default function HighlightActions(props) {
  const { translations: { initDone } } = useSelector(state => state);
  const { grade, search, getData, setPage } = props;

  const [ gradeValue, setGradeValue ] = grade;
  const [ searchValue, setSearchValue ] = search;

  const [classList, setClassList] = useState([]);

  const lang = value => initDone && intl.get(value);

  const getClass = useCallback(() => {
    school.getAllClassList().then(ret => {
      let _ret = ret.reduce(
        (acc, { grade_id, grade_name, class_name, grade_type, class_id }) => {
          if (!acc[grade_type]) {
            acc[grade_type] = {
              title: `${grade_name}`,
              value: `grade_id-${grade_id}`,
              key: `grade_id-${grade_id}`,
              children: [],
            };
          }
          if (!acc[grade_type]["children"][class_id]) {
            acc[grade_type]["children"][class_id] = {
              title: `${grade_name}${class_name}`,
              value: `class_id-${class_id}`,
              key: `class_id-${class_id}`
            };
          }
          return acc;
        },
        []
      );
      (Object.keys(_ret) || []).map((_gType) => {
        _ret[_gType]["children"] = Object.values(_ret[_gType]["children"]);
        return true;
      });
      setClassList(Object.values(_ret));
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      // setLoading(false);
    })
  }, []) 

  useEffect(() => {
    getClass();
  }, [])

  const handleChange = (value) => {
    setGradeValue(value);
    setPage(1);
  }

  return (
    <div style={{ paddingBottom: 15 }}>
      <TreeSelect
        style={{ width: 120, marginRight: 10 }}
        value={gradeValue}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={classList}
        placeholder={lang('schoolCourse.highlight.grade')}
        // treeDefaultExpandAll
        allowClear
        onChange={handleChange}
      />
      <Search
        placeholder={lang('schoolCourse.highlight.search')}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        allowClear
        onSearch={value => {
          setPage(1);
          getData(value);
        }}
        style={{ width: 200 }}
      />
    </div>
  )
}
