import React, { Fragment, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Icon, Modal } from 'antd';
import { CSSTransition } from 'react-transition-group';

import File from 'components/common/File';
import HighlightContext from './HighlightContext';

import styles from '../sass/highlightItem.module.scss';
import '../sass/transition.scss';

// import { fileTypeOf } from "components/utils/type";

import course from 'components/services/courseService';

export default function HighlightItem(props) {
  const { data, maskHidden, callback } = props;

  const { user_like } = data;

  const { id: user_id } = useSelector(state => state.user);

  const [maskShow, setMaskShow] = useState(false); // 遮罩的顯示
  const [previewVisible, setPreviewVisible] = useState(false); // 圖片預覽的顯示
  // const [likeLoading, setLikeLoading] = useState(false);

  const { useCurrent } = useContext(HighlightContext);

  const [_, setCurrent] = useCurrent;

  const handleAddLike = (type) => () => {
    const _type = !user_like || user_like.type !== type ? type : undefined;
    course.recordAddLike(data.ur_id, _type).then(ret => {
      if (callback) callback();
    }).catch(error => {
      console.log(error);
    })
  }

  const actions = [
    { id: 1, type: 'HEART', img: 'icon_01' },
    { id: 2, type: 'LIKE', img: 'icon_02' },
    { id: 3, type: 'FUN', img: 'icon_03' },
  ]

  const nums = [
    {id: 1, num: data.num_of_heart},
    {id: 2, num: data.num_of_like},
    {id: 3, num: data.num_of_fun},
    {id: 4, num: data.num_of_comment, hidden: data.is_comment === 'N'},
  ]

  const isLike = user_like && user_like.user_id === user_id;

  return (
    <Fragment>
      <div className={styles.root}>
        <div
          style={{ backgroundImage: `url(${data.cover})` }}
          className={styles.img_box}
          onMouseEnter={() => setMaskShow(true)}
          onMouseLeave={() => setMaskShow(false)}
          onClick={() => setPreviewVisible(true)}
        >
          <CSSTransition 
            in={maskShow && !maskHidden}   //用于判断是否出现的状态
            timeout={1000}           //动画持续时间
            classNames="actions-mask"   //className值，防止重复
            unmountOnExit
          >
            <div 
              className={styles.actions_mask}
              onClick={e => e.stopPropagation()}
            >
              {actions.map(item => (
                <div 
                  key={item.id} 
                  className={styles.icon_wrap}
                  style={{ backgroundColor: isLike && (user_like && user_like.type === item.type) ? '#5ACEFF' : '#fff' }}
                >
                  <img 
                    src={require(`assets/image/schoolCourse/highlight/${item.img}.svg`)} 
                    alt="action icon"
                    onClick={handleAddLike(item.type)}
                  />
                </div>
              ))}
              {data.is_comment === 'Y' && (
                <div className={styles.icon_wrap}>
                  <img 
                    src={require(`assets/image/schoolCourse/highlight/icon_04.svg`)} 
                    alt="action icon"
                    onClick={() => setCurrent(data)}
                  />
                </div>
              )}
              <Icon 
                className={styles.fullscreen_icon} 
                type="fullscreen"
                // onClick={() => setPreviewVisible(true)}
                onClick={() => setCurrent(data)}
              />
            </div>
          </CSSTransition>
          {/* 獎牌 */}
          {['1', '2', '3'].includes(data.rank) && (
            <img 
              src={require(`assets/image/schoolCourse/highlight/medal_0${data.rank}.png`)} 
              alt="medal icon"
              className={styles.medal_icon}
            />
          )}
          {data.reward && (
            <div className={styles.creative_awards}>
              <img 
                src={require(`assets/image/schoolCourse/highlight/medal.svg`)} 
                alt="medal icon"
              />
              &nbsp;
              {data.reward}
            </div>
          )}
        </div>
        <div className={styles.name_box}>
          <Typography.Title
            level={4}
            ellipsis
            className={styles.name}
          >
            {data.name}
          </Typography.Title>
          <div className={styles.actions_wrap}>
            {nums.filter(_ => !_.hidden).map(item => (
              <div key={item.id}>
                <img src={require(`assets/image/schoolCourse/highlight/icon_0${item.id}.svg`)} alt="action icon" />&nbsp;
                <span>{item.num}</span>
              </div>
            ))}
          </div>
        </div>
        <Typography.Paragraph
          ellipsis
          className={styles.course_name}
        >
          {data.course_name}
        </Typography.Paragraph>
      </div>
      {/* 文件預覽 */}
      <Modal
        visible={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        footer={null}
        className={styles.preview}
      >
        <File src={data.file} className={styles.file_wrap} />
      </Modal>
    </Fragment>
  )
}
