import { client } from "components/services/apiService";

const url = `${process.env.REACT_APP_API_URL_USER}/device`;

export async function getList(offset=0, limit=10) {
  return await client.post({
    url: `${url}/get_list`,
    form: {
      offset,
      limit
    }
  })
}

export async function updateRead(id) {
  return await client.post({
    url: `${url}/update_read`,
    form: {
      id
    }
  })
}

export async function archive(id) {
  return await client.post({
    url: `${url}/archive`,
    form: {
      id
    }
  })
}