import React, { useEffect, useState } from "react";
// import { useSelector } from 'react-redux';

import { Button } from "antd";

import intl from "react-intl-universal";

export default function webApp(params) {
    // const { route: { currentLocation } } = useSelector(state => state);
    const langStorage = localStorage.getItem('lang');
    const [pattern, setPattern] = useState('');

    const isAndroid = () => {
        const u = navigator.userAgent;
        if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
            setPattern('Android');
            return 'Android';
        }
        if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            setPattern('IOS');
            return 'IOS';
        }
        setPattern('Android');
        return '';
    }

    useEffect(() => {
        isAndroid();
    }, []);
    console.log(pattern);
    return (
        <div style={{ width: '100%', height: '100vh', paddingTop: 90 }}>
            <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block', }}>
                    <div
                        style={{
                            width: '100px',
                            height: '98px',
                            background: '#FFFFFF',
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                            borderRadius: '10px',
                            lineHeight: '98px',
                            margin: '0 auto'
                        }}>
                        <img
                            src={require("assets/image/WebApp/EVIGARTEN.png")}
                            alt="logo"
                            style={{ width: "100%", cursor: "pointer" }}
                        />
                    </div>
                    <div>
                        <div style={{ fontSize: '18px', fontWeight: '400', color: '#000000', marginTop: '13px' }}>EVIGARTEN</div>
                        <div style={{ fontSize: '14px', fontWeight: '400', color: '#8B8B8B', marginTop: '3px' }}>
                            {intl.get("home.publicMsg.web.EVIGARTENlook")}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button
                            style={{
                                backgroundColor: "#38AD52",
                                color: "#fff",
                                borderRadius: 10,
                                height: 40,
                                border: "none",
                                marginBottom: '13px',
                                marginTop: '13px',
                                width: '185px'
                            }}
                        >
                            <a
                                href={
                                    pattern === 'IOS' ?
                                        `https://apps.apple.com/hk/app/evigarten/id1464845844` :
                                        `https://play.google.com/store/apps/details?id=hk.com.evi.evifamily.kids`
                                }
                                target="blank"
                            >
                                {intl.get("home.publicMsg.web.obtain")}
                            </a>
                        </Button>
                        {
                            pattern !== 'IOS' && <Button
                                style={{
                                    backgroundColor: "#38AD52",
                                    color: "#fff",
                                    borderRadius: 10,
                                    height: 40,
                                    border: "none",
                                    width: '185px'
                                }}
                            >
                                <a
                                    href={`https://app-info.evigarten.com/release/${langStorage}/index.html`}
                                    target="blank"
                                >
                                    {intl.get("home.publicMsg.web.APK")}
                                </a>
                            </Button>
                        }
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center', paddingTop: '59px' }}>
                <div style={{ display: 'inline-block', }}>
                    <div
                        style={{
                            width: '100px',
                            height: '98px',
                            background: '#FFFFFF',
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                            borderRadius: '10px',
                            lineHeight: '98px',
                            margin: '0 auto'
                        }}>
                        <img
                            src={require("assets/image/WebApp/EVIFamily.png")}
                            alt="logo"
                            style={{ width: "100%", height: '100%', borderRadius: '10px', cursor: "pointer" }}
                        />
                    </div>
                    <div>
                        <div style={{ fontSize: '18px', fontWeight: '400', color: '#000000', marginTop: '13px' }}>EVIFamily</div>
                        <div style={{ fontSize: '14px', fontWeight: '400', color: '#8B8B8B', marginTop: '3px' }}>
                            {intl.get("home.publicMsg.web.EVIFamilylook")}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button
                            style={{
                                backgroundColor: "#38AD52",
                                color: "#fff",
                                borderRadius: 10,
                                height: 40,
                                border: "none",
                                marginBottom: '13px',
                                marginTop: '13px',
                                width: '185px'
                            }}
                        >
                            <a
                                href={
                                    pattern === 'IOS' ?
                                        `https://apps.apple.com/us/app/evi-family/id1219866515` :
                                        `https://play.google.com/store/apps/details?id=hk.com.evi.eviapp`
                                }
                                target="blank"
                            >
                                {intl.get("home.publicMsg.web.obtain")}
                            </a>
                        </Button>
                        {
                            pattern !== 'IOS' && <Button
                                style={{
                                    backgroundColor: "#38AD52",
                                    color: "#fff",
                                    borderRadius: 10,
                                    height: 40,
                                    border: "none",
                                    width: '185px'
                                }}
                            >
                                <a
                                    href={`https://app-info.evigarten.com/release/evifamilyapk/${langStorage}/index.html`}
                                    target="blank"
                                >
                                    {intl.get("home.publicMsg.web.APK")}
                                </a>
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}