import moment from "moment";
// var moment = require("moment");

function getStartMonth(time) {
  return moment(time).startOf("month").format("YYYY-MM-D");
}

function getEndMonth(time) {
  return moment(time).endOf("month").format("YYYY-MM-D");
}

function getTime(time) {
  return moment(time).format("YYYY-MM-D");
}

function getBool(date1, date2) {
  return moment(date1).isBefore(date2);
}

function getMondayTime(date) {
  return moment(date).startOf("isoWeek");
}

function getSundayTime(date) {
  return moment(date).endOf("isoWeek");
}

function getMonthDay(time) {
  return moment(time).format("MM-DD");
}

function getTimeAlpha(date, format) {
  return moment(date).format(format);
}

export default {
  getTime,
  getStartMonth,
  getEndMonth,
  getBool,
  getMondayTime,
  getSundayTime,
  getMonthDay,
  getTimeAlpha,
};
