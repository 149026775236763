import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Video from "components/common/Video";

import styles from "assets/css/resource/schoolResourceMcVideo.module.scss";

const McVideo = (props) => {
  const { data, lang } = props;
  const Lang = lang === "cn" ? "zh" : lang
  const { location } = useSelector((state) => state.new_global);
  const { item } = data;
  const [videoIdx, setVideoIdx] = useState(0);
  const [autoplay, setAutoplay] = useState(false);
  // 播放视频文件
  const [videoFile, setVideoFile] = useState("")
  const init = () => {
    setVideoIdx(0);
    setAutoplay(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    selectLangVideo()
  }, [lang, videoIdx]);

  // 自动播放下一个
  // useEffect(() => {
  //   if (autoplay) {
  //     const flag = videoIdx + 1 < data.item.length;
  //     setVideoIdx(flag ? videoIdx + 1 : 0);
  //     setAutoplay(false);
  //   }
  // }, [autoplay]);

  // 选择相应语言的视频
  const selectLangVideo = () => {
    if (data.item && data.item[videoIdx].lang.length > 0) {
      let videoObj = null
      // // hk mo tw 簡體中文時,要展示繁體字幕
      if (["hk", "mo", "tw"].includes(location) && lang === "cn") {
        videoObj = data.item[videoIdx].lang.find((item) => item.lang === "zh" && item.vo_lang === "PUTONGHUA");
      } else {
        videoObj = data.item[videoIdx].lang.find((item) => item.lang === lang);
      }
      videoObj && setVideoFile(videoObj.file)
    }
  }

  return (
    <div className={styles.wrap}>
      {!!item.length && (
        <Video
          videosrc={videoFile}
          light={false}
          onEndEnable={true}
          setAutoplay={setAutoplay}
        />
      )}

      <div style={{ textAlign: "center", margin: "8px 0" }}>
        <img
          src={require(`assets/image/resource/mc/video_img/${videoIdx === 0 ? `answer_${Lang}` : `question_${Lang}`
            }.png`)}
          onClick={() => {
            setVideoIdx(videoIdx + 1 < data.item.length ? videoIdx + 1 : 0);
          }}
          style={{ cursor: "pointer" }}
          alt=""
        />
      </div>
    </div>
  );
};

export default McVideo;
