import React, { useState, useEffect } from "react";
import intl from "react-intl-universal";
import { Row, Col, Form, Select, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { addSubscribe } from "store/action/auth";
import { setChildren } from "store/action/family";

import styles from "../../sass/newChildren/selectPackage.module.scss";
import toast from "components/utils/toast";

const { Option } = Select;

const SelectPackage = (props) => {
  const { subNext, subPrev, form } = props;

  const {
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    validateFields,
  } = form;

  const dispatch = useDispatch();
  const { addChildrenId } = useSelector((state) => state.new_family);
  const { family } = useSelector((state) => state.user);

  const { pay } = useSelector((state) => state.new_auth);

  const [plan, setPlan] = useState(null);

  const submitHandle = () => { };

  const { initDone } = useSelector((state) => state.translations);

  const { currentLocation, currentLanguage } = useSelector((state) => state.route);
  const { showUser } = useSelector((state) => state.new_global);

  const lang = (value, params) =>
    initDone && intl.get(`register.${value}`, params);

  // 根据选择,存储到 redux 中
  useEffect(() => {
    if (getFieldValue("grade_type") && plan && addChildrenId) {
      const result = family.find(
        (item) => item.permit_user === addChildrenId.toString()
      );
      if (plan === "pay") {
        dispatch(
          addSubscribe({
            id: addChildrenId.toString(),
            service_id: 1,
            grade_type: getFieldValue("grade_type"),
            name: result ? result.permit_user_name : "",
          })
        );
        if (!showUser) {
          toast.createToast({
            type: "info",
            msg: lang("step_three.add_plan") || "已選定訂閱計劃",
          });
        }
      } else {
        //remove subscription
        dispatch(
          addSubscribe({
            id: addChildrenId.toString(),
            service_id: "free",
            grade_type: getFieldValue("grade_type"),
            name: result ? result.permit_user_name : "",
          })
        );
        if (pay.find((_pay) => _pay.id == addChildrenId) && !showUser) {
          toast.createToast({
            type: "warning",
            msg: lang("step_three.remove_plan") || "已刪除購物車中的購買項目",
          });
        }
      }

      dispatch(setChildren());
      // subNext();
    }
  }, [plan, getFieldValue("grade_type")]);

  useEffect(() => {
    if (!addChildrenId) {
      subNext();
    }
  }, [addChildrenId]);

  const cur =
    family &&
    family.find(
      (item) => item.permit_user === (addChildrenId && addChildrenId.toString())
    );

  return (
    <div className={styles.box}>
      {!!showUser && (
        <div style={{ marginBottom: 35 }}>
          <h2 className={styles.describe}>
            {lang("step_three.package_title")}
          </h2>
          <h2 className={styles.describe2}>
            {lang("step_three.package_content")}
          </h2>
        </div>
      )}

      <Form onSubmit={submitHandle} layout="inline">
        <Form.Item className="" label={lang("step_three.form_grade")}>
          {getFieldDecorator("grade_type", {
            initialValue: (pay || []).length
              ? (
                pay.find(
                  (item) =>
                    item.id === (addChildrenId && addChildrenId.toString())
                ) || { grade_type: "K1" }
              ).grade_type
              : cur
                ? cur.permit_grade_type
                : "",
          })(
            <Select
              className={styles.select}
              placeholder={lang("step_three.form_grade")}
              size="large"
              autoFocus
            // className={styles.aequilatus}
            >
              <Option value="PN">PN</Option>
              <Option value="K1">K1</Option>
              <Option value="K2">K2</Option>
              <Option value="K3">K3</Option>
            </Select>
          )}
        </Form.Item>
        <Row
          type="flex"
          justify="space-around"
          align="middle"
          className={styles.images}
        >
          {!showUser && (
            <Col
              xs={20}
              md={10}
              className={`${styles.img} ${plan === "free" && styles.checked} `}
              onClick={() => setPlan("free")}
            >
              <img
                src={require(`assets/image/newRegister/addChildren/${currentLanguage.value}/price_free.png`)}
                alt=""
                width="100%"
              />
            </Col>
          )}
          {
            !["cn", "tw"].includes(currentLocation) && <Col
              xs={20}
              md={10}
              className={`${styles.img} ${plan === "pay" && styles.checked} `}
              onClick={() => setPlan("pay")}
            >
              <img
                src={require(`assets/image/newRegister/addChildren/${currentLanguage.value}/price.png`)}
                alt=""
                width="100%"
              />
            </Col>
          }
        </Row>
      </Form>
      <Button
        className={styles.back}
        onClick={() => {
          if (showUser) {
            setPlan("free");
          } else {
            subNext();
          }
        }}
      >
        {lang("btn_back")}
      </Button>
    </div>
  );
};

export default Form.create()(SelectPackage);
