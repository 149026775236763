import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams , useHistory } from 'react-router-dom';
import { Drawer, Row, Col, Spin, Tabs } from 'antd';
import intl from 'react-intl-universal';
import { useMediaQuery } from 'react-responsive';

import userLogService from 'components/services/userLogService';
import voteService from 'components/services/voteService';

import VoteItem from './voteItem';

import styles from 'assets/css/vote/index.module.scss';

const { TabPane } = Tabs;

const tabConfig = ['K1', 'K2', 'K3'];

const Vote = () => {
  const { translations } = useSelector(state => state);
  const history = useHistory();

  const isLg = useMediaQuery({ query: '(min-width: 992px)' });

  const { display_id } = useParams();

  const [ visible, setVisible ] = useState(true);
  const [ loading, setLoading ] = useState(false);
  const [ info, setInfo ] = useState({});
  const [ data, setData ] = useState([]);
  const [ grade, setGrade ] = useState('K1');

  const lang = (value) => translations.initDone && intl.get(value);

  // const getData = () => {
  //   const { course_id, res_id } = info;
  //   if (course_id && res_id) {
  //     userLogService.getUserReport({ course_id, res_id, vote_id: 1 }).then(res => {
  //       setData(res.filter(_ => _.project_file && _.ur_id));
  //     }).finally(() => {
  //       setLoading(false);
  //     })
  //   }
  // }

  const init = () => {
    return voteService.get({ id: display_id }).then(ret => {
      setInfo(ret);
      const { id, course_id, res_id } = ret;
      return userLogService.getUserReport({ course_id, res_id, vote_id: id }).then(res => {
        setData(res.filter(_ => _.project_file && _.ur_id));
      }).finally(() => {
        setLoading(false);
      })
    })
  }

  const handleTabChange = (key) => {
    setGrade(key);
  }

  useEffect(() => {
    if(display_id) {
      setLoading(true);
      init();
    }
  }, [])

  // useEffect(() => {
  //   getData();
  // }, [info])

  const gradeFilter = useMemo(() => {
    return data.filter(_ => _.grade_type === grade);
  }, [data, grade]);

  console.log();

  return (
    <Drawer
      title={info.title}
      visible={visible}
      onClose={() => history.length > 2 ? history.goBack() : history.push('/')}
      placement="bottom"
      closable
      height="100%"
      className={styles.root}
      destroyOnClose
    >
      <div className={styles.inner}>
        {loading && (
          <div className={styles.loading}>
            <Spin />
          </div>
        )}
        {!loading && (
          <Tabs activeKey={grade} onChange={handleTabChange} style={{ padding: 10 }}>
            {tabConfig.map(item => (
              <TabPane tab={item} key={item}>
                <Row
                  type="flex"
                  gutter={[20, 20]}
                  justify="start"
                >
                  {gradeFilter.map((item, index) => (
                    <Col
                      key={item.ur_id}
                      lg={4} md={6} sm={12} xs={24}
                      className={isLg ? styles.item_wrap : ''}
                    >
                      <VoteItem
                        voteId={info.id}
                        data={item}
                        userVote={info.user_vote || {}}
                        callback={init}
                        notInPeriod={!(new Date() >= new Date(info.start_time.replace(/-/g, "/")) && new Date() <= new Date(info.end_time.replace(/-/g, "/")))}
                      />
                    </Col>
                  ))}
                </Row>
              </TabPane>
            ))}
          </Tabs>
        )}
      </div>
    </Drawer>
  )
}

export default Vote;
