import { client } from "components/services/apiService";

const url = `${process.env.REACT_APP_API_URL_USER}/coin`;

export async function history(params = {}) {
  const { month, offset, limit = 10, search_user } = params;
  return await client.post({
    url: `${url}/history`,
    form: {
      date_month: month,
      offset: (offset || 0) * limit,
      search_user,
      limit,
    },
  });
}

export default {
  history,
};
