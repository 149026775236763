import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; 
import { Row, Col, Icon, Empty, Spin } from 'antd';
import intl from "react-intl-universal";

import Resource from "components/course/Resource";
import TitleCard from "components/common/TitleCard";
import Adapter from "components/resource/Adapter";
import service from "components/services/user_favourite";

const styles={
  star: {
    color: 'gold', 
    position: 'absolute',
    right: 0, 
    bottom: -1,
    cursor: 'pointer',
    padding: 5,
    zIndex: 1,
  }
}

const FavoritePage = () => {
  const { translations } = useSelector(state => state);

  const [ loading, setLoading ] = useState(false);
  const [ data, setData ] = useState({ total: 0, rows: [] });

  const lang = (value) => translations.initDone && intl.get(value);

  //获取数据
  const getList = () => {
    service.getList().then(ret => {
      setData(ret);
    }).finally(() => {
      setLoading(false);
    })
  }

  // 點擊星星
  const handleClickStar = (e, item) => {
    e.preventDefault();
    service.starDelete(item.res_id).then(ret => {
      getList();
    })
  }

  useEffect(() => {
    setLoading(true);
    getList();
  }, [])

  return (
    <TitleCard 
      title={lang("myFavorite.content.title")}
      titleBgColor='#ff92a1'
      titleColor='#fff'
      mainBgColor='#fff'
      span={{ span: 24 }}
    >
      <Spin 
        size='large' 
        tip='Loading...'
        spinning={loading}
      >
        <Row gutter={40} style={{ padding: '20px 45px' }}>
          {!!data.rows.length ? 
            data.rows.map(item => (
              <Col 
                key={item.ref_id} 
                lg={6} md={8} sm={12} xs={24}
                style={{ marginBottom: 20 }}
              >
                <Adapter 
                  id={item.res_id}
                  item={item}
                  res_type={item.type}
                  nWindow={true}
                >
                  <Resource 
                    picUrl={item.file} 
                    title={item.name}
                    titleFontSize='12px'
                    titlePadding='6px 20px 6px 10px'
                  />
                  <div 
                    style={styles.star}
                    onClick={(e) => handleClickStar(e, item)}
                  >
                    <Icon type='star' theme="filled" />
                  </div>
                </Adapter>
              </Col>
            ))
            :
            <Empty />
          }
        </Row>
      </Spin>
    </TitleCard>
  )
}

export default FavoritePage;