import React, { useState, useEffect } from "react";
import { Modal, Checkbox, Button, Icon, List } from "antd";
import { useHistory } from "react-router-dom";

const IframeModal = (props) => {
  const { data } = props;

  const [visible, setVisible] = useState(true);

  const history = useHistory();

  useEffect(() => {
    window.addEventListener("message", (e) => {
      const { data } = e;
      if (data.operation === "goRedeem") {
        history.push("/redemption");
      } else if (data.operation === "close") {
        history.push("/");
      }
    });
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(!visible)}
      footer={null}
      bodyStyle={{ padding: "0" }}
      centered
      zIndex={777}
      closable={false}
      keyboard={false}
      maskClosable={false}
    >
      {!!data.iframe_url ? (
        <iframe
          frameBorder="0"
          src={data.iframe_url}
          style={{ width: "100%" }}
          scrolling="no"
        ></iframe>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
      )}
      {/* <List
        dataSource={[data]}
        renderItem={(item) => (
          <List.Item>
            <div style={{ position: "relative", width: "100%" }}>
              <h2>{item.title}</h2>
              {!!item.iframe_url ? (
                <iframe
                  frameBorder="0"
                  src={item.iframe_url}
                  style={{ width: "100%", height: 500 }}
                ></iframe>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
              )}
            </div>
          </List.Item>
        )}
      /> */}
    </Modal>
  );
};

export default IframeModal;
