import React, { useState } from 'react';
import { Drawer, Empty } from 'antd';

const Capsule = (props) => {

  // const [ visible, setVisible ] = useState(true);

  // useEffect(() => {
  //   if(visible) {
  //     document.body.style.overflow = 'hidden';
  //   }
  // }, [visible])

  const token = window.localStorage.getItem("access_token");
  const device_id = window.localStorage.getItem("uuid");

  return (
    <Drawer
      // title=" "
      visible={true}
      // onClose={() => setVisible(false)}
      placement="bottom"
      closable={false}
      height="100%"
      headerStyle={{
        height: 56
      }}
      bodyStyle={{
        padding: 0,
        height: '100%'
      }}
      destroyOnClose
    > 
      {token && device_id ? (
        <object data={`${process.env.REACT_APP_CAPSULE_URL}/?accessToken=${token}&deviceID=${device_id}`} width="100%" height="100%">
          此瀏覽器不支持該文件，請更換瀏覽器
        </object>
      ) : <Empty style={{ marginTop: 40 }} />}
    </Drawer>
  )
}

export default Capsule;