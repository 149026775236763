import React, { useState, useEffect } from "react";
import intl from "react-intl-universal";
import { Button, Statistic, message, Icon, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import VerificationInput from "react-verification-input";

import { verifyRegister } from "../../../store/action/auth";
import { resend, logout } from "components/services/authService";
import userService from "components/services/userService";

import { putChildren } from "~/store/action/family";

import styles from "../sass/verification.module.scss";

// const { Countdown } = Statistic;

const Verification = (props) => {
  const { next, prev } = props;

  // 验证码
  const [code, setCode] = useState("");

  // 倒计时
  const [time, setTime] = useState(60);

  // 下一步禁用状态
  const [disabled, setDisabled] = useState(true);
  const [displayName, setName] = useState("");

  const dispatch = useDispatch();

  const { loginCode, kid_token } = useSelector((state) => state.auth);

  const { token, temp } = useSelector((state) => state.new_auth);

  // const { id, family=[], language } = useSelector((state) => state.user);

  const { initDone } = useSelector((state) => state.translations);
  const { skipBindChildren } = useSelector((state) => state.new_global);

  const lang = (value, params) =>
    initDone && intl.get(`register.${value}`, params);

  // 控制倒计时
  useEffect(() => {
    let timer = setTimeout(() => {
      setTime(time - 1);
    }, 1000);
    if (time <= 0) {
      setDisabled(false);
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  // 输入完验证码后自动 call api
  useEffect(() => {
    if (code.length === 6) {
      dispatch(
        verifyRegister({
          vcode: code,
          access_token: token,
          callback: async (error) => {
            if (error) {
              message.error("驗證失敗");
              return;
            }
            if (skipBindChildren) {
              next();
            }
            const ret = await userService.me(true).then((payload) => {
              dispatch({ type: "INIT", payload });
              return payload;
            });

            const { id, family = [], language } = ret;

            if (
              // true
              loginCode === 210 &&
              kid_token
            ) {
              dispatch({ type: "VERIFICATION_210_LOAD", payload: true });

              dispatch(
                putChildren({
                  language,
                  family_id: id ? family[0].family_id : undefined,
                  kid_token,
                  callback: async (error) => {
                    dispatch({ type: "VERIFICATION_210_LOAD", payload: false });

                    if (error) {
                      message.error(
                        initDone && intl.get(`general.msg.add_error`)
                      );
                      return;
                    }

                    await userService.me(true).then((payload) => {
                      dispatch({ type: "INIT", payload });
                    });
                  },
                })
              );
            }
            next();
          },
        })
      );
      setCode("");
    }
  }, [code]);

  useEffect(() => {
    const account = (
      (userService.$$user &&
        (userService.$$user.email || `+${userService.$$user.tel}`)) ||
      (temp && temp.account) ||
      ""
    )
      .split("")
      .map((i, k) => {
        return (
          (i === "@" && i) || (k < 4 && i) || (Math.random() >= 0.5 && "*") || i
        );
      })
      .join("");
    setName(account);
  }, [
    userService.$$user && userService.$$user.email,
    userService.$$user && userService.$$user.tel,
    temp && temp.account,
  ]);

  // 重新发送,设为初始值
  const submit = () => {
    setTime(60);
    setDisabled(true);
    resend();
  };

  return (
    <div className={styles.box}>
      {/* <Spin
        indicator={<Icon type="loading" spin />}
        className={styles.loading}
        spinning={load}
      /> */}
      <h2 className={styles.authCode}>{lang("step_two.title")}</h2>
      <p className={styles.describe}>
        {lang("step_two.tip", {
          user: displayName || (temp && temp.account),
          type:
            (userService.$$user &&
              ((userService.$$user.email && lang("step_two.email")) ||
                (userService.$$user.tel && lang("step_two.tel")))) ||
            (temp && !temp.account.includes("@") && lang("step_two.tel")) ||
            (temp && temp.type === "email" && lang("step_two.email")),
        })}
      </p>
      <VerificationInput
        inputField={{
          onChange: (value) => setCode(value.currentTarget.value),
          value: code,
        }}
        autoFocus={true}
        placeholder=""
        container={{
          className: styles.codeInput,
        }}
        characters={{
          className: styles.characters,
        }}
        character={{
          className: styles.character,
          classNameInactive: styles.characterInactive,
          classNameSelected: styles.characterSelected,
        }}
      />
      <div className={styles.center}>
        <Button
          className={styles.button}
          block
          onClick={submit}
          disabled={disabled}
          // type="primary"
        >
          {lang("step_two.btn_sent", { num: time ? `(${time}S)` : "" })}
        </Button>
        <Button
          className={styles.button}
          block
          onClick={() => {
            const location = userService.getHomeURL();
            logout().then(() => {
              window.location = location;
            });
          }}
        >
          {lang("btn_back")}
        </Button>
      </div>
    </div>
  );
};

export default Verification;
