import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import intl from "react-intl-universal";
import { Modal, Upload, Button, message } from "antd";

import { fileTypeOf } from "components/utils/type";

import course from "components/services/courseService";

import File from "components/common/File";

import 'assets/css/resource/schoolResourceUpload.module.scss';

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

//视频缩略图
const getVideoImage = (file, callback) => {
  if (file && file.type.indexOf("video/") === 0) {
    let video = document.createElement("video");
    video.currentTime = 1;
    video.src = URL.createObjectURL(file);
    video.addEventListener("loadeddata", function () {
      let canvas = document.createElement("canvas"); // 创建一个画布
      canvas.width = video.videoWidth * 0.8;
      canvas.height = video.videoHeight * 0.8;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height); // getContext:设置画布环境；drawImage:画画
      let image = canvas.toDataURL("image/jpeg");
      typeof callback == "function" && callback(image);
    });
  }
};

const SchoolResourceUpload = (props) => {
  const { id, refId, courseId, onUpload, uploaded, uploadTime, isComment, theme } = props;

  const { translations: { initDone } } = useSelector(state => state);

  const [ fileList, setFileList ] = useState([]);
  const [ previewImage, setPreviewImage ] = useState('');
  const [ previewVisible, setPreviewVisible ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ isResubmit, setIsResubmit ] = useState(false);

  const lang = (value) => initDone && intl.get(value);

  const init = () => {
    setFileList([]);
    setLoading(false);
    setIsResubmit(false);
  }

  useEffect(() => {
    if (uploaded) {
      setFileList([
        {
          uid: '1',
          status: 'done',
          url: uploaded,
      }])
    }
  }, [uploaded])

  // 上载文件预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  }

  const handleChange = ({ fileList, file }) => {
    const typeList = ['.gif', '.jpg', '.jpeg', '.png', '.mp4', '.mov', '.pdf' ];
    const fileType = fileTypeOf(file.name, typeList);
    // 類型判斷
    if (!fileType && !file.status) {
      Modal.error({ title: lang("general.msg.upload_type2") });
      return;
    }
    // 设置视频缩略图
    if (fileList.length && file.type === "video/mp4") {
      getVideoImage(file, (ret) => {
        fileList[0].thumbUrl = ret;
        fileList[0].preview = ret;
        setFileList(fileList);
      });
      return;
    }
    // 設置重新提交
    if (file.status === 'removed') {
      setIsResubmit(true);
    }
    setFileList(fileList);
  }

  const handleUpload = async () => {
    try {
      setLoading(true);
      message.loading({ content: lang('general.msg.uploading'), key: 'updatable' });
      const file = fileList[0].originFileObj;
      const formData = {
        id,
        res_id: refId,
        course_id: courseId,
        app_type: "KID_WEB",
      };
      const permit = id ? await course.getUserRecordUpdatePermit(file, formData) : await course.getUserRecordAddPermit(file, formData);
      await course
        .uploadFile(permit, file)
        .then(() => {
          if(onUpload) onUpload();
          message.success({ content: lang('general.msg.upload_success'), key: 'updatable', duration: 2 });
          init();
        })
        .catch(() => {
          return new Promise((resolve, reject) => {
            reject("UPLOAD_ERROR");
          });
        });
    } catch {
      console.log("上傳失敗");
    } finally {
      setLoading(false);
    }
  }

  const uploadProps = {
    listType: "picture-card",
    accept: ".gif, .jpg, .jpeg, .png, .mp4, .mov, .pdf",
    maxCount: 1,
    fileList,
    beforeUpload: () => false,
    onChange: handleChange,
    onPreview: handlePreview,
    showUploadList: {
      showRemoveIcon: !isComment
    }
  };

  return (
    <div className="schoolResourceUpload_root">
      <Upload {...uploadProps}>
        {fileList.length < 1 ? lang('schoolCourse.project_modal.btn_upload_file') : null}
      </Upload>
      {(!uploaded || isResubmit) ? (
        <Button 
          className="btn"
          onClick={handleUpload}
          loading={loading}
          style={{ 
            backgroundColor: theme,
            border: `1px solid ${theme}`
          }}
        >
          {lang('schoolCourse.project_modal.btn_submit')}
        </Button>
      ) : (
          <Button className="submitted_btn btn">
            {lang('schoolCourse.project_modal.btn_submitted')}
          </Button>
        )
      }
      {uploadTime && !isResubmit && (
        <span className="upload_time">
          {uploadTime}
        </span>
      )}
      {/* 預覽框 */}
      <Modal 
        visible={previewVisible}
        footer={null} 
        onCancel={() => setPreviewVisible(false)}
      >
        {/* <img alt="preview" style={{ width: '100%' }} src={previewImage} /> */}
        <File src={previewImage}/>
      </Modal>
    </div>
  )
}

export default SchoolResourceUpload;