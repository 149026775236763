import React, { useEffect, useState, Fragment } from "react";
import intl from "react-intl-universal";
import { Row, Col, Form, Button, Radio, Divider, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  subscriptionTotal,
  subscriptionPaypal,
} from "../../../../store/action/payment";

import styles from "../../sass/newChildren/pay.module.scss";

const Pay = (props) => {
  const { form, next, subPrev } = props;

  const { getFieldDecorator, getFieldValue, validateFields } = form;

  const { initDone } = useSelector((state) => state.translations);

  const lang = (value, params) =>
    initDone && intl.get(`register.${value}`, params);
  const dispatch = useDispatch();

  const { pay } = useSelector((state) => state.new_auth);
  const { total } = useSelector((state) => state.new_payment);
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const paymentIcon = ['paypal', 'paypal-visa'].reduce((acc, psp) => {
    acc[psp] = require(`assets/image/payment/${psp}.jpg`)
    return acc;
  }, {});


  // 页面打开时,call 订阅结算 api
  useEffect(() => {
    if (pay.length) {
      setLoading(true);
      dispatch(
        subscriptionTotal({
          user_services: pay.map((item) => ({
            user_id: item.id,
            grade_type: item.grade_type,
            service_id: 1,
            quantity: 1,
          })),
          callback: (ret) => {
            setLoading(false);
          }
        })
      );
    }
  }, []);

  const email = user.email ? user.email : "";

  const makePayment = () => {
    setLoading(true);
    dispatch(
      subscriptionPaypal({
        user_services: pay.map((item) => ({
          user_id: item.id,
          grade_type: item.grade_type,
          service_id: 1,
          quantity: 1,
          email: !!email ? email : getFieldValue("email"),
        })),
        psp_id: getFieldValue("pay"),
        callback: (ret) => {
          setLoading(false);
          if (ret) {
            window.location = ret.payload.method.detail.links[0].href;
            return;
          }
          message.error("獲取付款訊息失敗！");
        },
      })
    );
  };

  return (
    <div className={styles.box}>
      <Row type="flex" justify="space-around" align="middle" gutter={32} className={styles.reverse}>
        <Col xs={24} md={12} className={styles.border}>
          <Form layout="vertical" className={styles.form}>
            <Form.Item label={lang("step_three.label_payment")} style={{ flex: 1 }}>
              {getFieldDecorator("pay")(
                <Radio.Group size="large">
                  {(total.method || [])
                    .filter((payment) => !payment.payment_method.includes("-test"))
                    .map((payment) =>
                    (
                      <Radio key={`payment-${payment.payment_method}`} value={payment.psp_id} className={styles.pay_method}>
                        {(paymentIcon[payment.payment_method] && <img src={paymentIcon[payment.payment_method]} />)
                          || lang(`step_three.${payment.payment_method}`) || payment.payment_method || "Paypal"}
                      </Radio>
                    )
                    )}
                </Radio.Group>

              )}
            </Form.Item>
            <Col span={24} className={styles.tnc} style={{ padding: 0 }}>
              <h3 className={styles.describe}>{initDone && intl.getHTML(`register.step_three.tnc_title`)}</h3>
              <>{initDone && intl.getHTML(`register.step_three.tnc_content`)}</>
            </Col>
            <Col span={24} className={styles.payNow}>
              <Form.Item>
                <Button className={styles.back} onClick={subPrev}>
                  {lang("btn_prev")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className={styles.next}
                  type="primary"
                  onClick={makePayment}
                  loading={loading}
                  disabled={!getFieldValue("pay")}
                >
                  {lang("btn_paynow")}
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Col>
        <Col xs={24} md={12} className={styles.form}>
          <div style={{ flex: "1 1 0%" }}>
            <h3 className={styles.describe}>{lang("step_three.label_items")}</h3>
            <div className={styles.message}>
              <span className={styles.shop}>
                {
                  `${(
                    total && total.info ||
                    [{ name: lang("step_three.item_plan", { num: (total.quantity || 0) }) }]
                  )[0].name}${!!total.quantity && ` x${total.quantity}`}`
                }
              </span>
              <br />
              {lang("step_three.item_valid", {
                start: moment().format("DD-MM-YYYY"),
                end: moment().clone().add(1, "M").format("DD-MM-YYYY"),
              })}
              <br />
              <br />
              {
                pay.map(user => (
                  <Fragment key={user.name}>
                    <span>{lang("step_three.child_name")}：{user.name}</span>
                    <br />
                    <span>{lang("step_three.grade")}：{user.grade_type}</span>
                    <br />
                  </Fragment>
                ))
              }
              <br />
            </div>
          </div>
          <div>
            <Divider style={{ height: 2 }} />
            <h2 className={styles.price}>HK$ {total.amount}</h2>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create()(Pay);
