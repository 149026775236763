import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import intl from "react-intl-universal";

import authService from "components/services/authService";

function getCookie(name) {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");

  if ((arr = window.document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
}

function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}

const Logout = (props) => {
  const dispatch = useDispatch();
  const { children, style, className } = props;

  const { route, translations } = useSelector((state) => state);

  const lang = (value) => {
    return translations.initDone && intl.get("home.publicMsg.drawer." + value);
  };

  const handleLogout = () => {
    Modal.confirm({
      title: lang("LogoutTitle"), //"你確定要登出嗎？",
      okText: lang("Btnconfirm"), //"確認",
      cancelText: lang("Btncancel"), //"取消",
      async onOk() {
        delCookie("guide");
        authService.logout().then((ret) => {
          dispatch({ type: "SET_REGISTER_STATUS", payload: null });
          window.location.href = `/${route.currentLocation}/${route.currentLanguage.url}`;
        });
      },
    });
  };

  return (
    <div style={style} className={className} onClick={handleLogout}>
      {children}
    </div>
  );
};

export default Logout;
