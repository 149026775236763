import React from 'react';
import { useSelector } from 'react-redux';
import intl from "react-intl-universal";
import { Tabs } from 'antd';

import styles from 'assets/css/PersonalCenter.module.scss';
import user_API from 'components/services/userService';


import Binding from './Binding';
import Setting from './Setting';

const { TabPane } = Tabs;

const SettingPage = () => {
  const { translations } = useSelector(state => state);
  const binding = useSelector(state => state.route.currentLocation)

  const lang = (value) => {
    return translations.initDone && intl.get(value);
  }

  return (
    <div className={`${styles.settingPage} ${styles.card}`}>
      <Tabs>
        {user_API.getType() !== "PARENT" && (
          <TabPane tab={binding === "cn" ? lang("personalCenter.setting.bindingPlatform") : lang("personalCenter.setting.connectedPlatform")} key="1" >
            <Binding />
          </TabPane>
        )}
        <TabPane tab={lang('personalCenter.setting.setting')} key="2">
          <Setting />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default SettingPage;