import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import intl from "react-intl-universal";
import {
  List,
  Icon,
  Divider,
  Empty,
  Skeleton,
  Button,
} from "antd";

import styles from "assets/css/PersonalCenter.module.scss";

import coin from "components/services/coinService";

import TitleCard from "components/common/TitleCard";

import user_API from "components/services/userService";

const SkeletonList = () => {
  return Array.from({ length: 4 }, (_, i) => (
    <div key={i}>
      <Skeleton active />
      <Divider />
    </div>
  ));
};

const History = () => {
  const dispatch = useDispatch();
  const { user, translations } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ total: 0, rows: [] });
  const [page, setPage] = useState(0);
  // 是否顯示更多family name
  const [familyName, setFamilyName] = useState(false); 
  // 被选中家庭成员
  const [selectedFamily, setSelectedFamily] = useState([user.id]);

  const { family } = user;

  const lang = (value) => translations.initDone && intl.get(value);

  const curRole = family.find((item) => item.permit_user === user.id);

  const total = useMemo(() => {
    return family.reduce((acc, cur) => {
      const isSelected = selectedFamily.includes(cur.permit_user);
      return acc + (isSelected ? parseInt(cur.coin, 10) : 0);
    }, 0)
  }, [family, selectedFamily])

  const getData = async () => {
    try {
      const params = { offset: page, search_user: selectedFamily };
      const ret = await coin
        .history(params)
        .then((ret) => ret)
        .catch(() => ({ total: 0, rows: [] }));
      setData(ret);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUser = () => {
    user_API.me(true).then(_user => {
      dispatch({ type: "INIT", payload: _user })
    });
    setLoading(true);
    getData();
  }

  // 增加选择家庭成员 call api 获取 coin
  useEffect(() => {
    if(!data.rows.length) {
      setLoading(true);
    }
    getData();
  }, [page, selectedFamily]);

  // 点击家庭账号 把家庭添加到 selectedFamily 中
  const clickButton = (id) => {
    const isSelected = selectedFamily.includes(id);
    if (isSelected) {
      setSelectedFamily(selectedFamily.filter(_ => _ !== id));
    } else {
      setSelectedFamily([...selectedFamily, id]);
    }
  };

  return (
    <TitleCard
      title={lang("personalCenter.rewardActivities.coinHistory")}
      titleBgColor="#FF9354"
      titleColor="#fff"
      mainBgColor="#fff"
      span={{ md: 16, xs: 24 }}
    >
      <div className={styles.history}>
        <div className={styles.action}>
          <div>
            {selectedFamily.length > 1
              ? lang("personalCenter.rewardActivities.familyCoins")
              : lang("personalCenter.rewardActivities.personalCoins")}

            <Button 
              style={{ margin: 4 }} 
              className={styles.activeName}
              disabled
            >
              {curRole.permit_user_name}
            </Button>
            {family.map((item, index) => {
              const id = item.permit_user;

              if ((!familyName && index > 3) || id === curRole.permit_user) {
                return <></>;
              }

              return (
                <Button
                  className={
                    selectedFamily.find((items) => items === id)
                      ? styles.activeName
                      : styles.test
                  }
                  style={{ margin: 4 }}
                  onClick={() => clickButton(id)}
                >
                  {item.permit_user_name || "null"}
                </Button>
              );
            })}
            {family.length > 4 && (
              <Button
                size="small"
                type="primary"
                onClick={() => setFamilyName(!familyName)}
              >
                {familyName ? lang('personalCenter.rewardActivities.retract') : `${lang('personalCenter.rewardActivities.more')} +${family.length - 5}`}
              </Button>
            )}
            <div className={styles.coinTotal_wrap}>
              <img
                src={require("assets/image/personal/big_coin.png")}
                alt="coin"
                style={{ width: 60, height: 60, verticalAlign: "bottom" }}
              />
              <span className={styles.total}>
                {total}
              </span>
              <Button 
                shape="circle"
                icon="sync"
                size="small"
                className={styles.sync}
                loading={loading}
                onClick={handleUpdateUser}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <SkeletonList />
        ) : !!data.rows.length ? (
          <>
            <Divider />
            <List
              itemLayout="vertical"
              size="large"
              dataSource={data.rows}
              pagination={{
                onChange: (index) => {
                  setPage(index - 1);
                },
                pageSize: 10,
                total: data.total,
              }}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  actions={[
                    <span>
                      <Icon type="clock-circle" style={{ marginRight: 8 }} />
                      {item.create_time}
                    </span>,
                  ]}
                  extra={
                    <span
                      style={{
                        color: !!item.add_coin ? "#38c147" : "#ff7070",
                        fontSize: 30,
                        fontWeight: "bold",
                      }}
                    >
                      {(!!item.add_coin ? "+" : "-") + item.coin}
                    </span>
                  }
                >
                  <List.Item.Meta
                    // avatar={<Avatar src={item.avatar} />}
                    title={
                      <span style={{ fontWeight: 'bold' }}>
                        <Icon type={"read"} style={{ marginRight: 8 }} />
                        {item.cat_name}
                      </span>
                    }
                    description={
                      <span
                        style={{ fontSize: 20, color: "rgba(0, 0, 0, 0.65)" }}
                      >
                        {item.title}
                      </span>
                    }
                  />
                </List.Item>
              )}
            />
          </>
        ) : (
          <Empty />
        )}
      </div>
    </TitleCard>
  );
};

export default History;
