import React, { useEffect, useState } from "react";
import intl from "react-intl-universal";

import { Avatar, Icon, Row, Col, Button, Spin } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { setChildren } from "store/action/family";
import { swapUser } from "store/action/user";
import user_API from "components/services/userService";

import styles from "../../sass/bindFamily.module.scss";
import toast from "components/utils/toast";
import { useMediaQuery } from "react-responsive";

const redirectPath = ["resource", "redemption"];

const BindFamily = (props) => {
  const {
    subNext,
    subPrev,
    continueAdd,
    showFamily,
    skipPay,
    progressCallback,
    exitCallback,
    closeConfirm,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const urlParams = new URLSearchParams(useLocation().search);
  const key = urlParams.get("app");

  console.log(key);

  const { initDone } = useSelector((state) => state.translations);
  const account = useSelector((state) => state.route.currentLocation);
  const { locationUrl } = useSelector((state) => state.route);

  const [hover, setHover] = useState({});
  const [isSwap, setSwap] = useState(false);
  const [swapStatus, setSwapStatus] = useState(false);

  const lang = (value, params) =>
    initDone && intl.get(`register.${value}`, params);
  const {
    id: user_id,
    region,
    language,
    family,
    type,
    bg_id,
    permit_img,
    route,
  } = useSelector((state) => state.user);

  console.log(family);

  const { currentLocation } = useSelector((state) => state.route);
  const { pay, verification210Load, loginCode } = useSelector(
    (state) => state.new_auth
  );

  const { showUser, showUserManager } = useSelector(
    (state) => state.new_global
  );

  const isMD = useMediaQuery({ query: "(min-width: 768px)" });
  const isLG = useMediaQuery({ query: "(min-width: 992px)" });
  const isXL = useMediaQuery({ query: "(min-width: 1200px)" });
  const { skipRegister, skipBindChildren } = useSelector(
    (state) => state.new_global
  );

  useEffect(() => {
    if (skipBindChildren) {
      typeof skipPay === "function" && skipPay();
    }
  }, []);

  useEffect(() => {
    if (!!showUser) {
      dispatch(setChildren(user_id));
      subPrev();
    }
  }, [showUser]);

  useEffect(() => {
    if (!!swapStatus && !showUserManager) {
      setTimeout(() => {
        localStorage.setItem("show", true);
        const action = urlParams.get("action");
        if (redirectPath.includes(key) && action) {
          history.push(`${locationUrl}${action}`);
          return;
        }
        history.push(user_API.getHomeURL());
        typeof exitCallback === "function" && exitCallback();
        setTimeout(() => {
          setSwapStatus(false);
          setSwap(false);
        }, 500);
      }, 1000);
    }
    if (!!swapStatus && !!showUserManager) {
      typeof exitCallback === "function" && exitCallback();
      setSwapStatus(false);
      setSwap(false);
    }
  }, [swapStatus]);

  useEffect(() => {
    if (typeof progressCallback === "function") {
      progressCallback(isSwap);
    }
  }, [isSwap]);

  const emptyFamily =
    (family || []).filter(
      (user) => showFamily === true || user.relation === "CHILD"
    ).length === 0;

  const AvatarBorder = (data, resource_permit) => {
    const isParent = data.relation !== "CHILD";
    if (isParent) {
      return "#4A98FF";
    }

    if (!resource_permit) {
      return "#888888";
    }

    switch (data.permit_grade_type) {
      case "K1":
        return "#45A735";
      case "K2":
        return "#FF861C";
      case "K3":
        return "#F64949";
      // case value:
      //   return "#4A98FF";
      default:
        break;
    }
  };

  const switchAccount = (user) => {
    if (user_id == user.permit_user) {
      if (!!showUserManager) {
        setSwapStatus(true);
        return;
      }
      toast.createToast({
        type: "warning",
        msg:
          (initDone &&
            intl.get(
              "home.publicMsg.role.edit.parentEditMes.accountCurrent"
            )) ||
          "",
      });
      return;
    }

    const callback = () => {
      setSwap(true);
      dispatch({
        type: "SHOW_USER_MANAGER",
        payload: false,
      });
      dispatch(
        swapUser({
          user_id: user.permit_user,
          callback: (ret) => {
            if (!!ret && !ret.error) {
              // setSwap(false);
              setSwapStatus(ret);
              dispatch({
                type: "INIT",
                payload: ret,
              });
            } else {
              setSwap(false);
              toast.createToast({
                type: "error",
                msg:
                  (initDone &&
                    intl.get(
                      "home.publicMsg.role.edit.parentEditMes.accountNull"
                    )) ||
                  "",
              });
              //Show Message
            }
          },
        })
      );
    };
    if (pay.length > 0 && typeof closeConfirm === "function") {
      closeConfirm(callback.bind(this));
      return;
    }

    callback();
  };

  const clickHandle = (e, user) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (loginCode === 210 && redirectPath.includes(key)) {
      switchAccount(user);
      dispatch({ type: "SAVE_LOGIN_CODE", payload: { loginCode: null } });
      return;
    }

    if (!showFamily || isSwap) {
      return;
    }

    switchAccount(user);
  };

  return (
    <div
      className={`${styles.box} ${(emptyFamily && styles.emptyFamily) || ""}`}
    >
      {verification210Load ? (
        <Spin
          indicator={<Icon type="loading" spin />}
          className={styles.loading}
        />
      ) : (
        <>
          <div style={{ marginBottom: 35 }}>
            {emptyFamily && (
              <>
                <h2 className={styles.describe}>{lang("step_three.title")}</h2>
                <h2 className={styles.describe2}>{lang("step_three.tip")}</h2>
              </>
            )}
            {showFamily === true && (
              <h1 className={styles.watching}>
                {lang("step_three.who_is_watching")}
              </h1>
            )}
          </div>
          <Row
            type="flex"
            gutter={12}
            className={`${styles.childRow} ${
              (emptyFamily && styles.emptyFamily) || ""
            }`}
          >
            {!!isSwap && (
              <Col span={24} className={styles.loadingFrame}>
                {!!isSwap && (
                  <Spin
                    style={{ textAlign: "center", margin: "20 auto" }}
                    indicator={
                      <Icon type="loading" style={{ fontSize: 90 }} spin />
                    }
                  />
                )}
                {!!swapStatus && (
                  <h2 className={styles.describe}>
                    {initDone &&
                      intl.get("home.publicMsg.role.edit.parentEditMes.Coming")}
                  </h2>
                )}
              </Col>
            )}
            {!isSwap && (
              <React.Fragment>
                {family &&
                  family
                    .filter(
                      (user) =>
                        (showFamily === true || user.relation === "CHILD") &&
                        /**
                         * @todo [should check config and show specific user type for each application]
                         */
                        (key === "efunfun" ? user.relation !== "CHILD" : true)
                    )
                    .map((user) => {
                      const resource_permit =
                        user.role_group_list.indexOf("USER_RESOURCE") > 0;
                      const isParent = user.relation !== "CHILD";
                      const isSchool = !!user.permit_kid;
                      return (
                        <Col
                          xs={12}
                          sm={8}
                          md={8}
                          lg={6}
                          xl={4.8}
                          style={{
                            marginBottom: 43,
                            textAlign: "center",
                          }}
                          key={`user-${user.permit_user}`}
                        >
                          <div style={{ position: "relative" }}>
                            <Avatar
                              size={
                                (isXL && 200) ||
                                (isLG && 180) ||
                                (isMD && 150) ||
                                100
                              }
                              icon="user"
                              src={user.permit_img}
                              className={`${styles.avatar} ${
                                user_id === user.permit_user && styles.current
                              } ${showFamily === true && styles.clickable}`}
                              onClick={(e) => {
                                clickHandle(e, user);
                                // e.stopPropagation();
                                // e.nativeEvent.stopImmediatePropagation();
                                // if (
                                //   !showFamily ||
                                //   isSwap
                                //   // ||
                                //   // loginCode !== 210 ||
                                //   // !redirectPath.includes(key)
                                // ) {
                                //   return;
                                // }
                                // if (user_id == user.permit_user) {
                                //   if (!!showUserManager) {
                                //     setSwapStatus(true);
                                //     return;
                                //   }
                                //   toast.createToast({
                                //     type: "warning",
                                //     msg:
                                //       (initDone &&
                                //         intl.get(
                                //           "home.publicMsg.role.edit.parentEditMes.accountCurrent"
                                //         )) ||
                                //       "",
                                //   });
                                //   return;
                                // }
                                // const callback = () => {
                                //   setSwap(true);
                                //   dispatch({
                                //     type: "SHOW_USER_MANAGER",
                                //     payload: false,
                                //   });
                                //   dispatch(
                                //     swapUser({
                                //       user_id: user.permit_user,
                                //       callback: (ret) => {
                                //         if (!!ret && !ret.error) {
                                //           // setSwap(false);
                                //           setSwapStatus(ret);
                                //           dispatch({
                                //             type: "INIT",
                                //             payload: ret,
                                //           });
                                //         } else {
                                //           setSwap(false);
                                //           toast.createToast({
                                //             type: "error",
                                //             msg:
                                //               (initDone &&
                                //                 intl.get(
                                //                   "home.publicMsg.role.edit.parentEditMes.accountNull"
                                //                 )) ||
                                //               "",
                                //           });
                                //           //Show Message
                                //         }
                                //       },
                                //     })
                                //   );
                                // };
                                // if (
                                //   pay.length > 0 &&
                                //   typeof closeConfirm === "function"
                                // ) {
                                //   closeConfirm(callback.bind(this));
                                //   return;
                                // }
                                // callback();
                              }}
                            />
                            <p className={styles.name}>
                              <Icon type="user" style={{ marginRight: 8 }} />
                              <span>{user.permit_user_name}</span>
                            </p>
                            <p
                              style={{
                                color: AvatarBorder(user, resource_permit),
                              }}
                            >
                              {!isParent &&
                                !!resource_permit &&
                                !!user.permit_grade_type && (
                                  <span
                                    style={{
                                      backgroundColor: AvatarBorder(
                                        user,
                                        resource_permit
                                      ),
                                      color: "#fff",
                                      padding: "0 4px",
                                      margin: "0 4px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    {user.permit_grade_type}
                                  </span>
                                )}
                              {isParent
                                ? intl.get(
                                    "home.publicMsg.role.edit.kid.role.parent"
                                  )
                                : isSchool
                                ? account === "cn"
                                  ? lang("step_three.btn_school")
                                  : intl.get(
                                      "home.publicMsg.role.edit.kid.role.school"
                                    )
                                : !!resource_permit ||
                                  !!(pay || []).find(
                                    (subItem) =>
                                      user.permit_user === subItem.id.toString()
                                  )
                                ? intl.get(
                                    "home.publicMsg.role.edit.kid.role.isBinding"
                                  )
                                : intl.get(
                                    "home.publicMsg.role.edit.kid.role.unbound"
                                  )}
                            </p>
                          </div>
                          {!["cn", "tw"].includes(currentLocation) && (
                            <p className={styles.edit}>
                              {!isParent && !resource_permit && (
                                <Button
                                  className={
                                    pay.find(
                                      (subItem) =>
                                        user.permit_user ===
                                        subItem.id.toString()
                                    )
                                      ? styles.basic_vip
                                      : styles.join_evi
                                  }
                                  disabled={!!resource_permit || isParent}
                                  style={{ textDecoration: "underline" }}
                                  type="primary"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    dispatch(setChildren(user.permit_user));
                                    subPrev();
                                  }}
                                >
                                  {isParent
                                    ? lang("step_three.btn_parent")
                                    : !!resource_permit ||
                                      pay.find(
                                        (subItem) =>
                                          user.permit_user ===
                                          subItem.id.toString()
                                      )
                                    ? lang("step_three.btn_vip")
                                    : lang("step_three.btn_join")}
                                </Button>
                              )}
                            </p>
                          )}
                        </Col>
                      );
                    })}
                {(family || []).length < 5 && (
                  <Col
                    xs={12}
                    sm={8}
                    md={8}
                    lg={6}
                    xl={4.8}
                    key={`user-add-new-user`}
                  >
                    <Row type="flex" align="middle" justify={"center"}>
                      <Col
                        className={styles.add}
                        onClick={() => {
                          continueAdd();
                        }}
                      >
                        <Icon
                          type="plus-circle"
                          theme="filled"
                          className={`${styles.addIcon} ${
                            (emptyFamily && styles.emptyFamily) || ""
                          }`}
                        />
                        <p className={styles.name}>
                          <span>{lang("step_three.title")}</span>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                )}
              </React.Fragment>
            )}
          </Row>
          {/* swap -- no display, only bind family */}
          {!isSwap && (
            <div className={styles.buttonGroup}>
              {pay && pay.length > 0 && (
                <Button
                  block
                  className={styles.next}
                  type="primary"
                  onClick={subNext}
                >
                  {lang("btn_checkout")}
                </Button>
              )}
              {pay &&
                pay.length === 0 &&
                (family || []).filter((user) => user.relation === "CHILD")
                  .length > 0 &&
                showFamily !== true && (
                  <Button
                    block
                    className={styles.next}
                    type="primary"
                    onClick={subNext}
                  >
                    {lang("btn_done")}
                  </Button>
                )}
              {(pay && pay.length > 0) ||
                ((family || []).filter((user) => user.relation === "CHILD")
                  .length === 0 && (
                  <span href="#" className={styles.skip} onClick={skipPay}>
                    {lang("step_three.btn_skip")}
                  </span>
                ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BindFamily;
