const defaultState = {
  skipRegister: false,
  status: null,
  skipBindChildren: false,
  verifyCode: false,
  showUser: false,
  language: "zh",
  location: "hk",
  useLanguge: [],
};

export const new_global = (state = defaultState, { type, payload }) => {
  switch (type) {
    case "SKIP_REGISTER_PAY":
      return { ...state, skipRegister: payload };
    case "SET_REGISTER_STATUS":
      return { ...state, status: payload };
    case "SKIP_BIND_CHILDREN":
      return { ...state, skipBindChildren: payload };
    case "SET_VERIFY_CODE":
      return { ...state, verifyCode: payload };
    case "SET_SHOW_USER":
      return { ...state, showUser: payload };
    case "SHOW_USER_MANAGER":
      return { ...state, showUserManager: payload };
    case "UPDATE_LANGUAGE_LOCATION":
      const { language, location, useLanguge } = payload;
      return { ...state, language, location, useLanguge };
    default:
      return state;
  }
};
