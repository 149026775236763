import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import intl from "react-intl-universal";

import { Button, Row, Col, Icon, Select } from "antd";

import user_API from "components/services/userService";

// import Language from "components/language";
import { SUPPORT_LOCALES } from "config/locale.json";

import style from "assets/css/layout.module.scss";

const { Option } = Select;

const locations = [...Object.keys(SUPPORT_LOCALES)];

export default function NotLogDrawer(params) {
  const { uID, isRegister, type, visibleDrawer, UserOpen, visibleSwapUser } =
    params;

  const [languageList, setLanguageList] = useState([]);

  const route = useSelector((state) => state.route);

  const LinkAdapter =
    !uID || user_API.getType() === "STAFF"
      ? Link
      : (props) => <React.Fragment>{props.children}</React.Fragment>;

  const AdapterBtn = () => (
    <LinkAdapter
      to={
        !uID || user_API.getType() === "STAFF"
          ? `/${route.currentLocation}/${route.currentLanguage.url}/${
              route.tag === "parent" ? "" : "parent"
            }`
          : "#"
      }
    >
      <div
        className={style.btnWidth}
        style={{
          cursor: "pointer",
          display: "inline-block",
          paddingTop: !visibleDrawer ? "8px" : "0px",
        }}
        onClick={() => {
          if (uID && user_API.getType() !== "STAFF") {
            visibleSwapUser();
          }
        }}
      >
        {(!uID || user_API.getType() === "STAFF"
          ? route.tag === "parent"
          : type === "PARENT") && (
          <div
            style={{
              border: "none",
              padding: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            {!visibleDrawer && (
              <img
                src={require(`assets/image/button/kid_icon.png`)}
                alt=""
                style={{ width: 26, height: 26, marginRight: 8 }}
              />
            )}
            <span style={{ fontSize: 16, color: "#000", fontWeight: "400" }}>
              {intl.get("home.publicMsg.header.child")}
            </span>
          </div>
        )}
        {(!uID || user_API.getType() === "STAFF"
          ? route.tag !== "parent"
          : type !== "PARENT") && (
          <div
            style={{
              border: "none",
              padding: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            {!visibleDrawer && (
              <img
                src={require(`assets/image/button/parent_icon.png`)}
                alt=""
                style={{ width: 26, height: 26, marginRight: 8 }}
              />
            )}
            <span style={{ fontSize: 16, color: "#000", fontWeight: "400" }}>
              {intl.get("home.publicMsg.header.parents")}
            </span>
          </div>
        )}
      </div>
    </LinkAdapter>
  );

  const { currentLocation, currentLanguage } = route;

  useEffect(() => {
    if (!currentLocation) return;

    setLanguageList(SUPPORT_LOCALES[currentLocation].lang);

    // 初始化 当前地区 可选语言
  }, [route]);

  /** 点击切换语言 */
  const handleClick = (value) => {
    if (currentLanguage.value !== value) {
      window.location = `/${route.currentLocation}/${value}${
        route.realUrl ? route.realUrl : ""
      }`;
    }
  };

  // 切换地区
  const changeLocal = (value) => {
    console.log(currentLocation, value);
    if (currentLocation !== value) {
      var goCountry = !!SUPPORT_LOCALES[value]
        ? SUPPORT_LOCALES[value]
        : SUPPORT_LOCALES["hk"];
      window.location = goCountry.url;
    }
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        {!uID || user_API.getType() === "STAFF" ? (
          <AdapterBtn />
        ) : (
          <div
            style={{
              border: "none",
              padding: 0,
              display: "flex",
              alignItems: "center",
            }}
            onClick={UserOpen}
          >
            <img
              src={require(`assets/image/button/parent_icon.png`)}
              alt=""
              style={{ width: 26, height: 26, marginRight: 16 }}
            />
            <span style={{ fontSize: 16, color: "#000", fontWeight: "400" }}>
              {intl.get("home.publicMsg.header.switchUser")}
            </span>
          </div>
        )}
      </div>
      {!uID && !isRegister && (
        <div
          style={{ fontSize: "16px", fontWeight: "400", marginBottom: "20px" }}
        >
          <Link to={`${route.locationUrl}register`} style={{ color: "#000" }}>
            {intl.get("home.publicMsg.header.register")}
          </Link>
        </div>
      )}
      {/* 關於我們 */}
      <div
        style={{ fontSize: "16px", fontWeight: "400", marginBottom: "20px" }}
      >
        <Link
          to={`/${route.currentLocation}/${route.currentLanguage.url}/about_us`}
          style={{ color: "#000" }}
        >
          {intl.get("home.publicMsg.footer.about")}
        </Link>
      </div>
      {/* 聯絡我們 */}
      <div
        style={{ fontSize: "16px", fontWeight: "400", marginBottom: "20px" }}
      >
        <Link
          to={`/${route.currentLocation}/${route.currentLanguage.url}/contact_us`}
          style={{ color: "#000" }}
        >
          {intl.get("home.publicMsg.footer.liaison")}
        </Link>
      </div>
      {/* 隱私條款 */}
      <div
        style={{ fontSize: "16px", fontWeight: "400", marginBottom: "20px" }}
      >
        <Link
          to={`/${route.currentLocation}/${route.currentLanguage.url}/privacy`}
          style={{ color: "#000" }}
        >
          {intl.get("home.publicMsg.footer.agreement")}
        </Link>
      </div>
      {/* 免責條款 */}
      <div
        style={{ fontSize: "16px", fontWeight: "400", marginBottom: "20px" }}
      >
        <Link
          to={`/${route.currentLocation}/${route.currentLanguage.url}/disclaimer`}
          style={{ color: "#000" }}
        >
          {intl.get("home.publicMsg.footer.Disclaimer")}
        </Link>
      </div>
      <div style={{ marginBottom: "20px" }}>
        {route.currentLocation != 'tw' && (<Button
          style={{
            backgroundColor: "#38AD52",
            color: "#fff",
            borderRadius: 20,
            height: 33,
            border: "none",
            fontSize: "14px",
            fontWeight: "700",
          }}
        >
          <Link to={`${route.locationUrl}web_to_app`}>
            {intl.get("home.publicMsg.web.use")}
          </Link>
        </Button>)}
      </div>
      {/* 多语言 */}
      <div style={{ marginBottom: "20px" }} className="phoneLanguage">
        {intl.get("home.publicMsg.header.languageAndRegion")}
        <br />
        <Select
          defaultValue={
            route.currentLanguage
              ? route.currentLanguage.url
              : SUPPORT_LOCALES[process.env.REACT_APP_USER_LOCATION].lang.url
          }
          style={{ width: 100, marginBottom: 8 }}
          onChange={handleClick}
        >
          {languageList.map((item) => (
            <Option key={item.url}>{item.name}</Option>
          ))}
        </Select>
        <br />
        {route.currentLocation != 'tw' && route.currentLocation != 'cn' && (<Select
          defaultValue={
            route.currentLocation || process.env.REACT_APP_USER_LOCATION
          }
          style={{ width: 100 }}
          onChange={changeLocal}
        >
          {locations.map((value) => {
            if (value !== "tw") {
              return (
                <Option key={value} value={value}>
                  {intl.get(
                    `home.publicMsg.header.languageList.locations.${value}`
                  )}
                </Option>
              );
            }
          })}
        </Select>)}
      </div>
    </div>
  );
}
