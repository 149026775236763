import React, { useState, useEffect } from "react";

import { Modal, Row, Col, Menu, Dropdown } from "antd";
import { useSelector } from "react-redux";
import main from "components/services/mainService";

import McAudio from "./McAudio";
import McVideo from "./McVideo";

import styles from "assets/css/resource/schoolResourceMcNew.module.scss";
import closeImg from 'assets/image/resource/mc/close.svg'

const common_lang = {
  imgLang: ["zh", "zh_cn", "en"],
  lang: ["zh", "cn", "english"],
}

// 地區判斷語言.
const location_language = [
  {
    location: "hk",
    ...common_lang
  },
  {
    location: "mo",
    ...common_lang
  },
  {
    location: "tw",
    imgLang: ["zh_tw", "en"],
    lang: ["zh", "english"],
  },
  { location: "cn", imgLang: ["cn", "en"], lang: ["cn", "english"] },
];

const SchoolResourceMc = ({ refId }) => {
  // 获取当前地区信息
  const { location } = useSelector((state) => state.new_global);

  // 当前问答数据
  const [data, setData] = useState({});

  // 当前语言
  const [lang, setLang] = useState("zh");

  // audio Video
  const [videoShow, setVideoShow] = useState(false);

  const [twLLocation, setTwLocation] = useState("zh");

  useEffect(() => {
    setLang(
      location_language.find((item) => item.location === location).lang[0]
    );
  }, [location]);

  useEffect(() => {
    if (location === 'tw') {
      const twLang = ["zh", "english"].indexOf(lang) === 0 ? "cn" : lang
      setTwLocation(twLang);
    }
  }, [lang, location]);

  useEffect(() => {
    if (refId) {
      main.getFullInfo(refId).then((ret) => {
        setData(ret);
      });
    }
  }, [refId]);

  const imgLangList = location_language.find(
    (item) => item.location === location
  );
  const menu = () => {
    return (
      <Menu>
        {imgLangList.lang.map(
          (item, index) =>
            lang !== item && (
              <Menu.Item onClick={() => setLang(item)} key={item + index}>
                <img
                  src={require(`assets/image/resource/mc/${imgLangList.imgLang[index]}.png`)}
                  alt=""
                  width="70"
                  height="70"
                />
              </Menu.Item>
            )
        )}
      </Menu>
    );
  };

  const question = data.item ? data.item[0] : [];

  // 多语言 题目名称
  const renderName = (key) => {
    if (data.lang) {
      const result = data.lang.find((item) => item.lang === lang);
      // hk mo tw 簡體中文時,要展示繁體字幕
      if (["hk", "mo", "tw"].includes(location) && lang === "cn") {
        return data.lang.find((item) => item.lang === "zh")[key];
      }
      return result[key];
    }
    return data[key];
  };

  return (
    <Modal
      visible={true}
      onCancel={() => window.close()}
      footer={null}
      width={1160}
      className={styles.root}
      destroyOnClose
      centered
      keyboard={false}
      maskClosable={false}
      closeIcon={<img src={closeImg} alt="closeImg"></img>}
      maskStyle={{
        backgroundSize: "cover",
        backgroundPosition: "center,center",
        backgroundImage: `url(${require(`assets/image/resource/mc/bg.png`)})`
      }}
    >
      <div className={styles.bg_content}>
        <img src={require("assets/image/resource/mc/rainbow.png")} alt="rainbow" />
        <img src={require("assets/image/resource/mc/son.png")} alt="son" />
      </div>
      <div className={styles.lang_button}>
        <Dropdown overlay={menu} placement="bottomCenter" arrow>
          <img
            src={require(`assets/image/resource/mc/${imgLangList.imgLang[
              imgLangList.lang.findIndex((item) => lang === item)
            ]
              }.png`)}
            alt=""
            width="70"
            height="70"
          />
        </Dropdown>
      </div>

      <Row
        type="flex"
        // justify="space-around"
        align="middle"
        // style={{ marginBottom: 8 }}
        className={styles.header}
      >
        <Col xl={3} >
          <img
            src={require("assets/image/resource/mc/idea.png")}
            alt="idea.png"
            className={styles.idea_img}
          />
        </Col>
        <Col xl={21} >
          <h3 className={styles.question_title}>{renderName("name")}</h3>
        </Col>
      </Row>

      {question.item_type === "mc_question" && (
        <McAudio
          data={data}
          lang={location === 'tw' ? twLLocation : lang}
          videoShow={videoShow}
          setVideoShow={setVideoShow}
        />
      )}

      {question.item_type === "video" && <McVideo data={data} lang={location === 'tw' ? twLLocation : lang} />}
    </Modal>
  );
};

export default SchoolResourceMc;
