import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import authService from "components/services/authService";

export default function LogoutPage() {
  const { currentLocation, currentLanguage } = useSelector(state => state.route);

  useEffect(() => {
    authService.logout().then(() => {
      window.location.href = `/${currentLocation}/${currentLanguage.url}`;
    });
  }, [])

  return (
    <div 
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <h1>正在登出...</h1>
    </div>
  )
}
