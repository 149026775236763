import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, message, Icon, Select, Input } from 'antd';

import user_API from "components/services/userService";

const { Option } = Select;

const Name = ({ value, defaultValue, loading, saveEdit, style }) => {
  const [ visible, setVisible ] = useState(false);

  useEffect(() => {
    if(!loading) setVisible(false);
  }, [loading])

  return visible ? (
    <Input 
      defaultValue={defaultValue}
      autoFocus
      onBlur={() => setVisible(false)}
      onPressEnter={(e) => saveEdit(e.target.value)}
      suffix={<Icon type={loading ? 'loading' : 'enter'} />}
      disabled={loading}
    />
  ) : (
    <div style={style}>
      <Typography.Text
        style={{ fontSize: 'inherit', color: 'inherit' }}
      >
        {value}
      </Typography.Text>
      <Icon 
        type="edit" 
        style={{ cursor: 'pointer', marginLeft: 8 }}
        onClick={() => setVisible(true)}
      />
    </div>
  )
}

const Sex = ({ value, defaultValue, loading, saveEdit, style }) => {
  const [ visible, setVisible ] = useState(false);

  useEffect(() => {
    if(!loading) setVisible(false);
  }, [loading])

  return visible ? (
    <Select 
      defaultValue={defaultValue}
      style={{ width: 100 }}
      autoFocus
      onBlur={() => setVisible(false)}
      onChange={(value) => saveEdit(false, value)}
      loading={loading}
      disabled={loading}
    >
      <Option value="M">男</Option>
      <Option value="F">女</Option>
    </Select>
  ) : (
    <div style={style}>
      <Typography.Text
        style={{ fontSize: 'inherit', color: 'inherit' }}
      >
        {value}
      </Typography.Text>
      <Icon 
        type="edit" 
        style={{ cursor: 'pointer', marginLeft: 8 }}
        onClick={() => setVisible(true)}
      />
    </div>
  )
}

const UserEdit = (props) => {
  const dispatch = useDispatch();

  const { value, style, type } = props;

  const [ loading, setLoading ] = useState(false);

  const { user, translations } = useSelector(state => state);

  const _user = (user.family || []).filter(_fM => {
    return _fM.permit_user === user.id;
  });

  const _sex = _user.length > 0 ? _user[0].permit_user_sex : "M";

  const saveEdit = async (name, sex) => {
    try {
      setLoading(true);
      await user_API.updateUserMsg({
        first_name: name || user.full_name,
        sex: sex || _sex,
        relation: _user.length > 0 ? _user[0].relation : "FATHER",
        language: user.language
      });
      await user_API.me(true).then(_user => {
        dispatch({ type: "INIT", payload: _user })
      });
    } catch (ex) {
      message.error('更改失败')
    } finally {
      setLoading(false);
    }
  };

  const defaultProps = {
    value,
    style,
    loading,
    saveEdit
  }

  const view = {
    name: <Name {...defaultProps} defaultValue={user.full_name} />,
    sex: <Sex {...defaultProps} defaultValue={_sex} />
  }

  return view[type] || <></>;
}

export default UserEdit;