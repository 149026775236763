import React from "react";
import { Button, Modal, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import intl from "react-intl-universal";

import multipleText from "../utils/resultCode";
import * as redemptionAction from "store/action/redemption";

import styles from "assets/css/redemption/index.module.scss";

const RedeemModal = (props) => {
  const dispatch = useDispatch();
  const { visible, setVisible } = props;

  const { first_name } = useSelector((state) => state.user);

  const { code } = useParams();

  const redeemCodehandle = () => {
    dispatch(
      redemptionAction.fetchRedeem({
        code,
        callback: () => {
          message.success(intl.get("redemption.message_ssucess"));
          setVisible(false);
        },
        errorCallback: (err) => {
          message.error(intl.get(multipleText.get(err.result || "default")));
        },
      })
    );
  };

  return (
    <Modal
      title={null}
      visible={visible}
      footer={null}
      closeIcon={false}
      onCancel={() => setVisible(false)}
      className={styles.modal_body}
    >
      <h2>{intl.get("redemption.modal_title")}</h2>
      <h2>{intl.get("redemption.modal_account")}</h2>
      <p>{first_name}</p>
      <Button type="primary" onClick={() => redeemCodehandle()} block>
        {intl.get("redemption.modal_redeem")}
      </Button>
      <Button type="primary" onClick={() => setVisible(false)} block>
        {intl.get("redemption.modal_cancel")}
      </Button>
    </Modal>
  );
};

export default RedeemModal;
