import React, { useState } from "react";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import { Form, Input, Select, Button, Switch, Checkbox, message } from "antd";
import { useDispatch, useSelector } from "react-redux";

// import OperationButton from "../common/operationButton";

import { fetchRegister } from "store/action/auth";
import { setVerifyCodeToken } from "store/action/global";

import { SUPPORT_LOCALES } from "config/locale.json";
import { PASSWORD } from "config/app.json";
import { validateRegisterAccount } from "../../utils";

import auth_API from "components/services/authService";
import user_API from "components/services/userService";

import toast from "components/utils/toast";

import { putChildren } from "~/store/action/family";

import styles from "../../sass/registerForm.module.scss";

const { Option } = Select;

const Login = (props) => {
  const { next, subPrev, location, defaultAccount, defaultEmail } = props;

  const dispatch = useDispatch();

  const {
    auth: { kid_token },
    // user: { id, family=[], language }
  } = useSelector((state) => state);

  const {
    translations: { initDone },
    route: { locationUrl },
  } = useSelector((state) => state);

  const lang = (value) => initDone && intl.get(`register.${value}`);

  const {
    getFieldDecorator,
    validateFields,
    getFieldsError,
    getFieldsValue,
    getFieldValue,
  } = props.form;

  const [registerType, setRegisterType] = useState("email");
  const [submitLoading, setSubmitLoading] = useState(false);

  // 禁用状态 通过 error value 共同判断
  const disabled =
    Object.values(getFieldsError()).filter((item) => item).length !== 0 ||
    Object.values(getFieldsValue()).filter((item) => item).length === 0 ||
    Object.keys(getFieldsValue())
      .filter((key) => !(["subscription"].indexOf(key) > -1))
      .map((key) => getFieldValue(key))
      .reduce((acc, cur) => {
        acc = !!acc ? acc : !cur;
        return acc;
      }, false);

  const validateAccount = (rule, value, callback) => {
    const { type, result } = validateRegisterAccount(location, value);
    // console.log(rule);
    setRegisterType(type);
    if (!result) {
      callback(" ");
    }
    callback();
  };

  const onSubmit = (e) => {
    // next();
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        const { username, password } = values;
        try {
          setSubmitLoading(true);
          await auth_API.login(username, password);
          return toast.createToast({
            msg: "登入成功",
            type: "success",
            onClose: async () => {
              const ret = await user_API.me(true);
              const { id, family = [], language } = ret;

              dispatch({ type: "SET_REGISTER_STATUS", payload: "VERIFY" });
              dispatch(
                putChildren({
                  language,
                  family_id: id ? family[0].family_id : undefined,
                  kid_token,
                  callback: async (error) => {
                    setSubmitLoading(false);
                    if (error) {
                      message.error(
                        initDone && intl.get(`general.msg.add_error`)
                      );
                      return;
                    }
                    next(2);
                    await user_API
                      .me(true)
                      .then((payload) => dispatch({ type: "INIT", payload }));
                  },
                })
              );
            },
          });
        } catch (error) {
          setSubmitLoading(false);
          console.log(error);
        }
      }
    });
  };

  return (
    <div className={styles.registerForm}>
      <h2 className={styles.describe}>已注冊帳戶登入</h2>
      <Form onSubmit={onSubmit}>
        <Form.Item hasFeedback>
          {getFieldDecorator("username", {
            initialValue: defaultAccount || defaultEmail,
            rules: [
              {
                required: true,
                message: "請輸入帳戶",
              },
              // {
              //   validator: validateAccount,
              // },
            ],
          })(<Input placeholder={"帳戶"} size="large" />)}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message:
                  (initDone &&
                    intl.get(
                      "loading.publicMsg.register.form.invalidMsg.password"
                    )) ||
                  "",
              },
              {
                pattern: PASSWORD,
                message:
                  (initDone &&
                    intl.get(
                      "loading.publicMsg.register.form.verifyMsg.pwdWarnMsg"
                    )) ||
                  "",
              },
            ],
          })(<Input.Password placeholder={"密碼"} size="large" />)}
        </Form.Item>

        <Form.Item style={{ marginBottom: 16 }}>
          <Button
            block
            // className={disabled ? "" : styles.next}
            className={styles.next}
            htmlType="submit"
            // disabled={disabled}
            type="primary"
            loading={submitLoading}
          >
            {/* {lang("btn_continue")} */}
            登入
          </Button>
        </Form.Item>

        <Button block className={styles.back} onClick={subPrev}>
          {lang("btn_back")}
        </Button>
      </Form>
    </div>
  );
};

export default Form.create()(Login);
