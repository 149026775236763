/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { Drawer, Row, Col, Spin, Button } from "antd";

import intl from "react-intl-universal";

import main from "components/services/mainService";
import user_api from "components/services/userService";
import EVILoader from "components/spinner/Loader";

import Video from "components/common/Video";
import Multi from "../drWong/Multi";
// import Vocab from "../drWong/Vocab";
// import Document from "../drWong/Document";
import SelectLang from "../drWong/SelectLang";

import styles from "assets/css/DrWongModal.module.scss";

import background from "assets/image/cantoneseOpera/EVIGARTENICON.jpg";
import VOCABicon from "assets/image/cantoneseOpera/VOCABicon.png";
import GameIcon from "assets/image/cantoneseOpera/games.png";
// import Parent from "assets/image/cantoneseOpera/parent.jpg";
import GameModal from "./gameModal";

import logo from "../../../assets/image/logo.png";

import Titles from "./title";

const support_lang = [
  { lang: "zh", vo_lang: "cantonese" },
  { lang: "english", vo_lang: "english" },
];

const KeyGroups = [
  {
    play_type: "PN",
    name: "obverse",
    color: "#FFA24C",
    bgcolor: "#FFA24C",
  },
  {
    play_type: "ON",
    name: "reversed",
    color: "#63B432",
    bgcolor: "#63B432",
  },
  {
    play_type: "PS",
    name: "obverseSlowMotion",
    color: "#504ED3",
    bgcolor: "#504ED3",
  },
  {
    play_type: "OS",
    name: "reversedSlowMotion",
    color: "#CA3A7F",
    bgcolor: "#CA3A7F",
  },
  {
    play_type: "PURE",
    name: "PureMusicEdition",
    color: "#FF7777",
    bgcolor: "#FF7777",
  },
];

const append = [
  {
    play_type: "PA",
    name: "PositiveDirection",
    color: "#FFA24C",
    bgcolor: "#FFA24C",
  },
  {
    play_type: "OA",
    name: "OppositeDirection",
    color: "#CA3A7F",
    bgcolor: "#CA3A7F",
  },
  {
    play_type: "AN",
    name: "PositiveNegative",
    color: "#FFA24C",
    bgcolor: "#FFA24C",
  },
];

const CantoneseOpera = (props) => {
  const { section } = useParams();

  const {
    location: { pathname },
    push,
  } = useHistory();

  const translate = (value, params) =>
    intl.get(`cantoneseOpera.${value}`, params);

  const { currentLanguage } = useSelector((state) => state.route);

  const { id, visible, onCancel } = props;
  const onStaff = user_api.getType() === "STAFF";

  // 老师使用storybook， 学生使用VOCAB
  const IsWhich = onStaff ? "storybook" : 'vocab';

  const [data, setData] = useState({ item: [], lang: [] }); // api 数据
  // item_type
  const [itemType, steItemType] = useState('video');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(IsWhich); // 切换视图用的 type
  const [vocabVisible, setVocabVisible] = useState(false); // 词汇弹框开关
  const [language, setLanguage] = useState({}); // 页面语言

  // game
  const [gameData, setGameData] = useState([]);
  const [gameControl, setGameControl] = useState(false);

  // 什么类型 play_type
  const [versions, steVersions] = useState("PN");
  // 隐藏按键
  const [playt, setPlayt] = useState([]);
  // 是否顯示側邊欄
  const [imgVisibility, setImgVisibility] = useState([]);
  // 接收title 传来的值，做于打开弹窗时暂停播放视频
  const [ktvStop, setKtvStop] = useState(false);
  const [recommendStop, setRecommendStop] = useState(false);

  const getData = useCallback(
    (type) => data.item.filter((_) => _.item_type === type),
    [data]
  );

  const getSection = useCallback(
    (section) =>
      data.item.find((_) => {
        return ['STORYBOOK', 'VOCAB'].includes(section) ?
          (_.section === section)
          :
          (_.section === section && _.play_type === versions);
      }) || {},
    [data, versions]
  );
  // 获取视频上的资源
  const getVideo = useCallback(
    (section) => {
      return (
        (getSection(section).lang || []).find((_) => {
          return _.lang === language.lang;
        }) || {}
      );
    },
    [getSection, language]
  );
  // 获取 “考考你” 上的公仔图片
  const getIcon = useMemo(
    () => data.item.filter((_) => _.section === "ICON"),
    [data]
  );
  const init = () => {
    setType("main");
    setLoading(false);
    setVocabVisible(false);
  };

  const onSwitchover = (value) => {
    setType(value);
    const vocabType = data.item.filter(
      (item) => item.section === value.toUpperCase() && item
    );
    // console.log(vocabType);
    if (vocabType.length !== 0) {
      // console.log(vocabType[0].play_type);
      // 默认数组第一个
      steVersions(vocabType[0].play_type);
    }
  };

  const gameClose = () => {
    setGameControl(false);
  };

  useEffect(() => {
    if (!!section && section !== "answer" && section !== "guide") {
      push("/404");
    }
  }, [section]);

  useEffect(() => {
    setLanguage(support_lang.find((_) => _.lang === currentLanguage.value));
  }, [currentLanguage, support_lang]);

  useEffect(() => {
    if (id && visible) {
      setLoading(true);
      document.body.style.overflow = "hidden";
      main
        .getFullInfo(id)
        .then((ret) => {
          console.log(ret);
          setData(ret);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      init();
    }
  }, [id, visible]);

  useEffect(() => {
    if (data.item.length !== 0) {

      const Totype = type.toUpperCase();
      const playType = data.item.filter((it) => {
        return it.section === Totype && it;
      });
      const Images = data.item.map((item) => item.section);

      // 'GAME'
      const Gameitem = data.item.filter(
        (item) => item.section === "GAME" && item
      );

      if (Gameitem.length !== 0) {
        setGameData(Gameitem);
      }
      if (!!Images) {
        setImgVisibility(Images);
      }
      if (playType.length !== 0) {
        // console.log(playType);
        if (playType[0].play_type) steVersions(playType[0].play_type);
        if (playType[0].item_type) steItemType(playType[0].item_type);
        if (playType[0].section) setType(playType[0].section.toLowerCase());
        const palys = playType.map((item) => item.play_type);
        setPlayt(palys);
      } else {
        steVersions(data.item[0].play_type);
        steItemType(data.item[0].item_type);
        setType(data.item[0].section.toLowerCase());
      }
    }
  }, [data, type]);

  let view = {
    multi: (
      <Multi
        data={getData("multi")}
        language={language}
        icon={getIcon}
        isActive={type === "multi" && !vocabVisible && !section && !gameControl && !ktvStop && !recommendStop} // 用于判断音频是否继续播放
      />
    ),
    ktv: (
      <Video
        videosrc={getVideo("KTV").streaming_url || getVideo("KTV").file}
        playing={!vocabVisible && !section && !gameControl && !ktvStop && !recommendStop}
        light={false}
      />
    ),
    main: (
      <Video
        videosrc={getVideo("MAIN").streaming_url || getVideo("MAIN").file}
        playing={!vocabVisible && !section && !gameControl && !ktvStop && !recommendStop}
        light={false}
      />
    ),
    // EXTEND
    extend: (
      <Video
        videosrc={getVideo("EXTEND").streaming_url || getVideo("EXTEND").file}
        playing={!vocabVisible && !section && !gameControl && !ktvStop && !recommendStop}
        light={false}
      />
    ),
    // RECOMMEND
    recommend: (
      <Video
        videosrc={
          getVideo("RECOMMEND").streaming_url || getVideo("RECOMMEND").file
        }
        playing={!vocabVisible && !section && !gameControl}
        light={false}
      />
    ),
    storybook: (
      // item_type: "game" 时 是文档类型
      itemType === 'game' ?
        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden", }}>
          <iframe
            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", border: "none", }}
            src={getVideo("STORYBOOK").file}
            allowFullScreen
          />
        </div>
        :
        <Video
          videosrc={
            getVideo("STORYBOOK").streaming_url || getVideo("STORYBOOK").file
          }
          playing={!vocabVisible && !section && !gameControl && !ktvStop && !recommendStop}
          light={false}
        />
    ),
    vocab: (
      itemType === 'game' ?
        <div
          style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden", }}>
          <iframe
            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", border: "none", }}
            src={getVideo("VOCAB").file}
            allowFullScreen
          />
        </div>
        :
        <Video
          videosrc={
            getVideo("VOCAB").streaming_url || getVideo("VOCAB").file
          }
          playing={!vocabVisible && !section && !gameControl && !ktvStop && !recommendStop}
          light={false}
        />
    ),
  };

  return (
    <Drawer
      visible={visible}
      onClose={() => window.close()}
      placement="bottom"
      closable
      height="100%"
      className={styles.drWongRoot}
      destroyOnClose
    >
      {/* 背景图片 */}
      <div
        style={{
          backgroundImage: `url(${background})`,
        }}
        className={styles.background}
      />
      {loading ? (
        // <Spin size="large" className={styles.loading} />
        <div style={{ minHeight: 500, display: 'flex', alignItems: 'center' }}>
          <EVILoader />
        </div>
      ) : (
        <Row
          type="flex"
          justify="center"
          className={styles.drwongInner}
          style={{ paddingTop: "0px" }}
        >
          <Col xxl={20} xl={22} lg={24} xs={24}>
            {/* header */}
            <Row
              type="flex"
              align="middle"
              className={styles.header}
              gutter={[10, 20]}
            >
              <Col span={3}>
                <img
                  src={require(`assets/image/cantoneseOpera/iconv3.png`)}
                  alt="title"
                />
              </Col>
              <Col span={21}>
                <h1 className={styles.title}>
                  {(data.lang.find((_) => _.lang === language.lang) || {}).name}
                </h1>
                <Titles data={data} setKtvStop={setKtvStop} setRecommendStop={setRecommendStop} />
              </Col>
              {/* 语言切换 */}
              <Col span={0}>
                <SelectLang
                  list={support_lang}
                  current={language}
                  onChange={(ret) => setLanguage(ret)}
                />
              </Col>
            </Row>
            {/* main */}
            <Row type="flex" gutter={[20, 20]} className={styles.main}>
              <Col lg={18} xs={24}>
                <div
                  style={{
                    padding: "8px 8px 15px 8px",
                    background: "#FFFFFF",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    height: "auto",
                  }}
                >
                  <Row>
                    <Col lg={24}>
                      <div>{view[type] || ""}</div>
                    </Col>
                    {/* 当只有一个视频时隐藏按键栏 || 不是老师身份隐藏 */}
                    <Col
                      lg={24}
                      style={{
                        display: playt.length === 1 || !onStaff ? "none" : "block",
                      }}
                    >
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {KeyGroups.map((item) => {
                          return (
                            <Button
                              key={item.play_type}
                              onClick={() => steVersions(item.play_type)}
                              icon="caret-right"
                              style={{
                                background:
                                  versions === item.play_type
                                    ? item.bgcolor
                                    : "#fff",
                                color:
                                  versions === item.play_type
                                    ? "#FFF"
                                    : item.color,
                                display: playt.includes(item.play_type)
                                  ? "initial"
                                  : "none",
                                margin: "15px 10px 0px",
                              }}
                            >
                              {translate(item.name)}
                            </Button>
                          );
                        })}
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {append.map((item) => {
                          return (
                            <Button
                              key={item.play_type}
                              onClick={() => steVersions(item.play_type)}
                              icon="caret-right"
                              style={{
                                background:
                                  versions === item.play_type
                                    ? item.bgcolor
                                    : "#fff",
                                color:
                                  versions === item.play_type
                                    ? "#FFF"
                                    : item.color,
                                display: playt.includes(item.play_type)
                                  ? "initial"
                                  : "none",
                                margin: "15px 10px 0px",
                              }}
                            >
                              {translate(item.name)}
                            </Button>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} xs={24} className={styles.sideCopy}>
                <Row type="flex">
                  {data.item.length !== 0 &&
                    (data.images || [])
                      .filter((item) => item.type === "VOCAB")
                      .map((item) => (
                        <Col
                          lg={24}
                          xs={8}
                          key={`selection-type-${item.type}`}
                          onClick={() => onSwitchover(user_api.getType() === "STAFF" ? "storybook" : 'VOCAB')}
                        >
                          <div
                            style={{
                              margin: "8px",
                              display: imgVisibility.includes(item.type)
                                ? "visible"
                                : "none",
                            }}
                          >
                            <img src={item.file} alt="item" />
                          </div>
                        </Col>
                      ))}
                  {data.item.length !== 0 &&
                    (data.images || [])
                      .filter((item) => item.type !== "VOCAB")
                      .map((item) => {
                        return (
                          <Col
                            lg={24}
                            xs={8}
                            key={`selection-type-${item.type}`}
                            onClick={() =>
                              onSwitchover(item.type.toLowerCase())
                            }
                          >
                            <div
                              style={{
                                margin: "8px",
                                display: imgVisibility.includes(item.type)
                                  ? "visible"
                                  : "none",
                              }}
                            >
                              <img src={item.file} alt="item" />
                            </div>
                          </Col>
                        );
                      })}
                  {gameData.length !== 0 && (
                    <Col
                      lg={24}
                      xs={8}
                      onClick={() => {
                        setGameControl(true);
                      }}
                    >
                      <div style={{ margin: "8px" }}>
                        <img src={GameIcon} alt="item" />
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <div
              style={{
                paddingTop: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={logo} alt="" style={{ width: "243px" }} />
              <span style={{ fontSize: "14px", marginTop: "19.38px" }}>
                <span
                  style={{
                    fontSize: "14px",
                    color: "#FF3737",
                    marginRight: "5px",
                  }}
                >
                  EVI
                </span>
                Services Limited © 2022
              </span>
            </div>
          </Col>
        </Row>
      )}

      <GameModal
        gameClose={gameClose}
        gameControl={gameControl}
        gameData={gameData}
      />
    </Drawer>
  );
};

export default CantoneseOpera;
