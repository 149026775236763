import React, { useState, useRef, useEffect, useContext } from "react";

import { Input, Form } from "antd";

import { EditableContext } from "../context";

import "components/studentReport/less/cell.less";

const Cell = (props) => {
  const {
    editable,
    dataIndex,
    title,
    record,
    children,
    handleUpdate,
    ...restProps
  } = props;

  const form = useContext(EditableContext);

  const [edit, setEdit] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    // 自動聚焦
    if (edit) {
      ref.current.focus();
    }
  }, [edit]);

  // 保存 对 comment 的修改
  const saveComment = () => {
    if (record.comment !== form.getFieldValue(dataIndex)) {
      handleUpdate({
        record,
        _comment: form.getFieldValue(dataIndex),
        callback: () => setEdit(!edit),
      });
      return;
    }
    setEdit(!edit);
  };

  const judgeEditable = (form) =>
    edit ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [{}],
          initialValue: record[dataIndex],
        })(<Input ref={ref} onBlur={saveComment} onPressEnter={saveComment} style={{ height: 43 }} />)}
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={() => setEdit(!edit)}>
        {children}
      </div>
    );

  return (
    <td {...restProps}>
      {editable && record.ur_id ? (
        <EditableContext.Consumer>
          {(form) => judgeEditable(form)}
        </EditableContext.Consumer>
      ) : (
        children
      )}
    </td>
  );
};

export default Cell;
