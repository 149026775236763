import { client } from "components/services/apiService";

export async function putSubscription({ user_services }) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/payment/subscription/calculate`,
    form: {
      user_services
    },
  });
}

export async function paySubscription({ user_services, email , psp_id }) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/payment/subscription/pay`,
    form: {
      user_services,
      psp_id,
      email,
    },
  });
}

export async function getInvoiceView(invoice_code, trans_code) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/payment/invoice/view`,
    form: {
      invoice_code,
      trans_code
    },
  });
}
