const defaultState = {
  total: {},
  paypalMsg: {},
};

export const new_payment = (state = defaultState, { type, payload }) => {
  switch (type) {
    case "SAVE_SUBSCRIPTION_TOTAL":
      return { ...state, total: payload };
    case "SAVE_PAYPAL":
      return { ...state, paypalMsg: payload };
    default:
      return state;
  }
};
