export default new Map([
  [0, "redemption.message_error_271"],
  [1, "redemption.message_error_271"],
  [106, "redemption.message_error_271"],
  [271, "redemption.message_error_271"],
  [280, "redemption.message_error_280"],
  [5001, "redemption.message_error_5001"],
  [5002, "redemption.message_error_5002"],
  [5003, "redemption.message_error_5003"],
  [5004, "redemption.message_error_5004"],
  ["default", "redemption.message_error"],
]);
