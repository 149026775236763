import { uploadAvatar } from "../../api/user_api";
import user_API from "components/services/userService";

// 注册 -- 上传子女头像 -- 预设头像部分
export function getAvatar({ callback, errorCallback, ...rest }) {
  return (dispatch) => {
    uploadAvatar(rest)
      .then((ret) => {
        dispatch({ type: "UPLOAD_SUCCESS", payload: ret });
        callback();
      })
      .catch(() => {
        errorCallback();
      });
  };
}

export function swapUser({ callback, ...rest }) {
  return (dispatch) => {
    user_API
      .swap(rest)
      .then((ret) => {
        dispatch({ type: "SAVE_ID", payload: ret });
        typeof callback === "function" && callback(ret);
      })
      .catch((error) => callback({ error }));
  };
}
