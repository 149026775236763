import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Row } from 'antd';

import styles from 'assets/css/PersonalCenter.module.scss';

import Coin from './Coin';
import History from './History';

import user_API from "components/services/userService";

const CoinPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    user_API.me(true).then(_user => {
      dispatch({ type: "INIT", payload: _user })
    });
  }, [])

  return (
    <Row type="flex" gutter={[10, 10]} className={`${styles.coinPage}`}>
      <History />
      <Coin />
    </Row>
  )
}

export default CoinPage;