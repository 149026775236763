import React from "react";

import { Button, Row, Col } from "antd";

import { useSelector } from "react-redux";
import intl from "react-intl-universal";

import DeleteFile from "components/studentReport/components/deleteFile";
import CustomUpload from "components/studentReport/components/upload";

const VoiceComment = (props) => {
  const { record, againGetData, courseId } = props;

  const { translations } = useSelector((state) => state);

  const lang = (value) => translations.initDone && intl.get(value);

  return record.comment_voice ? (
    <Row type="flex" align='middle' gutter={5} >
      <Col span={18} style={{ fontSize: 0 }}>
        <audio src={record.comment_voice} style={{ width: "100%", height: 43 }} controls />
      </Col>
      <Col span={6}>
        <DeleteFile id={record.ur_id} action="voice" callback={againGetData}>
          <Button size="small">{lang("student_reports.table.delete")}</Button>
        </DeleteFile>
      </Col>
    </Row>
  ) : (
    <CustomUpload
      type="audio"
      id={{
        ur_id: record.ur_id,
        res_id: record.res_id,
        course_id: courseId,
      }}
      callback={againGetData}
    >
      <Button block disabled={!record.ur_id}>
        {lang("student_reports.table.upload")}
      </Button>
    </CustomUpload>
  );
};

export default VoiceComment;
