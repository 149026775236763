import React, { useState } from "react";
import { Select, message, Button, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import intl from "react-intl-universal";

import user_API from "components/services/userService";

import styles from "assets/css/redemption/index.module.scss";

const { Option } = Select;

const SuccessContent = (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const { setVisible } = props;

  const { info, result } = useSelector((state) => state.new_redemption);

  const { type, first_name, family } = useSelector((state) => state.user);

  // 是否用户类型一致,且没有被兑换
  const equalType = info.user_type === type && info.is_redeemed !== "Y";

  // 选择账号进行兑换
  const selectOnChagne = async (user_id) => {
    try {
      await user_API.swap({ user_id });
      window.location.reload();
    } catch (error) {
      message.error("切換賬號失敗,請重試或手動替換賬號.");
    }
  };

  const buttonHandle = () => {
    if (result === 1) {
      history.push("/");
      return;
    }

    if (!equalType) {
      setOpen(true);
      return;
    }

    setVisible(true);
  };

  return (
    <>
      <Col xs={20} className={styles.block_padding}>
        <h2 className={styles.main_title}>
          {intl.get("redemption.redeem_msg")} :
        </h2>

        {result === 1 ? (
          <p className={styles.account}>{first_name}</p>
        ) : (
          <>
            <Select
              className={styles.select}
              defaultValue={first_name}
              onChange={(event) => selectOnChagne(event)}
              onFocus={() => setOpen(true)}
              onBlur={() => setOpen(false)}
              onClick={() => setOpen(!open)}
              open={open}
            >
              {family.map((item) => {
                if (info.user_type === "STUDENT") {
                  return (
                    item.relation === "CHILD" && (
                      <Option value={item.permit_user} key={item.permit_user}>
                        {item.permit_user_name}
                      </Option>
                    )
                  );
                }
                return (
                  item.relation !== "CHILD" && (
                    <Option value={item.permit_user} key={item.permit_user}>
                      {item.permit_user_name}
                    </Option>
                  )
                );
              })}
            </Select>
          </>
        )}

        <div>
          <Button
            type="primary"
            onClick={buttonHandle}
            block
            className={styles.submit_button}
          >
            {result === 1
              ? intl.get("redemption.goback")
              : equalType
              ? intl.get("redemption.redeem")
              : intl.get("redemption.switch_account")}
          </Button>
          {result !== 1 && equalType && (
            <p className={styles.tips}>{intl.get("redemption.redeem_hint")}</p>
          )}
        </div>

        <p style={{ color: "red" }}>
          {info.is_redeemed === "Y" && intl.get("redemption.message_hint")}
        </p>
      </Col>

      <Col xs={20} className={styles.block_padding}>
        <h2 className={styles.main_title}>
          {intl.get("redemption.redeem_content")}
        </h2>
        {info.course.map((item) => (
          <div className={styles.content} key={item.name}>
            <h2 className={styles.content_title}>{item.name}</h2>
            <img
              src={item.file}
              alt=""
              width="100%"
              className={styles.content_img}
            />
          </div>
        ))}
      </Col>
    </>
  );
};

export default SuccessContent;
