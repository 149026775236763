import React from 'react';
import intl from "react-intl-universal";

import { useSelector } from "react-redux";

export default function Terms(props) {
  const { initDone } = useSelector(state => state.translations);

  const pathArr = props.location.pathname.split('/');

  const key = pathArr[pathArr.length - 1];

  const lang = (value) => initDone && intl.get(value);

  return (
    <div style={{ padding: 30, maxWidth: 1300, margin: '0 auto' }} dangerouslySetInnerHTML={{__html: lang(`terms.${key}`)}}></div>
  )
}
