/**
 * 生成一个从 start 到 end 的连续数组
 * @param start
 * @param end
 */
export const generateArray= (start, end)=> {
  return Array.from(new Array(end + 1).keys()).slice(start)
}
export default{
  generateArray
}