import React, { useEffect } from "react";
import { Icon } from "antd";
import intl from "react-intl-universal";

import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { skipRegisterBindChildren } from "store/action/global";

import styles from "../sass/finish.module.scss";

const Finish = ({ loading }) => {
  const urlParams = new URLSearchParams(useLocation().search);

  const history = useHistory();

  // const key = urlParams.get("action");
  const key = urlParams.get("app");
  const invoiceCode = urlParams.get("invoice_code");

  const { skipRegister, skipBindChildren } = useSelector(
    (state) => state.new_global
  );

  const {
    route: { locationUrl },
    new_auth: { pay },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const { initDone } = useSelector((state) => state.translations);

  const lang = (value, params) =>
    initDone && intl.get(`register.${value}`, params);

  useEffect(() => {
    if (!!key && key === "efunfun") {
      window.location.href = `${locationUrl}user/login?app=${key}`;
    }
    setTimeout(() => {
      dispatch({ type: "SET_REGISTER_STATUS", payload: null });
    }, 3000);
    return () => {
      dispatch({ type: "SET_REGISTER_STATUS", payload: null });
      dispatch(skipRegisterBindChildren({ payload: false }));
    };
  }, []);

  useEffect(() => {
    if (!!key && key !== "efunfun") {
      const action = urlParams.get("action");
      if (action) {
        history.push(`${locationUrl}${action}`);
        return;
      }
      history.push(`${locationUrl}${key}`);
    }
    return () => {};
  }, []);

  const payFinish = () => {
    return (
      <>
        <h2 className={styles.title}>{lang("step_four.sucess_msg_one")}</h2>
        <p className={styles.describe}>{lang("step_four.sucess_msg_two")}</p>
        <Link className={styles.back} to="/">
          返回面板
        </Link>
      </>
    );
  };

  const freeFinish = () => {
    return (
      <>
        <h2 className={styles.title}>{lang("step_four.sucess_msg_one")}</h2>
        <p className={styles.describe}>{lang("step_four.sucess_msg_two")}</p>
        <p>
          <Link to="/" className={styles.underline}>
            {lang("step_four.btn_evi")}
          </Link>
          <span className={styles.space}>{lang("step_four.or")}</span>
          <Link to="/" className={styles.underline}>
            {lang("step_four.btn_parens")}
          </Link>
        </p>
      </>
    );
  };

  return (
    <div className={styles.box}>
      {!loading && (
        <div>
          {skipRegister || skipBindChildren || !pay.length
            ? freeFinish()
            : payFinish()}
        </div>
      )}
      {loading && (
        <div className={styles.mask_inner}>
          <Icon type="reload" spin />
          &nbsp;
          <span>
            {invoiceCode} {lang("step_four.loading")}...
          </span>
        </div>
      )}
    </div>
  );
};

export default Finish;
