import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Button, message, Icon, Modal } from 'antd';

import intl from 'react-intl-universal';

import Video from "components/common/Video";

import { fileTypeOf } from "components/utils/type";

import styles from 'assets/css/vote/voteItem.module.scss';

import voteService from 'components/services/voteService';

const isImg = (file) => fileTypeOf(file, [".jpeg", ".jpg", ".png", ".gif"]);
const isVideo = (file) => fileTypeOf(file, [".mp4", ".mov", ".m3u8"]);

const VoteItem = (props) => {
  const { translations, user: { intranet={} } } = useSelector(state => state);

  const lang = (value) => translations.initDone && intl.get(value);

  const { voteId, data, userVote, callback , notInPeriod } = props;

  const { project_file: file, name, class_name, student_no, num_of_vote, ur_id, grade_type } = data;

  const voted = userVote.ur_id && userVote.ur_id === ur_id;

  const handleVote = () => {
    Modal.confirm({
      title: lang('general.msg.vote_confirm_msg'),
      icon: <Icon type="delete" theme="twoTone" twoToneColor="#eb2f96" />,
      onOk: (e) => {
        if(ur_id && voteId) {
          return voteService.addVote({ ur_id, vote_id: voteId }).then(ret => {
            return callback && callback();
          }).catch(error => {
            message.error('投票失敗');
          })
        }
      },
      okText: lang(`general.button.confirm`),
      cancelText: lang(`general.button.cancel`),
    });
  }

  return (
    <div className={styles.root}>
      {!!num_of_vote && (
        <div className={styles.num_box}>
          {num_of_vote}{lang('general.msg.votes')}
        </div>
      )}
      <div className={styles.file_box}>
        {file ? (
          <Fragment>
            {isImg(file) && <img src={file} alt="" />}
            {isVideo(file) && <Video videosrc={file} rootStyle={{ height: 'auto' }} />}
          </Fragment>
        ) : (
          <h1>{lang("general.msg.not_submitted")}</h1>
        )}
      </div>
      <div className={styles.text_box}>
        <h3>{name}</h3>
        <h4>{class_name}{!!student_no && '('}{student_no}{!!student_no && ')'}</h4>
      </div>
      {ur_id && intranet.grade_type && intranet.grade_type === (grade_type || '').toLowerCase() && (
        <Button
          disabled={!!userVote.ur_id  || !!notInPeriod}
          className={styles.vote_btn}
          onClick={handleVote}
        >
          {voted ? <><Icon type="check-circle" /> {lang('general.button.voted')}</> : lang('general.button.vote')}
        </Button>
      )}
    </div>
  )
}

export default VoteItem;
