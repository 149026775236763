const defaultState = {
  class: {
    classTree: [],
    classList: []
  },
  student: [],
  staff: []
}

export const common = (state = defaultState, { type, payload }) => {
  switch (type) {
    case "GET_CLASS":
      return { ...state, class: payload };
    case "GET_STUDENT":
      return { ...state, student: payload };
    case "GET_STAFF":
      return { ...state, staff: payload };
    default:
      return state;
  }
};
