import { client } from "components/services/apiService";

// 兑换
export async function fetchRedeem({ code }) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/payment/invoice/redeem_code`,
    form: {
      code,
    },
  });
}

// 检测兑换可用
export async function checkRedeem({ code }) {
  return await client.post({
    url: `${process.env.REACT_APP_API_URL_USER}/payment/invoice/get_code_info`,
    form: {
      code,
    },
  });
}
