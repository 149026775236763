export const overridePath = ({ location, _auth, key, oldPath }) => {
  // 是否登入
  if (_auth) {
    return oldPath;
  }

  // 支持登录重定向跳转的路径地址
  const address = ["redemption", "resource"];

  const { pathname } = location;

  const result = address.find((item) => pathname.indexOf(item) !== -1);

  // 是否包含key path
  if (result) {
    const redirectPath = pathname.slice(
      pathname.indexOf(result),
      pathname.length
    );

    const code = redirectPath.replace(result + "/", "");

    if (code) {
      const encode = window.encodeURIComponent(redirectPath);

      return `${oldPath}user/login?app=${result}&action=${encode}`;
    }

    return `${oldPath}user/login?app={result}`;
  }

  return oldPath;
};
