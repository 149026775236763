import React, { Fragment, useState } from "react";
import { Modal } from "antd";

import styles from "assets/css/file.module.scss";
import CommonPdf from 'components/common/CommonPdf'

const ImgPreView = (props) => {
  const { children, src, style } = props;

  const [visible, setVisible] = useState(false);

  return (
    <Fragment>
      <div onClick={() => setVisible(true)} style={style}>
        {children}
      </div>
      <Modal visible={visible} onCancel={() => setVisible(false)} footer={null}>
        {src.endsWith(".pdf") ? (
          <CommonPdf file={src} pageClass={styles.documentPage} />
        ) : (
          <img
            src={src}
            style={{
              width: "100%",
            }}
            alt="preview"
          />
        )}
      </Modal>
    </Fragment>
  );
};

export default ImgPreView;
