import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";
import { Pagination, Modal, Icon } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import PropTypes from 'prop-types'
import 'assets/css/CommonPdf.scss'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function CommonPdf(props) {
  const { file, pageClass } = props;
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  //设置多语言
  const { translations: { initDone } } = useSelector(state => state);
  const lang = value => initDone && intl.get(value);

  // 重置页数
  useEffect(() => {
    setNumPages(0)
    setPageNumber(1)
  }, [file])

  //获取pdf页数
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }
  //点击分页，改变页数
  const onChangePage = (page) => {
    setPageNumber(page)
  }
  // 展示弹窗
  const showModal = () => {
    setIsModalVisible(true);
  };
  // 关闭弹窗
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="common-pdf-content">
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess} renderMode="canvas">
        <Page pageNumber={pageNumber} className={pageClass} />
        <div className="mask" onClick={showModal} >
          <Icon type="zoom-in" className="camera-icon" />
        </div>
      </Document>
      <Pagination
        total={numPages}
        showTotal={total => `${lang('home.publicMsg.adapter.total')} ${total} ${lang('home.publicMsg.adapter.page')}`}
        current={pageNumber}
        pageSize={1}
        onChange={onChangePage}
        hideOnSinglePage
        size="small"
        style={{ position: "relative", zIndex: 99, marginTop: "15px" }}
      />

      <Modal width={1100} visible={isModalVisible} onCancel={handleCancel} footer={null} wrapClassName="modalWrapClass" >
        <Document file={file} renderMode="canvas"  >
          <Page pageNumber={pageNumber} scale={1.3} />
        </Document>
      </Modal>
    </div>
  )
}

CommonPdf.propTypes = {
  //文件路径
  file: PropTypes.string.isRequired
}

export default CommonPdf

