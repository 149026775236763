import React from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";

import DeleteFile from "components/studentReport/components/deleteFile";

import CustomUpload from "components/studentReport/components/upload";
import ImgPreview from "components/studentReport/components/imgPreview";

const RecordUpload = (props) => {
  const { record, againGetData, courseId } = props;

  const { ur_id, res_id, comment_file } = record;

  const { translations } = useSelector((state) => state);

  const lang = (value) => translations.initDone && intl.get(value);

  return comment_file ? (
    <div 
      style={{ 
        display: 'flex',
        justifyContent: 'space-around'
      }}
    >
      <ImgPreview src={comment_file} style={{ display: "inline" }}>
        <Button size="small">
          {lang("student_reports.table.preview")}
        </Button>
      </ImgPreview>
      <DeleteFile
        id={ur_id}
        action="file"
        callback={againGetData}
        style={{ display: "inline" }}
      >
        <Button size="small">
          {lang("student_reports.table.delete")}
        </Button>
      </DeleteFile>
    </div>
  ) : (
    <CustomUpload
      type="file"
      id={{
        ur_id: ur_id,
        res_id: res_id,
        course_id: courseId,
      }}
      callback={againGetData}
    >
      <Button block disabled={!ur_id}>
        {lang("student_reports.table.upload")}
      </Button>
    </CustomUpload>
  );
};

export default RecordUpload;
