import React, { useState } from "react";
import intl from "react-intl-universal";
import moment from "moment";
import {
  Avatar,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  message
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import { putChildren } from "../../../../store/action/family";
import userService from "components/services/userService";

import AvatarEdit from "./AvatarEdit";

import styles from "../../sass/newChildrenForm.module.scss";

const { Option } = Select;

const ChildrenForm = (props) => {
  const { subNext, subPrev, setStep } = props;

  const { getFieldDecorator, validateFields } = props.form;

  const dispatch = useDispatch();

  const { id, family=[], language } = useSelector((state) => state.user);

  const [visible, setVisible] = useState(false);

  const [defaultBg, setDefaultBg] = useState((Math.floor(Math.random() * 4) +1));

  const [submitLoading, setSubmitLoading] = useState(false);

  const { initDone } = useSelector(state => state.translations);

  const lang = (value, params) => initDone && intl.get(`register.${value}`, params);

  const submitHandle = (e) => {
    if (e.cancelable) e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const { firstname, sex, birthday } = values;
        setSubmitLoading(true);
        dispatch(
          putChildren({
            first_name: firstname,
            // last_name: lastname,
            sex,
            language,
            family_id: id ? family[0].family_id : undefined,
            birthday: moment(birthday).format("YYYY-MM-DD"),
            bg_id: defaultBg,
            callback: async (error) => {
              setSubmitLoading(false);
              if (error) {
                message.error(initDone && intl.get(`general.msg.add_error`));
                return;
              }
              await userService
                .me(true)
                .then((payload) => dispatch({ type: "INIT", payload }));
              setStep(4);
            },
          })
        );
      }
    });
  };

  return (
    <div className={styles.box}>
      <Form onSubmit={submitHandle}>
        <Row type="flex" justify="space-between">
          <Col xs={24} md={8} className={styles.relative} onClick={() => setVisible(true)}>
            {defaultBg ? (
              <Avatar
              size={200}
              src={require(`assets/image/newRegister/Group${defaultBg}.png`)}
              />
            ) : (
              <Avatar size={200} icon="user" />
            )}
            {/*<span>
              <Button
                icon="camera"
                className={styles.editButton}
                onClick={() => setVisible(true)}
              />
            </span>*/}
          </Col>
          <Col xs={24} md={14} style={{marginTop : "1rem" , marginBottom : "1rem"}}>
            <Row type="flex" justify="space-between">
              <Col span={24}>
                <Form.Item className={styles.aequilatus}>
                  {getFieldDecorator("firstname")(
                    <Input placeholder={lang('step_three.form_name')} size="large" />
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator("birthday")(
                    <DatePicker
                      size="large"
                      placeholder={lang('step_three.form_birthday')}
                      format={"YYYY-MM-DD"}
                      className={styles.aequilatus}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item required>
                  {getFieldDecorator("sex")(
                    <Select
                      placeholder={lang('step_three.form_gender')}
                      size="large"
                      className={styles.aequilatus}
                    >
                      <Option value="M">{intl && intl.get('home.publicMsg.role.edit.userEdit.gender.M') || "M"}</Option>
                      <Option value="F">{intl && intl.get('home.publicMsg.role.edit.userEdit.gender.F') || "F"}</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row type="flex" justify="space-around">
          <Form.Item>
            <Button block className={styles.back} onClick={() => setStep(1)}>
              {lang('btn_back')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button block htmlType="submit" loading={submitLoading} className={styles.next}>
              {lang('btn_continue')}
            </Button>
          </Form.Item>
        </Row>
      </Form>
      <AvatarEdit
        visible={visible}
        close={() => setVisible(false)}
        defaultBg={defaultBg}
        setDefaultBg={setDefaultBg}
      />
    </div>
  );
};

export default Form.create()(ChildrenForm);
