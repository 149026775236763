import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import intl from "react-intl-universal";
import { useSelector } from "react-redux";
import { Row, Col, Checkbox, Button } from "antd";

import styles from "../../sass/registerParent/notice.module.scss";

const Notice = (props) => {
  const { subNext } = props;

  const history = useHistory();

  const [evigarten, setEvigarten] = useState(false);

  const [parentDaily, setParentDaily] = useState(false);

  const disabled = !evigarten || !parentDaily;

  const { initDone } = useSelector(state => state.translations);

  const { locationUrl } = useSelector(state => state.route);

  const lang = value => initDone && intl.get(`register.${value}`);

  return (
    <div className={styles.box}>
      <h2 className={styles.title}>{lang('step_one.create_account')}</h2>
      <Row type="flex" justify="space-around" align="middle">
        <Col xs={{ span: 10 }} sm={{ span : 6}}>
          <img
            src={require(`assets/image/logo_s.png`)}
            alt={lang('step_one.kindergarten_platform')}
            className={styles.img}
          />
          <span className={styles.logo_describe}>{lang('step_one.kindergarten_platform')}</span>
        </Col>
        <Col xs={{ span: 10 }} sm={{ span : 6}}>
          <img
            src={require(`assets/image/newRegister/parent_logo.png`)}
            alt={lang('step_one.parental_platform')}
            className={styles.img}
          />
          <span className={styles.logo_describe}>{lang('step_one.parental_platform')}</span>
        </Col>
      </Row>
      <div className={styles.describe}>
        <Checkbox
          checked={evigarten}
          onChange={(e) => setEvigarten(e.target.checked)}
        >
          {lang('step_one.service_l')} <Link to={`${locationUrl}terms`} target="blank">{lang('step_one.evi_service_r')}</Link>
        </Checkbox>
        <br />
        <Checkbox
          checked={parentDaily}
          onChange={(e) => setParentDaily(e.target.checked)}
        >
          {lang('step_one.service_l')} <Link to={`${locationUrl}parentsdaily-terms`} target="blank">{lang('step_one.parents_service_r')}</Link>
        </Checkbox>
      </div>
      <div className={styles.buttonGroup}>
        <Button
          block
          className={disabled ? "" : styles.next}
          disabled={disabled}
          type="primary"
          onClick={subNext}
        >
          {lang('btn_continue')}
        </Button>
        <Button 
          block 
          className={styles.back} 
          onClick={() => history.push(locationUrl)}
        >
          {lang('btn_back')}
        </Button>
      </div>
    </div>
  );
};

export default Notice;
