import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Textfit } from 'react-textfit';
import { Modal, Row, Col, Spin, Button, Popover, Empty } from 'antd';

import main from 'components/services/mainService';
import NewPage from 'components/services/user_commentService';
import user_api from 'components/services/userService';

// import { Thumbnail, Preview } from "components/common/FilePreview";
// import CommentList from "components/course/CommentList";
// import FilePreview from "./FilePreview";
import StudentReport from 'components/studentReport';
import SchoolResourceUpload from './SchoolResourceUpload';
import File from 'components/common/File';

import styles from 'assets/css/resource/schoolResourceDefaultModal.module.scss';

const color = {
  project: '#10975C',
  eval: '#F88D30'
};

const splitFileName = (file) => {
  return file && file.split('/')[file.split('/').length - 1];
};

const SchoolResourceProject = (props) => {
  const { refId, courseId, visible, onCancel, item } = props;

  const courseType = item.res_type || item.type;

  const {
    translations: { initDone },
    route: { currentLocation }
  } = useSelector((state) => state);

  const [info, setInfo] = useState({});
  const [commentData, setCommentData] = useState({ comment: [] });
  const [loading, setLoading] = useState(false);

  const theme = color[courseType] || '#10975C';

  const lang = (value) => initDone && intl.get(`schoolCourse.project_modal.${value}`);

  const init = () => {
    setInfo({});
    setCommentData({ comment: [] });
  };

  const getInfo = async () => {
    return await main
      .getFullInfo(refId)
      .then((ret) => {
        setInfo(ret);
        return ret;
      })
      .catch((error) => {
        console.log(error);
        return {};
      });
  };

  const getCommentData = async () => {
    return await NewPage.VerifyUplaod(refId, courseId)
      .then((ret) => {
        setCommentData(ret);
        return ret;
      })
      .catch((error) => {
        console.log(error);
        return {};
      });
  };

  const getData = async () => {
    try {
      setLoading(true);
      await getInfo();
      if (courseId) await getCommentData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      getData();
    }
    return () => init();
  }, [visible]);

  return (
    <Modal
      visible={visible}
      footer={null}
      width={1100}
      className={styles.root}
      closable={false}
      bodyStyle={{
        border: `5px solid ${theme}`,
        borderRadius: 15,
        padding: 0
      }}
      zIndex={700}
    >
      {/* 头部 */}
      <div className={styles.header} style={{ backgroundColor: theme }}>
        {courseType && <img className={styles.logo} src={require(`assets/image/schoolCourse/icon/${courseType}_no_border.png`)} alt="project_icon" />}
        <div className={styles.title}>
          <Textfit mode="multi" className={styles.name} max={28}>
            {lang(`${courseType}_title`)} - {info.name}
          </Textfit>
          <Textfit mode="multi" className={styles.time} max={20}>
            {lang('submit_time')} : {info.publish_time}
          </Textfit>
        </div>
        <Button type="link" icon="close" className={styles.close_btn} onClick={onCancel} />
      </div>
      {/* 主要内容 */}
      <div className={styles.main}>
        {loading && (
          <div className={styles.loading}>
            <Spin />
          </div>
        )}
        {!loading && (
          <Row type="flex" style={{ minHeight: 580 }}>
            <Col lg={14} xs={24} className={styles.file_container}>
              {info.teaching_point && (
                <div>
                  <div className={styles.label}>{lang('label_description')} :</div>
                  <div className={styles.teaching_point}>{info.teaching_point}</div>
                </div>
              )}
              <div>
                <div className={styles.label}>{lang('label_download_file')} :</div>
                <div className={styles.file_box}>
                  {!(info.item || []).length && <Empty />}
                  {(info.item || []).map((item) => {
                    const _file = item.streaming_url || item.file;
                    const fileName = item.file_name || splitFileName(_file);
                    return (
                      <Popover
                        key={item.id}
                        content={
                          <>
                            <File
                              src={_file}
                              className={styles.filePreview_wrap}
                              // videoToImg
                              isShowContextMenu={info.is_download === 'Y'}
                            />
                          </>
                        }
                        // arrowPointAtCenter
                        destroyTooltipOnHide
                        trigger="hover"
                        placement="bottom"
                        overlayClassName={styles.file_preview}
                      >
                        <a
                          href={_file}
                          target="blank"
                          className={styles.file_name}
                          onContextMenu={(e) => {
                            if (info.is_download === 'N') {
                              e.preventDefault();
                            }
                          }}
                          onClick={(e) => {
                            if (info.is_download === 'N') {
                              e.preventDefault();
                            }
                          }}
                        >
                          {fileName}
                        </a>
                        <span style={{ color: '#000' }}> , </span>
                      </Popover>
                    );
                  })}
                </div>
              </div>
              {!!courseId && (
                <div>
                  <div className={styles.label}>{lang('label_submit_file')} :</div>
                  <div className={styles.btn_box}>
                    <SchoolResourceUpload id={commentData.id} courseId={courseId} refId={refId} onUpload={getCommentData} uploaded={commentData.file} uploadTime={commentData.create_time} isComment={!!commentData.comment.length} theme={theme} />
                    {user_api.getType() === 'STAFF' && (
                      <StudentReport item={item}>
                        <Button icon="pie-chart" shape="circle" />
                      </StudentReport>
                    )}
                  </div>
                </div>
              )}
            </Col>
            {/* 評價 */}
            <Col lg={10} xs={24} className={styles.commont_container}>
              {!commentData.file && (
                <div className={styles.no_file}>
                  {!commentData.file && (
                    <>
                      <img src={require('assets/image/schoolCourse/no_submitted.png')} alt="no_submitted" />
                      <span>{lang('msg_no_submitted')}</span>
                    </>
                  )}
                </div>
              )}
              {commentData.file && (!commentData.comment.length || !commentData.comment[0].star) && (
                <div className={styles.no_comment}>
                  <>
                    <span>{lang('msg_no_comment')}</span>
                  </>
                </div>
              )}
              {!!commentData.comment.length && commentData.comment[0].star && (
                <div className={styles.comment_wrap}>
                  <div>
                    <span style={{ display: 'inline-block' }} className={styles.label}>
                      {lang('label_teacher_comment')} :
                    </span>
                    <img className={styles.comment_icon} src={commentData.comment[0].icon} alt="comment_icon" />
                  </div>
                  {commentData.comment[0].comment_voice && (
                    <div>
                      <div className={styles.label}>{lang('label_coice_comment')} :</div>
                      <audio src={commentData.comment[0].comment_voice} controls></audio>
                    </div>
                  )}
                  {commentData.comment[0].comment && (
                    <div>
                      <div className={styles.label}>{lang('label_text_comment')} :</div>
                      <div className={styles.comment_text}>{commentData.comment[0].comment}</div>
                      <span className={styles.comment_tile} style={{ color: theme }}>
                        {commentData.comment[0].last_update_time}
                      </span>
                    </div>
                  )}
                  {commentData.comment[0].comment_file && (
                    <div>
                      <div className={styles.label}>{lang('label_teacher_file_comment')} :</div>
                      <div className={styles.file_box}>
                        <Popover
                          key={item.id}
                          content={
                            <>
                              <File
                                src={commentData.comment[0].comment_file}
                                className={styles.filePreview_wrap}
                                // videoToImg
                              />
                            </>
                          }
                          // arrowPointAtCenter
                          destroyTooltipOnHide
                          trigger="hover"
                          placement="bottom"
                          overlayClassName={styles.file_preview}
                        >
                          <a href={commentData.comment[0].comment_file} target="blank" className={styles.file_name}>
                            {splitFileName(commentData.comment[0].comment_file)}
                          </a>
                        </Popover>
                      </div>
                    </div>
                  )}
                  {commentData.comment[0].highlight === 'Y' && (
                    <div className={styles.highlight_box} style={{ color: theme }}>
                      <img src={require('assets/image/schoolCourse/featured_works.png')} alt="featured_works" />
                      <br />
                      <span>{currentLocation === 'cn' ? lang('msg_featured_article') : lang('msg_featured_Works')}</span>
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  );
};

export default SchoolResourceProject;
