import React, { useMemo } from 'react';
import { Menu, Dropdown, Icon } from 'antd';

const SelectLang = (props) => {
  const { list, current, onChange } = props;

  const langList = useMemo(() => {
    return list.filter(_ => !(_.lang === current.lang && _.vo_lang === current.vo_lang));
  }, [list, current]);

  const menu = (
    <Menu>
      {langList.map((item, index) => (
        <Menu.Item key={index} onClick={() => onChange(item)}>
          <img src={require(`assets/image/drWong/vocab/${item.vo_lang}_${item.lang}.png`)} alt="lang"/>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown 
      overlay={menu}
      trigger={['click']}
      placement="bottomCenter"
    >
      <div style={{ width: 80, margin: '0 auto', textAlign: 'center' }}>
        <Icon type="caret-down" /><br />
        {current.lang && current.vo_lang && (
          <img 
            src={require(`assets/image/drWong/vocab/${current.vo_lang}_${current.lang}.png`)}
            alt="lang"
            style={{ maxWidth: 80 }}
          />
        )}
      </div>
    </Dropdown>
  )
}

export default SelectLang;