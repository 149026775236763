import React, { useState, useEffect } from "react";
import { Steps, Button, message } from "antd";

// import ChildrenForm from "./childrenForm";
// import BindFamily from "./bindFamily";

import Notice from "./notice";
import RegisterForm from "./registerForm";
import Login from "./login";

const { Step } = Steps;

const StepRegisterParent = (props) => {
  const { next, prev, location, codeData } = props;

  const [current, setCurrent] = useState(0);
  const [defaultAccount, setDefaultAccount] = useState();

  useEffect(() => {
    if (codeData.code === 205) {
      setCurrent(2);
    }
  }, [codeData]);

  const steps = [
    {
      title: "注册须知",
      content: <Notice subNext={() => subNext()} prev={() => prev()} />,
    },
    {
      title: "注册账号",
      content: (
        <RegisterForm
          next={() => next()}
          subNext={() => setCurrent(current + 1)}
          subPrev={() => subPrev()}
          location={location}
          setAccount={(account) => setDefaultAccount(account)}
        />
      ),
    },
    {
      title: "登入",
      content: (
        <Login
          next={(step) => next(step)}
          subPrev={() => subPrev()}
          location={location}
          defaultAccount={defaultAccount}
          defaultEmail={codeData.email}
        />
      ),
    },
  ];

  const subNext = () => {
    setCurrent(current + 1);
  };

  const subPrev = () => {
    setCurrent(current + -1);
  };

  return (
    <>
      <Steps
        current={current}
        labelPlacement={"vertical"}
        style={{ display: "none" }}
        // className={stepStyles.step}
      >
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      {/* <div className="steps-action">
        {current > 0 && (
          <Button
            style={{ marginLeft: 8, marginBottom: "16px" }}
            onClick={() => subPrev()}
          >
            输入个人资料 -- Previous
          </Button>
        )}

        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            输入个人资料 -- Done
          </Button>
        )}

        {current < steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => subNext()}
            style={{ marginBottom: "16px" }}
          >
            输入个人资料 -- Next
          </Button>
        )}
      </div> */}
    </>
  );
};

export default StepRegisterParent;
