import React, { useState } from "react";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";
import { Modal, Radio } from "antd";

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

const EventsModal = ({
  visible,
  showOption1,
  onCancel,
  onConfirm,
  operationType,
  deleteCallback,
}) => {
  const [value, setValue] = useState(1);

  const { initDone } = useSelector((state) => state.translations);

  const lang = (value) => {
    return initDone && intl.get(value);
  };

  return (
    <Modal
      title={lang("zoomCalendar.events_title")}
      visible={visible}
      onOk={() => {
        if (operationType === "delete") {
          deleteCallback(value);
        } else {
          onConfirm(value);
        }
        onCancel();
      }}
      onCancel={onCancel}
      okText={lang("general.button.confirm")}
      cancelText={lang("general.button.cancel")}
    >
      <Radio.Group value={value} onChange={(e) => setValue(e.target.value)}>
        {showOption1 && (
          <Radio style={radioStyle} value={1}>
            {lang("zoomCalendar.events_option_1")}
          </Radio>
        )}
        {/* <Radio style={radioStyle} value={2} disabled>
          {lang("zoomCalendar.events_option_2")}
        </Radio> */}
        <Radio style={radioStyle} value={3}>
          {lang("zoomCalendar.events_option_3")}
        </Radio>
      </Radio.Group>
    </Modal>
  );
};

export default EventsModal;
