import { client } from "./apiService";

const url = `${process.env.REACT_APP_API_URL_RESOURCE}`;

export async function get(form) {
  return await client.post({
    url: `${url}/vote/get`,
    form,
  });
}

export async function addVote(form) {
  return await client.post({
    url: `${url}/user_record/add_vote`,
    form,
  });
}

export default {
  get,
  addVote
};