//新增课程页面
import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Drawer, Steps, Button, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import intl from "react-intl-universal";

import Resource from "components/course/Resource";
import SubClass from "components/course/SubClass";
import CourseData from "components/course/CourseData";
// import CourseSort from "components/course/CourseSort";
import course from "components/services/courseService";
// import img from "assets/image/addCourseBorder.png"

import QueueAnim from "rc-queue-anim";

import { StickyContainer } from "react-sticky";

const TabPane = Tabs.TabPane;

const styles = {
  // background: `url(${img}) no-repeat`,
  // padding: '21px 6px 6px 8px',
  border: "8px solid #e1ecff",
  borderRadius: 6,
  backgroundSize: "100%, 100%",
  marginBottom: 10
};

const CourseEditor = (props , ref) => {
  const dispatch = useDispatch();
  const { route, user, translations } = useSelector(state => state);

  const updateFileName = (payload) => dispatch({ type: "updateFileName", payload });
  const [visible, setVisible] = useState(false); //彈框狀態
  const [title, setTitle] = useState("");  // 标题
  const [courseTitle, setCourseTitle] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");  // 副标题
  const [category, setCategory] = useState([]);
  const [data, setData] = useState([]) //新增课程数据
  const [subPic, setSubPic] = useState(""); //子分类图片
  const [tag, setTag] = useState(null);
  const [tagID, setTagID] = useState(null);
  const [render, setRender] = useState(1); //决定渲染父页面还是子页面
  const [resource, setResource] = useState([]);
  const [formData, setFormData] = useState({}); //课程资料页面传过来的表单数据
  const [activeKey, setActiveKey] = useState("1");  //tab的key
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(loading);
  const [update, setUpdate] = useState(false);

  const init = async () => {
    let list = await course
      .getCourseList()
      .then(ret => {
        return ret.rows.map(menu => {
          if (!!menu.parent_id) {
            menu.parent_name = (ret.rows.filter(
              _menu => _menu.id === menu.parent_id
            ) || [{ name: menu.parent_id }])[0].name;
          }
          return menu;
        });
      })
      .catch(_msg => {
        console.log(_msg);
      });
    setData(list.filter(menu => !!menu && !!menu.parent_id));
    setCategory(list.filter(menu => !menu.parent_id));
    // setState("data" , list.filter(menu => !!menu && !!menu.parent_id));
    // setState("category" , list.filter(menu => !menu.parent_id));
  }

  useEffect(() => {
    if (!!visible) {
      init();
    }
  }, [visible]);

  const reset = (res) => {
    setData([]);
    setCategory([]);
    setUpdate(res);
    setRender((!!res && 3 || 1));
  }

  //显示弹框
  const showModal = (res) => {
    console.log('showing modal ...');
    setVisible(true);
    reset(res);
  }

  //隐藏弹框
  const onCancel = () => {
    console.log('cancelling modal ...');
    setVisible(false);
    setActiveKey(1);
    setDisabled(true);
    setRender(1);
  };

  const refresh = () => {
    const { refresh } = props;
    typeof refresh === "function" && refresh();
  }

  const _prevStep = () => {
    goStep(render - 1);
  }

  const _nextStep = () => {
    goStep(render + 1);
  }

  const goStep = (step = 1) => {
    if (step == 1) {
      setResource([]);
    }
    setRender(step);
  }

  //渲染子分類頁面
  const subTag = (item) => {
    const { id, file, name, tag } = item;
    _nextStep();
    setTitle(name);
    setSelectedTitle(name);
    setTag(tag);
    setTagID(id);
    setSubPic(file);
  }

  //子分類頁面返回
  const backSubClass = () => {
    setRender(1);
  }

  //課程資料頁面下一步
  const prevStep = _prevStep;

  const nextStep = () => {
    setActiveKey(2);
    setDisabled(false);
  }

  //課程排序頁面上一步
  const sortCancel = () => {
    setActiveKey(1);
    setDisabled(true);
  }

  const handleSubmit = async (info = { id: null }, itemList = []) => {
    setLoading(true);
    const type = (!!info.id && "修改" || "添加");
    const AddOrUpdate = (!!info.id && "courseUpdate" || "courseAdd");

    return course[AddOrUpdate](
      info,
      itemList.map(item => {
        return item.type === "COURSE"
          ? null
          : { ref_id: item.id, type: "RESOURCE", sort: item.sort };
      })
    )
      .then(ret => {
        onCancel();
        refresh();
        message.success(`${type}成功`);
      })
      .catch(_msg => {
        message.error(`${type}失敗`);
      })
      .then(() => {
        setLoading(false);
      });
  }

  const _alert = (render) => {
    switch (render) {
      case 1:
        return (
          <div
            className="card-container"
            style={
              !(category.length > 0)
                ? { minHeight: "400px", backgroundColor: "white" }
                : {}
            }
          >
            <div style={{ margin: "16px" }}>
              <Button
                type="primary"
                shape="round"
                icon="plus"
                onClick={() => {
                  goStep(3);
                }}
              >
                {translations.initDone && intl.get("general.button.customize")}
              </Button>
            </div>
            <Tabs
              defaultActiveKey={
                (category[0] || [{ alias: 1 }]).alias
              }
              tabBarStyle={{ padding: "0 20px" }}
            >
              {category.map(menu => {
                return (
                  <TabPane
                    tab={menu.name}
                    key={menu.alias}
                    className={"tag-container"}
                  >
                    <Row style={{ padding: "0 1rem", margin: 0 }} gutter={10}>
                      <QueueAnim duration={100} interval={80} type="scale">
                        {data.length > 0
                          ? data.map(item => {
                            return item.parent_id.toString() ===
                              menu.id.toString() ? (
                              <Col key={item.id} lg={4} md={6} sm={8} xs={12}>
                                <div style={styles}>
                                  <Resource
                                    onClick={() => {
                                      subTag(item);
                                    }}
                                    picUrl={item.file}
                                    title={item.name}
                                    titleBgcolor={"rgba(0, 110, 255, 0.5)"}
                                    height={109}
                                  />
                                </div>
                              </Col>
                            ) : null;
                          })
                          : null}
                      </QueueAnim>
                    </Row>
                  </TabPane>
                );
              })}
            </Tabs>
          </div>
        );
      case 2:
        return (
          <SubClass
            picUrl={subPic}
            tag={tag}
            tagID={tagID}
            prevStep={_prevStep}
            callback={({ tag, resource, tagList }) => {
              setResource(resource);
              setCourseTitle(`${selectedTitle}${tag.length === tagList.length || tagList.length === 0
                ? ""
                : "(" + tag.map(_select => _select.name).join(",") + ")"
                }`);
              setTitle(translations.initDone && intl.get("course_1.courseData.addTitle"));
              _nextStep();
            }}
          />
        );
      case 3:
        return (
          <CourseData
            courseTitle={courseTitle}
            autoEdit={resource.length === 0}
            data={resource}
            URLid={props.URLid}
            prevStep={() => {
              if (!!update) {
                return;
              }
              resource.length === 0
                ? goStep(1)
                : prevStep();
            }}
            reset={() => {
              setResource([]);
            }}
            nextStep={handleSubmit}
            updateData={update}
            setChange={props.setChange}
            loading={loading}
          />
        );
      default:
        return null;
    }
  }

  const DrawerRef = React.useRef();
  React.useImperativeHandle(ref , () => ({
    showModal : (res) => {
      showModal(res);
    }
  }));

  return (
    <Drawer
      title={
        <h3 style={{ textAlign: "center" }}>
          {!!update
            ? translations.initDone &&
            intl.get("course_1.courseData.editTitle")
            : translations.initDone &&
            intl.get("course_1.content.option.calssAdd")}
        </h3>
      }
      ref={DrawerRef}
      visible={visible}
      keyboard={true}
      wrapperClassName="EVI-Course-Editor"
      onCancel={onCancel}
      placement="right"
      closable={true}
      maskClosable={false}
      zIndex={300}
      onClose={() => {
        setVisible(false);
      }}
      afterVisibleChange={visible => {
        if (!visible) {
          onCancel();
        }
      }}
    >
      <StickyContainer>
        <Row className="course-editor-area">
          {_alert(render)}
        </Row>
      </StickyContainer>
      {!update && (
        <Row
          style={{ ...{ padding: "1rem 0", borderTop: "1px solid #eaeaea" } }}
          className={"hidden visible-sm"}
        >
          <Col xs={{ span: 22, offset: 1 }}>
            <Steps current={render - 1}>
              <Steps.Step
                title={
                  translations.initDone &&
                  intl.get("general.msg.select_topic")
                }
              />
              <Steps.Step
                title={
                  translations.initDone &&
                  intl.get("general.msg.select_subtopic")
                }
              />
              <Steps.Step
                title={
                  translations.initDone &&
                  intl.get("general.msg.release_settings")
                }
              />
            </Steps>
          </Col>
        </Row>
      )}
    </Drawer>
  );
}

export default React.forwardRef(CourseEditor);