import React from "react";
import { useSelector, useDispatch } from "react-redux";
import intl from "react-intl-universal";
import { Tabs, Row, Col } from "antd";

const { TabPane } = Tabs;

const imageList = [1, 2, 3, 4];

const DefaultImg = (props) => {
  const { defaultBg, setDefaultBg } = props;

  const { initDone } = useSelector((state) => state.translations);

  const lang = function (text) {
    return initDone && intl.get(text);
  };

  return (
    <Tabs
      defaultActiveKey="0"
      onChange={(key) => console.log(key)}
      style={{ width: "100%", minHeight: 100 }}
    >
      <TabPane tab={lang('register.step_three.default_picture')} key="default" style={{ maxHeight: 500 }}>
        <Row
          type="flex"
          justify="start"
          gutter={[10, 10]}
          style={{ padding: 5 }}
        >
          {imageList.map((item, index) => (
            <Col
              span={8}
              style={{ cursor: "point" }}
              key={index}
              onClick={() => {
                setDefaultBg(item);
              }}
            >
              <img
                src={require(`assets/image/newRegister/Group${item}.png`)}
                alt=""
                width="100%"
                height="100%"
                style={{
                  borderRadius: "50%",
                  outline: defaultBg === item ? "2.5px #39c5bb solid" : "",
                }}
              />
            </Col>
          ))}
        </Row>
      </TabPane>
    </Tabs>
  );
};

export default DefaultImg;
