export const validateRegisterAccount = (region, account) => {
  const example = {
    cn: /^[1][3-8]\d{9}$/,
    hk: /^(5|6|8|9)\d{7}$/,
    mo: /^[1-9]\d{7}$/,
    my: /^[0-9]\d{8}$/,
  };

  const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    account
  );

  const phoneRegexp = example[region].test(account);

  return {
    type: emailRegexp || phoneRegexp ? "email" : "tel",
    result: emailRegexp || phoneRegexp,
  };
};

//base64轉file
export const dataURLtoFile = (dataurl, filename = "file") => {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split("/")[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  u8arr.uid = 1;
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, { type: mime });
};
