import { EVENT_LIST, GET_INFO } from "components/actions/event_action";

const defaultState = {
  eventList: { rows: [], total: 0 },
  info: {},
  error: {},
};

// event reducer 派发
export const eventReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case EVENT_LIST.SUCCES:
      const { rows, total } = payload;
      return {
        ...state,
        eventList: {
          // 格式转换
          rows: rows.map((item) => ({
            ...item,
            start: new Date(item.start.replace(/-/g, "/")),
            end: new Date(item.end.replace(/-/g, "/")),
            all_day: item.all_day === "Y",
          })),
          total,
        },
      };
    case EVENT_LIST.ERROR:
      return { ...state, error: payload };
    case GET_INFO.SUCCES:
      return { ...state, info: payload };
    case GET_INFO.RESET:
      return { ...state, info: {} };
    default:
      return state;
  }
};
