import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";
import { Modal, Button, Icon, Row, Col, Spin, Tooltip, Upload, message} from "antd";
import Video from "components/common/Video";
import FilePreview from "components/resource/FilePreview";
import CommentList from "components/course/CommentList";
import CommonPdf from 'components/common/CommonPdf'
import { Textfit } from "react-textfit";

import main from "components/services/mainService";
import NewPage from "components/services/user_commentService";
import course from "components/services/courseService";

import styles from "assets/css/PopupSpecial.module.scss";

import { fileTypeOf } from "components/utils/type";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

//视频缩略图
const getVideoImage = (file, callback) => {
  if (file && file.type.indexOf("video/") === 0) {
    let video = document.createElement("video");
    video.currentTime = 1;
    video.src = URL.createObjectURL(file);
    video.addEventListener("loadeddata", function () {
      let canvas = document.createElement("canvas"); // 创建一个画布
      canvas.width = video.videoWidth * 0.8;
      canvas.height = video.videoHeight * 0.8;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height); // getContext:设置画布环境；drawImage:画画
      let image = canvas.toDataURL("image/jpeg");
      typeof callback == "function" && callback(image);
    });
  }
};

const SchoolResourceDefault = (props) => {
  const { refId, courseId, visible, onCancel } = props;

  const { translations: { initDone } } = useSelector(state => state);

  const [ info, setInfo ] = useState({});
  const [ commentData, setCommentData ] = useState({ comment: [] });
  const [ loading, setLoading ] = useState(false);
  const [ uploadLoading, setUploadLoading ] = useState(false);
  const [ file, setFile ] = useState();
  const [ fileName, setFileName ] = useState();
  const [ type, setType ] = useState();
  const [ fileList, setFileList ] = useState([]);
  const [ previewImage, setPreviewImage ] = useState('');
  const [ previewVisible, setPreviewVisible ] = useState(false);

  const lang = value => initDone && intl.get(value);

  const init = () => setInfo({});

  const getInfo = async () => {
    const ret = await main.getFullInfo(refId).then(ret => ret).catch(() => {});
    const { teaching_point, item } = ret;
    const [newItem] = item.filter(_ => !!_.file);
    setInfo(ret);
    setFile(!!teaching_point
      ? teaching_point
      : !!newItem
      ? { streamingUrl: newItem.streaming_url, url: newItem.file }
      : "");
    setFileName(!teaching_point ? (!!newItem ? newItem.file_name : "") : "");
    setType(!!teaching_point ? "text" : "");
    return ret;
  }

  const getCommentData = async () => {
    return await NewPage.VerifyUplaod(refId, courseId).then(ret => {
      console.log(ret);
      setCommentData(ret);
      return ret;
    }).catch(error => {
      console.log(error);
      return {};
    })
  }

  const getData = async () => {
    try {
      setLoading(true);
      await getInfo();
      if(courseId) await getCommentData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(visible) {
      getData();
    } else {
      init();
    }
  }, [visible])

  const changeFile = (file, fileName, type = "file") => {
    if (file) {
      setFile(file);
      setFileName(fileName);
      setType(type);
    }
  };

  const setIcon = (item) => {
    if (!!item.file) {
      const file = item.file.toLowerCase();
      switch (true) {
        case file.endsWith(".mp4"):
          return "video-camera";
        case file.endsWith(".jpeg") ||
          file.endsWith(".jpg") ||
          file.endsWith(".png") ||
          file.endsWith(".gif"):
          return "picture";
        case file.endsWith(".mp3"):
          return "audio";
        case file.endsWith(".pdf"):
          return "file-pdf";
        case file.endsWith(".ppt"):
          return "file-ppt";
        default:
          return "file";
      }
    }
  };

  const getType = (file) => {
    let filename = file;
    let index1 = filename.lastIndexOf(".");
    let index2 = filename.length;
    let type = filename.substring(index1, index2);
    return type;
  };

  const view = (data, type) => {
    if (!!data) {
      const file =
        typeof data === "string"
          ? data
          : (!!data.streamingUrl ? data.streamingUrl : data.url).toLowerCase();
      switch (true) {
        case file.endsWith(".mp4"):
        case file.endsWith(".m3u8"):
          return <Video videosrc={file} light={false} />;
        case file.endsWith(".jpeg") ||
          file.endsWith(".jpg") ||
          file.endsWith(".png") ||
          file.endsWith(".gif"):
          return <img src={file} alt="" />;
        case file.endsWith(".mp3"):
          return (
            <audio
              src={file}
              autoPlay
              controls
              controlsList="nodownload"
            ></audio>
          );
        case file.endsWith(".pdf"):
          return (
            <CommonPdf file={file} />
          );
        case type === "text":
          return (
            <Textfit
              mode="multi"
              min={12}
              max={20}
              style={{ height: "100%", lineHeight: "200%", textAlign: "left" }}
            >
              <div dangerouslySetInnerHTML={{ __html: data }} />
            </Textfit>
          );
        default:
          return (
            <div>
              <Icon type="file" className={styles.icon} />
              <p>{getType(file)}</p>
            </div>
          );
      }
    }
    return null;
  };

  // 上载文件预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  }

  //上传选择框
  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">
        {lang("course_1.UploadingFile.tip")}
      </div>
    </div>
  );

  const handleChange = ({ fileList, file }) => {
    const typeList = ['.gif', '.jpg', '.jpeg', '.png', '.mp4', '.mov', '.pdf' ];
    const fileType = fileTypeOf(file.name, typeList);
    // 類型判斷
    if (!fileType && !file.status) {
      Modal.error({ title: lang("general.msg.upload_type2") });
      return;
    }
    // 设置视频缩略图
    if (fileList.length && file.type === "video/mp4") {
      getVideoImage(file, (ret) => {
        fileList[0].thumbUrl = ret;
        fileList[0].preview = ret;
        setFileList(fileList);
      });
      return;
    }
    // 設置重新提交
    // if (file.status === 'removed') {
    //   setIsResubmit(true);
    // }
    setFileList(fileList);
  }

  const handleUpload = async () => {
    try {
      setUploadLoading(true);
      message.loading({ content: lang('general.msg.uploading'), key: 'updatable' });
      const file = fileList[0].originFileObj;
      const formData = {
        id: commentData.id,
        res_id: refId,
        course_id: courseId,
        app_type: "KID_WEB",
      };
      const permit = commentData.id ? await course.getUserRecordUpdatePermit(file, formData) : await course.getUserRecordAddPermit(file, formData);
      await course
        .uploadFile(permit, file)
        .then(() => {
          getCommentData();
          message.success({ content: lang('general.msg.upload_success'), key: 'updatable', duration: 2 });
          setFileList([]);
          setUploadLoading(false);
          // setIsResubmit(false);
        })
        .catch(() => {
          return new Promise((resolve, reject) => {
            reject("UPLOAD_ERROR");
          });
        });
    } catch {
      console.log("上傳失敗");
    } finally {
      setUploadLoading(false);
    }
  }

  //<Upload/>属性
  const uploadProps = {
    listType: "picture-card",
    accept: ".gif, .jpg, .jpeg, .png, .mp4, .mov, .pdf",
    maxCount: 1,
    fileList,
    beforeUpload: () => false,
    onChange: handleChange,
    onPreview: handlePreview,
    disabled: !!commentData.comment.length
    // showUploadList: {
    //   showRemoveIcon: !isComment
    // }
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      width={1100}
      // className={styles.root}
      closable={false}
      bodyStyle={{
        padding: 0
      }}
      destroyOnClose
    >
      <div className={styles.wrap}>
        {loading && (
          <div
            style={{ textAlign: "center", width: "100%", padding: "150px 0" }}
          >
            <Spin />
          </div>
        )}
        {!loading && (
          <Row style={{ borderRadius: 30, overflow: "hidden", padding: 20 }}>
            <Col sm={12} xs={24} style={{ marginBottom: 20 }}>
              <div className={styles.container}>
                <h1>{info.name}</h1>
                <Col
                  className={styles.fileWrap}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    e.returnValue = false;
                  }}
                >
                  {view(file, type)}
                  <p className={styles.fileName} title={fileName}>
                    {fileName}
                  </p>
                </Col>
                {info.is_download === "N"
                  ? ""
                  : type !== "text" &&
                    !!file && (
                      <a href={file.url} alt="download">
                        <Button type="primary">
                          <Icon type="download" />
                          {lang(`general.button.download`)}
                        </Button>
                      </a>
                    )}
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <div className={styles.container}>
                <Col
                  lg={{ span: 16, offset: 4 }}
                  sm={{ span: 20, offset: 2 }}
                  style={{ marginBottom: 20 }}
                >
                  <Row gutter={5} type="flex">
                    {info.teaching_point && (
                      <Col span={8}>
                        <div
                          className={styles.iconWrap}
                          onClick={() => changeFile(info.teaching_point, "", "text")}
                        >
                          <Icon type="font-size" className={styles.text} />
                        </div>
                      </Col>
                    )}
                    {(info.item || []).map(
                      (item) =>
                        !!item.file && (
                          <Col span={8} key={item.id}>
                            <div
                              className={styles.iconWrap}
                              onClick={() =>
                                changeFile(
                                  {
                                    streamingUrl: item.streaming_url,
                                    url: item.file,
                                  },
                                  item.file_name
                                )
                              }
                            >
                              {!!item.streaming_url && (
                                <div className={styles.streaming}>
                                  <Tooltip title={lang(`course_1.content.PopupAECoursware.streaming_tootip`)}>
                                    <Icon type="safety-certificate" theme="twoTone" />
                                  </Tooltip>
                                </div>
                              )}
                              <div className={styles.file}>
                                <Icon
                                  type={setIcon(item)}
                                  className={styles.icon}
                                />
                                {setIcon(item) === "file" && (
                                  <p>{getType(item.file)}</p>
                                )}
                              </div>
                            </div>
                            <p
                              className={styles.fileName}
                              title={item.file_name}
                            >
                              {item.file_name}
                            </p>
                          </Col>
                        )
                    )}
                  </Row>
                </Col>
                {info.type === "project" && courseId && (
                  <>
                    <Col span={16} offset={4}>
                      <Upload {...uploadProps}>
                        {fileList.length >= 1 ? null : uploadButton}
                      </Upload>
                      <Modal
                        visible={previewVisible}
                        footer={null}
                        onCancel={() => setPreviewVisible(false)}
                        // zIndex={9999}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </Col>
                    <Button
                      className={styles.xs_update}
                      onClick={handleUpload}
                      disabled={!!commentData.comment.length}
                      htmlType="submit"
                      loading={uploadLoading}
                    >
                      {lang("general.button.upload")}
                    </Button>
                    {!!commentData.file && (
                      <FilePreview
                        file={commentData.file}
                        display="block"
                        zIndex={1200}
                      >
                        <p
                          style={{
                            color: "red",
                            lineHeight: "40px",
                            cursor: "pointer",
                          }}
                        >
                          已上載檔案
                        </p>
                      </FilePreview>
                    )}
                    <span style={{ display: "inline-block", fontSize: 14 }}>
                      * {lang("general.msg.upload_type2")}
                    </span>
                  </>
                )}
              </div>
            </Col>
            {!!commentData.comment.length && (
              <Col xs={24}>
                <CommentList
                  data={commentData.comment}
                  listItemStyle={{ borderTop: "1px solid #ddd" }}
                />
              </Col>
            )}
          </Row>
        )}
        <Button
          className={styles.close}
          style={{ zIndex: 9 }}
          onClick={onCancel}
        >
          <Icon type="close" />
        </Button>
      </div>
    </Modal>
  )
}

export default SchoolResourceDefault;