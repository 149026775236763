const defaultState = {
  drawerVisible: false,
  // 记录 tour visible 调用次数
  count: 0,
};

export const globalReducer = (
  state = defaultState,
  { type, payload, callback }
) => {
  switch (type) {
    case "changeDrawerVisible":
      // 超过2次就取消设置
      if (state.count > 2) {
        return defaultState;
      }
      if (callback) {
        callback();
      }

      return {
        ...state,
        count: state.count + 1,
        drawerVisible: payload,
      };
    default:
      return state;
  }
};
