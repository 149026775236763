import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';

import { verifyKey } from "components/services/verifyService";

export default function VerifyPage() {

  const [thirdPKey, setThirdPKey] = useState({ key : null , origin : null});
  const [status, setStatus] = useState(true);

  // const { device_id } = useSelector(state => state.user);

  const handleVerify = async () => {
    const { key , origin } = thirdPKey;
    if (!!key) {
      setStatus(true);
      const message = await verifyKey(key).then(data => {
        return {
          action : "token-after-verified",
          data
        }
      }).catch(error => {
        setStatus(false);
        return {
          action : "token-verify-error",
          data : error
        };
      })
      if (message) {
        // 把验证结果发送给 efunfun
        window.top.postMessage(message, origin)
      }
    }
  }

  // 
  
  /**
   * @description 
   * 接受 postMessage 信息
   * 支援 不同第三方應用
   */
  const handleMessage = (event) => {
    const {REACT_APP_EFUNFUN_URL , REACT_APP_PD_URL} = process.env;
    
    if([REACT_APP_EFUNFUN_URL , REACT_APP_PD_URL].indexOf(event.origin) > -1){
      const data = event.data;
      const { action , key , origin } = event;
      if(action ==="token-verify" && !!key) {
        setThirdPKey({ key  , origin });
      } else if(data.key) {
        setThirdPKey({ key : data.key , origin : event.origin});
      }
    }
  }

  useEffect(() => {
    if (!!thirdPKey && !!thirdPKey.key) {
      handleVerify();
    }
  }, [thirdPKey])

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    window.top.postMessage({ action : "verify-ready"}, process.env.REACT_APP_EFUNFUN_URL);
    window.top.postMessage({ action : "verify-ready"}, process.env.REACT_APP_PD_URL);
    return () => {
      window.removeEventListener("message", handleMessage);
    }
  }, [])

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      {status && <h1>正在進行驗證，請稍候...</h1>}
      {!status && (
        <Button type="link" onClick={handleVerify}>
          點擊重新驗證
        </Button>
      )}
    </div>
  )
}
