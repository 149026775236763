import { newRegister, newVerify } from "../../api/auth_api";
import { setUToken } from "components/services/apiService";

// 注册家长账号
export function fetchRegister({ callback, ...rest }) {
  return (dispatch) => {
    newRegister(rest)
      .then((ret) => {
        console.log(ret);
        setUToken(ret);
        dispatch({ type: "SAVE_TOKEN", payload: ret , temp : rest });
        callback();
      })
      .catch(error => callback(error));
  };
}

// 提交家长账号验证码
export function verifyRegister({ callback, ...rest }) {
  return (dispatch) => {
    newVerify(rest)
      .then((ret) => {
        dispatch({ type: "SET_REGISTER_STATUS", payload: 'VERIFY' });
        callback();
      })
      .catch((error) => callback(error));
  };
}

// 新增子女订阅
export function addSubscribe({ ...rest }) {
  return (dispatch) => {
    dispatch({ type: "ADD_PAY_SUBSCRIBE", payload: rest });
  };
}
