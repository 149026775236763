import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

import intl from "react-intl-universal";
import { Textfit } from 'react-textfit';


import config from "../route";

import user_API from "components/services/userService";

import WithSubMenuConfig from "components/common/HOC/WithSubMenuConfig";
import Logout from "components/common/Logout";

import styles from "assets/css/PersonalCenter.module.scss";

const ProfileMenu = (props) => {
  const { menuConfig } = props; // 通过 WithSubMenuConfig 传过来的
  const { operation } = useParams();

  const {
    route: { locationUrl },
    translations
  } = useSelector((state) => state);

  const lang = (value) => {
    return translations.initDone && intl.get(value);
  }

  return (
    <div className={styles.profileMenu}>
      <ul className={`${styles.menuList} ${styles.card}`}>
        {menuConfig.map((item) => (
          <li key={item.icon}>
            <Link to={`${locationUrl}${item.url}`} target={item.target || '_self'}>
              <img src={require(`assets/image/personal/menu/${item.icon}.png`)} alt={item.icon} />
              <Textfit
                mode="single"
                forceSingleModeWidth={false}
                min={10}
                max={18}
                style={{ color: operation === item.icon ? 'rgba(63, 181, 242, 1)' : 'rgba(68, 68, 68, 1)' }}
                className={styles.label}
              >
                {item.label}
              </Textfit>
            </Link>
          </li>
        ))}
        <li>
          <Logout
            style={{
              width: '100%',
              cursor: 'pointer'
            }}
          >
            <img src={require(`assets/image/personal/menu/logout.png`)} alt="logout" />
            <span
              style={{ color: 'rgba(68, 68, 68, 1)' }}
            >
              {lang('personalCenter.menu.logout')}
            </span>
          </Logout>
        </li>
      </ul>
    </div>
  );
};

export default ProfileMenu;