import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "antd";

import { useSelector } from "react-redux";

import styles from "assets/css/resource/schoolResourceMcNew.module.scss";

const serialNum = ["A", "B", "C", "D", "E", "F"];

// 切換語言文本
const switchValue = {
  zh: "CANTONESE",
  cn: "PUTONGHUA",
  english: "ENGLISH",
};

const McAudio = (props) => {
  const audioRef = useRef(new Audio());
  const { lang, data, videoShow, setVideoShow } = props;
  const language = lang === "cn" ? "zh" : lang
  const { location } = useSelector((state) => state.new_global);
  // 选中的答案
  const [selected, setSelected] = useState("");
  // 是否已经作答
  const [respondence, setRespondence] = useState(false);
  // 播放视频文件
  const [videoFile, setVideoFile] = useState("")

  useEffect(() => {
    return () => {
      setSelected("");
      setVideoShow(false);
      setRespondence(false);
      setVideoFile("");
      audioRef.current.src = null;
    };
  }, []);

  const question = data.item ? data.item[0] : [];

  // 播放对应语言的语音
  const playQuestionAudio = (_, language = lang) => {
    if (question.sound.length) {
      const voice = question.sound.find(
        (item) => item.vo_lang === switchValue[lang]
      );
      playAudio(voice.file);
    }
  };

  useEffect(() => {
    setVideoShow(false);
    setRespondence(false);
    setSelected("");
    playQuestionAudio();
    selectLangVideo()
  }, [lang]);
  const { answer } = question;

  // 播放多条音频 onended
  const audioOnended = (file) => {
    if (!file.length) {
      // 取消 onended 并显示 video
      setVideoShow(true);
      audioRef.current.onended = null;
    }
    setPlayAudio(file.shift());
  };

  // 播放音频文件判断
  const playAudio = (file) => {
    if (Array.isArray(file)) {
      audioRef.current.onended = () => audioOnended(file);
      setPlayAudio(file.shift());
    } else {
      setPlayAudio(file);
    }
  };

  // 播放音频
  const setPlayAudio = (file) => {
    audioRef.current.src = file;
    audioRef.current.play();
  };

  const handleSelect = (item) => () => {
    if (!respondence) {
      setSelected(item.id);

      // 播放指定 lang 语音
      if (item.sound.length) {
        const result = item.sound.find(
          (item) => item.vo_lang === switchValue[lang]
        );
        if (!!result) {
          playAudio(result.file ? result.file : "");
        }
      }
    }
  };

  // 是否是正确的答案
  const isCorrect = (answer || []).some(
    (_) => _.id === selected && _.is_correct === "Y"
  );

  const handleSubmit = () => {
    // 未选择或未作答，则可以点击
    if (!selected || respondence) return
    const Lang = lang === "english" ? "ENGLISH" : lang
    if (isCorrect) {
      // 正确的语音
      playAudio([
        require("assets/audio/resource/mc/correct-effect.mp3"),
        require(`assets/audio/resource/mc/${Lang}/correct.mp3`),
      ]);
    } else {
      // 错误的语音
      const result =
        answer
          .find((item) => item.is_correct === "Y")
          .sound.find((item) => item.vo_lang === switchValue[lang]) || {};

      playAudio([
        require("assets/audio/resource/mc/wrong-effect.mp3"),
        require(`assets/audio/resource/mc/${Lang}/wrong.mp3`),
        require(`assets/audio/resource/mc/${Lang}/answer.mp3`),
        result.file ? result.file : "",
      ]);
    }
    setRespondence(true);
  };

  // 答案边框颜色
  const answerBorder = (item) => {
    // 正确的 id
    const trueId = answer.find((item) => item.is_correct === "Y").id;

    // 是否已经选择
    if (respondence) {
      if (trueId === item.id) {
        return "5px solid #adff2f";
      } else if (item.id !== trueId && item.id === selected) {
        return "5px solid #ff0000";
      } else {
        return "5px solid #fff";
      }
    } else {
      return item.id === selected ? "5px solid #31a7ff" : "5px solid #fff";
    }
  };

  // 多语言 题目名称
  const renderName = (key) => {
    const question = data.item ? data.item[0] : [];
    if (data.lang) {
      const result = question.lang.find((item) => item.lang === lang);
      // hk mo tw 簡體中文時,要展示繁體字幕
      if (["hk", "mo", "tw"].includes(location) && lang === "cn") {
        return question.lang.find((item) => item.lang === "zh")[key];
      }
      return result[key];
    }
    return data[key];
  };

  // 选择相应语言的视频
  const selectLangVideo = () => {
    if (data.item && data.item[1].lang.length > 0) {
      let videoObj = null
      // // hk mo tw 簡體中文時,要展示繁體字幕
      if (["hk", "mo", "tw"].includes(location) && lang === "cn") {
        videoObj = data.item[1].lang.find((item) => item.lang === "zh" && item.vo_lang === "PUTONGHUA");
      } else {
        videoObj = data.item[1].lang.find((item) => item.lang === lang);
      }
      videoObj && setVideoFile(videoObj.file)
    }
  }

  return (
    <>
      {!videoShow && (
        <>
          <div className={styles.title_text_wrap}>
            <img
              src={require("assets/image/resource/mc/audio.png")}
              alt=""
              className={styles.title_play}
              onClick={playQuestionAudio}
              width="55"
              height="55"
            />
            <span className={styles.title_text}>{renderName("name")}</span>
          </div>

          <Row
            type="flex"
            justify="space-around"
            gutter={[10, 10]}
            className={styles.answer_content}
          >
            {answer.map((item, index) => {
              const imgFile = item.lang.find((item) => item.lang === lang);
              return (
                <Col key={item.id} xl={9} xs={24}>
                  <div
                    className={styles.answer_item}
                    onClick={handleSelect(item)}
                  >
                    <div className={styles.option_logo}>
                      <img
                        src={require(`assets/image/resource/mc/${serialNum[index]}.png`)}
                        alt=""
                        width="100%"
                      />
                    </div>
                    {respondence && selected === item.id && !isCorrect && (
                      <img
                        src={require("assets/image/resource/mc/wrong.webp")}
                        alt=""
                        className={styles.error}
                      />
                    )}
                    {respondence &&
                      answer.find((_) => _.is_correct === "Y").id ===
                      item.id && (
                        <img
                          src={require("assets/image/resource/mc/tick.webp")}
                          alt=""
                          className={styles.error}
                        />
                      )}
                    <img
                      src={!!imgFile ? imgFile.file : item.file}
                      alt="answer img"
                      className={styles.answer_img}
                      style={{
                        border: answerBorder(item),
                      }}
                    />
                  </div>
                </Col>
              );
            })}
            {/* 确认按钮 */}
            <div style={{ textAlign: "center", width: "100%", marginTop: "15px" }}>
              <img
                src={require(`assets/image/resource/mc/audio_img/confirm_${language}.png`)}
                onClick={handleSubmit}
                style={!!selected && !respondence ? { cursor: "pointer", filter: "grayscale(0%)" } : { cursor: "no-drop", filter: "grayscale(100%)" }}
                alt="confirm_btn"
              />
            </div>
          </Row>
        </>
      )}

      {!!videoShow && (
        <div className={styles.video}>
          <video
            width="100%"
            height="90%"
            src={videoFile}
            controls
            autoPlay
            onEnded={() => {
              // setVideoShow(false);
            }}
          />
          <div style={{ textAlign: "center" }}>
            <img
              src={require(`assets/image/resource/mc/audio_img/question_${language}.png`)}
              onClick={() => {
                setSelected("");
                setRespondence(false);
                setVideoShow(false);
              }}
              style={{ cursor: "pointer" }}
              alt="question_btn"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default McAudio;
