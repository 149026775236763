import React, { Component } from "react";
import {
  Drawer,
  Card,
  Avatar,
  Icon,
  Button,
  Modal,
  List,
  Divider,
  Statistic,
  Col
} from "antd";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import intl from "react-intl-universal";

import toast from "components/utils/toast";

import WithSubMenuConfig from "components/common/HOC/WithSubMenuConfig";
import DraggerImgUploading from "components/common/UploadingAvatar";
import Logout from "components/common/Logout";

import user_API from "components/services/userService";
import MediaQuery from "react-responsive";
import style from "assets/css/layout.module.scss";

// import Achieve from "../../Achieve";
// import Weekchart from "../../Weekchart";
// import ChangePassword from "../../ChangePassword";
// import authService from "components/services/authService";

// import FristLogin from "components/common/login/verify/firstLogin";
// import ParentEdit from "components/common/login/role/parentEdit";
// import TipsAddKid from "components/common/login/account/tipsAddKid";
// import AddKid from "components/common/login/account/addKid";
// import AddParent from "components/common/login/account/addParent";
// import UserEdit from "components/common/login/role/userEdit";
// import Register from "components/Register";
import NewChildren from "components/NewRegister/components/NewChildren";

import "assets/css/drawer.module.scss";
// import parentLoginScss from "assets/css/login/parentLogin.module.scss";

import icon3 from "assets/image/pageIcon3.png";
import icon6 from "assets/image/pageIcon6.png";

// import { MENU } from "config/personal.json";

const { Meta } = Card;
const confirm = Modal.confirm;

class MyDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routerLink: "",
      locationUrl: props.locationUrl,
      avatarUpload: false,
      visible: false,
      userEdit: false,
      viewState: {
        switchRole: false,
        switchClose: true,
        // switch user
        switchUser: false,
      },
      icon: [
        {
          // 历史
          src: icon3,
          link: "history",
        },
        {
          // 我的最爱
          src: icon6,
          link: "favorite",
        },
      ],
      isSwaping: false,
    };
  }

  parentLock = () => {
    const {
      route: { tag: routeTag },
    } = this.props;
    return user_API.getType() === "PARENT" && routeTag === "student";
  };

  async componentDidMount() {
    const { showUser, showUserManager } = this.props.new_global;
    this.setState({
      viewState: {
        ...this.state.viewState,
        switchRole: this.props.swapUser || showUser || false,
        swapUser: this.props.swapUser || showUserManager || showUser || false,
        swapUserVisible: this.props.swapUser || showUserManager || showUser || false,
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      user: prevUser,
      global: prevGlobal,
      swapUser: prevSwap,
      new_global: {
        showUser: prevShowUser,
        showUserManager: prevShowUM,
      },
    } = prevProps;

    const {
      viewState: { switchRole: prevSwitchRole },
    } = prevState;

    const {
      props: {
        user,
        user: { type, status },
        UPDATE_AUTH,
        swapUser,
        // global: { drawerVisible },
        new_global: {
          showUser,
          showUserManager
        }
      },
      state: {
        viewState: { switchRole },
      },
    } = this;

    // if (drawerVisible !== prevGlobal.drawerVisible && drawerVisible === false) {
    //   this.setState({ visible: false });
    // }
    //
    if (
      (prevSwap !== swapUser && !!swapUser) ||
      (prevShowUser !== showUser && !!showUser) ||
      (prevShowUM !== showUserManager && !!showUserManager)
    ) {
      this.showSlickModal();
    }

    if (prevUser !== user) {
      if (type === "PARENT") {
        UPDATE_AUTH({
          isParent: true,
          userState: status === "VERIFY" ? "verify" : "",
        });
      }
    }
    if (prevSwitchRole !== switchRole && switchRole === true)
      UPDATE_AUTH({ isParent: true });
  }

  //彈出框
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  //关闭用户抽屉
  onClose = () => {
    this.props.onClose();
    // this.destory();
  };

  UserOpen = () => {
    this.props.swapUserOpen();
  };

  // _Logout(title = "", okText = "", cancelText = "") {
  //   const { route } = this.props;
  //   confirm({
  //     title: title, //"你確定要登出嗎？",
  //     okText: okText, //"確認",
  //     cancelText: cancelText, //"取消",
  //     // content: 'Some descriptions',
  //     async onOk() {
  //       authService.logout().then((ret) => {
  //         window.location.href = `/${route.currentLocation}/${route.currentLanguage.url}`;
  //       });
  //     },
  //     onCancel() {
  //       // console.log('Cancel');
  //     },
  //   });
  // }
  //
  closeConfirm = (callback) => {
    const { new_auth: { pay } } = this.props;
    if (pay.length > 0) {
      confirm({
        title: this.lang('home.publicMsg.drawer.close_confirm'),//'Do you want to delete these items?',
        content: this.lang('home.publicMsg.drawer.close_content'),
        okText: this.lang('home.publicMsg.drawer.Btnconfirm'),
        cancelText: this.lang('home.publicMsg.drawer.Btncancel'),
        onOk() {
          callback()
        },
        onCancel() { },
      });
      return;
    }
    callback();
  }
  swapClose = () => {
    const { showUser, showUserManager } = this.props;
    showUserManager(false);
    showUser(false);
    this.setState({
      viewState: { ...this.state.viewState, swapUserVisible: false },
    });
    this.props.resetPayment();
    if (typeof this.props.swapUserOnClose === "function") {
      this.props.swapUserOnClose();
    }
    setTimeout((() => {
      this.setState({ viewState: { ...this.state.viewState, swapUser: false }, isSwaping: false });
    }).bind(this), 500);
  }

  /** 点击切换 展示 切换模态框 */
  showSlickModal = () => {
    // console.log(this.state.viewState.swapUser);
    this.setState({ viewState: { ...this.state.viewState, swapUser: true, swapUserVisible: true } })
    // const {
    //   // props: { UPDATE_AUTH },
    //   state: {
    //     viewState,
    //     viewState: { switchRole },
    //   },
    // } = this;
    // this.setState(
    //   { viewState: { ...viewState, switchRole: true } }
    //   // () => UPDATE_AUTH({ isLogin: true })
    // );
  };

  /** 点击关闭 关闭模态框 */
  setSwitchRole = (switchRole) => {
    const {
      props: { UPDATE_AUTH, translations },
      state: {
        viewState,
        viewState: { switchClose },
      },
    } = this;
    if (this.parentLock() && switchClose)
      return toast.createToast({
        msg:
          translations.initDone &&
          intl.get("home.publicMsg.drawer.PleaseSelectChild"),
        position: "top-left",
        onOpen: () =>
          this.setState({
            viewState: { ...viewState, switchClose: !switchClose },
          }),
        onClose: () =>
          this.setState({ viewState: { ...viewState, switchClose } }),
      });

    if (
      !switchRole &&
      !!this.props.swapUser &&
      typeof this.props.swapUserOnClose === "function"
    ) {
      this.props.swapUserOnClose();
    }
    this.setState({ viewState: { ...viewState, switchRole } }, () =>
      UPDATE_AUTH({ userState: "" })
    );
  };

  /** 渲染 modal el */
  // modalElRender = () => {
  //   const {
  //     props: {
  //       auth: { userState },
  //     },
  //   } = this;
  //
  //   const view = new Map()
  //     .set("tips", <TipsAddKid />)
  //     .set("addKid", <AddKid />)
  //     .set("addParent", <AddParent $location={this.props.$location} />)
  //     .set("kidAccount", <FristLogin />)
  //     .set("verify", <Register result="1" />);
  //   return view.get(userState) || <ParentEdit />;
  // };

  lang = (value) => {
    return this.props.translations.initDone && intl.get(value);
  };

  render() {
    const {
      // modalElRender,
      props: {
        visible,
        locationUrl,
        user,
        global: { drawerVisible },
        auth: { userState },
        translations,
        initUser,
        route: { currentLanguage: $language },
        route,
        user: { img: userAvatar, id: uID, type },
        location
        // UPDATE_AUTH
      },
      state: {
        viewState: { switchRole, swapUser, swapUserVisible },
        viewState
      },
    } = this;
    const intranet = !!user.intranet ? user.intranet : null;

    const isRegister = location.pathname.includes("/register");

    const Coin = () => (
      <Link
        to={`/${route.currentLocation}/${route.currentLanguage.url}/user/coin`}
        style={{ display: "flex", alignItems: "center" }}
      >
        <img
          src={require("assets/image/personal/big_coin.png")}
          alt="coin"
          style={{ width: 25, height: 25 }}
        />
        <Statistic
          value={this.props.user.coin < 9999 ? this.props.user.coin : 9999}
          groupSeparator=","
          suffix={this.props.user.coin > 9999 ? "+" : undefined}
          valueStyle={{ fontSize: 16, color: "#fff" }}
        />
      </Link>
    );

    const LinkAdapter =
      !uID || user_API.getType() === "STAFF"
        ? Link
        : (props) => <React.Fragment>{props.children}</React.Fragment>;

    const AdapterBtn = () => (
      <LinkAdapter
        to={
          !uID || user_API.getType() === "STAFF"
            ? `/${route.currentLocation}/${route.currentLanguage.url}/${route.tag === "parent" ? "" : "parent"
            }`
            : "#"
        }
        style={{
          width: '100%'
        }}
      >
        <div
          className={style.btnWidth}
          style={{
            cursor: "pointer",
            display: "inline-block",
            width: '100%'
          }}
          onClick={() => {
            if (uID && user_API.getType() !== "STAFF") {
              this.setState({ visible: true, swapUser: true });
            }
          }}
        >
          {(!uID || user_API.getType() === "STAFF"
            ? route.tag === "parent"
            : type === "PARENT") && (
              <div style={{ border: "none", padding: 0, display: 'flex', alignItems: 'center' }}>
                <img
                  src={require(`assets/image/button/kid_icon.png`)}
                  alt=""
                  style={{ width: 26, height: 26, marginRight: 16 }}
                />
                <span style={{ fontSize: 16, color: 'rgb(68, 68, 68)' }}>{this.lang("home.publicMsg.header.child")}</span>
              </div>
            )}
          {(!uID || user_API.getType() === "STAFF"
            ? route.tag !== "parent"
            : type !== "PARENT") && (
              <div style={{ border: "none", padding: 0, display: 'flex', alignItems: 'center' }}>
                <img
                  src={require(`assets/image/button/parent_icon.png`)}
                  alt=""
                  style={{ width: 26, height: 26, marginRight: 16 }}
                />
                <span style={{ fontSize: 16, color: 'rgb(68, 68, 68)' }}>{this.lang("home.publicMsg.header.parents")}</span>
              </div>
            )}
        </div>
      </LinkAdapter>
    );

    return (
      //抽屉
      <>
        <Drawer
          width={320}
          className="drawer_container"
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={this.parentLock() || visible || drawerVisible}
          bodyStyle={{ padding: 0 }}
          maskStyle={{
            background: "rgba(255, 255, 255, 0.45)",
          }}
        >
          <Modal
            centered
            title={
              <h3>
                {translations.initDone && intl.get("home.publicMsg.avatar.tip")}
              </h3>
            }
            bodyStyle={{ backgroundColor: "#fff" }}
            visible={this.state.avatarUpload}
            onCancel={() => {
              this.setState({ avatarUpload: false });
            }}
            footer={null}
            maskClosable={false}
          >
            <DraggerImgUploading
              onCancel={() => {
                this.setState({ avatarUpload: false });
              }}
            />
          </Modal>
          <Card
            className="drawer_header"
            bordered={false}
            bodyStyle={{ backgroundColor: "#41c6e3" }}
          >
            <Meta
              className="meta"
              avatar={
                <div className="avatar_container">
                  <Avatar
                    size={100}
                    src={userAvatar + "?t=" + Math.random() || null}
                    icon={!!userAvatar ? "" : "user"}
                    style={{
                      backgroundColor: !userAvatar ? "#efefef" : "white",
                      color: "black",
                    }}
                  />
                  <div
                    className="avatar_button"
                    onClick={() => {
                      this.setState({ avatarUpload: true });
                    }}
                  >
                    <Icon type="camera" />
                    {translations.initDone &&
                      intl.get("course_1.content.edit.logo")}
                  </div>
                  <Button className="SlickModal"
                    onClick={this.showSlickModal}>
                    <Icon type="sync" />
                  </Button>
                </div>
              }
              title={<span style={{ fontWeight: "bold" }}>{user.full_name}</span>}
              description={
                <React.Fragment>
                  <p>
                    {!!intranet && intranet.school["name_" + $language.value]}
                  </p>
                  <p>
                    {user.type === "STUDENT" && !!intranet && (
                      <Button type="primary" size="small" shape="round">{`${intranet.grade["name_" + $language.value]
                        }${intranet.class["name_" + $language.value]}`}</Button>
                    )}
                  </p>
                  {/* 金幣 */}
                  {!!uID && !isRegister && (
                    <Col>
                      <Coin />
                    </Col>
                  )}
                </React.Fragment>
              }
            />
            <br />
          </Card>
          <List
            itemLayout="horizontal"
            id="drawer"
            data-tut="drawer"
            style={{
              padding: "5px 30px 0px",
            }}
          >
            <List.Item>
              {!uID || user_API.getType() === "STAFF" ? (
                <AdapterBtn />
              ) : (
                <div style={{ border: "none", padding: 0, display: 'flex', alignItems: 'center' }} onClick={() => this.UserOpen()}>
                  <img
                    src={require(`assets/image/button/parent_icon.png`)}
                    alt=""
                    style={{ width: 26, height: 26, marginRight: 16 }}
                  />
                  <span style={{ fontSize: 16, color: 'rgb(68, 68, 68)' }}>{this.lang("home.publicMsg.header.switchUser")}</span>
                </div>
              )}
            </List.Item>
            <Divider style={{ margin: 0 }} />
          </List>
          <List
            itemLayout="horizontal"
            id="drawer"
            dataSource={this.props.menuConfig}
            data-tut="drawer"
            onClick={() => (window.document.cookie = "guide=true")}
            renderItem={(item) => (
              <List.Item>
                <Link
                  to={`${locationUrl}${item.url}`}
                  target={item.target || "_self"}
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "rgba(68, 68, 68, 1)",
                  }}
                  onClick={this.onClose}
                >
                  <div>
                    <img
                      src={require(`assets/image/personal/menu/${item.icon}.png`)}
                      alt={item.icon}
                      style={{ width: 26, height: 26, marginRight: 16 }}
                    />
                    <span style={{ fontSize: 16 }}>{item.label}</span>
                  </div>
                  <Icon type="right" />
                </Link>
              </List.Item>
            )}
            style={{
              padding: "5px 30px 30px",
            }}
          >
            <Divider style={{ margin: 0 }} />
            {/* 登出 */}
            <List.Item>
              <Logout
                style={{
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <img
                  src={require(`assets/image/personal/menu/logout.png`)}
                  alt="logout"
                  style={{ width: 26, height: 26, marginRight: 16 }}
                />
                <span
                  style={{
                    color: "rgba(68, 68, 68, 1)",
                    fontSize: 16,
                  }}
                >
                  {this.lang("personalCenter.menu.logout")}
                </span>
              </Logout>
            </List.Item>
          </List>
        </Drawer>
        {
          !!swapUser &&
          (
            <Drawer
              title={(
                <div style={{ maxWidth: 1200, margin: "0 auto" }}>
                  <img src={require(`assets/image/logo.png`)} />
                </div>
              )}
              placement={"top"}
              closable={!this.state.isSwaping}
              onClose={() => this.closeConfirm(this.swapClose.bind(this))}
              visible={this.state.viewState.swapUserVisible}
              key={"user-swap-drawer"}
              width={"100%"}
              height={"100%"}
            >
              <NewChildren
                skipPay={this.swapClose.bind(this)}
                showFamily={true}
                closeConfirm={this.closeConfirm}
                exitCallback={this.swapClose}
                progressCallback={(isProgress) => this.setState({ isSwaping: isProgress })}
              />
            </Drawer>
          )
        }
      </>
    );
  }
}

function mapStateToProps({ route, user, auth, translations, global, new_global, new_auth }) {
  return {
    route,
    user,
    auth,
    translations,
    global,
    new_global,
    new_auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showUserManager: payload => dispatch({ type: "SHOW_USER_MANAGER", payload }),
    showUser: (payload) => dispatch({ type: "SET_SHOW_USER", payload }),
    UPDATE_AUTH: (payload) => dispatch({ type: "UPDATE_AUTH", payload }),
    initUser: (payload) => dispatch({ type: "INIT", payload }),
    resetPayment: (payload) => dispatch({ type: "RESET_PAY_SUBSCRIBE", payload }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WithSubMenuConfig(MyDrawer)));
