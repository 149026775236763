import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";
import { Button, Modal, Row, Col } from "antd";
import Love from "components/common/LoveNum";

import userJoinLog from "components/services/userJoinLogService";

import styles from "assets/css/PersonalCenter.module.scss";

const Relationship = () => {
  const [data, setData] = useState([]);
  const [type, setType] = useState("WEEK");
  const [visible, setVisible] = useState(false);

  const { initDone } = useSelector((state) => state.translations);

  const lang = (value) => {
    return initDone && intl.get("achievements.content." + value);
  };

  useEffect(() => {
    userJoinLog
      .getChart()
      .then((ret) => {
        setData(ret);
      })
      .catch((_msg) => {
        console.log(_msg);
      });
  }, []);

  const num = useMemo(() => {
    return (data.find((_) => _.type === type) || {}).point || 0;
  }, [type, data]);

  return (
    <div className={styles.relationship}>
      <h2>
        {initDone &&
          intl.get("personalCenter.personalInfo.parentChildRelationship")}
      </h2>
      <div className={styles.heart_box}>{Love(num)}</div>
      <div className={styles.btn_box}>
        <Button
          type="primary"
          className={type === "WEEK" ? styles.btn_active : styles.btn_primary}
          onClick={() => setType("WEEK")}
        >
          7 {initDone && intl.get("personalCenter.personalInfo.day")}
        </Button>
        <Button
          type="primary"
          className={type === "MONTH" ? styles.btn_active : styles.btn_primary}
          onClick={() => setType("MONTH")}
        >
          30 {initDone && intl.get("personalCenter.personalInfo.day")}
        </Button>
      </div>
      <Button
        className={styles.question_icon}
        shape="circle"
        icon="question"
        onClick={() => setVisible(true)}
      />
      <img
        src={require("assets/image/personal/personal/person.png")}
        alt="person"
        className={styles.person}
      />
      <Modal
        visible={visible}
        footer={null}
        closable={true}
        onCancel={() => setVisible(false)}
        style={{ maxWidth: 500 }}
        bodyStyle={{
          background: "#fa96a3",
          color: "#fff",
          fontSize: "16px",
          lineHeight: "40px",
        }}
      >
        <Row>
          <Col sm={8}>{Love(0)}</Col>
          <Col sm={16}>
            <p>{lang("nowatch")}</p>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>{Love(1)}</Col>
          <Col sm={16}>
            <p>{lang("lesshalfwatch")}</p>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>{Love(2)}</Col>
          <Col sm={16}>
            <p>{lang("halfmorewatch")}</p>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>{Love(3)}</Col>
          <Col sm={16}>
            <p>{lang("fullwatch")}</p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default Relationship;
