const defaultState = {
  result: null,
  info: { course: [] }
};

export const new_redemption = (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'REDEEM_SUCCESS':
      return { ...state, result: payload };
    case 'CHECK_REDEEM_SUCCESS':
      return { ...state, info: payload };
    case 'CHECK_REDEEM_FAILED':
      return { ...state, info: { ...state.info, errorCode: payload } };
    default:
      return state;
  }
};
