import React from 'react';
import { useSelector } from 'react-redux'; 
import intl from "react-intl-universal";
import { useMediaQuery } from 'react-responsive';
import { Row, Col } from 'antd';

import Weekchart from "components/Weekchart";

import Relationship from "./PersonalPage/Relationship";
import UseTime from "./PersonalPage/UseTime";

import styles from 'assets/css/PersonalCenter.module.scss';

const StatisticsPage = () => {
  const { translations } = useSelector(state => state);

  const isMobile = useMediaQuery({ query: '(max-width: 575px)' })

  const lang = (value) => {
    return translations.initDone && intl.get("home.publicMsg.drawer." + value);
  }

  return (
    <div>
      <Row type="flex" gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col md={12} xs={24}>
          <Relationship />
        </Col>
        <Col md={12} xs={24}>
          <UseTime />
        </Col>
      </Row>
      <div className={`${styles.statisticsPage} ${styles.card}`}>
        <Weekchart
          title={lang("charttitle")}
          min={lang("chartmin")}
          day={lang("chartweek")}
          height={isMobile ? 300 : 400}
        />
      </div>
    </div>
  )
}

export default StatisticsPage;