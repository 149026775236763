import React, { useState, useEffect } from 'react';
import { Modal, Spin } from 'antd';

import main from "components/services/mainService";

const SchoolResourceDownload = (props) => {
  const { visible, onCancel, refId } = props;

  const [ loading, setLoading ] = useState(false);
  const [ data, setData ] = useState({ item: [] });

  useEffect(() => {
    if(visible) {
      setLoading(true);
      main.getFullInfo(refId).then(ret => {
        setData(ret);
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 200
      }}
    >
      {loading ? (
        <Spin  />
      ) : (
        <div>
          {data.item.map((item, index) => (
            <div key={index}>
              <a
                href={item.file}
                rel="noopener noreferrer"
                target="_blank"
                onClick={onCancel}
              >
                <h3>{index + 1} 文件已準備好，立即下載！</h3>
              </a>
            </div>
          ))}
        </div>
      )}
    </Modal>
  )
}

export default SchoolResourceDownload;