import { client } from "./apiService";

const url = `${process.env.REACT_APP_API_URL_RESOURCE}/event`;

export async function getList(form) {
  return await client.post({
    url: `${url}/get_list`,
    form: {
      limit: 9999,
      ...form
    },
  });
}

// 新增
export async function add(form) {
  return await client.post({
    url: `${url}/add`,
    form,
  });
}

// 更新
export async function update(form) {
  return await client.post({
    url: `${url}/update`,
    form,
  });
}

// 刪除
export async function eventDelete(id, is_update_parent) {
  return await client.post({
    url: `${url}/delete`,
    form: {
      id,
      is_update_parent
    },
  });
}

// 獲取詳情
export async function get(id) {
  return await client.post({
    url: `${url}/get`,
    form: {
      id,
    },
  });
}

export default {
  add,
  getList,
  update,
  get,
  eventDelete
};
