import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import intl from "react-intl-universal";
import { Calendar, momentLocalizer, Navigate } from "react-big-calendar";
import { store } from "components/store";

import styles from "assets/css/ZoomCalendar.module.scss";

import { Col, Row, Card, Icon } from "antd";

import moment from "moment";

import Editor from "./Editor";
import Info from "./Info";

import user from "components/services/userService";

import { get_list } from "components/actions/event_action";

import myDate from "../utils/dates";

import * as dates from "date-arithmetic";

import zoom from "assets/image/zoomCalendar/zoom.png";

const localizer = momentLocalizer(moment);

class MyWeek extends React.Component {
  static navigate(date, action) {
    switch (action) {
      case Navigate.PREVIOUS:
        return dates.add(date, -7, "day");

      case Navigate.NEXT:
        return dates.add(date, 7, "day");

      default:
        return date;
    }
  }

  static title = (date) => {
    return `My awesome week: ${date.toLocaleDateString()}`;
  };

  state = {
    id: null,
    visible: false,
  };

  componentDidMount() {
    const { date } = this.props;
    const monday = myDate.getMondayTime(date);
    const sunday = myDate.getSundayTime(date);

    store.dispatch(get_list({ start: monday, end: sunday }));
  }

  onSelect = (item) => {
    if (item.id) {
      this.setState({
        id: item.id,
        visible: true,
      });
    }
  };

  render() {
    const { id, visible } = this.state;
    const { date, events } = this.props;

    const monday = myDate.getMondayTime(date);
    const sunday = myDate.getSundayTime(date);

    // 过滤后的events
    const filterEvents = events.filter(
      (item) =>
        myDate.getBool(monday, item.start) && myDate.getBool(item.start, sunday)
    );

    filterEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

    return (
      <Row type="flex" gutter={[20, 20]} style={{ padding: 10 }}>
        {filterEvents.map((item) => (
          <Col lg={6} md={8} sm={12} xs={24} key={item.id}>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => this.onSelect(item)}
            >
              <h2>{myDate.getMonthDay(item.start)}</h2>
              <h6>
                {myDate.getTimeAlpha(item.start, "HH:mm A")} -
                {myDate.getTimeAlpha(item.end, "HH:mm A")}
              </h6>
              <EventComponent event={item} />
            </Card>
          </Col>
        ))}
        <Col>
          <Modal
            id={id}
            visible={visible}
            onCancel={() => this.setState({ visible: false })}
          />
        </Col>
      </Row>
    );
  }
}

const Modal = (props) => {
  return user.getType() === "STAFF" ? (
    <Editor {...props} />
  ) : (
    <Info {...props} />
  );
};

const EventComponent = ({ event }) => {
  const {
    translations: { initDone },
  } = useSelector((state) => state);

  const lang = (value) => {
    return initDone && intl.get("zoomCalendar." + value);
  };
  return (
    <div style={{ position: "relative" }}>
      <span style={{ marginRight: 10 }}>
        {event.title}
      </span>
      {!!(event.third_party || []).length &&
        event.third_party[0].third_party === "ZOOM" && event.status === "DONE" && (
          <a
            href={event.third_party[0].join_url}
            target="blank"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              style={{ width: 25, verticalAlign: "text-top" }}
              src={zoom}
              alt="zoom"
            />
          </a>
        )}
      {event.status !== "DONE" && (
        <Icon type="loading" />
      )}
    </div>
  );
};

const MyCalendar = (props) => {
  const {
    translations: { initDone },
  } = useSelector((state) => state);

  const lang = (value) => {
    return initDone && intl.get("zoomCalendar." + value);
  };

  const { eventList } = useSelector((state) => state.event);

  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [visible, setVisible] = useState(null);
  const [view, setView] = useState("month");

  const [monthDate, setMonthDate] = useState(null);

  const onSelect = (item) => {
    if (item.id) {
      setId(item.id);
      setVisible(true);
    }
  };

  // 拉取event数据
  useEffect(() => {
    dispatch(
      get_list({ start: myDate.getStartMonth(), end: myDate.getEndMonth() })
    );
    document.documentElement.clientWidth <= 600 && setView("agenda");
  }, []);
  // 如果數據中有 status 不爲 DONE 的，每隔五秒刷新一次數據
  useEffect(() => {
    let timer = null;
    if(eventList.rows.some(_ => _.status !== 'DONE')) {
      timer = setTimeout(() => {
        dispatch(
          get_list({ start: myDate.getStartMonth(), end: myDate.getEndMonth() })
        );
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [eventList])

  return (
    <div>
      <Calendar
        className={styles.root}
        localizer={localizer}
        events={eventList.rows}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 1500 }}
        view={view}
        views={{ month: true, week: true, day: true, agenda: MyWeek }}
        allDayAccessor="all_day"
        // dayLayoutAlgorithm="no-overlap"
        // 切换视图时调用
        onView={(rest) => {
          setView(rest);
        }}
        messages={{
          today: lang("btn_today"),
          previous: "<",
          next: ">",
          month: lang("btn_month"),
          week: lang("btn_week"),
          day: lang("btn_day"),
          agenda: lang("btn_agenda"),
        }}
        // 当切换日期时调用
        onRangeChange={(time, view) => {
          console.log(time, view);

          setMonthDate([time.start, time.end]);

          if (Array.isArray(time)) {
            dispatch(
              get_list({
                start: myDate.getTime(time[0]),
                end: myDate.getTime(time[time.length - 1]),
              })
            );
            return;
          }
          const { start, end } = time;

          dispatch(
            get_list({
              start: myDate.getTime(start),
              end: myDate.getTime(end),
            })
          );
        }}
        // onSelectSlot={(...rest) => console.log(rest)}
        // 选择日期上的时间时调用
        onSelectEvent={onSelect}
        // 日期更改时触发
        onNavigate={(...rest) => console.log(rest)}
        components={{
          // toolbar: TestingComponent,
          month: {
            event: EventComponent,
          },
          week: {
            event: EventComponent,
          },
          day: {
            event: EventComponent,
          },
        }}
      />
      <Modal
        id={id}
        visible={visible}
        onCancel={() => setVisible(false)}
        monthDate={monthDate}
      />
    </div>
  );
};

export default MyCalendar;
