import { addChildren } from "../../api/family_api";

// 新增子女
export function putChildren({ callback, ...rest }) {
  return (dispatch) => {
    addChildren(rest)
      .then((ret) => {
        dispatch({ type: "SAVE_ID", payload: ret });
        callback();
      })
      .catch(error => callback(error));
  };
}

export function setChildren(id) {
  return (dispatch) => {
    dispatch({ type: "SET_ID", payload: id });
  };
}
