import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Row, Col, Icon } from "antd";
import intl from "react-intl-universal";

import { SUPPORT_LOCALES } from "config/locale.json";

// import IntlPolyfill from "intl";

const { Option } = Select;

let $$lastLoad = {};
let $$langCache = {};

const locations = [...Object.keys(SUPPORT_LOCALES)];

// global.Intl = IntlPolyfill;

const multiLanguage = () => {
  const [languageList, setLanguageList] = useState([]);

  // const [initDone, setInitDone] = useState(false);

  // const [show, setShow] = useState(true);

  const route = useSelector((state) => state.route);

  const translations = useSelector((state) => state.translations);

  const dispatch = useDispatch();

  const { currentLocation, currentLanguage } = route;

  useEffect(() => {
    if (currentLocation) {
      setLanguageList(SUPPORT_LOCALES[currentLocation].lang);
    }

    // dispatch({ type: "updateTranslations", initDone });
    if (!!currentLanguage) return;

    try {
      httptranslations({
        currentLocale: "zh",
        fileName: translations.fileName || "home",
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (!currentLocation) return;

    setLanguageList(SUPPORT_LOCALES[currentLocation].lang);

    // 初始化 当前地区 可选语言

    if (!!translations.fileName) {
      // 當舊 props 更新時 調用 驗證語言種類function
      if (
        checkFileLoaded(
          translations.fileName,
          route.currentLanguage.value,
          true
        )
      ) {
        httptranslations({
          fileName: translations.fileName,
          currentLocale: route.currentLanguage.value,
        });
      }
    }
  }, [route]);

  // useEffect(() => {
  //   dispatch({ type: "updateTranslations", initDone: true });
  // }, [initDone]);

  /** 获取 多语言文本 异步请求
   * params
   *  currentLocale  當前選用的語言 string
   *
   *  fileName 需要在 `public/locales/${language}/${fileName}` 查找的對應文件名稱
   */
  const httptranslations = async ({ currentLocale, fileName }) => {
    // const { updateTranslations } = .props;
    try {
      const data = [];

      checkFileLoaded(fileName, currentLocale).map((_file) => {
        $$langCache[currentLocale][
          _file
        ] = require(`locales/${currentLocale}/${_file}.json`);
        data.push($$langCache[currentLocale][_file]);
        return null;
      });

      //        Object.keys(this.$$langCache[currentLocale]).map(_file => {
      //            data.push(this.$$langCache[currentLocale][_file]);
      //        });

      let _ret = data.reduce((_ret, cur) => {
        _ret = mergeDeep(_ret, cur);
        return _ret;
      }, $$lastLoad);

      $$lastLoad = _ret;

      // console.log("Translations current locale data", _ret);
      // 调用 init 进行多语言渲染
      intl.init({
        currentLocale,
        locales: { [currentLocale]: _ret },
      });
      // .then(() => setInitDone(true));
      // .then(() => {
      //   this.setState({ initDone: true }, () => {
      //     dispatch({ type: "updateTranslations", initDone });
      //   });
      // });
    } catch (error) {
      console.error(error);
      // toast.createToast({
      //   type: "error",
      //   msg: "加載出現了錯誤,您可以稍後再試.",
      //   position: "top-right",
      // });
    }
  };

  const checkFileLoaded = (
    i_file = "",
    i_locale = "zh",
    i_retBoolean = false
  ) => {
    let _fileList = Array.isArray(i_file) ? i_file : [i_file];
    if (!$$langCache[i_locale]) {
      $$langCache[i_locale] = {};
    }
    let ret = _fileList.filter(
      (_file) => !(Object.keys($$langCache[i_locale]).indexOf(_file) > -1)
    );
    return !!i_retBoolean ? ret.length > 0 : ret;
  };

  const isObject = (item) => {
    return item && typeof item === "object" && !Array.isArray(item);
  };

  const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return mergeDeep(target, ...sources);
  };

  /** 点击切换语言 */
  const handleClick = (value) => {
    if (currentLanguage.value !== value) {
      window.location = `/${route.currentLocation}/${value}${
        route.realUrl ? route.realUrl : ""
      }`;
    }
  };

  // 切换地区
  const changeLocal = (value) => {
    console.log(currentLocation, value);
    if (currentLocation !== value) {
      var goCountry = !!SUPPORT_LOCALES[value]
        ? SUPPORT_LOCALES[value]
        : SUPPORT_LOCALES["hk"];
      window.location = goCountry.url;
    }
  };

  return (
    // <div style={{ width: "100%", backgroundColor: "#666", color: "#fff" }}>
    <Row
      style={{
        // maxWidth: 1200,
        width: "100%",
        margin: "0 auto",
        padding: "5px 0",
        position: "relative",
      }}
      type="flex"
      justify="center"
      gutter={16}
    >
      <Col>
        {translations.initDone &&
          intl.get("home.publicMsg.header.languageList.locationLanguage")}
        :
        <Select
          defaultValue={
            route.currentLanguage
              ? route.currentLanguage.url
              : SUPPORT_LOCALES[process.env.REACT_APP_USER_LOCATION].lang.url
          }
          style={{ width: 120, marginLeft: 8 }}
          onChange={handleClick}
        >
          {languageList.map((item) => (
            <Option key={item.url}>{item.name}</Option>
          ))}
        </Select>
      </Col>
      {!["cn", "tw"].includes(currentLocation) && (
        <Col>
          {translations.initDone &&
            intl.get("home.publicMsg.header.languageList.location")}
          :
          <Select
            defaultValue={
              route.currentLocation || process.env.REACT_APP_USER_LOCATION
            }
            style={{ width: 120, marginLeft: 8 }}
            onChange={changeLocal}
          >
            {locations.map((value) => {
              if (value !== "tw") {
                return (
                  <Option key={value} value={value}>
                    {translations.initDone &&
                      intl.get(
                        `home.publicMsg.header.languageList.locations.${value}`
                      )}
                  </Option>
                );
              }
            })}
          </Select>
        </Col>
      )}
      {/* <Col style={{ position: "absolute", right: 4 }}>
          <Icon
            type="close"
            onClick={() => setShow(false)}
            style={{ cursor: "pointer" }}
          />
        </Col> */}
    </Row>
    // </div>
  );
};

export default multiLanguage;
