import React from "react";
import { useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";

import { Tabs } from "antd";

import config from "../route";

import WithSubMenuConfig from "components/common/HOC/WithSubMenuConfig";

import user_API from "components/services/userService";

const { TabPane } = Tabs;

// 移動端menu
const MobileMenu = (props) => {
  const { menuConfig } = props;
  const { operation } = useParams();
  const {
    route: { locationUrl },
  } = useSelector((state) => state);

  return (
    <Tabs defaultActiveKey={operation} tabPosition="top">
      {menuConfig.map((item) => (
        <TabPane
          key={item.icon}
          tab={
            <Link
              to={`${locationUrl}${item.url}`}
              target={item.target || '_self'}
              style={{
                fontSize: 18,
                color: operation === item.icon ? "#67c7f8" : "rgb(0 0 0 / 65%)",
              }}
            >
              {item.label}
            </Link>
          }
        ></TabPane>
      ))}
    </Tabs>
  );
};

export default MobileMenu;
