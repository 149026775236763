import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from "react-intl-universal";
import moment from 'moment';
import { Modal, Descriptions, Spin, Icon, Button } from 'antd';

import { getInfoAction } from "components/actions/event_action";

import zoom from "assets/image/zoomCalendar/zoom.png";

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const Info = (props) => {
  const dispatch = useDispatch();

  const { id, visible, onCancel } = props;

  const { translations: { initDone }, event: { info } } = useSelector(state => state);

  const [ loading, setLoading ] = useState(false);

  const now = moment().format("YYYY-MM-DD HH:mm:ss");

  const lang = (value) => {
    return initDone && intl.get("zoomCalendar." + value);
  }

  useEffect(() => {
    if(id && visible) {
      setLoading(true);
      dispatch(getInfoAction(id, () => setLoading(false)));
    }
  }, [id, visible])

  // 如果數據中的 status 不爲 DONE，每隔五秒刷新一次數據
  useEffect(() => {
    let timer = null;
    if(info.status !== 'DONE' && id && visible) {
      timer = setTimeout(() => {
        dispatch(getInfoAction(id))
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [info])

  return (
    <Modal 
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Spin indicator={antIcon} spinning={loading} >
        <Descriptions 
          bordered
          column={1}
        >
          <Descriptions.Item label={lang("form_name")}>{info.title}</Descriptions.Item>
          <Descriptions.Item label={lang("form_description")}>{info.description}</Descriptions.Item>
          <Descriptions.Item label={lang("form_date")}>{(info.start || '').split(' ')[0]}</Descriptions.Item>
          <Descriptions.Item label={lang("form_start")}>{(info.start || '').split(' ')[1]}</Descriptions.Item>
          <Descriptions.Item label={lang("form_end")}>{(info.end || '').split(' ')[1]}</Descriptions.Item>
        </Descriptions>
        {!!(info.third_party || []).find(_ => _.third_party === 'ZOOM') && (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Button 
              type="primary" 
              href={info.third_party.find(_ => _.third_party === 'ZOOM').join_url} 
              target="blank"
              disabled={!(now >= info.start && now <= info.end)}
              loading={info.status !== 'DONE'}
            >
              <img style={{ width: 25, marginRight: 10 }} src={zoom} alt="zoom"/> 
              {lang("zoom")}
            </Button>
          </div>
        )}
      </Spin>
    </Modal>
  )
}

export default Info;