import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { Drawer, Row, Col, Spin } from "antd";
import intl from "react-intl-universal";

import main from "components/services/mainService";

import Video from "components/common/Video";
import Multi from "./Multi";
import Vocab from "./Vocab";
import Document from "./Document";
import SelectLang from "./SelectLang";

import styles from "assets/css/DrWongModal.module.scss";

import background from "assets/image/drWong/k1bg.png";

const support_lang = [
  { lang: "zh", vo_lang: "cantonese" },
  { lang: "english", vo_lang: "english" },
];

const DrWongModal = (props) => {
  const { section } = useParams();
  const {
    location: { pathname },
    push,
  } = useHistory();

  const translate = (value, params) => intl.get(`resource.${value}`, params);

  const { currentLanguage } = useSelector((state) => state.route);

  const { id, visible, onCancel } = props;

  const [data, setData] = useState({ item: [], lang: [] }); // api 数据
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("main"); // 切换视图用的 type
  const [vocabVisible, setVocabVisible] = useState(false); // 词汇弹框开关
  const [language, setLanguage] = useState({}); // 页面语言

  const getData = useCallback(
    (type) => data.item.filter((_) => _.item_type === type),
    [data]
  );
  const getSection = useCallback(
    (section) => data.item.find((_) => _.section === section) || {},
    [data]
  );
  // 获取视频上的资源
  const getVideo = useCallback(
    (section) => {
      return (
        (getSection(section).lang || []).find((_) => {
          return (
            _.lang === language.lang &&
            _.vo_lang.toLowerCase() === language.vo_lang
          );
        }) || {}
      );
    },
    [getSection, language]
  );
  // 获取 “考考你” 上的公仔图片
  const getIcon = useMemo(
    () => data.item.filter((_) => _.section === "ICON"),
    [data]
  );

  const init = () => {
    setType("main");
    setLoading(false);
    setVocabVisible(false);
  };

  useEffect(() => {
    if (!!section && section !== "answer" && section !== "guide") {
      push("/404");
    }
  }, [section]);

  useEffect(() => {
    setLanguage(support_lang.find((_) => _.lang === currentLanguage.value));
  }, [currentLanguage, support_lang]);

  // console.log(getData());

  useEffect(() => {
    if (id && visible) {
      setLoading(true);
      document.body.style.overflow = "hidden";
      main
        .getFullInfo(id)
        .then((ret) => {
          console.log(ret);
          setData(ret);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      init();
    }
  }, [id, visible]);

  let view = {
    multi: (
      <Multi
        data={getData("multi")}
        language={language}
        icon={getIcon}
        isActive={type === "multi" && !vocabVisible && !section} // 用于判断音频是否继续播放
      />
    ),
    ktv: (
      <Video
        videosrc={getVideo("KTV").streaming_url || getVideo("KTV").file}
        playing={!vocabVisible && !section}
        light={false}
      />
    ),
    main: (
      <Video
        videosrc={getVideo("MAIN").streaming_url || getVideo("MAIN").file}
        playing={!vocabVisible && !section}
        light={false}
      />
    ),
    // RECOMMEND
    recommend: (
      <Video
        videosrc={
          getVideo("RECOMMEND").streaming_url || getVideo("RECOMMEND").file
        }
        playing={!vocabVisible && !section}
        light={false}
      />
    ),
  };

  return (
    <Drawer
      visible={visible}
      onClose={() => window.close()}
      placement="bottom"
      closable
      height="100%"
      className={styles.drWongRoot}
      destroyOnClose
    >
      {/* 背景图片 */}
      <div
        style={{
          backgroundImage: `url(${
            getSection("BACKGROUND").file || background
          })`,
        }}
        className={styles.background}
      />
      {loading ? (
        <Spin size="large" className={styles.loading} />
      ) : (
        <Row type="flex" justify="center" className={styles.drwongInner}>
          <Col xxl={16} xl={22} lg={22} xs={24}>
            {/* header */}
            <Row
              type="flex"
              align="middle"
              className={styles.header}
              gutter={[10, 20]}
            >
              <Col span={3}>
                <img
                  src={require(`assets/image/drWong/title.png`)}
                  alt="title"
                />
              </Col>
              <Col span={17}>
                <h1 className={styles.title}>
                  {(data.lang.find((_) => _.lang === language.lang) || {}).name}
                </h1>
                {!!getData("document").length && (
                  <h3 className={styles.answer}>
                    <i />
                    <Link
                      to={
                        pathname +
                        (pathname.endsWith("/") ? "" : "/") +
                        "answer"
                      }
                    >
                      活動冊答案
                    </Link>
                    {!!getSection("RECOMMEND") && (
                        <Link
                          to={
                            pathname +
                            (pathname.endsWith("/") ? "" : "/") +
                            "guide"
                          }
                        >
                          <img
                            src="https://assets.evi.com.hk/images/jttw/new/common/icon/pencil.svg"
                            alt=""
                            style={{ width: 15, height: 15, marginLeft: 16 }}
                          />
                          {translate("teacherGuide")}
                        </Link>
                    )}
                  </h3>
                )}
              </Col>
              {/* 语言切换 */}
              <Col span={0}>
                <SelectLang
                  list={support_lang}
                  current={language}
                  onChange={(ret) => setLanguage(ret)}
                />
              </Col>
            </Row>
            {/* main */}
            <Row type="flex" gutter={[20, 20]} className={styles.main}>
              <Col lg={20} xs={24}>
                {view[type] || ""}
              </Col>
              <Col lg={4} xs={24} className={styles.side}>
                <Row type="flex">
                  <Col lg={24} xs={6} onClick={() => setType("main")}>
                    <img
                      src={require(`assets/image/drWong/item4.png`)}
                      alt="item"
                    />
                  </Col>
                  <Col lg={24} xs={6} onClick={() => setType("multi")}>
                    <img
                      src={require(`assets/image/drWong/item1.png`)}
                      alt="item"
                    />
                  </Col>
                  <Col lg={24} xs={6} onClick={() => setType("ktv")}>
                    <img
                      src={require(`assets/image/drWong/item2.png`)}
                      alt="item"
                    />
                  </Col>
                  <Col lg={24} xs={6} onClick={() => setVocabVisible(true)}>
                    <img
                      src={require(`assets/image/drWong/item3.png`)}
                      alt="item"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {/* 识字词 */}
      <Vocab
        data={getData("vocab")}
        visible={vocabVisible}
        onCancel={() => setVocabVisible(false)}
      />
      {/* 活动册答案 */}
      {!!getData("document").length && (
        <Document
          file={getData("document")[0].file}
          visible={section === "answer"}
          onCancel={() => {
            push(pathname.replace("answer", ""));
          }}
        />
      )}
	  {/* 教學資源手冊 */}
      {!!getSection("RECOMMEND") && (
        <Document
          file={getSection("RECOMMEND").file}
          visible={section === "guide"}
          onCancel={() => {
            push(pathname.replace("guide", ""));
          }}
        />
      )}
    </Drawer>
  );
};

export default DrWongModal;
