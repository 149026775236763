import React from "react";
// import { Row, Col, Button } from "antd";

import BindEviAccount from "./bindEviAccount";

import styles from "../../sass/selectMode.module.scss";

export default function SelectMode(props) {
  const { setStep } = props;

  return (
    <div className={styles.root}>
      <BindEviAccount
        setStep={setStep}
        // subNext={() => subNext()}
        // subPrev={() => subPrev()}
      />

      {/* <Button size="large" onClick={() => setStep(5)} className={styles.btn}>
        返回
      </Button>
      <Button
        size="large"
        onClick={() => setStep(2)}
        className={`${styles.btn} ${styles.select_btn}`}
      >
        新增測試帳戶
      </Button>
      <Button
        size="large"
        onClick={() => setStep(3)}
        className={`${styles.btn} ${styles.select_btn}`}
      >
        新增 EVI 帳戶
      </Button> */}

      {/* <Row type='flex' gutter={20}>
        <Col span={12}>
          <div
            className={styles.select_btn}
            onClick={() => setStep(2)}
          >
            新增測試帳戶
          </div>
        </Col>
        <Col span={12}>
          <div
            className={styles.select_btn}
            onClick={() => setStep(2)}
          >
            新增 EVI 帳戶
          </div>
        </Col>
      </Row> */}
      {/* <Button onClick={() => setStep(4)}>
        返回
      </Button> */}
    </div>
  );
}
