import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import intl from "react-intl-universal";
import {
  Form,
  Input,
  Checkbox,
  DatePicker,
  TimePicker,
  TreeSelect,
  Button,
  Select,
  Spin,
  Icon,
  message,
} from "antd";

import {
  getClassAction,
  getStudentAction,
  getStaffAction,
} from "components/actions/common_action";
import {
  editorAction,
  getInfoAction,
  GET_INFO,
} from "components/actions/event_action";
import { eventDelete } from "components/actions/event_action";

import authService from "components/services/authService";

import user from "components/services/userService";

import ManagePopup from "components/common/ManagePopup";
import Delete from "./Delete"; // 刪除
import Notification from "./notification"; // 通知
import EventsModal from "./eventsModal"; // 編輯周期性活動
import CustomCycleModal from "./customCycleModal"; // 自定周期

import zoom from "assets/image/zoomCalendar/zoom.png";

import styles from "assets/css/ZoomCalendar.module.scss";

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    span: 24,
  },
};

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const Editor = (props) => {
  const dispatch = useDispatch();

  const {
    user: { user_token = [] },
    route: { currentLocation },
    translations: { initDone },
    common: {
      class: { classTree },
      student: studentList,
      staff: staffList,
    },
    event: { info },
  } = useSelector((state) => state);

  const { id, visible, onCancel, monthDate } = props;
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;

  const [loading, setLoading] = useState(false); // 加載數據 loading
  const [hasPermission, setHasPermission] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false); // 提交 loading
  const [notification, setNotification] = useState([]); // 通知
  const [eventsVisible, setEventsVisible] = useState(false); // 周期性活動彈框顯示
  const [repeat, setRepeat] = useState({}); // 自定周期数据

  const [operationType, setOperationType] = useState("");

  // 判斷是否可以做更改
  const isEdit =
    (user_token.find((_) => _.type_name === "ZOOM") || {}).token_uid ===
    ((info.third_party || []).find((_) => _.third_party === "ZOOM") || {})
      .host_id;

  const lang = (value) => {
    return initDone && intl.get("zoomCalendar." + value);
  };

  // 把班級改成 post 所需格式
  const classListMap = (list = []) => {
    return list
      .map((item) => {
        const split = item.split("-");
        return `dummy-${split[0]}-${split[2]}`;
      })
      .join(",");
  };

  // 提交
  const onSubmit = (res) => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      const type = id ? "update" : "add";
      const {
        title,
        description,
        date,
        start,
        end,
        grade,
        student,
        staff,
        target,
        // repeat_day,
        // repeat_end_date,
      } = values;
      // 重新定義 post 需要的格式
      const formData = {
        id,
        title,
        description,
        start: date.format("YYYY-MM-DD") + " " + start.format("HH:mm"),
        end: date.format("YYYY-MM-DD") + " " + end.format("HH:mm"),
        target: target.join(","),
        grade: classListMap(grade),
        user_list: [...(student || []), ...(staff || [])],
        notification,
        repeat_day: repeat.day,
        repeat_end_date: !!repeat.date
          ? repeat.date.format("YYYY-MM-DD")
          : undefined,
        is_update_parent: res === 3 ? "Y" : undefined,
      };
      setSubmitLoading(true);
      dispatch(
        editorAction(type, formData, monthDate, (error) => {
          const msg = error && error.result && error.result.toString();
          if (msg) {
            message.error(lang(`error_msg.${msg}`));
          }
          setSubmitLoading(false);
          onCancel();
        })
      );
    });
  };
  // 提交之前的處理
  const submitBefore = (e) => {
    e.preventDefault();
    // 如果是更改，并且有 parent_id，彈出詢問框
    if (id && info.parent_id) {
      setEventsVisible(true);
      return;
    }
    onSubmit();
  };
  // 編輯周期性活動彈框確認
  const handleEventsConfirm = (res) => {
    onSubmit(res);
  };

  // 選擇班級
  const handleSelectClass = (value) => {
    const grade = classListMap(value);
    // 選擇班級后 call 學生列表 api
    dispatch(getStudentAction(grade));
  };
  // 禁用部分 开始時間 - 小時
  const startDisabledHours = () => {
    if (!getFieldValue("end")) return;
    const endTime = Number(getFieldValue("end").format("HH")) + 1;
    return Array.from({ length: 24 - endTime }, (_, i) => i).map(
      (_) => _ + endTime
    );
  };
  // 禁用部分 开始時間 - 分鐘
  const startDisabledMinutes = () => {
    if (getFieldValue("end") && getFieldValue("start")) {
      const [startHour, startMin] = getFieldValue("start")
        .format("HH:mm")
        .split(":");
      const [endHour] = getFieldValue("end").format("HH:mm").split(":");
      return startHour === endHour
        ? Array.from({ length: 60 - Number(startMin)}, (_, i) => i).map(_ => _ + Number(startMin))
        : [];
    }
  };
  // 禁用部分 結束時間 - 小時
  const endDisabledHours = () => {
    if (!getFieldValue("start")) return;
    const startTime = getFieldValue("start").format("HH");
    return Array.from({ length: startTime }, (_, i) => i);
  };
  // 禁用部分 結束時間 - 分鐘
  const endDisabledMinutes = () => {
    if (getFieldValue("end") && getFieldValue("start")) {
      const startTime = getFieldValue("start").format("HH:mm").split(":");
      const endTime = getFieldValue("end").format("HH:mm").split(":");
      return startTime[0] === endTime[0]
        ? Array.from({ length: Number(startTime[1]) + 1 }, (_, i) => i)
        : [];
    }
  };

  useEffect(() => {
    if (visible) {
      setHasPermission(true);
      !classTree.length && dispatch(getClassAction(currentLocation));
      !staffList.length && dispatch(getStaffAction());
      !id && dispatch(getStudentAction());
      if (id) {
        setLoading(true);
        dispatch(
          getInfoAction(id, (ret) => {
            setLoading(false);
            if (ret) {
              const grade = classListMap(ret.class_list);
              dispatch(getStudentAction(grade));
            }
          })
        );
        setSubmitLoading(true);
        authService
          .checkToken()
          .catch((error) => {
            setHasPermission(false);
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      }
    } else {
      dispatch({ type: GET_INFO.RESET });
    }
  }, [visible, id]);

  // 如果數據中的 status 不爲 DONE，每隔五秒刷新一次數據
  useEffect(() => {
    let timer = null;
    if(info.status !== 'DONE' && id && visible) {
      timer = setTimeout(() => {
        dispatch(getInfoAction(id))
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [info])

  useEffect(() => {
    const newData = (info.notification || []).map(
      ({ id, type, interval_field, interval_value }) => {
        return { id, type, interval_field, interval_value };
      }
    );
    setNotification(newData);
    setRepeat({
      day: info.repeat_day,
      date: info.repeat_end_date ? moment(info.repeat_end_date) : null
    })
  }, [info]);

  const userList = (type) => {
    return (
      info.user_list &&
      info.user_list.reduce((acc, item) => {
        const { user_type, token_uid } = item;
        if (user_type === type) {
          acc.push(token_uid);
        }
        return acc;
      }, [])
    );
  };

  return (
    <ManagePopup
      visible={visible}
      title={id ? lang("title_modal_update") : lang("title_modal_add")}
      width={600}
      onCancel={onCancel}
      zIndex={999}
    >
      <EventsModal
        visible={eventsVisible}
        showOption1={true}
        operationType={operationType}
        onConfirm={(res) => handleEventsConfirm(res)}
        onCancel={() => setEventsVisible(false)}
        deleteCallback={(res) => {
          dispatch(
            eventDelete({id, is_update_parent: res === 3 ? "Y" : undefined }, (error) => {
              const msg = error && error.result && error.result.toString();
              if (msg) {
                message.error(lang(`error_msg.${msg}`));
              }
              if (onCancel) onCancel();
            })
          );
        }}
      />
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          padding: "30px 70px 30px 40px",
        }}
      >
        {/* 刪除 */}
        {id &&
          isEdit &&
          hasPermission &&
          (info.parent_id ? (
            <Button
              icon="delete"
              shape="circle"
              type="danger"
              style={{ position: "absolute", top: 33, right: 12 }}
              onClick={() => {
                setEventsVisible(true);
                setOperationType("delete");
              }}
            />
          ) : (
            <Delete id={id} loading={submitLoading} onCancel={onCancel} />
          ))}
        <Spin indicator={antIcon} spinning={loading}>
          <Form
            {...formItemLayout}
            onSubmit={submitBefore}
            className={styles.form}
          >
            <Form.Item label={lang("form_name")}>
              {getFieldDecorator("title", {
                initialValue: info.title,
                rules: [
                  {
                    required: true,
                    message: lang("form_msg_name"),
                    whitespace: true,
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={lang("form_description")}>
              {getFieldDecorator("description", {
                initialValue: info.description,
                rules: [
                  { message: lang("form_msg_description"), whitespace: true },
                ],
              })(<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />)}
            </Form.Item>
            <Form.Item label={lang("form_date")}>
              {getFieldDecorator("date", {
                initialValue:
                  info.start && moment(info.start.split(" ")[0], "YYYY-MM-DD"),
                rules: [{ required: true, message: lang("form_msg_date") }],
              })(
                <DatePicker
                  disabledDate={(current) => {
                    const endDate = repeat.date;
                    if(endDate) {
                      // 用 moment 包裹一次是防止 add 操作改变原来的值
                      const lastYear = moment(endDate).subtract(1, 'y');
                      return current.isSameOrAfter(endDate, 'day') || current.isSameOrBefore(lastYear, 'day');
                    }
                  }}
                />
              )}
            </Form.Item>
            <Form.Item
              label={lang("form_time")}
              extra={
                <span style={{ color: "#f5222d" }}>
                  * {lang("info_msg_endTime")}
                </span>
              }
              required
            >
              <Form.Item style={{ display: "inline-block", marginBottom: 0 }}>
                {getFieldDecorator("start", {
                  initialValue:
                    info.start && moment(info.start.split(" ")[1], "HH:mm"),
                  rules: [{ required: true, message: lang("form_msg_start") }],
                })(
                  <TimePicker
                    format="HH:mm"
                    disabledHours={startDisabledHours}
                    disabledMinutes={startDisabledMinutes}
                  />
                )}
              </Form.Item>
              <span
                style={{
                  display: "inline-block",
                  width: "24px",
                  textAlign: "center",
                }}
              >
                -
              </span>
              <Form.Item style={{ display: "inline-block", marginBottom: 0 }}>
                {getFieldDecorator("end", {
                  initialValue:
                    info.end && moment(info.end.split(" ")[1], "HH:mm"),
                  rules: [{ required: true, message: lang("form_msg_end") }],
                })(
                  <TimePicker
                    format="HH:mm"
                    disabledHours={endDisabledHours}
                    disabledMinutes={endDisabledMinutes}
                  />
                )}
              </Form.Item>
            </Form.Item>
            {/* 自定周期 */}
            <CustomCycleModal
              data={repeat}
              onChange={(res) => setRepeat(res)}
              getFieldValue={getFieldValue}
            />
            {!!(info.third_party || []).length &&
              info.third_party[0].third_party === "ZOOM" && (
                <Form.Item
                  colon={false}
                  label={<img style={{ width: 25 }} src={zoom} alt="zoom" />}
                >
                  <Button
                    type="primary"
                    href={info.third_party[0].join_url}
                    target="blank"
                    loading={info.status !== 'DONE'}
                  >
                    {lang("zoom")}
                  </Button>
                </Form.Item>
              )}
            {user.getType() === "STAFF" && (
              <>
                <Form.Item label={lang("form_target")}>
                  {getFieldDecorator("target", {
                    initialValue: (info.target || "").split(","),
                    rules: [
                      { required: true, message: lang("form_msg_target") },
                    ],
                  })(
                    <Checkbox.Group>
                      <Checkbox value="STUDENT">
                        {lang("option_student")}
                      </Checkbox>
                      <Checkbox value="STAFF">{lang("option_staff")}</Checkbox>
                    </Checkbox.Group>
                  )}
                </Form.Item>
                {getFieldValue("target").includes("STUDENT") && (
                  <>
                    <Form.Item label={lang("form_grade")}>
                      {getFieldDecorator("grade", {
                        initialValue: info.class_list || [],
                        rules: [
                          { required: true, message: lang("form_msg_grade") },
                        ],
                      })(
                        <TreeSelect
                          treeData={classTree}
                          filterTreeNode={(_search, node) =>
                            !!node.props &&
                            !!node.props.title &&
                            node.props.title
                              .toUpperCase()
                              .indexOf(_search.toUpperCase()) > -1
                          }
                          treeCheckable
                          allowClear
                          showSearch
                          onChange={handleSelectClass}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label={lang("form_student")}>
                      {getFieldDecorator("student", {
                        initialValue: userList("STUDENT"),
                        rules: [
                          { required: true, message: lang("form_msg_student") },
                        ],
                      })(
                        <TreeSelect
                          treeData={studentList}
                          treeCheckable
                          allowClear
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          treeNodeFilterProp="title"
                          treeDefaultExpandAll
                        />
                      )}
                    </Form.Item>
                  </>
                )}
                {getFieldValue("target").includes("STAFF") && (
                  <Form.Item label={lang("form_staff")}>
                    {getFieldDecorator("staff", {
                      initialValue: userList("STAFF"),
                      rules: [
                        { required: true, message: lang("form_msg_staff") },
                      ],
                    })(
                      <Select mode="multiple" allowClear filterOption>
                        {staffList.map((item) => (
                          <Select.Option key={item.id} value={item.user_id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                )}
                <Form.Item label={lang("form_notification")}>
                  <Notification
                    data={notification}
                    onChange={(ret) => setNotification(ret)}
                  />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitLoading}
                      disabled={!(!id || isEdit) || !hasPermission}
                    >
                      {initDone && intl.get("general.button.confirm")}
                    </Button>
                    <Button type="primary" onClick={onCancel}>
                      {initDone && intl.get("general.button.cancel")}
                    </Button>
                  </div>
                </Form.Item>
              </>
            )}
          </Form>
        </Spin>
      </div>
    </ManagePopup>
  );
};

export default Form.create()(Editor);
