import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Menu, Row, Col, Layout } from "antd";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import intl from "react-intl-universal";
import style from "assets/css/layout.module.scss";

import user_API from "components/services/userService";

const { Footer } = Layout;

class MyFooter extends Component {
  render() {
    const { translations, route, location } = this.props;
    const { id: uID } = this.props.user;
    const _selectKey = [`${route.tag}`];

    // const urlParams = new URLSearchParams(location.search)
    // const key = urlParams.get('app');
    const isHidden = (location.search || "").includes("app=");
    const isWebApp = location.pathname.includes("/web_to_app"); 

    return (
      <Footer
        className={style.footer}
        style={{
          display: isHidden || isWebApp ? "none" : "block",
        }}
      >
        <Row type="flex" align="middle" justify="end">
          <Col xs={{ span: 24 }} style={{ textAlign: "right" }}>
            <Col
              sm={0}
              xs={0}
              md={24}
              style={{
                position: "fixed",
                zIndex: "2",
                right: "30px",
                width: 150,
                bottom: "100px",
              }}
            >
              {!!uID && !!user_API.checkRPermit() && (
                <>
                  <Link
                    to={`/${route.currentLocation}/${route.currentLanguage.url}/user/coin`}
                  >
                    {/* <img
                      src={require(`assets/image/fishgame/fish_${route.currentLanguage.value}.png`)}
                      alt=""
                    /> */}
                    <img
                      src={require(`assets/image/personal/coin/${route.currentLanguage.value}/reward_activities.png`)}
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </Link>
                </>
              )}
            </Col>
            <Menu
              theme="light"
              mode="horizontal"
              selectedKeys={_selectKey}
              style={{ lineHeight: "64px" }}
            >
              <Menu.Item key="about_us">
                <Link
                  to={`/${route.currentLocation}/${route.currentLanguage.url}/about_us`}
                >
                  {translations.initDone &&
                    intl.get("home.publicMsg.footer.about")}
                </Link>
              </Menu.Item>
              <Menu.Item key="contact_us">
                <Link
                  to={`/${route.currentLocation}/${route.currentLanguage.url}/contact_us`}
                >
                  {translations.initDone &&
                    intl.get("home.publicMsg.footer.liaison")}
                </Link>
              </Menu.Item>
              <Menu.Item key="privacy">
                <Link
                  to={`/${route.currentLocation}/${route.currentLanguage.url}/privacy`}
                >
                  {translations.initDone &&
                    intl.get("home.publicMsg.footer.agreement")}
                </Link>
              </Menu.Item>
              <Menu.Item key="disclaimer">
                <Link
                  to={`/${route.currentLocation}/${route.currentLanguage.url}/disclaimer`}
                >
                  {translations.initDone &&
                    intl.get("home.publicMsg.footer.Disclaimer")}
                </Link>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        <Row style={{ color: "#555555", backgroundColor: "#cbcbcb" }}>
          {!!process.env.REACT_APP_CN_ICP_PAYMENT && (
            <Col xs={{ span: 24 }} style={{ textAlign: "center" }}>
              <p style={{ margin: 0 }}>
                <small>
                  <strong>
                    <a
                      href="https://beian.miit.gov.cn/"
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      style={{
                        color: "rgb(85, 85, 85)",
                      }}
                    >
                      {process.env.REACT_APP_CN_ICP}
                    </a>
                  </strong>
                </small>
              </p>
              <p style={{ margin: 0 }}>
                <small>
                  <strong>{process.env.REACT_APP_CN_ICP_PAYMENT}</strong>
                </small>
              </p>
            </Col>
          )}
          <Col xs={{ span: 24 }} style={{ textAlign: "center" }}>
            <small>
              <strong>
                {process.env.REACT_APP_COPYRIGHT} (
                {process.env.REACT_APP_LOCATION} v
                {process.env.REACT_APP_VERSION} )
              </strong>
            </small>
          </Col>
        </Row>
      </Footer>
    );
  }
}

function mapStateToProps({ route, user, translations }) {
  return {
    route,
    user,
    translations,
  };
}

export default connect(mapStateToProps)(withRouter(MyFooter));
