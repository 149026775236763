import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Carousel } from 'antd';

import logoutStyle from "assets/css/logout.module.scss";
const LogoutBanner = (props) => {
  const carouselRef = useRef();
  const { img } = props;

  const handlePrev = () => {
    carouselRef.current.prev();
  }

  const handleNext = () => {
    carouselRef.current.next();
  }
  
  return (
    <div style={{ position: "relative" }}>
      {/* 上一頁按鈕 */}
      <Icon
        type="left"
        theme="outlined"
        onClick={handlePrev}
        style={{ left: 0 }}
        className={logoutStyle.Icon}
      />
      {/* 輪播 */}
      <Carousel autoplay dots={false} ref={carouselRef}>
        {/* 輪播内容 */}
        {(img || []).map((item, index) => (
          <div key={index} className={logoutStyle.banner}>
            <a 
              href={item.link} 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={e => !item.link && e.preventDefault()}
            >
              <img src={item.file} className={logoutStyle.bg} alt="" />
            </a>
          </div>
        ))}
      </Carousel>
      {/* 下一頁按鈕 */}
      <Icon
        type="right"
        theme="outlined"
        style={{ right: 0 }}
        className={logoutStyle.Icon}
        onClick={handleNext}
      />
    </div>
  )
}

LogoutBanner.propTypes = {
  img: PropTypes.array
}

export default LogoutBanner;