import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from "react-intl-universal";
import { Button, Modal, Icon, message } from 'antd';

import { eventDelete } from "components/actions/event_action";

export default function ({ id, loading, onCancel }) {
  const dispatch = useDispatch();
  const { translations } = useSelector(state => state);

  const lang = (value) => {
    return translations.initDone && intl.get(value);
  }

  const handleDelete = () => {
    Modal.confirm({
      title: lang("general.msg.delete_confirm_msg"),
      icon: <Icon type="delete" theme="twoTone" twoToneColor="#eb2f96" />,
      confirmLoading: true,
      onOk: () => {
        return dispatch(eventDelete({ id }, (error) => {
          const msg = error && error.result && error.result.toString();
          if (msg) {
            message.error(lang(`error_msg.${msg}`));
          }
          if(onCancel) onCancel();
        }));
      },
      okText: lang(`general.button.confirm`),
      cancelText: lang(`general.button.cancel`)
    });
  }
  return (
    <Button 
      icon="delete" 
      shape="circle" 
      type="danger"
      loading={loading}
      onClick={handleDelete} 
      style={{ position: 'absolute', top: 33, right: 12 }}
    />
  )
}