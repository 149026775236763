import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Modal, Button, Checkbox, Row, Col, DatePicker } from 'antd';
import moment from "moment";
import intl from "react-intl-universal";

const CustomCycleModal = (props) => {
  const { translations: { initDone } } = useSelector((state) => state);
  const { data, onChange, getFieldValue } = props;
  const [ visible, setVisible ] = useState(false);
  const [ repeatDay, setRepeatDay ] = useState([]);
  const [ repeatEndDate, setRepeatEndDate ] = useState(null);

  const lang = (value) => initDone && intl.get("zoomCalendar." + value);

  const _lang = (value) => initDone && intl.get(value);

  useEffect(() => {
    setRepeatDay(data.day);
    setRepeatEndDate(data.date);
  }, [data, visible])

  const handleCancel = () => {
    setVisible(false);
  }

  const handleOk = () => {
    setVisible(false);
    onChange({
      day: repeatDay,
      date: repeatEndDate
    })
  }

  const weekConfig = [
    { label: lang("week_SUN"), value: "SUN" },
    { label: lang("week_MON"), value: "MON" },
    { label: lang("week_TUE"), value: "TUE" },
    { label: lang("week_WED"), value: "WED" },
    { label: lang("week_THU"), value: "THU" },
    { label: lang("week_FRI"), value: "FRI" },
    { label: lang("week_SAT"), value: "SAT" },
  ];

  return (
    <>
      <Form.Item
        wrapperCol={{ xs: { span: 24 }, sm: { span: 18, offset: 6 } }}
      >
        <Button size="small" onClick={() => setVisible(true)}>
          {lang("btn_custom_cycle")}
        </Button>
        {!!data.day && !!data.date && (
          <div>
            <span style={{ marginRight: 10 }}>
              {(data.day || []).map((item, index) => (
                lang(`week_${item}`) + (index === data.day.length - 1 ? '' : '、')
              ))}
            </span>
            <span>
              {lang('text_end')} {data.date.format("YYYY-MM-DD")}
            </span>
          </div>
        )}
      </Form.Item>
      <Modal
        title={lang('btn_custom_cycle')}
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        okText={_lang("general.button.confirm")}
        cancelText={_lang("general.button.cancel")}
        okButtonProps={{ disabled: ((repeatDay || []).length && !repeatEndDate) || repeatEndDate && !(repeatDay || []).length }}
      >
        <Form.Item 
          label={lang("form_repeat_date")} 
          style={{width: '100%'}} 
        >
          <Checkbox.Group
            value={repeatDay} 
            onChange={(value) => setRepeatDay(value)}
            style={{ width: "100%" }} 
          >
            <Row>
              {weekConfig.map((item) => (
                <Col span={6} key={item.value}>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label={lang("form_end")}>
          <DatePicker
            onChange={(date) => setRepeatEndDate(date)}
            value={repeatEndDate}
            disabledDate={(current) => {
              const startDate = getFieldValue('date');
              if(startDate) {
                // 用 moment 包裹一次是防止 add 操作改变原来的值
                const nextYear = moment(startDate).add(1, 'y');
                return current.isSameOrBefore(startDate, 'day') || current.isSameOrAfter(nextYear, 'day');
              }
            }}
          />
        </Form.Item>
      </Modal>
    </>
  )
}

export default CustomCycleModal;