import React, { useState } from "react";
import { Button, Input } from "antd";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import intl from "react-intl-universal";

import multipleText from "../utils/resultCode";

import styles from "assets/css/redemption/index.module.scss";

const ErrorContent = () => {
  const { code } = useParams();
  const { pathname } = useLocation();
  const { info } = useSelector((state) => state.new_redemption);

  const [inputCode, setInputCode] = useState(code);

  // 地区path
  const localPath = pathname.slice(0, pathname.indexOf("/redemption"));

  return (
    <>
      <Input
        placeholder={intl.get("redemption.input_placeholder")}
        value={inputCode}
        onChange={(e) => setInputCode(e.target.value)}
        className={styles.input}
      />
      {!!info.errorCode && (
        <p style={{ color: "red" }}>
          {intl.get(multipleText.get(info.errorCode || "default"))}
        </p>
      )}
      <Button type="primary" className={styles.input_button}>
        <Link to={`${localPath}/redemption/${inputCode}`}>
          {intl.get("redemption.input_submit")}
        </Link>
      </Button>
    </>
  );
};

export default ErrorContent;
