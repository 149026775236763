import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Empty, Modal } from "antd";

import intl from "react-intl-universal";

import styles from "assets/css/PersonalCenter.module.scss";

import TitleCard from "components/common/TitleCard";

import user_API from "components/services/userService";

const Coin = () => {
  const { user, route, translations } = useSelector((state) => state);

  const [imgModalVisible, setImgModalVisible] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);

  const lang = (value) => translations.initDone && intl.get(value);

  return (
    <Fragment>
      <TitleCard
        title={lang("personalCenter.menu.coin")}
        titleBgColor="#FF9354"
        titleColor="#fff"
        mainBgColor="#fff"
        span={{ md: 8, xs: 24 }}
      >
        <div style={{ padding: 10 }}>
          {!!user.id &&
            !!user_API.checkRPermit() ? (
            <Row
              type="flex"
              justify="start"
              gutter={[10, 10]}
              className={styles.coin}
            >
              {(route.currentLocation == 'hk' || route.currentLocation == 'mo') && (<div><Col span={24} onClick={() => setVideoModalVisible(true)}>
                <img src={require(`assets/image/personal/coin/${route.currentLanguage.value}/b0.png`)} alt="" />
              </Col>
                <Col span={24}>
                  <Link
                    to={`/${route.currentLocation}/${route.currentLanguage.url}/capsule`}
                    target="_blank"
                  >
                    <img
                      src={require(`assets/image/personal/coin/${route.currentLanguage.value}/b2.png`)}
                      alt=""
                    />
                  </Link>
                </Col></div>)}
              <Col span={24}>
                <Link
                  to={`/${route.currentLocation}/${route.currentLanguage.url}/fishgame`}
                  target="_blank"
                >
                  <img
                    src={route.currentLocation === "cn" ? require(`assets/image/personal/coin/${route.currentLanguage.value}/b1s.png`) 
                    : require(`assets/image/personal/coin/${route.currentLanguage.value}/b1.png`)}
                    alt=""
                  />
                </Link>
              </Col>
              {/* <Col span={24}>
                <img src={require(`assets/image/personal/coin/${route.currentLanguage.value}/b3.png`)} alt=""/>
              </Col> */}
              <Col span={24} onClick={() => setImgModalVisible(true)}>
                <img src={require(`assets/image/personal/coin/${route.currentLanguage.value}/b4.png`)} alt="" />
              </Col>
            </Row>
          ) : (
            <div style={{ padding: 20 }}>
              <Empty />
            </div>
          )}
        </div>
      </TitleCard>
      <Modal
        className={styles.imgModal}
        visible={imgModalVisible}
        width={800}
        onCancel={() => setImgModalVisible(false)}
        footer={null}
        closable={false}
        bodyStyle={{
          padding: 0,
        }}
      >
        <img
          src={require(`assets/image/personal/coin/${route.currentLanguage.value}/function${route.currentLocation == 'hk' ? '_hk' : ''}.png`)}
          style={{ width: "100%" }}
          alt="function"
        />
      </Modal>
      <Modal
        visible={videoModalVisible}
        width={800}
        onCancel={() => setVideoModalVisible(false)}
        footer={null}
        closable={false}
        destroyOnClose
      >
        <video
          src="https://oss-marketing.evigarten.com/evigarten_video/capsulte.mp4"
          style={{ width: '100%' }}
          controls
          autoPlay
        />
      </Modal>
    </Fragment>
  );
};

export default Coin;
