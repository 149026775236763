import React from "react";
import { Modal, Typography, Icon } from "antd";

import intl from "react-intl-universal";

import Icon2 from "assets/image/cantoneseOpera/2.png";

const styles = {
  padding: 0,
  borderRadius: "4PX",
};

export default function GameModal(params) {
  const { gameControl, gameClose, gameData } = params;

  const translate = (value, params) =>
    intl.get(`cantoneseOpera.${value}`, params);

  const { Title } = Typography;

  return (
    <div>
      <Modal
        visible={gameControl}
        onCancel={gameClose}
        footer={null}
        closable={false}
        bodyStyle={styles}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            backgroundColor: "#E91E63",
            paddingTop: "14px",
            paddingBottom: "18px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          }}
        >
          <img src={Icon2} alt="" style={{ width: "42px", height: "42px" }} />
          <Title
            style={{
              fontSize: "25px",
              marginBottom: "0px",
              fontWeight: "400",
              marginLeft: "19.41px",
              color: "#fff",
            }}
          >
            {translate("OnlineGames")}
          </Title>
          <div
            style={{ position: "absolute", right: 10, paddingTop: 6 }}
            onClick={gameClose}
          >
            <Icon
              type="close"
              style={{ color: "#FFF", fontSize: "17px" }}
              onClick={gameClose}
            />
          </div>
        </div>
        <div
          style={{
            padding: "44px 28px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <ul>
            {gameData.length !== 0 &&
              gameData.map((item) => {
                return (
                  <li key={item.name} style={{ marginRight: 8 }}>
                    <a target="_blank" href={item.link}>
                      {item.name}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      </Modal>
    </div>
  );
}
