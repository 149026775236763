import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Steps, Button, message } from "antd";

import { useSelector, useDispatch } from "react-redux";

import SelectMode from "./selectMode";
import BindEviAccount from "./bindEviAccount";
import ChildrenForm from "./childrenForm";
import BindFamily from "./bindFamily";
import Introduce from "./introduce";
import SelectPackage from "./selectPackage";
import Pay from "./pay";

const { Step } = Steps;

const StepNewChildren = (props) => {
  const dispatch = useDispatch();
  const {
    next,
    prev,
    skipPay,
    showFamily,
    progressCallback,
    exitCallback,
    closeConfirm,
  } = props;

  const { pay } = useSelector((state) => state.new_auth);
  const { id: user_id } = useSelector((state) => state.user);

  const urlParams = new URLSearchParams(useLocation().search);

  const key = urlParams.get("action");

  const initStep = key === "cancel" ? 5 : 5;

  const [current, setCurrent] = useState(initStep);
  const { showUser } = useSelector((state) => state.new_global);

  const steps = [
    // 0
    {
      title: "新增子女介紹頁面",
      // 该 prev -- 应该回到首页
      content: (
        <Introduce
          subNext={() => subNext()}
          prev={() => prev()}
          skipPay={() => skipPay()}
        />
      ),
    },
    // 1
    {
      title: '选择新增子女方式',
      content: (
        <SelectMode
          setStep={step => setCurrent(step)}
        />
      )
    },
    // 2
    {
      title: "编辑子女页面",
      content: (
        <ChildrenForm setStep={step => setCurrent(step)} subNext={() => subNext()} subPrev={() => subPrev()} />
      ),
    },
    // 3
    {
      title: "綁定 Evi 賬戶",
      content: (
        <BindEviAccount setStep={step => setCurrent(step)} subNext={() => subNext()} subPrev={() => subPrev()} />
      ),
    },
    // 4
    {
      title: "选择付费套餐",
      content: (
        <SelectPackage
          subNext={() => {
            if (showUser) {
              // dispatch({ type : "SET_SHOW_USER" , payload : false});
              // console.log(pay);
              if (pay.length > 0) {
                setCurrent(6);
                return;
              }
              return typeof exitCallback === "function" && exitCallback();
            } else {
              subNext();
            }
          }}
          subPrev={() => {
            console.log(showUser);
            if (showUser) {
              dispatch({ type: "SET_SHOW_USER", payload: false });
              return typeof exitCallback === "function" && exitCallback();
            } else {
              subPrev();
            }
          }}
        />
      ),
    },
    // 5
    {
      title: "绑定子女",
      content: (
        <BindFamily
          subNext={() => {
            if (pay.length) {
              subNext();
              return;
            }
            next();
          }}
          subPrev={() => subPrev()}
          continueAdd={() => continueAdd()}
          skipPay={() => skipPay()}
          showFamily={showFamily}
          progressCallback={progressCallback}
          exitCallback={exitCallback}
          closeConfirm={closeConfirm}
        />
      ),
    },
    // 6
    {
      title: "缴费预览",
      content: <Pay next={() => next()} subPrev={() => subPrev()} />,
    },
  ];

  const subNext = () => {
    setCurrent(current + 1);
  };

  const subPrev = () => {
    setCurrent(current + -1);
  };

  // 继续新增子女
  const continueAdd = () => {
    setCurrent(1);
  };

  return (
    <>
      <Steps
        current={current}
        labelPlacement={"vertical"}
        style={{ display: "none" }}
        // className={stepStyles.step}
      >
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div
        className="steps-content"
        style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        {steps[current].content}
      </div>
      {/*<div className="steps-action">
        {current > 0 && (
          <Button
            style={{ marginLeft: 8, marginBottom: "16px" }}
            onClick={() => subPrev()}
          >
            新增子女 -- Previous
          </Button>
        )}

        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            新增子女 -- Done
          </Button>
        )}

        {current < steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => subNext()}
            style={{ marginBottom: "16px" }}
          >
            新增子女 -- Next
          </Button>
        )}
      </div>*/}
    </>
  );
};

export default StepNewChildren;
