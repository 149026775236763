import React, { useState, useMemo, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import intl from "react-intl-universal";

import {
  Table,
  Form,
  Checkbox,
  Button,
  Select,
  Row,
  Col,
  Input,
  Tag,
  Typography,
  Tooltip,
  Popover,
  Icon,
  message,
  Switch,
  TreeSelect,
  Modal,
} from "antd";

import { EditableContext } from "./components/context";
import { ExploreContext } from "components/context/explore360Context";

import course from "components/services/courseService";
import userLog from "components/services/userLogService";

import PreviewListPage from "components/studentReport/previewListPage";
import File from "components/common/File";

// columns 组件
import Cell from "./components/columns/cell";
import MyPopover from "./components/columns/popover";
import RecordUpload from "./components/columns/recordUpload";
import VoiceComment from "./components/columns/voiceComment";
import BatchDelete from "./components/BatchDelete";

import "./less/index.less";

const { Option, OptGroup } = Select;
const { CheckableTag } = Tag;
const { TreeNode } = TreeSelect;

const { Search } = Input;

const icons = ["cmt_icon1", "cmt_icon2", "cmt_icon3", "cmt_icon4"];

const TableListPage = (props) => {
  const {
    data,
    setCurrentUser,
    classId,
    onSelect,
    form,
    courseId,
    resId,
    setVisible,
    tablePage,
    setTablePage,
    checked,
    setChecked,
    tableLoading,
    isProject,
  } = props;

  const {
    common: { class: classData },
    translations,
    route: { currentLocation },
  } = useSelector((state) => state);

  const lang = (value) => translations.initDone && intl.get(value);

  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState(""); // 搜尋
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 已选项
  const [batchValue, setBatchValue] = useState(""); // 批量评价 value
  const [batchLoading, setBatchLoading] = useState({
    STAR: false,
    COMMENT: false,
  }); // 批量修改loading
  const [sendLoading, setSendLoading] = useState(false); // 提醒呈交 loading
  // 即使發佈
  const [isShowComment, setIsShowComment] = useState(false);
  const [isShowCommentLoading, setIsShowCommentLoading] = useState(false);

  useEffect(() => {
    if (page === 1) {
      setTablePage("table");
    } else {
      setTablePage("preview");
    }
  }, [page]);

  useEffect(() => {
    setPage(tablePage === "table" ? 1 : 2);
  }, []);

  // 獲取即時發佈狀態
  const handleGetRecordSetting = async () => {
    try {
      const { is_show_comment } = await course
        .getUserRecordSetting(courseId, resId)
        .then((ret) => ret)
        .catch(() => {});
      setIsShowComment(is_show_comment === "Y");
    } catch (error) {
      console.log(error);
    } finally {
      setIsShowCommentLoading(false);
    }
  };
  // 設置即時發佈狀態
  const handleSetRecordSetting = async (checked) => {
    try {
      setIsShowCommentLoading(true);
      await course.setUserRecordSetting(courseId, resId, checked ? "Y" : "N");
      handleGetRecordSetting();
    } catch (error) {
      console.log(error);
    } finally {
      setIsShowCommentLoading(false);
    }
  };

  useEffect(() => {
    setIsShowCommentLoading(true);
    if (isProject) {
      handleGetRecordSetting();
    }
  }, []);

  const againGetData = useContext(ExploreContext);

  const newData = useMemo(() => {
    const config = {
      all: data,
      submitY: data.filter((_) => !!_.project_file),
      submitN: data.filter((_) => !_.project_file),
      scoreY: data.filter((_) => !!_.project_file && !!_.icon),
      scoreN: data.filter((_) => !!_.project_file && !_.icon),
    };
    const ret = config[checked].filter((item) => {
      const name = (item.name || "").toLowerCase();
      const value = (searchValue || "").toLowerCase();
      return name.indexOf(value) !== -1;
    });
    return ret;
  }, [data, checked, searchValue]);

  const handleFilter = (value) => {
    setChecked(value);
  };

  // 更改 table row 数据
  const updateTableRow = ({
    record,
    // 因爲每次需要傳遞這些值，所以默認爲空
    _star = undefined,
    _comment = undefined,
    _highlight = undefined,
    callback,
  }) => {
    const {
      ur_id,
      star,
      comment,
      highlight,
      is_comment,
      rank,
      reward,
      publish_time,
      end_time,
    } = record;

    if (!record.ur_id) {
      alert("还没有呈交,暂时不能修改.");
      if (callback) callback();
      return;
    }
    course
      .addCommentForEditable({
        ur_id,
        star: _star || star,
        comment: _comment || comment,
        highlight: _highlight || highlight,
        is_comment,
        rank,
        reward,
        publish_time,
        end_time,
      })
      .then((ret) => {
        againGetData();
        if (callback) callback();
      })
      .catch((err) => console.log(err));
  };

  //批量刪除
  const handleDel = BatchDelete({
    title: lang("student_reports.barch_star_del"),
    content: lang("student_reports.barch_del_contant"),
    isFun: true,
    onOk() {
      course
        .delUpdate(selectedRowKeys)
        .then((ret) => {
          message.success("批量修改成功");
          againGetData(); // 更新列表
          setSelectedRowKeys([]); // 清空已勾選
        })
        .catch((error) => {
          message.error("批量修改失敗");
        });
    },
  });
  // 批量修改
  const handleBatch = (action, value) => {
    if (selectedRowKeys.length && value === "del") {
      handleDel();
      return;
    }
    if (selectedRowKeys.length && value) {
      setBatchLoading((loading) => ({ ...loading, [action]: true }));
      const formData = {
        ur_ids: selectedRowKeys,
        action,
        value,
      };
      course
        .batchUpdate(formData)
        .then((ret) => {
          message.success("批量修改成功");
          againGetData(); // 更新列表
          setSelectedRowKeys([]); // 清空已勾選
        })
        .catch((error) => {
          message.error("批量修改失敗");
        })
        .finally(() => {
          setBatchLoading((loading) => ({ ...loading, [action]: false }));
        });
    }
  };

  // 提醒呈交
  const handleSendNotification = () => {
    setSendLoading(true);
    userLog
      .sendNotification({
        class_id: classId,
        res_id: resId,
        course_id: courseId,
        user_ids: selectedRowKeys,
      })
      .then((ret) => {
        console.log(ret);
        message.success("提醒成功");
      })
      .catch(() => {
        message.error("提醒失敗");
      })
      .finally(() => {
        setSendLoading(false);
      });
  };

  const columns = [
    {
      title: lang("student_reports.table.name"),
      width: 160,
      dataIndex: "name",
    },
    {
      title: lang("student_reports.table.class"),
      width: 100,
      dataIndex: "class_name",
    },
    {
      title: lang("student_reports.table.production"),
      width: 100,
      dataIndex: "cover",
      render: (text, record, index) => {
        return (
          text && (
            <Popover
              content={
                <>
                  {/* <File src={text} className="filePreview_wrap" videoToImg /> */}
                  <div className="filePreview_wrap">
                    <img src={text} alt="" />
                  </div>
                  <div className="create_time">
                    呈交時間：{record.create_time}
                  </div>
                </>
              }
              arrowPointAtCenter
              trigger="hover"
              placement="right"
              overlayClassName="studentReport_production"
            >
              <Button
                className="editable_open_btn"
                onClick={() => setCurrentUser(record.user_id)}
              >
                {lang("student_reports.table.open")}
              </Button>
            </Popover>
          )
        );
      },
    },
    {
      title: lang("student_reports.table.score"),
      dataIndex: "icon",
      width: 80,
      render: (text, record) => {
        return <MyPopover record={record} updateTableRow={updateTableRow} />;
      },
    },
    {
      title: lang("student_reports.table.voiceComment"),
      dataIndex: "address",
      width: 300,
      render: (text, record) => {
        return (
          <VoiceComment
            record={record}
            againGetData={againGetData}
            courseId={courseId}
          />
        );
      },
    },
    {
      title: lang("student_reports.table.textComment"),
      dataIndex: "comment",
      // width: 150,
      editable: true,
      render: (text) => {
        return (
          <Tooltip placement="top" title={text}>
            <Typography.Text style={{ width: "100%" }} ellipsis>
              {text}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: lang(
        currentLocation === "cn"
          ? "student_reports.table.exhibition"
          : "student_reports.table.hightlight"
      ),
      width: currentLocation === "cn" ? 130 : 80,
      dataIndex: "highlight",
      hidden: !isProject,
      render: (text, record) => {
        return (
          <Checkbox
            defaultChecked={text === "Y"}
            disabled={!record.ur_id}
            onChange={(e) =>
              updateTableRow({
                record,
                _highlight: e.target.checked ? "Y" : "N",
              })
            }
          />
        );
      },
    },
    {
      title: lang("student_reports.table.record"),
      width: currentLocation === "cn" ? 100 : 150,
      dataIndex: "record",
      render: (text, record) => {
        return (
          <RecordUpload
            record={record}
            courseId={courseId}
            againGetData={againGetData}
          />
        );
      },
    },
  ];

  const transformColumns = columns
    .filter((_) => !_.hidden)
    .map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editable: col.editable,
          handleUpdate: updateTableRow,
        }),
      };
    });

  return (
    <EditableContext.Provider value={form}>
      <div className="studentReport_tablePage_root">
        <div className="editable_header">
          <Row type="flex" justify="start" align="middle" gutter={[10, 10]}>
            <Col md={6} sm={12} xs={24}>
              <Select
                style={{ width: "100%" }}
                value={classId}
                onChange={(class_id) => onSelect(class_id)}
                placeholder={lang("student_reports.selectedGrade")}
              >
                {classData.classList.map((item) => (
                  <Option
                    key={item.class_id}
                    value={item.class_id}
                    title={
                      item.year_name + " " + item.grade_name + item.class_name
                    }
                  >
                    {item.year_name + " " + item.grade_name + item.class_name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col md={3} sm={12} xs={24}>
              <Search
                placeholder={lang("student_reports.search")}
                style={{ width: "100%" }}
                onSearch={(value) => setSearchValue(value)}
              />
            </Col>
            <Col md={3} sm={12} xs={24}>
              <Select
                style={{ width: "100%" }}
                onChange={(value) => handleBatch("STAR", value)}
                placeholder={lang("student_reports.batch_star")}
                value={undefined}
                loading={batchLoading.STAR}
                dropdownClassName="batch_star_dropdown"
              >
                <OptGroup
                  label={lang("student_reports.table.score")}
                  icon={{ type: "caret-right" }}
                >
                  {icons.map((item, index) => (
                    <Option value={index + 1} key={index}>
                      <img
                        style={{ width: "80px", height: "80px" }}
                        src={require(`assets/image/${item}.png`)}
                        alt="icon"
                      />
                    </Option>
                  ))}
                </OptGroup>
                <Option
                  value="del"
                  key="del"
                  style={{ marginTop: "15px", color: "red" }}
                >
                  <Icon type="caret-right" />
                  {lang("student_reports.barch_star_del")}
                </Option>
              </Select>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Input
                placeholder={lang("student_reports.batch_comment")}
                style={{ width: "100%" }}
                value={batchValue}
                onChange={(e) => setBatchValue(e.target.value)}
                onPressEnter={() => handleBatch("COMMENT", batchValue)}
                disabled={batchLoading.COMMENT}
                suffix={
                  <Icon
                    type={batchLoading.COMMENT ? "loading" : "enter"}
                    onClick={() => handleBatch("COMMENT", batchValue)}
                  />
                }
              />
            </Col>
            <Col md={3} xs={24}>
              <TreeSelect
                style={{ width: "100%" }}
                placeholder="Please select"
                onChange={handleFilter}
                value={checked}
                treeDefaultExpandAll
              >
                <TreeNode
                  value="all"
                  title={lang("student_reports.all")}
                  key="all"
                />
                <TreeNode
                  value="submitY"
                  title={lang("student_reports.submitted")}
                  key="submitY"
                >
                  <TreeNode
                    value="scoreY"
                    title={lang("student_reports.hasScore")}
                    key="scoreY"
                  />
                  <TreeNode
                    value="scoreN"
                    title={lang("student_reports.notScore")}
                    key="scoreN"
                  />
                </TreeNode>
                <TreeNode
                  value="submitN"
                  title={lang("student_reports.notSubmitted")}
                  key="submitN"
                />
              </TreeSelect>
            </Col>
            <Col md={5} xs={24} className="right_box">
              {isProject && (
                <div>
                  {lang("student_reports.is_show_comment")}&nbsp;
                  <Switch
                    checked={isShowComment}
                    loading={isShowCommentLoading}
                    style={{ verticalAlign: "bottom" }}
                    onChange={handleSetRecordSetting}
                  />
                </div>
              )}
              <div className="btn_box">
                <Button
                  icon="unordered-list"
                  type="link"
                  onClick={() => setPage(1)}
                />
                <Button
                  icon="appstore"
                  type="link"
                  onClick={() => setPage(2)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="editable_table">
          {page === 1 && (
            <Table
              components={{
                body: {
                  cell: Cell,
                },
              }}
              loading={tableLoading}
              rowKey={(record) => record.ur_id || record.user_id}
              dataSource={newData}
              columns={transformColumns}
              scroll={{ y: 600, x: 1180 }}
              style={{ minHeight: 658 }}
              // bodyStyle={{ overflow: 'auto !important' }}
              pagination={{
                total: newData.length,
                style: {
                  margin: "16px auto",
                  width: "fit-content",
                  float: "none",
                },
              }}
              rowClassName={() => "editable-row"}
              rowSelection={{
                selectedRowKeys,
                getCheckboxProps: (record) => ({ disabled: !record.ur_id }),
                onChange: (selected) => setSelectedRowKeys(selected),
              }}
            />
          )}
          {page === 2 && (
            <PreviewListPage
              setCurrentUser={(ret) => setCurrentUser(ret)}
              data={newData}
              loading={tableLoading}
            />
          )}
        </div>
        <div className="editable_footer">
          {currentLocation === "cn" ? null : (
            <Button
              onClick={handleSendNotification}
              loading={sendLoading}
              disabled={!classId}
              icon="bell"
            >
              {lang("student_reports.send_notification")}
            </Button>
          )}
          <Button onClick={setVisible}>{lang("student_reports.done")}</Button>
        </div>
      </div>
    </EditableContext.Provider>
  );
};

export default Form.create()(TableListPage);
