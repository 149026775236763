import React from "react";
import { useSelector } from 'react-redux';
import { Card } from "antd";

import { useParams, useHistory } from "react-router-dom";

import WithSubMenuConfig from "components/common/HOC/WithSubMenuConfig";
import CoinPage from "../page/CoinPage";
import PersonalPage from "../page/PersonalPage";
import FavoritePage from "../page/FavoritePage";
import HistoryPage from "../page/HistoryPage";
import AchievementPage from "../page/AchievementPage";
import SettingPage from "../page/SettingPage";
import GamePage from "../page/GamePage";
import StatisticsPage from "../page/StatisticsPage";

import user_API from "components/services/userService";

import config from "../route";

const page = {
  personal: <PersonalPage />,
  favorite: <FavoritePage />,
  history: <HistoryPage />,
  statistics: <StatisticsPage />,
  achievement: <AchievementPage />,
  coin: <CoinPage />,
  game: <GamePage />,
  setting: <SettingPage />,
}

const ProfileContent = (props) => {
  // const { cur } = props;
  const { menuConfig } = props; // 通过 WithSubMenuConfig 传过来的
  const { operation } = useParams();
  const history = useHistory();

  const { route: { locationUrl } } = useSelector(state => state);

  // console.log(urlPath);

  const render = () => {
  
    const a = menuConfig.find(
      (item) =>
        item.icon === operation
        // (item.subMenu &&
        //   item.subMenu.find((subitem) => subitem.page === operation))
    );

    if(!a) {
      history.push(`${locationUrl}user/personal`);
      return;
    }

    // if (a && a.subMenu) {
    //   return a.subMenu.find((item) => item.page === operation).components;
    // }

    // return a.components;
    return page[a.icon];
  };

  return render() || <></>;
};

export default ProfileContent;
