import React, { useRef, useState, useEffect, Fragment } from 'react';
// import { useSelector } from 'react-redux';
import { Row, Col, Icon, Empty } from 'antd';
import Slider from 'react-slick';

import styles from 'assets/css/DrWongModal.module.scss';

const settings = {
  arrows: false,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Multi = (props) => {
  const sliderRef = useRef();
  const audioRef = useRef(new Audio());
  const { data, language, icon, isActive } = props;

  const [ page, setPage ] = useState(0);

  const [ paused, setPaused ] = useState(true);

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  }

  const handleNext = () => {
    sliderRef.current.slickNext();
  }
  // 播放音頻
  const handlePlay = (sound) => {
    audioRef.current.src = sound.file;
    audioRef.current.play();
  }

  // 幻燈片切換
  const onSlierChange = (index) => {
    try {
      setPage(index);
      const sound = data[index].sound.find(_ => _.vo_lang.toLowerCase() === language.vo_lang);
      if(sound) {
        handlePlay(sound);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    onSlierChange(0);
  }, [])

  useEffect(() =>{
    return () => {
      audioRef.current.pause();
    }
  }, [isActive])

  const pause = () => {
    console.log('pause');
    setPaused(true);
  }

  const play = () => {
    console.log('play');
    setPaused(false);
  }
  // 监听音频播放状态
  useEffect(() => {
    audioRef.current.addEventListener('pause', pause)
    audioRef.current.addEventListener('play', play)
    return () => {
      audioRef.current.removeEventListener('pause', pause);
      audioRef.current.removeEventListener('play', play);
    }
  }, [])


  return (
    <Row 
      type="flex" 
      justify="center"
      className={styles.multi}
    >
      {/* 左右按钮 */}
      {data.length > 1 && (
        <Fragment>
          {page > 0 && (
             <div
              onClick={handlePrev}
              className={`${styles.btn} ${styles.btnLeft}`}
            >
              <Icon type="left" />
            </div>
          )}
          {page + 1 < data.length && (
            <div
              onClick={handleNext}
              className={`${styles.btn} ${styles.btnRight}`}
            >
              <Icon type="right" />
            </div>
          )}
        </Fragment>
      )}
      {icon.length === 1 && (
        <img
          className={styles.mascot}
          src={(icon[0] || {}).file}
          alt="mascot"
        />
      )}
      {icon.length === 2 && (
        <Fragment>
          <img
            className={styles.mascot}
            src={(icon[0] || {}).file}
            alt="mascot"
            style={{ display: paused ? 'block' : 'none' }}
          />
          <img
            className={styles.mascot}
            src={(icon[1] || {}).file}
            alt="mascot"
            style={{ display: paused ? 'none' : 'block' }}
          />
        </Fragment>
      )}
      <Col span={18} className={styles.inner}>
        {/* 幻灯片 */}
        {data.length ? (
          <Slider 
            {...settings}
            ref={sliderRef}
            afterChange={onSlierChange}
            className={styles.multiSlider}
          >
            {data.map(item => {
              const lang = ((item.lang || []).find(_ => _.lang === language.lang) || {});
              const sound = ((item.sound || []).find(_ => _.vo_lang.toLowerCase() === language.vo_lang) || {});

              return (
                <div key={item.id} className={styles.sliderList}>
                  <div className={styles.imgBox}>
                    <img src={require("assets/image/drWong/multi/title.png")} alt="title"/>
                    {sound.file && (
                      <img 
                        src={require("assets/image/drWong/multi/audio.png")}
                        alt="audio"
                        onClick={() => handlePlay(sound)}
                      />
                    )}
                  </div>
                  <p className={styles.text}>
                    {lang.text}
                  </p>
                </div>
              )
            })}
          </Slider>
        ) : (
          <Empty />
        )}
      </Col>
    </Row>
  )
}

export default Multi;