import event from "components/services/eventService";
import date from "components/zoomCalendar/utils/dates";

const url = process.env.REACT_APP_API_URL_ZOOM;

export function addAction(form, callback) {
  return (dispatch) => {
    event
      .add(form)
      .then((ret) => {
        console.log(ret);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (callback) callback();
      });
  };
}

// actions event-list
export const EVENT_LIST = {
  // PADDING: "CALL_EVENT_LIST",
  SUCCES: "EVENT_LIST_SUCCES",
  ERROR: "EVENT_LIST_ERROR",
};

// export const CALL_LIST = EVENT_LIST.PADDING;

export function get_list(form) {
  return (dispatch) => {
    event
      .getList(form)
      .then((ret) => {
        console.log(ret);
        dispatch({ type: EVENT_LIST.SUCCES, payload: ret });
      })
      .catch((err) => {
        dispatch({ type: EVENT_LIST.ERROR, payload: err });
      })
      .finally(() => {
        // if (callback) callback();
      });
  };
}
// 新增和更改
export function editorAction(type, form, monthDate, callback) {
  return (dispatch) => {
    return event[type](form)
      .then((ret) => {
        if (callback) callback();
        if (monthDate) {
          dispatch(
            get_list({
              start: date.getTimeAlpha(monthDate[0], "YYYY-MM-DD"),
              end: date.getTimeAlpha(monthDate[1], "YYYY-MM-DD"),
            })
          );
        } else {
          dispatch(
            get_list({ start: date.getStartMonth(), end: date.getEndMonth() })
          );
        }
      })
      .catch((error) => {
        if (callback) callback(error);
        // if(error && error.result === '401') {
        //   window.location.href = url;
        // }
      });
    // .finally(() => {
    //   if (callback) callback();
    // });
  };
}
// 刪除
export function eventDelete(params, callback) {
  const { id, is_update_parent } = params;
  return (dispatch) => {
    return event.eventDelete(id, is_update_parent)
      .then(() => {
        if (callback) callback();
        dispatch(
          get_list({ start: date.getStartMonth(), end: date.getEndMonth() })
        );
      })
      .catch((error) => {
        if (callback) callback(error);
        // if(error && error.result === '401') {
        //   window.location.href = url;
        // }
      });
    // .finally(() => {
    //   if (callback) callback();
    // });
  };
}

// actions event-list
export const GET_INFO = {
  // PADDING: "CALL_EVENT_LIST",
  SUCCES: "GET_INFO_SUCCES",
  ERROR: "GET_INFO_ERROR",
  RESET: "GET_INFO_RESET",
};

// export const CALL_LIST = EVENT_LIST.PADDING;

export function getInfoAction(id, callback) {
  return (dispatch) => {
    event
      .get(id)
      .then((ret) => {
        dispatch({ type: GET_INFO.SUCCES, payload: ret });
        if (callback) callback(ret);
        return ret;
      })
      .catch((err) => {
        if (callback) callback();
        return err;
      });
  };
}
