import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import intl from "react-intl-universal";
import { Modal, Form, Input, Icon, message } from 'antd';

import auth from "components/services/authService";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const KidBind = (props) => {
  const { visible, onCancel, onSubmitAfter, form } = props;
  const { getFieldDecorator, validateFields, getFieldsError } = form;

  const { translations: { initDone } } = useSelector(state => state);

  const [ loading, setLoading ] = useState(false);

  const lang = (value) => initDone && intl.get(value); 

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (!err) {
        setLoading(true);
        auth.kidBind(values).then(() => {
          if(onSubmitAfter) onSubmitAfter(); 
        }).catch(error => {
          console.log(error);
          message.error('綁定失敗');
        }).finally(() => {
          setLoading(false);
        })
      }
    })
  }

  return (
    <Modal
      title={"KID綁定"}
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      okButtonProps={{
        loading,
        disabled: hasErrors(getFieldsError())
      }}
      okText={lang('general.button.confirm')}
      cancelText={lang('general.button.cancel')}
      destroyOnClose
    >
      <Form onSubmit={handleSubmit}>
        <Form.Item>
          {getFieldDecorator('user_account', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('user_password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />,
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Form.create()(KidBind);
